import React from 'react';

import { Logger } from '@vk-hr-tek/core/logger';

import { PageError } from '../page';

interface ErrorBoundaryProps {
  children: React.ReactNode | React.ReactNode[];
  logger: Logger;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(
    error: Error & { cause?: Error },
    { componentStack }: React.ErrorInfo,
  ) {
    this.props.logger.fatal(error, {
      tags: {
        vkdoc_error_type: 'unknown',
      },
      context: {
        react: {
          stack: componentStack,
        },
      },
    });
  }

  render() {
    if (this.state.hasError) {
      return <PageError status={500} />;
    }

    return this.props.children;
  }
}
