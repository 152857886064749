import { IsNotEmpty, IsUUID, IsString } from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class DeclineSignDto {
  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  eventId: string;

  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  nodeId: string;

  @IsString({
    ...notEmptyOptions,
    groups: ['form'],
  })
  comment: string;
}
