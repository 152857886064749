import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    day: {
      width: theme.px(32),
      height: theme.px(32),
      fontSize: theme.tokens.typography.variant.body3.fontSize,
      margin: `0 ${theme.tokens.spacing['2']}px`,
      color: theme.tokens.colors.text.light.primary,
      padding: 0,
      borderRadius: theme.tokens.radius.s,
      '&:hover': {
        backgroundColor: 'none',
        border: `${theme.tokens.border.s}px solid ${theme.tokens.colors.original.brand.primary}`,
      },
    },
    hidden: {
      opacity: 0,
      pointerEvents: 'none',
    },
    holiday: {
      color: theme.tokens.colors.accent.text.error,
    },
    current: {
      color: theme.tokens.colors.original.brand.primary,
      fontWeight: theme.tokens.typography.variant.subtitle1.fontWeight,
    },
    dayDisabled: {
      pointerEvents: 'none',
      color: theme.tokens.colors.text.light.tertirary,
    },
    dayWrapper: {
      padding: `0 ${theme.tokens.spacing['2']}px`,
    },
    dayWrapperInRange: {
      backgroundColor: theme.tokens.colors.original.brand.secondary,
      borderRadius: 0,
    },
    dayWrapperStart: {
      borderTopLeftRadius: theme.tokens.radius.s,
      borderBottomLeftRadius: theme.tokens.radius.s,
      paddingLeft: 0,
      marginLeft: theme.tokens.spacing['2'],
    },
    dayWrapperEnd: {
      borderTopRightRadius: theme.tokens.radius.s,
      borderBottomRightRadius: theme.tokens.radius.s,
      paddingRight: 0,
      marginRight: theme.tokens.spacing['2'],
    },
    daySelected: {
      color: theme.tokens.colors.text.dark.primary,
      backgroundColor: theme.tokens.colors.original.brand.primary,
      fontWeight: theme.typography.fontWeightMedium,
      '&:hover': {
        backgroundColor: theme.tokens.colors.original.brand.primary,
      },
    },
  }),
  { name: 'MuiPickersDay' },
);

export default useStyles;
