/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import { FieldProps, FieldRenderProps, useField } from 'react-final-form';
import { Collapse } from '@material-ui/core';

import { TextInput as Input } from '../../input';

import { Autorecognition } from './Autorecognition';

const TextInput = ({
  label,
  name,
  onChange,
  type,
  mask,
  placeholder,
  id,
  multiline = false,
  rows = 1,
  showError = true,
  required = false,
  tooltip,
  after,
  disabled = false,
  loading = false,
  clearable = false,
  recognizedValue,
  isRecognitionUsedBefore = false,
  validationWithoutTouch = false,
  inputMode,
  autocomplete,
  testId,
  ...rest
}: { after?: (value: string | undefined) => React.ReactNode } & FieldProps<
  string | undefined,
  FieldRenderProps<string | undefined>
>) => {
  const { input, meta } = useField(name, { ...rest });
  const inputChange = input.onChange;

  const handleChange = useCallback(
    (value: string | undefined) => {
      inputChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [inputChange, onChange],
  );

  const renderError = validationWithoutTouch
    ? !!input.value && !!meta.error
    : meta.touched && !!meta.error;

  return (
    <div>
      <Autorecognition
        recognizedValue={recognizedValue}
        isRecognitionUsedBefore={isRecognitionUsedBefore}
        onBlur={input.onBlur}
        disabled={disabled}
        setValue={input.onChange}
        value={input.value}
        mask={mask}
      >
        <Input
          {...input}
          testId={testId}
          label={label}
          required={required}
          disabled={disabled}
          value={input.value}
          onChange={handleChange}
          type={type}
          error={renderError}
          helperText={showError && renderError && meta.error}
          placeholder={placeholder}
          tooltip={tooltip}
          multiline={multiline}
          rows={rows}
          mask={mask}
          loading={loading}
          clearable={clearable}
          inputMode={inputMode}
          autocomplete={autocomplete}
        />
        {after && typeof after === 'function' && (
          <Collapse in={meta.visited}>{after(input.value)}</Collapse>
        )}
      </Autorecognition>
    </div>
  );
};

export default TextInput;
