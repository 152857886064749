import React, { useEffect, useState, ReactElement, useCallback } from 'react';

import MUILink from '@material-ui/core/Link';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

import { FileType } from '@vk-hr-tek/core/file-type';
import { ImageConverter } from '@vk-hr-tek/core/image-converter';
import { useInject } from '@vk-hr-tek/core/ioc';

import { useIsDesktop } from '../hooks';
import { Box } from '../Box';
import { Button } from '../Button';
import { CircularProgress } from '../CircularProgress';
import { Dialog } from '../Dialog';
import { Link } from '../Link';
import { Snackbar } from '../Snackbar';
import { SigFileIcon, XmlFileIcon } from '../icons';

import { PreviewImage, PreviewPdf, PreviewDocx } from './Previews';
import { useStyles } from './FilePreviewContent.styles';

interface FilePreviewContentProps {
  onLoad: () => Promise<Blob>;
  shape?: 'auto' | 'square';
  onClickPrint?: () => void;
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  onError?: (e: Error | undefined) => void;
  onClickOpen?: () => void;
  onLoadSuccess?: () => void;
  isClickable?: boolean;
  withCollapsableFooter?: boolean;
  view?: 'file' | 'fileLarge' | 'link';
  showSnackbarOnDesktop?: boolean;
  snackbarMessage?: string;
  snackbarAction?: string;
  linkText?: string | ReactElement;
  hideLoader?: boolean;
  withFlex?: boolean;
  centerImage?: boolean;
}

interface FileWithPath extends File {
  path: string;
}

export const FilePreviewContent = ({
  onLoad,
  shape = 'auto',
  onClickPrint,
  onClickDownload,
  onError,
  isClickable = false,
  onClickOpen,
  onLoadSuccess,
  withCollapsableFooter,
  view = 'file',
  showSnackbarOnDesktop = false,
  snackbarMessage,
  snackbarAction,
  linkText,
  hideLoader = false,
  withFlex = false,
  centerImage = false,
}: FilePreviewContentProps) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const imageConverter = useInject(ImageConverter);
  const fileTypeService = useInject(FileType);
  const clickable = isClickable;

  const [error, setError] = useState(false);
  const [blob, setBlob] = useState<Blob | null>(null);
  const [blobType, setBlobType] = useState<string>();
  const [objectUrl, setObjectUrl] = useState<string>('');
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const getBlobType = useCallback(
    async (file: Blob) => {
      const result = await fileTypeService.fileTypeFromBlob(file);
      setBlobType(result);
    },
    [fileTypeService],
  );

  const handleOpen = useCallback(() => {
    if (onClickOpen) {
      onClickOpen();
    } else {
      setIsDialogOpened(true);
    }
  }, [onClickOpen]);

  const handleClose = useCallback(() => {
    setIsDialogOpened(false);
  }, []);

  useEffect(() => {
    let isCancelled = false;
    onLoad()
      .then(async (file) => {
        const fileType = await fileTypeService.fileTypeFromBlob(file);

        if (
          [
            'image/heic',
            'image/heif',
            'image/tiff',
            'image/dng',
            'image/x-adobe-dng',
          ].includes(fileType)
        ) {
          return imageConverter.convert(file);
        }

        return file;
      })
      .then(async (file) => {
        if (!isCancelled) {
          await getBlobType(file);
          setBlob(file);
          setError(false);

          if (onError) {
            onError(undefined);
          }
        }
      })
      .catch((e) => {
        if (!isCancelled) {
          setError(true);

          if (onError) {
            onError(e);
          }
        }
      });

    return () => {
      isCancelled = true;
    };
  }, [onLoad, imageConverter, onError, getBlobType, fileTypeService]);

  useEffect(() => {
    if (blob !== null) {
      const newObjectUrl = URL.createObjectURL(blob);
      setObjectUrl(newObjectUrl);
      return () => URL.revokeObjectURL(newObjectUrl);
    }
  }, [blob]);

  if (error) {
    return (
      <Box
        p="32"
        color="text.light.secondary"
        textAlign="center"
        className="aqa_file_preview_error"
      >
        Ошибка при загрузке файла
      </Box>
    );
  }

  if (objectUrl === null || blob === null) {
    if (hideLoader) {
      return null;
    }

    return (
      <Box
        display="flex"
        justifyContent="center"
        p="32"
        className="aqa_file_preview_loader"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (blobType === 'application/pdf') {
    return (
      <>
        {clickable ? (
          <>
            {view === 'file' ? (
              <Box
                className={`${classes.documentCursor} aqa_file_preview_pdf_wrapper`}
              >
                <PreviewPdf
                  url={objectUrl}
                  shape={shape}
                  onClickPrint={onClickPrint}
                  onOpen={handleOpen}
                  onClickDownload={onClickDownload}
                  withCollapsableFooter={withCollapsableFooter}
                  onLoadSuccess={onLoadSuccess}
                />
              </Box>
            ) : view === 'fileLarge' ? (
              <Box
                p="24"
                py="16"
                bgcolor="bg.greyscale.primary"
                radius="l"
                maxHeight={400}
                overflow="hidden"
                position="relative"
                className="aqa_file_preview_pdf_wrapper"
              >
                <PreviewPdf
                  url={objectUrl}
                  shape={shape}
                  onClickDownload={onClickDownload}
                  onLoadSuccess={onLoadSuccess}
                />
                <MUILink onClick={handleOpen}>
                  <Box
                    position="absolute"
                    bottom={0}
                    left={0}
                    right={0}
                    bgcolor="bg.dark.primary"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p="12"
                  >
                    <Box color="bg.light.primary">{linkText}</Box>
                    <Box color="bg.light.primary">
                      <ArrowForwardIos fontSize="small" />
                    </Box>
                  </Box>
                </MUILink>
              </Box>
            ) : (
              <Link onClick={handleOpen} stroke={false} size="small">
                {linkText || 'Просмотр'}
              </Link>
            )}
            <Dialog
              scroll="body"
              onClose={handleClose}
              open={isDialogOpened}
              fullScreen={!isDesktop}
              className="aqa_file_preview_dialog"
              classes={{
                paper: classes.dialogPaper,
              }}
            >
              <PreviewPdf
                url={objectUrl}
                shape="auto"
                onLoadSuccess={onLoadSuccess}
              />
            </Dialog>
            {(showSnackbarOnDesktop || !isDesktop) && (
              <Snackbar
                open={isDialogOpened}
                message={snackbarMessage || ''}
                action={
                  <Box display="flex">
                    <Button onClick={handleClose} size="small">
                      {snackbarAction || 'Закрыть'}
                    </Button>
                  </Box>
                }
                className="aqa_file_preview_snackbar"
                variant={!isDesktop ? 'static' : 'sticky'}
              />
            )}
          </>
        ) : (
          <PreviewPdf
            url={objectUrl}
            shape={shape}
            onClickPrint={onClickPrint}
            onClickDownload={onClickDownload}
            withCollapsableFooter={withCollapsableFooter}
            onLoadSuccess={onLoadSuccess}
          />
        )}
      </>
    );
  }

  if (blobType?.startsWith('image/')) {
    return (
      <>
        {withFlex ? (
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <PreviewImage
              url={objectUrl}
              shape={shape}
              clickable={clickable}
              onClickDownload={onClickDownload}
              withCollapsableFooter={withCollapsableFooter}
              centerImage={centerImage}
            />
          </Box>
        ) : (
          <PreviewImage
            url={objectUrl}
            shape={shape}
            clickable={clickable}
            onClickDownload={onClickDownload}
            withCollapsableFooter={withCollapsableFooter}
            centerImage={centerImage}
          />
        )}
      </>
    );
  }

  if (blobType === 'text/xml' || blobType === 'application/xml') {
    return (
      <Box>
        <Box
          px="32"
          py="48"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <XmlFileIcon />
        </Box>
      </Box>
    );
  }

  if (
    blobType === 'application/msword' ||
    blobType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return (
      <PreviewDocx
        shape={shape}
        onClickDownload={onClickDownload}
        withCollapsableFooter={withCollapsableFooter}
      />
    );
  }

  if ((blob as FileWithPath)?.path?.endsWith('.sig')) {
    return (
      <Box>
        <Box
          px="32"
          py="48"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <SigFileIcon />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      p="32"
      color="text.light.secondary"
      textAlign="center"
      className="aqa_file_preview_unsupported"
    >
      Неподдерживаемый тип файла {blobType || blob.type}
    </Box>
  );
};
