import { IsNotEmpty, IsUUID } from 'class-validator';

export class GetDocumentHashDto {
  @IsNotEmpty()
  @IsUUID()
  eventId: string;

  @IsNotEmpty()
  @IsUUID()
  documentId: string;
}
