import React from 'react';

import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FilterNone';

import { IconButton } from '../IconButton';

interface ContentCopyProps {
  content: string | null;
}

const ContentCopy = ({ content }: ContentCopyProps) => (
  <>
    {content && (
      <TextField
        type="text"
        value={content}
        variant="outlined"
        className="aqa_copy_content"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton
              type="button"
              size="medium"
              onClick={() => {
                navigator.clipboard.writeText(content);
              }}
            >
              <FileCopyIcon color="primary" fontSize="medium" />
            </IconButton>
          ),
        }}
      />
    )}
  </>
);

export default ContentCopy;
