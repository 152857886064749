import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import {
  AppStoreIcon,
  GooglePlayIcon,
  RuStoreIcon,
  AppGalleryIcon,
} from '@vk-hr-tek/ui/icons';

import {
  APP_GALLERY_LINK,
  APP_STORE_LINK,
  GOOGLE_PLAY_LINK,
  RU_STORE_LINK,
} from './Links.constants';

export const Links = () => (
  <>
    <Box maxHeight={24}>
      <Link href={APP_STORE_LINK} variant="simple">
        <AppStoreIcon />
      </Link>
    </Box>
    <Box maxHeight={24}>
      <Link href={GOOGLE_PLAY_LINK} variant="simple">
        <GooglePlayIcon />
      </Link>
    </Box>
    <Box maxHeight={24}>
      <Link href={RU_STORE_LINK} variant="simple">
        <RuStoreIcon />
      </Link>
    </Box>
    <Box maxHeight={24}>
      <Link href={APP_GALLERY_LINK} variant="simple">
        <AppGalleryIcon />
      </Link>
    </Box>
  </>
);
