import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useInject } from '@vk-hr-tek/core/ioc';
import { TokenService } from '@vk-hr-tek/core/http';

export function useAuth(): boolean {
  const location = useLocation();
  const token = useInject<TokenService>(TokenService);
  const [isAuthenticated, setAuth] = useState(!!token.get());

  useEffect(() => {
    setAuth(!!token.get());
  }, [location, token]);

  return isAuthenticated;
}
