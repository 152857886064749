import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ palette, tokens, px }) => ({
  multipleAutocompleteInput: {
    width: '100%',
    marginBottom: tokens.spacing['16'],
  },

  input: {
    '& .MuiInputBase-root': {
      paddingRight: 0,
      lineHeight: 1.5,
      backgroundColor: tokens.colors.bg.light.primary,

      '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        padding: 0,
      },

      '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot .MuiInputBase-input': {
        height: px(24),
        padding: tokens.spacing['16'],
      },

      '& .MuiSelect-iconOutlined': {
        right: tokens.spacing['16'],
      },

      '&.Mui-focused': {
        '& .multipleAutocompleteClearIcon': {
          display: 'block',
        },

        '& fieldset': {
          borderColor: tokens.colors.original.brand.primary,
          borderWidth: tokens.border.s,
        },
      },

      '&.Mui-error': {
        backgroundColor: tokens.colors.accent.bg.error,

        '& fieldset': {
          borderColor: tokens.colors.accent.stroke.error,
        },

        '&.Mui-focused': {
          '& fieldset': {
            borderColor: tokens.colors.accent.text.error,
          },
        },

        '&:hover': {
          '& fieldset': {
            borderColor: palette.error.hover,
          },
        },
      },

      '&.Mui-disabled': {
        backgroundColor: tokens.colors.bg.greyscale.secondary,

        '& fieldset': {
          borderColor: tokens.colors.stroke.primary,
        },
      },

      '& fieldset': {
        borderColor: tokens.colors.stroke.primary,
      },

      '&:hover': {
        '& .multipleAutocompleteClearIcon': {
          display: 'block',
        },

        '& fieldset': {
          borderColor: tokens.colors.stroke.secondary,
        },
      },

      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiAutocomplete-endAdornment': {
        position: 'relative',
        display: 'flex',

        '& .MuiAutocomplete-popupIndicator': {
          marginLeft: -tokens.spacing['2'],

          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },

      '& ul.MuiAutocomplete-listbox': {
        padding: 0,
        margin: `${tokens.spacing['8']}px 0`,
        maxHeight: px(270),
        overflow: 'auto',
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  option: {
    fontSize: tokens.typography.variant.body3.fontSize,
    lineHeight: tokens.typography.variant.body3.lineHeight,
    paddingTop: tokens.spacing['4'] - px(1),
    paddingRight: tokens.spacing['16'],
    paddingBottom: tokens.spacing['4'] - px(1),
    paddingLeft: tokens.spacing['16'],
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
  },

  listItemLabel: {
    pointerEvents: 'auto',
  },

  popupIndicator: {
    padding: tokens.spacing['2'],
    marginRight: -tokens.spacing['2'],
  },

  popupIndicatorOpen: {
    transform: 'rotate(180deg)',
  },

  paper: {
    maxHeight: px(276),

    '& [aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '& [data-focus="true"]': {
      backgroundColor: `${palette.action.hover}`,
    },
    '& [aria-disabled="true"]': {
      cursor: 'default',
      opacity: palette.action.disabledOpacity,
      pointerEvents: 'none',
    },
  },

  checkboxLabel: {
    color: tokens.colors.text.light.secondary,
    fontSize: `${tokens.typography.variant.body3.fontSize}px !important`,
  },

  clearIcon: {
    display: 'none',
    width: px(24),
    height: px(24),
    cursor: 'pointer',
  },
}));

export default useStyles;
