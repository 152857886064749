import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

const SVGStatusCreatedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <rect width="16" height="16" rx="8" fill="#99A2AD" />
      <g clipPath="url(#clip0_2989_17500)">
        <path
          d="M8.40319 5.00005C8.40319 4.59124 8.08907 4.30005 7.70159 4.30005C7.31411 4.30005 7 4.61345 7 5.00005L7.00731 8.60104C7.04587 8.86572 7.23397 9.08917 7.49596 9.16931L10.2032 9.98944C10.5625 10.094 10.875 9.83831 10.9783 9.50229C11.0628 9.13254 10.899 8.74588 10.5132 8.62786L8.40319 7.98205V5.00005Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2989_17500">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export const StatusCreatedIcon = ({
  size = 'small',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGStatusCreatedIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
