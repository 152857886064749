import { TransformFnParams } from 'class-transformer';

import { Filter } from './filter.entity';

export function filtersTransform(filters: Filter[]) {
  return ({ value }: TransformFnParams) => {
    if (typeof value !== 'object' || Array.isArray(value) || !value) {
      return value;
    }

    return Object.keys(value).reduce((acc, filter) => {
      const booleanFilters = filters
        .filter(({ type }) => type === 'bool')
        .map(({ key }) => key);

      const toBoolean = (val: unknown) => {
        if (val === 'true') {
          return true;
        }

        if (val === 'false') {
          return false;
        }

        return val;
      };

      return {
        ...acc,
        [filter]: booleanFilters.includes(filter)
          ? toBoolean(value[filter])
          : value[filter],
      };
    }, {});
  };
}
