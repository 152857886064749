import React from 'react';

import locale from 'date-fns/locale/ru';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';

import { DateFnsUtils } from '@vk-hr-tek/core/date-fns-utils';

import { GlobalStyles, createTheme } from './config';
import { Theme } from './types';

type ThemeProviderProps = Theme & {
  children: React.ReactNode | React.ReactNode[];
};

export const ThemeProvider = ({ children, ...rest }: ThemeProviderProps) => {
  return (
    <MUIThemeProvider theme={createTheme(rest)}>
      <GlobalStyles />
      <CssBaseline />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        {children}
      </MuiPickersUtilsProvider>
    </MUIThemeProvider>
  );
};
