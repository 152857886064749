import React from 'react';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Box } from '@vk-hr-tek/ui/Box';

import { useSelector } from '@app/hooks';

import { MobileMenu } from '../MobileMenu';
import { User } from '../../user';
import { selectUserRepresentative } from '../../../user';

import { useStyles } from './Header.styles';

export const Header = () => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();

  const userRepresentative = useSelector(selectUserRepresentative);

  const headerPaddingY = isDesktop ? '12' : '8';

  const headerPaddingCondition =
    window.REACT_APP_VKHRTEK_LK_DISABLED && !userRepresentative
      ? '16'
      : headerPaddingY;

  const justifyContentCondition = isDesktop ? 'flex-end' : 'space-between';

  return (
    <Box className={classes.header} py={headerPaddingCondition}>
      <Box
        justifyContent={justifyContentCondition}
        alignItems="center"
        display="flex"
        gap="16"
      >
        {!isDesktop && (
          <Box display="flex">
            <MobileMenu />
          </Box>
        )}

        <Box>
          <User />
        </Box>
      </Box>
    </Box>
  );
};
