import { Palette } from '@material-ui/core/styles/createPalette';

export enum ChipSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extraSmall = 'extraSmall',
}

export enum ChipColor {
  gray = 'gray',
  darkgray = 'darkgray',
  green = 'green',
  orange = 'orange',
  red = 'red',
  blue = 'blue',
  purple = 'purple',
}

export type ChipColorUnion = keyof typeof ChipColor;

export type SizeScheme = {
  [key in keyof typeof ChipSize]: {
    height: number;
    borderRadius: number;
    counter: {
      marginLeft: number;
    };
    icon: {
      fontSize: number;
      width: number;
      height: number;
      marginRight: number;
      marginLeft: number;
    };
    padding: string;
    font: {
      size: number | string;
      lineHeight: string | number;
    };
  };
};

export type ColorScheme = {
  [key in keyof typeof ChipColor]: {
    color: (palette: Palette) => string;
    bgColor: (palette: Palette) => string;
  };
};
