import React from 'react';

import { InputAdornment } from '@material-ui/core';

import { CancelIcon } from '../../../icons';
import { Box } from '../../../Box';

interface ClearButtonProps {
  className?: string;
  color?: 'disabled' | 'error';
  onClick: () => void;
}

export const ClearButton = ({
  className = '',
  color = 'disabled',
  onClick,
}: ClearButtonProps) => (
  <InputAdornment className={className} position="end">
    <Box onClick={onClick}>
      <CancelIcon color={color} />
    </Box>
  </InputAdornment>
);
