import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { UserRouter } from '../../../user/types';
import { modules } from '../../modules';

@injectable()
export class UserRouterService implements UserRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goBack(prevState: { pathname: string; search: string }) {
    this.history.push(prevState);
  }

  redirectToHome() {
    this.history.push('/');
  }

  goToCreateCertificate() {
    this.history.push(`/${modules.create_certificate.basename}`);
  }

  goToProfile(sectionId?: string) {
    if (sectionId) {
      this.history.push(`/${modules.user.basename}#${sectionId}`);
    } else {
      this.history.push(`/${modules.user.basename}`);
    }
  }

  goToProfileAttorney({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(`/${modules.user.basename}/${companyId}/attorney/${id}`);
  }

  goToProfileAttorneySettings(companyId: string) {
    this.history.push(`/${modules.user.basename}/${companyId}/attorney`);
  }

  goToProfileSubstitutes({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }) {
    this.history.push(
      `/${modules.user.basename}/substitutes?employeeId=${employeeId}&companyId=${companyId}`,
    );
  }

  goToProfileApprovalSettings({
    employeeId,
    companyId,
    unitId,
  }: {
    employeeId: string;
    companyId: string;
    unitId: string;
  }) {
    const params = [
      `employeeId=${employeeId}`,
      `companyId=${companyId}`,
      `unitId=${unitId}`,
    ].join('&');

    this.history.push(`/${modules.user.basename}/approval-settings?${params}`);
  }
}
