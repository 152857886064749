import { injectable } from 'inversify';
import printJS from 'print-js';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

@injectable()
export class PrintService {
  /** Вывести файлы на печать
   * @param {Blob[]} files
   * @returns { Promise<unknown>}
   * */
  async print(files: Blob[]) {
    const mergedPdf = await PDFDocument.create();
    const fontUrl = '/Roboto-Regular.ttf';
    const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer());

    for (const file of files) {
      const pdfDoc = await PDFDocument.load(await file.arrayBuffer());

      pdfDoc.registerFontkit(fontkit);
      const font = await pdfDoc.embedFont(fontBytes);

      const form = pdfDoc.getForm();
      form.updateFieldAppearances(font);
      form.flatten();

      const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices(),
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }

    const document = await mergedPdf.save();

    return new Promise((resolve, reject) => {
      printJS({
        printable: URL.createObjectURL(
          new window.Blob([document], {
            type: 'application/pdf',
          }),
        ),
        onPdfOpen: () => resolve(null),
        onPrintDialogClose: () => resolve(null),
        onError: (error) => reject(error),
      });
    });
  }
}
