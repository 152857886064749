import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { PageError } from '../layout';

import {
  CreateCertificate,
  Profile,
  Substitutes,
  ApprovalSettings,
  AttorneySettings,
  AttorneyDetail,
} from './pages';

export const User = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path="/create-certificate">
        <CreateCertificate />
      </Route>
      <Route exact path="/profile">
        <Profile />
      </Route>
      <Route exact path="/profile/substitutes">
        <Substitutes />
      </Route>
      <Route exact path="/profile/approval-settings">
        <ApprovalSettings />
      </Route>
      <Route exact path="/profile/:companyId/attorney">
        <AttorneySettings />
      </Route>
      <Route exact path="/profile/:companyId/attorney/:id">
        <AttorneyDetail />
      </Route>
      <Route path={`${match.url}`}>
        <div>
          <PageError status={404} />
        </div>
      </Route>
    </Switch>
  );
};
