import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Content } from '@vk-hr-tek/ui/Content';
import { useInject } from '@vk-hr-tek/core/ioc';
import { UserApiService } from '@vk-hr-tek/app/user/services';
import { FilePreview } from '@vk-hr-tek/ui/FilePreview';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Grid } from '@vk-hr-tek/ui/Grid';

import { Links } from './Links';
import { Step } from './Step';

export const MobileApp = () => {
  const userApiService = useInject(UserApiService);
  const isDesktop = useIsDesktop();

  const loadQr = async () => {
    const res = await userApiService.getAppQr();

    return res.file;
  };

  return (
    <Content>
      <Box px={{ xs: '16', md: '32' }} py={{ xs: '24', md: '32' }}>
        <Grid container spacing="24" direction={isDesktop ? 'row' : 'column'}>
          <Grid item xs={12} md={4} alignContent="center">
            <Box display="flex" flexDirection="column" gap="8">
              <Box>
                <Typography variant="h6">
                  Мобильное приложение VK HR Tek
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textTertiary">
                  {isDesktop
                    ? 'Начните пользоваться приложением, выполнив всего два простых шага'
                    : 'Сервисы'}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Step
              label={isDesktop ? 'Шаг 1' : ''}
              text={
                isDesktop
                  ? 'Скачай приложение по ссылке:'
                  : 'Скачать приложение:'
              }
            >
              {isDesktop ? (
                <Paper>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    gap="16"
                    p="16"
                    width={96}
                    height={96}
                  >
                    <Links />
                  </Box>
                </Paper>
              ) : (
                <Box display="flex" gap="12">
                  <Links />
                </Box>
              )}
            </Step>
          </Grid>
          {isDesktop && (
            <Grid item xs={12} md={4}>
              <Step label="Шаг 2" text="Наведи камеру телефона на QR-код:">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width={96}
                  height={96}
                >
                  <Paper>
                    <FilePreview onLoad={loadQr} />
                  </Paper>
                </Box>
              </Step>
            </Grid>
          )}
        </Grid>
      </Box>
    </Content>
  );
};
