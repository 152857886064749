import { IsNotEmpty, IsUUID } from 'class-validator';

export class GetUnitTreeChildren {
  @IsUUID()
  @IsNotEmpty()
  unitId: string;

  @IsUUID()
  @IsNotEmpty()
  companyId: string;
}
