import { Notification as NotificationResponse } from '../gen/notifications';

export enum NotificationActionTypeEnum {
  Redirect = 'redirect',
  Refresh = 'refresh',
}

export type NotificationData = {
  title?: string;
  subtitle?: string;
  message: string;
  type: 'info' | 'error';
  action?: {
    text: string;
    link?: string;
    actionType: NotificationActionTypeEnum;
  };
  count?: number;
  group?: 'eventSigning';
};

export type Notification = NotificationData & { id: number };

export interface NotificationsMapper {
  mapNotifications(
    data: NotificationResponse[],
    currentEventId?: string,
  ): NotificationData[];
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationsMapper = Symbol('NotificationsMapper');

export interface GroupingStrategy {
  groupNotifications(notifications: Notification[]): Notification[];
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupingStrategy = Symbol('GroupingStrategy');

export interface NotificationsEventEmitter {
  onShow(notification: NotificationData): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotificationsEventEmitter = Symbol('NotificationsEventEmitter');
