import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  {
    submenuHeight: number;
    expanded: boolean;
  }
>(({ tokens, transitions }) => ({
  menuItemWithSubmenu: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: tokens.spacing['16'],
    padding: tokens.spacing['8'],
    borderRadius: tokens.radius.s,
    overflow: 'hidden',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },
  },

  popupMenuItem: {
    alignItems: 'center',
    width: '100%',
    gap: tokens.spacing['16'],
    paddingTop: tokens.spacing['12'],
    paddingRight: tokens.spacing['16'],
    paddingBottom: tokens.spacing['12'],
    paddingLeft: tokens.spacing['16'],
    borderRadius: tokens.radius.s,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },
  },

  dropdownMenu: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    opacity: ({ expanded }) => (expanded ? 1 : 0),
    height: ({ submenuHeight }) => submenuHeight,
    transition: `all ${transitions.easing.easeInOut} 0.5s`,
    willChange: 'height',
  },

  submenuItem: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginLeft: tokens.spacing['8'],
    paddingTop: tokens.spacing['8'],
    paddingRight: tokens.spacing['12'],
    paddingBottom: tokens.spacing['8'],
    paddingLeft: tokens.spacing['40'],
    borderRadius: tokens.radius.s,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },
  },

  active: {
    backgroundColor: tokens.colors.accent.bg.blue,
    '&:hover': {
      backgroundColor: tokens.colors.accent.bg.blue,
    },
  },

  submenu: {
    '& .MuiMenu-paper': {
      '& .MuiList-root': {
        pointerEvents: 'auto',
      },

      '&::-webkit-scrollbar': {
        width: 0,
      },
    },
  },

  absoluteIcon: {
    position: 'absolute',
    fontSize: tokens.typography.variant.caption.fontSize,
    display: 'flex',
    top: tokens.spacing['12'] + tokens.spacing['2'],
    left: tokens.spacing['24'] + tokens.spacing['4'],
  },

  menuItemExpandIcon: {
    transform: ({ expanded }) => `rotate(${expanded ? 180 : 0}deg)`,
    transition: `transform ${transitions.easing.easeInOut} 0.5s`,
  },

  popOverRoot: {
    pointerEvents: 'none',
  },
}));
