import { useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { useDispatch, useIsWidget, useSelector } from '@app/hooks';

import { AuthConfigService, AuthOpenIdService } from '../services';
import {
  getAuthInstance,
  selectAuthInstance,
  selectAuthInstanceError,
  selectAuthInstanceStatus,
} from '../slice';

export const useAuthType = () => {
  const dispatch = useDispatch();
  const config = useInject(AuthConfigService);
  const openIdConfig = useInject(AuthOpenIdService);
  const isWidget = useIsWidget();

  const status = useSelector(selectAuthInstanceStatus);
  const error = useSelector(selectAuthInstanceError);
  const authInstance = useSelector(selectAuthInstance);

  let localAuthType = 'unknown';

  if (isWidget) {
    localAuthType = openIdConfig.get() ? 'openid' : 'default';
  } else {
    localAuthType = config.getAuthType();
  }

  useEffect(() => {
    if (
      localAuthType === 'unknown' &&
      !authInstance?.auth_type &&
      ['idle'].includes(status)
    ) {
      dispatch(getAuthInstance());
    }
  }, [dispatch, localAuthType, status, authInstance?.auth_type]);

  return {
    status: localAuthType !== 'unknown' ? 'complete' : status,
    error,
    authType:
      localAuthType !== 'unknown' ? localAuthType : authInstance?.auth_type,
    oauth2Source: authInstance?.oauth2_source,
  };
};
