import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme, { maxWidth?: number }>((theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.px(6)}px ${theme.tokens.spacing['12']}px`, // TODO - fix нет в токенах
    borderRadius: theme.tokens.radius.m,
    border: `${theme.tokens.border.s}px solid ${theme.tokens.colors.accent.text.blue}`,
    color: `${theme.tokens.colors.original.brand.primary}`,
    backgroundColor: `${theme.tokens.colors.original.brand.secondary}`,
    cursor: 'pointer',
  },
  chip: {
    backgroundColor: theme.tokens.colors.bg.greyscale.primary,
    color: theme.tokens.colors.text.light.primary,
  },
  chipError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.secondary.main,

    '&:active, &:focus': {
      backgroundColor: theme.palette.error.light,
    },
  },
  iconError: {
    color: theme.palette.secondary.main,

    '&:hover': {
      color: theme.palette.error.dark,
    },
  },
  chipInput: ({ maxWidth }) => ({
    maxWidth: maxWidth ? maxWidth : 'unset',
  }),
}));
