import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';

import { ChatService } from '../chat.service';
import { ThunkExtra } from '../../utils';

import { ChatState } from './chat.state';

export const setStatus = createAction<
  'idle' | 'loading' | 'complete' | 'failed'
>('chat/setStatus');

export const loadChat = createAsyncThunk<
  void,
  undefined,
  ThunkExtra<{ chat: ChatState }>
>('core/chat/loadChat', async (_, { dispatch, extra: { inject } }) => {
  try {
    dispatch(setStatus('loading'));

    const service = inject(ChatService);

    const credentials = await service.getSupportCredentials();

    const script = document.createElement('script');

    window.webim = {
      accountName: window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME,
      domain: `${window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME}.webim.ru`,
      location: 'hr_box',
    };
    window.webim_visitor = credentials;

    script.type = 'text/javascript';
    script.defer = true;
    script.src = `https://${window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME}.webim.ru/js/button.js`;
    document.head.appendChild(script);

    const intervalId = setInterval(() => {
      if (window.webim.api && document.querySelector('.webim-button-corner')) {
        dispatch(setStatus('complete'));
        window.webim.api.chat.start();
        clearInterval(intervalId);
      }
    }, 100);
  } catch (e) {
    dispatch(setStatus('failed'));
  }
});

export const chatReducers = (builder: ActionReducerMapBuilder<ChatState>) => {
  builder.addCase(setStatus, (state, action) => {
    state.status = action.payload;
  });
};
