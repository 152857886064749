import {
  IsArray,
  IsNotEmpty,
  IsUUID,
  IsDate,
  IsOptional,
  IsString,
  IsBoolean,
} from 'class-validator';
import { Type } from 'class-transformer';

import type { FileInputValue } from '@vk-hr-tek/ui/input';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class CreatePolicyDto {
  @IsNotEmpty(notEmptyOptions)
  name: string;

  @IsNotEmpty(notEmptyOptions)
  file: FileInputValue;

  @IsUUID()
  @IsNotEmpty(notEmptyOptions)
  companyId: string;

  @IsOptional()
  unitId?: string;

  @IsOptional()
  unitType?: string;

  @IsArray()
  @Type(() => String)
  @IsOptional()
  positions?: string[];

  @IsDate()
  @IsNotEmpty(notEmptyOptions)
  activeFrom: string;

  @IsDate()
  @IsNotEmpty(notEmptyOptions)
  activeTo: string;

  @IsDate()
  @IsNotEmpty(notEmptyOptions)
  documentDate: string;

  @IsOptional()
  documentNumber?: string;

  @IsNotEmpty(notEmptyOptions)
  signatureType: 'pep' | 'unep';

  @IsBoolean()
  @IsNotEmpty(notEmptyOptions)
  withOrder: boolean;

  @IsString()
  @IsOptional()
  govDocument?: string;

  @IsString()
  @IsOptional()
  govDocumentTypeCode?: string;

  @IsBoolean()
  @IsOptional()
  hideIfAccepted?: boolean;
}
