import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { Container } from '../../../container';

interface PageErrorVkProps {
  error?: { title: string; description: string } | null;
}

export const PageErrorVk = ({ error }: PageErrorVkProps) => (
  <Container theme="vk">
    <Box
      bgcolor="bg.light.primary"
      borderColor="stroke.primary"
      border={1}
      p="32"
      radius="l"
    >
      <Box mb="32" display="flex" justifyContent="center">
        <Typography variant="h5" align="center">
          {error ? error.title : 'Неизвестная ошибка'}
        </Typography>
      </Box>
      <Box
        color="text.light.secondary"
        display="flex"
        justifyContent="center"
        textAlign="center"
      >
        <Typography variant="body2">
          {error ? (
            <span dangerouslySetInnerHTML={{ __html: error.description }} />
          ) : (
            'Упс! Что-то пошло не так'
          )}
        </Typography>
      </Box>
    </Box>
  </Container>
);
