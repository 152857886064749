import React, { createContext, ReactNode } from 'react';

export const InfiniteScrollContext = createContext<string | null>(null);

interface InfiniteScrollProviderProps {
  target?: string;
  children: ReactNode | ReactNode[];
}

export const InfiniteScrollProvider = ({
  target,
  children,
}: InfiniteScrollProviderProps) => (
  <InfiniteScrollContext.Provider value={target || null}>
    {children}
  </InfiniteScrollContext.Provider>
);
