import React from 'react';

export const DocFileIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.581 16.5H26.3205C25.7534 16.4995 25.2094 16.7243 24.8081 17.1249C24.4069 17.5256 24.1811 18.0692 24.1807 18.6362V28.1587L44.0346 33.9881L62.7226 28.1587V18.6362C62.7222 18.0689 62.4962 17.525 62.0945 17.1243C61.6928 16.7236 61.1484 16.4991 60.581 16.5Z"
        fill="#41A5EE"
      />
      <path
        d="M62.7226 28.1582H24.1807V39.8169L44.0346 43.3146L62.7226 39.8169V28.1582Z"
        fill="#2B7CD3"
      />
      <path
        d="M24.1807 39.8184V51.4771L42.8669 53.8088L62.7226 51.4771V39.8184H24.1807Z"
        fill="#185ABD"
      />
      <path
        d="M26.3205 63.1353H60.5792C61.1469 63.1367 61.6918 62.9123 62.0939 62.5116C62.496 62.1109 62.7222 61.5667 62.7226 60.9991V51.4766H24.1807V60.9991C24.1811 61.5661 24.4069 62.1097 24.8081 62.5103C25.2094 62.911 25.7534 63.1358 26.3205 63.1353Z"
        fill="#103F91"
      />
      <path
        opacity="0.1"
        d="M38.39 25.8262H24.1807V54.973H38.39C39.5682 54.9671 40.5228 54.015 40.5316 52.8368V27.9624C40.5228 26.7842 39.5682 25.832 38.39 25.8262Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M37.2223 26.9922H24.1807V56.139H37.2223C38.4006 56.1332 39.3551 55.181 39.3639 54.0028V29.1284C39.3551 27.9502 38.4006 26.998 37.2223 26.9922V26.9922Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M37.2223 26.9922H24.1807V53.8073H37.2223C38.4006 53.8014 39.3551 52.8493 39.3639 51.671V29.1284C39.3551 27.9502 38.4006 26.998 37.2223 26.9922V26.9922Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M36.0546 26.9922H24.1807V53.8073H36.0546C37.2329 53.8014 38.1874 52.8493 38.1963 51.671V29.1284C38.1874 27.9502 37.2329 26.998 36.0546 26.9922V26.9922Z"
        fill="black"
      />
      <path
        d="M14.6416 26.9922H36.0542C37.2353 26.9912 38.1939 27.9473 38.1958 29.1284V50.5052C38.1939 51.6863 37.2353 52.6424 36.0542 52.6414H14.6416C14.0743 52.6424 13.5298 52.4178 13.1281 52.0171C12.7264 51.6164 12.5005 51.0725 12.5 50.5052V29.1284C12.5005 28.5611 12.7264 28.0172 13.1281 27.6165C13.5298 27.2158 14.0743 26.9912 14.6416 26.9922Z"
        fill="url(#paint0_linear_32908_14075)"
      />
      <path
        d="M21.2885 43.3826C21.3298 43.7126 21.3585 43.9996 21.371 44.2453H21.4213C21.4392 44.0121 21.4787 43.7305 21.5378 43.4023C21.597 43.0741 21.6491 42.796 21.6975 42.5682L23.9485 32.87H26.8614L29.1931 42.423C29.329 43.0139 29.426 43.613 29.4837 44.2166H29.5232C29.566 43.6291 29.6469 43.0449 29.7653 42.4678L31.6289 32.8574H34.2781L31.0065 46.7546H27.9089L25.6901 37.5604C25.6256 37.2949 25.5526 36.9493 25.4713 36.5236C25.39 36.098 25.3398 35.7871 25.3207 35.5909H25.283C25.2579 35.8169 25.2077 36.1524 25.1323 36.5972C25.057 37.0438 24.9978 37.372 24.953 37.5873L22.8669 46.76H19.7173L16.4277 32.87H19.1182L21.1468 42.588C21.2074 42.8503 21.2547 43.1155 21.2885 43.3826V43.3826Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_32908_14075"
          x1="2.44354"
          y1="33.6727"
          x2="19.147"
          y2="62.7059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2368C4" />
          <stop offset="0.5" stopColor="#1A5DBE" />
          <stop offset="1" stopColor="#1146AC" />
        </linearGradient>
      </defs>
    </svg>
  );
};
