import React from 'react';

import MUIMenuItem from '@material-ui/core/MenuItem';

import { useStyles } from './MenuItem.styles';

interface MenuItemProps {
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  onClick?(): void;
  testId?: string;
}

export const MenuItem = ({
  children,
  onClick,
  disabled,
  testId,
}: MenuItemProps) => {
  const classes = useStyles();

  return (
    <MUIMenuItem
      disabled={disabled}
      data-testid={testId}
      onClick={onClick}
      className={classes.menuItem}
    >
      {children}
    </MUIMenuItem>
  );
};
