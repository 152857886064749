import React, { forwardRef } from 'react';

import MUIPaper, { PaperProps } from '@material-ui/core/Paper';

export const Paper = forwardRef<
  HTMLElement,
  JSX.IntrinsicAttributes & PaperProps
>((props: JSX.IntrinsicAttributes & PaperProps, ref) => {
  return (
    <MUIPaper
      {...props}
      ref={ref}
      className={props.className ? `${props.className} aqa_paper` : 'aqa_paper'}
    >
      {props.children}
    </MUIPaper>
  );
});
