import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  createAction,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { TokenService } from '@vk-hr-tek/core/http';
import { AppError } from '@vk-hr-tek/core/error';

import { UserLoginResponse as AuthResponse } from '@app/gen/users';

import { AuthAliRegisterService, AuthRouterService } from '../../../services';
import { RegisterAliInitDto, RegisterAliDto } from '../../../dto';
import {
  AuthState,
  startLoading,
  completeLoading,
  resetState,
  clearState,
  setError,
  ThunkExtra,
} from '../../auth.state';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const registerAliInit = createAsyncThunk<
  void,
  {
    values: RegisterAliInitDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerAliInit',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { snils } = state.values;
      const { passCode } = state;

      await inject(AuthAliRegisterService).registerInit({
        snils: snils || undefined,
        passCode: passCode || undefined,
        phone: values.phone,
      });

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const changePhoneAliInit = createAsyncThunk<
  void,
  {
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/changePhoneAliInit',
  async ({ actions }, { rejectWithValue, getState, extra: { inject } }) => {
    try {
      const state = getState().auth;

      const { passCode } = state;

      await inject(AuthAliRegisterService).changePhoneInit({
        passCode: passCode || undefined,
      });

      actions.resolve(null);
      inject(AuthRouterService).redirectToPhoneChange();
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setSnilsAli = createAction<RegisterAliInitDto>('auth/setSnilsAli');

export const registerAli = createAsyncThunk<
  AuthResponse,
  {
    values: RegisterAliDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerAli',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { phone } = state.values;
      const { passCode } = state;

      const result = await inject(AuthAliRegisterService).register({
        phone: phone || undefined,
        code: values.code,
        passCode: passCode || undefined,
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(1000);

      actions.resolve(null);
      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setStageAli = createAction<
  'init' | 'verification' | 'finish' | 'error'
>('auth/setStageAli');

export const aliRegisterReducers = (
  builder: ActionReducerMapBuilder<AuthState>,
) => {
  builder.addCase(registerAliInit.pending, (state, action) => {
    startLoading(state);
    state.values.phone = action.meta.arg.values.phone || null;
  });
  builder.addCase(registerAliInit.fulfilled, (state) => {
    state.stage = 'finish';

    completeLoading(state);
  });
  builder.addCase(registerAliInit.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
  builder.addCase(setSnilsAli, (state, action) => {
    state.values.snils = action.payload.snils || null;
    state.stage = 'verification';
  });

  builder.addCase(registerAli.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(registerAli.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(registerAli.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(changePhoneAliInit.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(changePhoneAliInit.fulfilled, (state) => {
    clearState(state);
  });
  builder.addCase(changePhoneAliInit.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(setStageAli, (state, action) => {
    state.stage = action.payload;
  });
};
