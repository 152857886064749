import React, { useEffect } from 'react';

import { useQuery } from '@vk-hr-tek/core/query';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { useDispatch } from '../../../app/hooks';
import { GetCreateParamsDto } from '../../dto';
import { getCompanyEventTypes } from '../../slice';

import { Substitutes as SubstitutesDesktop } from './desktop';
import { Substitutes as SubstitutesMobile } from './mobile';

export const Substitutes = () => {
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();
  const [company] = useQuery<GetCreateParamsDto>(GetCreateParamsDto);

  const { companyId, employeeId } = company;

  useEffect(() => {
    if (isDesktop) {
      dispatch(getCompanyEventTypes({ companyId, employeeId }));
    }
  }, [companyId, employeeId, dispatch, isDesktop]);

  return isDesktop ? (
    <SubstitutesDesktop companyId={companyId} employeeId={employeeId} />
  ) : (
    <SubstitutesMobile />
  );
};
