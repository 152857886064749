import { useContext } from 'react';

import { useMediaQuery } from '@material-ui/core';

import { RootWidthContext } from '../../rootWidth';

export const useMatchQuery = (query: string) => {
  const rootWidth = useContext(RootWidthContext);
  const mediaQuery = useMediaQuery(query);

  if (typeof rootWidth === 'number') {
    const isMinWidth = query.includes('min-width');
    const isMaxWidth = query.includes('max-width');
    const queryWidth = parseInt(query.match(/\d+/) as unknown as string);

    if (isMinWidth) {
      return rootWidth >= queryWidth;
    }
    if (isMaxWidth) {
      return rootWidth <= queryWidth;
    }
  }

  return mediaQuery;
};
