import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './auth.state';
import {
  authReducers,
  inviteReducers,
  registerReducers,
  loginReducers,
  changePasswordReducers,
  routerReducers,
  oauth2Reducers,
  openIdReducers,
  aliCodeReducers,
  aliRegisterReducers,
  loginAliReducers,
  loginCandidateReducers,
} from './actions';

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    authReducers(builder);
    inviteReducers(builder);
    registerReducers(builder);
    loginReducers(builder);
    changePasswordReducers(builder);
    routerReducers(builder);
    oauth2Reducers(builder);
    openIdReducers(builder);
    aliCodeReducers(builder);
    aliRegisterReducers(builder);
    loginAliReducers(builder);
    loginCandidateReducers(builder);
  },
});

export const authReducer = auth.reducer;
