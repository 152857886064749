import {
  createAction,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { QueryService } from '@vk-hr-tek/core/query';
import { AppError } from '@vk-hr-tek/core/error';
import { TokenService } from '@vk-hr-tek/core/http';
import { History } from '@vk-hr-tek/core/history';

import {
  OAuth2VerifyResponse,
  UserLoginResponse as AuthResponse,
} from '@app/gen/users';

import {
  AuthState,
  completeLoading,
  setError,
  startLoading,
  ThunkExtra,
} from '../../auth.state';
import { AuthRouterService, OAuth2Service } from '../../../services';
import { RegisterOAuth2Dto, RegisterOAuth2InitDto } from '../../../dto';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const clearNotification = createAction<void>('auth/clearNotification');

export const setAuthCode = createAction<string | null>('auth/setAuthCode');

export const verifyOAuth2Code = createAsyncThunk<
  OAuth2VerifyResponse,
  void,
  ThunkExtra
>(
  'auth/verifyOAuth2Code',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    const router = inject(AuthRouterService);
    const history = inject<History>(History);

    try {
      const { auth_code: authCode } = inject(QueryService).parse(
        history.location.search,
      );

      dispatch(setAuthCode(authCode));

      const result = await inject(OAuth2Service).verifyOAuth2Code({
        authCode,
      });

      if (result.status === 'registration') {
        router.redirectToRegister();
      } else if (result.status === 'phone_change') {
        router.redirectToPhoneChange();
      } else {
        if (!result.access_token) {
          throw new AppError('server', {
            code: 500,
            message: 'Пустой токен',
          });
        }

        inject<TokenService>(TokenService).save(result.access_token);

        await delay(1000);
        router.redirectToLogin();
      }

      return result;
    } catch (err) {
      router.redirectToLogin();
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const registerInitOAuth2 = createAsyncThunk<
  void,
  {
    values: RegisterOAuth2InitDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerInitOAuth2',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { authCode } = state;

      await inject(OAuth2Service).registerInitOAuth2({
        authCode: authCode || undefined,
        phone: values.phone,
      });

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const registerOAuth2 = createAsyncThunk<
  AuthResponse,
  {
    values: RegisterOAuth2Dto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerOAuth2',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { authCode } = state;

      const result = await inject(OAuth2Service).registerOAuth2({
        code: values.code,
        authCode: authCode || undefined,
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(1000);

      actions.resolve(null);
      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const oauth2Reducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(setAuthCode, (state, action) => {
    state.authCode = action.payload;
  });

  builder.addCase(verifyOAuth2Code.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(verifyOAuth2Code.fulfilled, (state) => {
    completeLoading(state);
    state.stage = 'init';
  });
  builder.addCase(verifyOAuth2Code.rejected, (state, { payload }) => {
    if (payload && payload.errorCode === 'not_found_employee') {
      state.stage = 'error';
      setError(state, {
        payload: {
          title: 'Вас пока не подключили к сервису',
          description:
            'Если вы считаете, что должны быть подключены, пожалуйста, <a href="mailto:hrbox-support@lists.vk.team">напишите нам</a>',
        },
      });
    } else {
      setError(state, { payload });
    }
  });

  builder.addCase(registerInitOAuth2.pending, (state, action) => {
    startLoading(state);

    state.values.phone = action.meta.arg.values.phone;
  });
  builder.addCase(registerInitOAuth2.fulfilled, (state) => {
    state.stage = 'finish';

    completeLoading(state);
  });
  builder.addCase(registerInitOAuth2.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(registerOAuth2.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(registerOAuth2.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(clearNotification, (state) => {
    state.notification = null;
  });
};
