import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
} from 'class-validator';

export class GetCatalogAttributesDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  eventId: string;

  @IsUUID()
  @IsString()
  @IsNotEmpty()
  attributeId: string;

  @IsNumber()
  @IsOptional()
  limit?: number;

  @IsNumber()
  @IsOptional()
  offset?: number;

  @IsString()
  @IsOptional()
  query?: string;

  @IsOptional()
  @IsUUID()
  id?: string;
}
