import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens, px }) => ({
  menuList: {
    padding: `${tokens.spacing['8']}px 0`,
    maxHeight: px(360),
  },
  paper: {
    marginTop: tokens.spacing['8'],
    marginBottom: tokens.spacing['8'],
  },
}));
