import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';

import {
  ChangePasswordInitDto,
  ChangePasswordDto,
  VerifyChangePasswordPhoneCodeDto,
} from '../../dto';

@injectable()
export class AuthPasswordService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
  ) {}

  async changePasswordInit(changePasswordInitDto: ChangePasswordInitDto) {
    await this.validator.validateOrReject(
      changePasswordInitDto,
      ChangePasswordInitDto,
    );

    await this.http.post(
      '/user/password/init_change',
      {
        phone: changePasswordInitDto.phone,
        snils: changePasswordInitDto.snils
          ? changePasswordInitDto.snils.replace(/(-| )/g, '')
          : /* istanbul ignore next */
            '',
      },
      { withAuth: false },
    );
  }

  async verifyChangePasswordPhoneCode(
    verifyChangePasswordPhoneCodeDto: VerifyChangePasswordPhoneCodeDto,
  ) {
    await this.validator.validateOrReject(
      verifyChangePasswordPhoneCodeDto,
      VerifyChangePasswordPhoneCodeDto,
    );

    const { code, phone } = verifyChangePasswordPhoneCodeDto;

    await this.http.post(
      '/user/password/verify_code',
      {
        phone,
        code,
      },
      { withAuth: false },
    );
  }

  async changePassword(changePasswordDto: ChangePasswordDto) {
    await this.validator.validateOrReject(changePasswordDto, ChangePasswordDto);

    await this.http.put(
      '/user/password',
      {
        credentials: {
          phone_verification: {
            phone: changePasswordDto.phone,
            code: changePasswordDto.code,
          },
          password: changePasswordDto.password,
        },
        snils: changePasswordDto.snils
          ? changePasswordDto.snils.replace(/(-| )/g, '')
          : /* istanbul ignore next */
            '',
      },
      { withAuth: false },
    );
  }
}
