import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tokens, px }) => ({
  badge: {
    '& .MuiBadge-badge': {
      border: `${tokens.border.m}px solid ${tokens.colors.bg.light.primary}`,
      background: tokens.colors.accent.text.error,
      borderRadius: '50%',
      width: px(12),
      height: px(12),
    },
  },

  avatar: {
    textTransform: 'uppercase',
    backgroundColor: tokens.colors.original.brand.primary,
    color: tokens.colors.text.dark.primary,
  },

  extraSmall: {
    width: px(24),
    height: px(24),
    fontSize: 8, // TODO - fix нет в токенах
  },

  small: {
    width: px(32),
    height: px(32),
    fontSize: tokens.typography.variant.overline.fontSize,
  },

  medium: {
    width: px(40),
    height: px(40),
    fontSize: tokens.typography.variant.caption.fontSize,
  },

  large: {
    width: px(56),
    height: px(56),
    fontSize: tokens.typography.variant.body2.fontSize,

    '& + .MuiBadge-badge': {
      height: px(14),
      width: px(14),
    },
  },

  extraLarge: {
    width: px(64),
    height: px(64),
    fontSize: tokens.typography.variant.body1.fontSize,

    '& + .MuiBadge-badge': {
      height: px(14),
      width: px(14),
    },
  },
}));
