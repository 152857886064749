import { IsString, IsPhoneNumber, IsNotEmpty } from 'class-validator';

import { REQUIRED_FIELD_VALIDATION_MESSAGE } from '../../auth.constants';

export class RegisterOAuth2InitDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
    groups: ['form'],
  })
  phone: string;

  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  authCode?: string;
}
