import { useEffect, useRef } from 'react';

/** Хук возвращает предыдущее сохраненное между рендерами значение
 *  @template T
 *  @param {T} value
 *  @returns {T|undefined}
 *  */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
