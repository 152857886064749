import React, { createContext, ReactNode } from 'react';

import { InjectReducer } from '../../app/types';

export const InjectReducerContext = createContext<InjectReducer | null>(null);

interface InjectReducerContextProviderProps {
  injectReducer: InjectReducer;
  children: ReactNode | ReactNode[];
}

export const InjectReducerContextProvider = ({
  injectReducer,
  children,
}: InjectReducerContextProviderProps) => (
  <InjectReducerContext.Provider value={injectReducer}>
    {children}
  </InjectReducerContext.Provider>
);
