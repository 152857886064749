import React, { ReactNode } from 'react';

import classNames from 'classnames';
import { FormControlLabel } from '@material-ui/core';

import { Box } from '../../Box';
import { Checkbox } from '../../Checkbox';
import { Label } from '../common';
import { TypographyColor } from '../../Typography';

import useStyles from './CheckboxInput.styles';

interface CheckboxInputProps {
  checked?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  indeterminate?: boolean;
  label: string;
  labelPlacement?: 'start' | 'end';
  labelColor?: TypographyColor;
  labelVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  onChange?: (value: boolean) => void;
  tooltip?: ReactNode | null;
  testId?: string;
}

export const CheckboxInput = ({
  checked,
  disabled = false,
  fullWidth = false,
  indeterminate,
  label,
  labelPlacement = 'end',
  labelColor = 'textSecondary',
  labelVariant = 'body3',
  onChange = () => {},
  tooltip,
  testId,
  ...rest
}: CheckboxInputProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classNames(
        classes.checkboxInput,
        'aqa_checkbox_input',
        fullWidth && classes.fullWidth,
      )}
      control={
        <Checkbox
          {...rest}
          checked={checked}
          disabled={disabled}
          indeterminate={indeterminate}
          onChange={onChange}
          testId={testId}
        />
      }
      label={
        <Box
          mr={labelPlacement === 'start' ? '8' : '0'}
          ml={labelPlacement === 'end' ? '8' : '0'}
        >
          <Label
            label={label}
            tooltip={tooltip}
            color={labelColor}
            variant={labelVariant}
            mb="0"
          />
        </Box>
      }
      labelPlacement={labelPlacement}
    />
  );
};
