import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';

import { ThunkExtra } from '@app/store';

import { VerifyCodeDto } from '../../dto';
import { UserApiService } from '../../services';
import { UserState } from '../user.state';

export const initCertificateCreation = createAsyncThunk<
  void,
  {
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'user/certificateCreationInit',
  async ({ actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(UserApiService).certificateCreationInit();

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const initCertificateCreationEsia = createAsyncThunk<
  void,
  {
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'user/certificateCreationInitEsia',
  async ({ actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(UserApiService).certificateCreationInit();

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const verifyCertificateCreation = createAsyncThunk<
  void,
  {
    values: VerifyCodeDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'user/certificateCreationVerify',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(UserApiService).certificateCreationVerify(
        plainToClass(VerifyCodeDto, { code: values.code }),
      );

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const certificateReducers = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(initCertificateCreation.pending, (state) => {
    state.processes.certificateCreationInit = {
      error: null,
      inProgress: true,
    };
  });
  builder.addCase(initCertificateCreation.fulfilled, (state) => {
    state.processes.certificateCreationInit.inProgress = false;
    if (state.data) {
      if (state.data.certificate.unep_type === 'cryptopro_simple') {
        state.data.certificate.status = 'verifying';
      } else {
        state.data.certificate.status = 'need_verify_code';
      }
    }
  });
  builder.addCase(
    initCertificateCreation.rejected,
    (state, { payload, error }) => {
      state.processes.certificateCreationInit = {
        error:
          /* istanbul ignore next */
          payload ||
          ({
            info: (error && error.message) || 'Unknown error',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError),
        inProgress: false,
      };
    },
  );
  builder.addCase(initCertificateCreationEsia.pending, (state) => {
    state.processes.certificateCreationInit = {
      error: null,
      inProgress: true,
    };
  });
  builder.addCase(initCertificateCreationEsia.fulfilled, (state) => {
    state.processes.certificateCreationInit.inProgress = false;
    if (state.data) {
      state.data.certificate.status = 'waiting_confirmation';
    }
  });
  builder.addCase(
    initCertificateCreationEsia.rejected,
    (state, { payload, error }) => {
      state.processes.certificateCreationInit = {
        error:
          /* istanbul ignore next */
          payload ||
          ({
            info: (error && error.message) || 'Unknown error',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError),
        inProgress: false,
      };
    },
  );

  builder.addCase(verifyCertificateCreation.pending, (state) => {
    state.processes.certificateCreationVerify = {
      error: null,
      inProgress: true,
    };
  });
  builder.addCase(verifyCertificateCreation.fulfilled, (state) => {
    state.processes.certificateCreationVerify.inProgress = false;
    if (state.data) {
      state.data.certificate.status = 'verifying';
    }
  });
  builder.addCase(
    verifyCertificateCreation.rejected,
    (state, { payload, error }) => {
      state.processes.certificateCreationVerify = {
        error:
          /* istanbul ignore next */
          payload ||
          ({
            info: (error && error.message) || 'Unknown error',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError),
        inProgress: false,
      };
    },
  );
};
