import React from 'react';

import { Form } from 'react-final-form';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos';

import { ValidationService } from '@vk-hr-tek/core/validation';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { FormError, TextInput } from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';

import { useDispatch, useSelector } from '@app/hooks';

import { ChangePasswordInitDto } from '../../../dto';
import {
  selectNotification,
  selectStatus,
  changePasswordInit,
  redirectToSendInviteForPhone,
  redirectToLogin,
  selectAuthInstance,
} from '../../../slice';
import { Button } from '../../../ui/Button';

export const ChangePasswordInit = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: ChangePasswordInitDto) =>
    new Promise((resolve) => {
      dispatch(
        changePasswordInit({
          values,
          actions: { resolve },
        }),
      );
    });

  return (
    <Form
      validate={(values) => validator.validate(values, ChangePasswordInitDto)}
      onSubmit={onSubmit}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <TextInput
            name="snils"
            label="СНИЛС"
            placeholder="Введите свой СНИЛС"
            mask="999-999-999 99"
            id="qaAuthChangePasswordInitSnilsInput"
          />
          <Box mt="8">
            <TextInput
              name="phone"
              label="Номер телефона"
              placeholder="+7"
              mask="+7 (999) 999-99-99"
              id="qaAuthChangePasswordInitPhoneInput"
            />
          </Box>
          <Box mt="32">
            <Button
              color={buttonColor && `#${buttonColor}`}
              disabled={pristine || hasValidationErrors}
              loading={submitting}
            >
              Продолжить
            </Button>
          </Box>
          {!dirtySinceLastSubmit && status && status === 'failed' && (
            <FormError error={error} />
          )}
          <Box
            mt="8"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              onClick={() => {
                dispatch(redirectToLogin());
              }}
              size="small"
              variant="tertiary"
              startIcon={<ArrowBackIcon />}
            >
              Вернуться назад
            </Link>
            <Link
              onClick={() => dispatch(redirectToSendInviteForPhone())}
              size="small"
              variant="tertiary"
            >
              Я сменил телефон
            </Link>
          </Box>
        </form>
      )}
    />
  );
};
