import { Palette } from '@material-ui/core/styles/createPalette';

export enum LinkSize {
  large = 'large',
  medium = 'medium',
  small = 'small',
  extraSmall = 'extraSmall',
}

export type SizeScheme = {
  [key in keyof typeof LinkSize]: {
    underlineOffset: string;
    icon: {
      fontSize: number | string;
      width: number;
      height: number;
      marginRight: number;
      marginLeft: number;
    };
  };
};

export enum LinkColor {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  success = 'success',
  error = 'error',
  simple = 'simple',
}

export type ColorScheme = {
  [key in keyof typeof LinkColor]: {
    color: (palette: Palette) => string;
    hoveredColor: (palette: Palette) => string;
  };
};

export type LinkStroke = boolean | 'custom';
