import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { PageError } from '../../../layout';

import { Login } from './Login';
import { VerifyInvite } from './VerifyInvite';
import { Register } from './Register';
import { ChangePassword } from './ChangePassword';
import { SendInvite } from './SendInvite';

export const GeneralAuth = (): JSX.Element => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/login`}>
        <Login />
      </Route>
      <Route exact path={`${match.url}/register`}>
        <Register />
      </Route>
      <Route exact path={`${match.url}/invite`}>
        <VerifyInvite />
      </Route>
      <Route exact path={`${match.url}/send-invite`}>
        <SendInvite />
      </Route>
      <Route exact path={`${match.url}/change-password`}>
        <ChangePassword />
      </Route>
      <Route path={`${match.url}`}>
        <div>
          <PageError status={404} />
        </div>
      </Route>
    </Switch>
  );
};
