import { createEntityAdapter } from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';

import {
  Link,
  QuickActionCreateEvent,
  WidgetTasksResponse,
  Widget,
  CalendarWidgetCompany,
  BirthdayEmployee,
  WidgetPartition,
} from '@app/gen/dashboard';

export interface DashboardState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  error: AppError | null;
  layout: Widget[];
  fastActions: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    actions: QuickActionCreateEvent[];
    error: AppError | null;
  };
  fastActionData: {
    modalType: string;
    companyIds?: string[];
    eventTypeIds?: string[];
  } | null;
  electronicServices: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: Link[];
  };
  partitionsEmployee: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: WidgetPartition[];
  };
  partitionsCompany: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: WidgetPartition[];
  };
  taskList: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    items: WidgetTasksResponse['tasks'];
  };
  calendar: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    birthdays: BirthdayEmployee[];
    companies: CalendarWidgetCompany[];
    companyId: string | undefined;
    includedEmployees: string[];
  };
}

export const initialState: DashboardState = {
  status: 'idle',
  error: null,
  layout: [],
  fastActions: {
    status: 'idle',
    actions: [],
    error: null,
  },
  fastActionData: null,
  electronicServices: {
    status: 'idle',
    items: [],
  },
  partitionsEmployee: {
    status: 'idle',
    items: [],
  },
  partitionsCompany: {
    status: 'idle',
    items: [],
  },
  taskList: {
    status: 'idle',
    items: [],
  },
  calendar: {
    status: 'idle',
    birthdays: [],
    companies: [],
    companyId: undefined,
    includedEmployees: [],
  },
};

export const dashboardAdapter = createEntityAdapter<unknown>();
