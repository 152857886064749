import { UserRoleEnum, UserRoles } from './types';

export const ATTORNEYS_POWERS_DEFAULT = 'MINTRUD_MIN01';

export const ROLES: UserRoles[] = [
  {
    role: UserRoleEnum.Company,
    title: 'Сервисы компании',
  },
  {
    role: UserRoleEnum.Employee,
    title: 'Сервисы сотрудника',
  },
];
