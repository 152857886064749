import React from 'react';

import { useTheme } from '@material-ui/core';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

const SVGStatusApprovedIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <rect
        width="16"
        height="16"
        rx="8"
        fill={theme.tokens.colors.accent.text.success}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0864 5.53975C10.306 5.75942 10.306 6.11558 10.0864 6.33525L5.58637 10.8352C5.3667 11.0549 5.01054 11.0549 4.79087 10.8352L2.91475 8.95913C2.69508 8.73946 2.69508 8.38331 2.91475 8.16364C3.13442 7.94397 3.49058 7.94397 3.71025 8.16364L5.18862 9.642L9.29087 5.53975C9.51054 5.32008 9.8667 5.32008 10.0864 5.53975Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2727 5.53976C13.4924 5.75943 13.4924 6.11558 13.2727 6.33525L8.77275 10.8353C8.55308 11.0549 8.19692 11.0549 7.97725 10.8353C7.75758 10.6156 7.75758 10.2594 7.97725 10.0398L12.4773 5.53976C12.6969 5.32009 13.0531 5.32009 13.2727 5.53976Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const StatusApprovedIcon = ({
  size = 'small',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGStatusApprovedIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
