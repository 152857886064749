import { FORM_ERROR } from 'final-form';
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createAction,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { QueryService } from '@vk-hr-tek/core/query';
import { UnitProcessor, UnitService } from '@vk-hr-tek/core/units';
import type {
  UnitType,
  UnitTypeOption,
  UnitNodeLabeled,
} from '@vk-hr-tek/core/units';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { showNotification } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import {
  CreateEventOptionsList as OptionsResponse,
  CreateEventCompanyItem,
  EventListItem as EventListEntity,
  CreateEventResponse as CreateResponse,
  EventBatchEmployee as EmployeeItem,
  EventTypeItem,
  EventBatchItem,
  CreateEventTypeOptions,
} from '@app/gen/events';

import { ThunkExtra, ThunkValuesAndActions } from '../../../app/store';
import { EventsListService } from '../../services';
import { EventsRouter } from '../../types';
import { EventsState, EventsWithRootState } from '../events.state';
import {
  CreateEventDto,
  GetEventBatchOptionsDto,
  CreateEventBatchDto,
  GetCreateEventTypeOptionsDto,
  GetOptionsDto,
} from '../../dto';

const PromiseAllIgnoreErrors = async <T>(
  promises: Promise<T>[],
): Promise<T[]> => {
  const result = await Promise.all(
    promises.map(async (promise) => {
      try {
        const resolved = await promise;
        return resolved;
      } catch (e) {
        return null;
      }
    }),
  );

  return result.filter((promiseResult) => promiseResult !== null) as T[];
};

export const getCanCreateEvent = createAsyncThunk<
  boolean,
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCanCreateEvent',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreateOptionsCompany();

      return res.items.some(async (item) => {
        const [employeesRes, eventTypesRes] = await Promise.all([
          await service.getEventEmployees({
            companyId: item.company_id,
            limit: 1,
            offset: 0,
          }),
          await service.getCreateBatchEventTypes({
            companyId: item.company_id,
          }),
        ]);

        return (
          employeesRes?.employees?.length || eventTypesRes?.event_types?.length
        );
      });
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setCreateCompanyItems = createAction<
  (CreateEventCompanyItem & {
    event_types: EventTypeItem[];
  })[]
>('events/setCreateCompanyItems');

export const createEvent = createAsyncThunk<
  CreateResponse,
  ThunkValuesAndActions<CreateEventDto>,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const eventsService = inject(EventsListService);
      const result = await eventsService.createEvent(values);

      inject<EventsRouter>(EventsRouter).goToDetail(result.event_id);

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventCompany = createAsyncThunk<
  CreateResponse,
  ThunkValuesAndActions<CreateEventDto>,
  ThunkExtra<EventsWithRootState>
>(
  'createEventCompany',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      const eventsService = inject(EventsListService);
      const result = await eventsService.createEvent(values);

      inject<EventsRouter>(EventsRouter).goToDetail(result.event_id);

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getOptions = createAsyncThunk<
  OptionsResponse,
  GetOptionsDto | undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getOptions',
  async (values, { rejectWithValue, extra: { inject } }) => {
    try {
      return await inject(EventsListService).getCreateOptions(values);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getOptionsCompany = createAsyncThunk<
  {
    items: (CreateEventCompanyItem & {
      event_types: EventTypeItem[];
    })[];
  },
  { batchEnabled: boolean; noEmployee: boolean; parentEventId?: string },
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getOptionsCompany',
  async (payload, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);
      const res = await service.getCreateOptionsCompany();

      const itemsWithEventTypes = await PromiseAllIgnoreErrors(
        res.items.map(async (item) => {
          const eventTypesRes = await service.getCreateBatchEventTypes({
            companyId: item.company_id,
            noEmployee: payload.noEmployee,
            batchEnabled: payload.batchEnabled,
            parentEventId: payload.parentEventId,
          });

          return {
            ...item,
            event_types: eventTypesRes.event_types,
          };
        }),
      );

      return { items: itemsWithEventTypes };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreateEventTypeOptions = createAsyncThunk<
  CreateEventTypeOptions,
  GetCreateEventTypeOptionsDto,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getCreateEventTypeOptions',
  async (
    getCreateEventTypeOptionsDto,
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const {
        events: { createEventTypeOptions },
      } = getState();
      const { event_type_id: eventTypeId } = getCreateEventTypeOptionsDto;
      const optionById = createEventTypeOptions.options[eventTypeId];

      if (optionById) {
        return {
          assignable_roles: optionById.assignableRoles,
          copy_documents: optionById.copyDocuments,
          copy_attributes: optionById.copyAttributes,
        };
      }

      const service = inject(EventsListService);
      const result = await service.getCreateEventTypeOptions(
        getCreateEventTypeOptionsDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getBatchOptionsCompany = createAsyncThunk<
  {
    company: { id: string; name: string };
    eventType: { id: string; name: string; documentInfoRequired: boolean };
    employees: EmployeeItem[];
    existingBatches: EventBatchItem[];
    unitTypeOptions?: UnitTypeOption[];
    unitType: UnitType | null;
    rootUnit: UnitNodeLabeled | null;
    employeesLoadingError: boolean;
  },
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getBatchOptionsCompany',
  async (_, { rejectWithValue, getState, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const validator = inject(ValidationService);

      await validator.validateOrReject(batchOptions, GetEventBatchOptionsDto);

      const service = inject(EventsListService);
      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);
      const state = getState().events.creationCompany;
      let items = state.items;

      dispatch(
        getCreateEventTypeOptions({
          event_type_id: batchOptions.eventTypeId,
          parent_event_id: batchOptions.parentEventId,
        }),
      );

      if (!items || !items.length) {
        const res = await service.getCreateOptionsCompany();

        items = await PromiseAllIgnoreErrors(
          res.items.map(async (item) => {
            const eventTypesRes = await service.getCreateBatchEventTypes({
              companyId: item.company_id,
              parentEventId: batchOptions.parentEventId,
            });

            return {
              ...item,
              event_types: eventTypesRes.event_types,
            };
          }),
        );

        dispatch(setCreateCompanyItems(items));
      }

      const selectedCompany = items.find(
        (company) => company.company_id === batchOptions.companyId,
      );

      if (!selectedCompany) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const selectedEventType = selectedCompany.event_types.find(
        (eventType) => eventType.id === batchOptions.eventTypeId,
      );

      if (!selectedEventType) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const { event_batches: existingBatches } =
        await service.getExistingBatchEvents(batchOptions);

      const { types } = await unitService.getUnitTypes(batchOptions);

      let rootUnit = null;
      let unitType = null;

      if (Array.isArray(types) && types.length) {
        unitType = types[0].type as UnitType;
        const unitTree = await unitService.getUnitTree({
          companyId: batchOptions.companyId,
          unitType,
        });

        rootUnit = unitTree.root_unit ?? null;
      }

      const { employees } = await service.getCreateBatchEmployees(batchOptions);

      if (!Array.isArray(employees)) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      return {
        company: {
          id: selectedCompany.company_id,
          name: selectedCompany.company_name,
        },
        eventType: {
          id: selectedEventType.id,
          name: selectedEventType.name,
          documentInfoRequired: !!selectedEventType.document_info_required,
        },
        employees,
        existingBatches: existingBatches || [],
        unitTypeOptions: types.map(
          ({ type: value, name: label }) =>
            ({
              value,
              label,
            } as UnitTypeOption),
        ),
        unitType,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
        employeesLoadingError: false,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getUpdateOfBatchOptionsCompanyForUnitType = createAsyncThunk<
  {
    rootUnit: UnitNodeLabeled | null;
    employees: EmployeeItem[];
  },
  { unitType: UnitType; companyId: string },
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/updateUnitType',
  async ({ unitType, companyId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const service = inject(EventsListService);
      const batchOptions = inject(QueryService).parse(
        history.location.search,
      ) as GetEventBatchOptionsDto;

      const unitService = inject(UnitService);
      const unitProcessor = inject(UnitProcessor);

      const { root_unit: rootUnit = null } = await unitService.getUnitTree({
        companyId,
        unitType,
      });

      const { employees } = await service.getCreateBatchEmployees(batchOptions);

      return {
        employees,
        rootUnit: rootUnit ? unitProcessor.processUnitsTree(rootUnit) : null,
      };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEmployeesForUnit = createAsyncThunk<
  { employees: EmployeeItem[] },
  GetEventBatchOptionsDto,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/updateUnit',
  async (batchOptions, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(EventsListService);

      const result = await service.getCreateBatchEmployees(batchOptions);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createBatchEvents = createAsyncThunk<
  void,
  {
    values: CreateEventBatchDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/createBatchEvents',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      await inject(EventsListService).createBatchEvents(values);

      dispatch(
        showNotification(
          `Заявки (${values.employees.length}) успешно созданы!`,
        ),
      );
      actions.resolve(null);
      const location = history.location as {
        state?: { prev?: { pathname: string; search: string }[] };
      };

      if (location.state?.prev) {
        inject<EventsRouter>(EventsRouter).goToList(
          location.state.prev?.[0]?.search,
        );
      } else {
        inject<EventsRouter>(EventsRouter).goToList();
      }
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getOptionsWithTypeGroup = createAsyncThunk<
  OptionsResponse,
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'createEvent/getOptionsWithTypeGroup',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const { event_type_group: eventTypeGroup } = inject(QueryService).parse(
        history.location.search,
      );

      const result = await inject(EventsListService).getCreateOptions({
        eventTypeGroup,
      });

      if (result.items && result.items.length === 1) {
        if (
          result.items[0].employees &&
          result.items[0].employees.length === 1
        ) {
          if (
            result.items[0].employees[0].event_types &&
            result.items[0].employees[0].event_types.length === 1
          ) {
            const employeeId = result.items[0].employees[0].id;
            const eventTypeId = result.items[0].employees[0].event_types[0].id;

            dispatch(
              createEvent({
                values: { employeeId, eventTypeId } as CreateEventDto,
                actions: { resolve: () => {}, reject: () => {} },
              }),
            );
          }
        }
      }

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const creationReducers = (
  builder: ActionReducerMapBuilder<EntityState<EventListEntity> & EventsState>,
) => {
  builder.addCase(getCanCreateEvent.pending, (state) => {
    state.canCreateEvent = false;
  });
  builder.addCase(getCanCreateEvent.fulfilled, (state, action) => {
    state.canCreateEvent = action.payload;
  });
  builder.addCase(getCanCreateEvent.rejected, (state) => {
    state.canCreateEvent = false;
  });
  builder.addCase(setCreateCompanyItems, (state, action) => {
    state.creationCompany.items = action.payload;
  });
  builder.addCase(getOptionsCompany.pending, (state) => {
    state.creationCompany.status = 'loading';
    state.creationCompany.error = null;
  });
  builder.addCase(getOptionsCompany.fulfilled, (state, { payload }) => {
    state.creationCompany.status = 'complete';
    state.creationCompany.items = payload.items;
    state.creationCompany.error = null;
  });
  builder.addCase(getOptionsCompany.rejected, (state, { payload, error }) => {
    state.creationCompany.status = 'failed';
    state.creationCompany.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(getCreateEventTypeOptions.pending, (state) => {
    state.createEventTypeOptions.status = 'loading';
    state.createEventTypeOptions.error = null;
  });
  builder.addCase(
    getCreateEventTypeOptions.fulfilled,
    (state, { payload, meta }) => {
      state.createEventTypeOptions.status = 'complete';
      state.createEventTypeOptions.options[meta.arg.event_type_id] = {
        ...(state.createEventTypeOptions.options[meta.arg.event_type_id] || {}),
        assignableRoles: payload.assignable_roles,
        copyDocuments: payload.copy_documents,
        copyAttributes: payload.copy_attributes,
      };
      state.createEventTypeOptions.error = null;
    },
  );
  builder.addCase(
    getCreateEventTypeOptions.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        state.createEventTypeOptions.status = 'failed';
        state.createEventTypeOptions.error =
          payload ||
          ({
            info: (error && error.message) || 'Что-то пошло не так',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError);
      }
    },
  );
  builder.addCase(getBatchOptionsCompany.pending, (state) => {
    state.creationCompany.status = 'loading';
    state.creationCompany.error = null;
    state.creationCompany.selected = null;
  });
  builder.addCase(getBatchOptionsCompany.fulfilled, (state, { payload }) => {
    state.creationCompany.status = 'complete';
    state.creationCompany.selected = payload;
    state.creationCompany.error = null;
  });
  builder.addCase(
    getBatchOptionsCompany.rejected,
    (state, { payload, error }) => {
      state.creationCompany.status = 'failed';
      state.creationCompany.selected = null;
      state.creationCompany.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.pending,
    (state) => {
      state.creationCompany.error = null;
      if (state.creationCompany.selected) {
        state.creationCompany.selected.employees = [];
        state.creationCompany.selected.rootUnit = null;
      }
    },
  );
  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.fulfilled,
    (state, { payload }) => {
      if (state.creationCompany.selected) {
        const { rootUnit, employees } = payload;
        state.creationCompany.selected.rootUnit = rootUnit;
        state.creationCompany.selected.employees = employees;
        state.creationCompany.selected.employeesLoadingError =
          !Array.isArray(employees);
      }
      state.creationCompany.error = null;
    },
  );
  builder.addCase(getOptionsWithTypeGroup.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(getOptionsWithTypeGroup.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.error = null;
    state.creation.options = payload.options;
    state.creation.items = payload.items;
  });
  builder.addCase(
    getOptionsWithTypeGroup.rejected,
    (state, { payload, error }) => {
      state.creation.status = 'failed';
      state.creation.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
  builder.addCase(
    getUpdateOfBatchOptionsCompanyForUnitType.rejected,
    (state, { payload, error }) => {
      state.creationCompany.status = 'failed';
      state.creationCompany.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
  builder.addCase(getEmployeesForUnit.pending, (state) => {
    state.creationCompany.error = null;
    if (state.creationCompany.selected) {
      state.creationCompany.selected.employees = [];
    }
  });
  builder.addCase(getEmployeesForUnit.fulfilled, (state, { payload }) => {
    if (state.creationCompany.selected) {
      const { employees } = payload;
      state.creationCompany.selected.employees = employees;
      state.creationCompany.selected.employeesLoadingError =
        !Array.isArray(employees);
    }
    state.creationCompany.error = null;
  });
  builder.addCase(getEmployeesForUnit.rejected, (state, { payload, error }) => {
    state.creationCompany.status = 'failed';
    state.creationCompany.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(getOptions.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
    state.canCreateEvent = false;
  });
  builder.addCase(getOptions.fulfilled, (state, { payload }) => {
    state.creation.status = 'complete';
    state.creation.error = null;
    state.creation.options = payload.options;
    state.creation.items = payload.items;
    state.canCreateEvent = !!payload.items.length;
  });
  builder.addCase(getOptions.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
    state.canCreateEvent = false;
  });
  builder.addCase(createEvent.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createEvent.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(createEvent.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(createEventCompany.pending, (state) => {
    state.creationCompany.status = 'loading';
    state.creationCompany.error = null;
  });
  builder.addCase(createEventCompany.fulfilled, (state) => {
    state.creationCompany.status = 'complete';
    state.creationCompany.error = null;
  });
  builder.addCase(createEventCompany.rejected, (state, { payload, error }) => {
    state.creationCompany.status = 'failed';
    state.creationCompany.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};
