/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';

import { IsIdle, IdleOptions } from './is-idle';

export const Idle = ({ onIdle }: Pick<IdleOptions, 'onIdle'>) => {
  useEffect(() => {
    const isIdle = new IsIdle({ onIdle });
    isIdle.start();

    return () => {
      isIdle.stop();
    };
  }, [onIdle]);

  return null;
};
