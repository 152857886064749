import React from 'react';

import classNames from 'classnames';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

const SVGSidebarMoreIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.1669 7.27818C4.42753 6.95624 4.89979 6.90652 5.22174 7.16715L9.99984 11.0351L14.7779 7.16715C15.0999 6.90652 15.5721 6.95624 15.8328 7.27818C16.0934 7.60013 16.0437 8.07239 15.7217 8.33301L10.4717 12.583C10.1966 12.8058 9.80311 12.8058 9.52794 12.583L4.27794 8.33301C3.95599 8.07239 3.90628 7.60013 4.1669 7.27818Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.01145 7.15234C4.34158 6.74454 4.93978 6.68158 5.34757 7.0117L9.99984 10.7778L14.6521 7.0117C15.0599 6.68158 15.6581 6.74454 15.9882 7.15234C16.3183 7.56014 16.2554 8.15834 15.8476 8.48846L10.5976 12.7385C10.249 13.0206 9.75064 13.0206 9.4021 12.7385L4.1521 8.48846C3.7443 8.15834 3.68133 7.56014 4.01145 7.15234ZM5.22174 7.16715C4.89979 6.90652 4.42753 6.95624 4.1669 7.27818C3.90628 7.60013 3.95599 8.07239 4.27794 8.33301L9.52794 12.583C9.80311 12.8058 10.1966 12.8058 10.4717 12.583L15.7217 8.33301C16.0437 8.07239 16.0934 7.60013 15.8328 7.27818C15.5721 6.95624 15.0999 6.90652 14.7779 7.16715L9.99984 11.0351L5.22174 7.16715Z"
      />
    </SvgIcon>
  );
};

export const SidebarMoreIcon = ({
  size = 'default',
  color = 'textPrimary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGSidebarMoreIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
