import { injectable } from 'inversify';

import { FilterSelectOption } from '@vk-hr-tek/core/filter/gen/filter';

import { GetUnitTypesResponse } from '@app/gen/organization';
import { PolicyCompany, PolicyListItemsResponse } from '@app/gen/policy';

@injectable()
export class CreationMapper {
  processCreatePolicyCompany(companies: PolicyCompany[]) {
    return companies.map((company) => ({
      label: company.name,
      value: company.company_id,
    }));
  }

  processCompanyPositions(positions: PolicyListItemsResponse) {
    return positions.map(({ label, value }) => ({
      label,
      value,
    }));
  }

  policyUnitTypeSorting(values: GetUnitTypesResponse) {
    const legalType = values?.types.find((i) => i.type === 'legal');
    const filteredTypes = values?.types?.filter((i) => i.type !== 'legal');
    const newArr = legalType ? [legalType, ...filteredTypes] : filteredTypes;

    return { types: newArr };
  }

  processGovDocumentOptions(govDocumentOptions: FilterSelectOption[]) {
    return govDocumentOptions.map(({ label, value }) => ({
      value,
      label: `${value}, ${label}`,
    }));
  }
}
