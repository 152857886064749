import { FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

interface WhenProps<T> {
  field: string;
  isIn: T[];
  reset: string;
}

export const WhenFieldChanges = <T extends unknown>({
  field,
  isIn,
  reset,
}: WhenProps<T>) => (
  <FormSpy>
    {({ form }) => (
      <OnChange name={field}>
        {(value) => {
          const errors = form.getState().errors || {};

          if (isIn.includes(value) && errors[reset]) {
            form.change(reset, undefined);
            form.resetFieldState(reset);
          }
        }}
      </OnChange>
    )}
  </FormSpy>
);
