import React, { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { SwitchInput as Input } from '../../input';

interface SwitchInputProps {
  label: string;
  onChange?: (check: boolean) => void;
  disabled?: boolean;
  labelPlacement?: 'start' | 'end';
}

export const SwitchInput = ({
  label,
  onChange,
  disabled,
  labelPlacement,
  tooltip,
  ...rest
}: SwitchInputProps &
  FieldProps<boolean | undefined, FieldRenderProps<boolean | undefined>>) => {
  return (
    <Field {...rest}>
      {({ input }) => {
        return (
          <Input
            {...input}
            checked={!!input.value}
            label={label}
            onChange={(check) => {
              input.onChange(check);
              if (onChange) {
                onChange(check);
              }
            }}
            disabled={disabled}
            labelPlacement={labelPlacement}
            tooltip={tooltip}
          />
        );
      }}
    </Field>
  );
};
