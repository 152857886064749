import { Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  IsUUID,
  ValidateNested,
  IsString,
} from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

class PolicyVersion {
  @IsNotEmpty(notEmptyOptions)
  policy_version_id: string;

  @IsUUID()
  @IsNotEmpty(notEmptyOptions)
  employee_id: string;

  @IsNotEmpty(notEmptyOptions)
  signature: string;
}

export class AcceptCryptoLocalDto {
  @Type(() => PolicyVersion)
  @ValidateNested({ each: true })
  @IsArray()
  @IsNotEmpty(notEmptyOptions)
  policyVersions: PolicyVersion[];

  @IsString()
  @IsNotEmpty()
  certificateId: string;
}
