import { IsString, IsNotEmpty, Length, IsNumberString } from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class SignPolicyDto {
  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['code-verify'],
  })
  @IsString({
    groups: ['code-verify'],
  })
  @Length(6, 6, {
    message: 'Неверный СМС-код',
    groups: ['code-verify'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный СМС-код',
      groups: ['code-verify'],
    },
  )
  code: string;
}
