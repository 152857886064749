import React from 'react';

import MUICircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

interface CircularProgressProps {
  size?: number | string;
  color?: 'primary' | 'secondary' | 'inherit';
  className?: string;
}

export const CircularProgress = ({
  size,
  color,
  className,
  ...rest
}: CircularProgressProps) => {
  return (
    <MUICircularProgress
      {...rest}
      color={color}
      size={size}
      className={classNames(className, 'aqa_circular_progress')}
    />
  );
};
