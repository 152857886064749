import { IsString, IsNotEmpty } from 'class-validator';

const invitCodeValidationOptions = {
  message: 'Неверный код',
};

export class VerifyOAuth2CodeDto {
  @IsString(invitCodeValidationOptions)
  @IsNotEmpty(invitCodeValidationOptions)
  authCode: string;
}
