import React from 'react';

import { Box } from '../../Box';

interface FormKnownValuesProps {
  children: React.ReactNode | React.ReactNode[];
}

export const FormKnownValues = ({ children }: FormKnownValuesProps) => (
  <Box
    display="flex"
    flexDirection="column"
    gap="20"
    p="16"
    bgcolor="bg.greyscale.primary"
    radius="l"
  >
    {children}
  </Box>
);
