import React from 'react';

import classNames from 'classnames';

import { DocFileIcon } from '../../icons';

import { PreviewButtons } from './Buttons';
import { useStyles } from './Preview.styles';

interface PreviewDocxProps {
  shape: 'auto' | 'square';
  onClickDownload?: () => Promise<
    Blob | { file: Promise<Blob>; filename?: string }
  >;
  withCollapsableFooter?: boolean;
}

export const PreviewDocx = ({
  shape,
  onClickDownload,
  withCollapsableFooter,
}: PreviewDocxProps) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.root,
        classes.collapseWrapper,
        classes.previewDocx,
      )}
    >
      {shape === 'square' && <div className={classes.square} />}
      <div className={classes.docIcon}>
        <DocFileIcon />
      </div>
      {onClickDownload && (
        <PreviewButtons
          onClickDownload={onClickDownload}
          withCollapsableFooter={withCollapsableFooter}
        />
      )}
    </div>
  );
};
