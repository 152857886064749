import { injectable } from 'inversify';
import { fileTypeFromBlob } from 'file-type/core';

import { Logger } from '../logger';

@injectable()
export class FileType {
  constructor(private readonly logger: Logger) {}

  async fileTypeFromBlob(blob: Blob) {
    let mime = blob.type;

    try {
      const result = await fileTypeFromBlob(blob);

      if (result?.mime) {
        mime = result.mime;
      }
    } catch (e) {
      this.logger.error(e, {
        tags: {
          vkdoc_error_type: 'mime-type',
        },
        context: {
          error: e,
        },
      });
    }

    return mime;
  }
}
