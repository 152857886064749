import React from 'react';

import { useTheme } from '@material-ui/core';

export const LogoEmptyIconText = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="32"
      viewBox="0 0 116 32"
      fill="none"
    >
      <path
        d="M15.4241 23.4943H19.2363L24.8347 8.16016H22.0682L17.5807 20.7973H17.1451L12.8101 8.16016H10L15.4241 23.4943Z"
        fill={theme.tokens.colors.text.light.primary}
      />
      <path
        d="M39.1504 23.4943L32.0924 15.5554L38.6058 8.16016H35.3818L29.9358 14.4026H29.3041V8.16016H26.6901V23.4943H29.3041V16.9256H29.9576L35.7085 23.4943H39.1504Z"
        fill={theme.tokens.colors.text.light.primary}
      />
      <path
        d="M55.4479 14.7948V8.36632H58.0758V23.7815H55.4479V17.2H48.1118V23.7815H45.484V8.36632H48.1118V14.7948H55.4479Z"
        fill={theme.tokens.colors.text.light.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.0022 23.7815H69.9145L65.8413 18.1183H63.9799V23.7815H61.352V8.36632H67.0457C68.0969 8.36632 69.0385 8.57768 69.8707 9.00042C70.7028 9.40857 71.3525 9.98436 71.8197 10.7278C72.2868 11.4566 72.5204 12.2948 72.5204 13.2423C72.5204 14.3939 72.1701 15.3924 71.4693 16.2379C70.7831 17.0688 69.8634 17.63 68.71 17.9215L73.0022 23.7815ZM63.9799 10.7715V15.7131H67.0457C67.8633 15.7131 68.5275 15.4872 69.0385 15.0353C69.5641 14.5688 69.8269 13.9712 69.8269 13.2423C69.8269 12.5135 69.5641 11.9231 69.0385 11.4712C68.5275 11.0048 67.8633 10.7715 67.0457 10.7715H63.9799Z"
        fill={theme.tokens.colors.text.light.primary}
      />
      <path
        d="M83.8325 23.7815H86.4604V10.7715H91.7161V8.36632H78.5768V10.7715H83.8325V23.7815Z"
        fill={theme.tokens.colors.text.light.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.348 17.9653C102.348 18.1839 102.334 18.4536 102.304 18.7743H93.1506C93.2674 19.6635 93.6324 20.3778 94.2456 20.9171C94.8587 21.4419 95.6398 21.7043 96.5887 21.7043C97.2311 21.7043 97.8078 21.5804 98.3188 21.3326C98.8443 21.0848 99.2458 20.7495 99.5232 20.3267L101.888 20.7203C101.479 21.7699 100.771 22.5789 99.7641 23.1474C98.7713 23.7159 97.6618 24.0002 96.4355 24.0002C95.2967 24.0002 94.2748 23.7523 93.3696 23.2567C92.4791 22.7465 91.7783 22.0468 91.2673 21.1576C90.771 20.2539 90.5228 19.2335 90.5228 18.0965C90.5228 16.9595 90.771 15.9464 91.2673 15.0572C91.7783 14.1534 92.4791 13.4537 93.3696 12.9581C94.2748 12.4479 95.2967 12.1928 96.4355 12.1928C97.5742 12.1928 98.5888 12.4479 99.4794 12.9581C100.385 13.4537 101.085 14.1461 101.582 15.0353C102.093 15.9099 102.348 16.8866 102.348 17.9653ZM96.4355 14.3793C95.6763 14.3793 95.012 14.598 94.4427 15.0353C93.8879 15.4726 93.5083 16.063 93.3039 16.8064H99.6108C99.3918 16.0776 98.9976 15.4945 98.4283 15.0572C97.8735 14.6053 97.2092 14.3793 96.4355 14.3793Z"
        fill={theme.tokens.colors.text.light.primary}
      />
      <path
        d="M111.493 23.7815H114.8L109.566 17.8778L114.362 12.4114H111.362L107.551 16.8283H106.938V8.36632H104.42V23.7815H106.938V19.2553H107.551L111.493 23.7815Z"
        fill={theme.tokens.colors.text.light.primary}
      />
    </svg>
  );
};
