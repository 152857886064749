import React, { useEffect } from 'react';

import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Box } from '@vk-hr-tek/ui/Box';
import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import Can from '@app/ability/Can';
import { AppAbilities } from '@app/ability/ability';
import { useDispatch, useSelector } from '@app/hooks';

import { useAuth } from '../../auth';
import {
  selectStatus,
  getUser,
  getCandidateUser,
  selectError,
} from '../../user';
import { selectCandidateUserStatus } from '../../user/slice';
import { PageError } from '../page';
import { useApplication } from '../../auth/hooks/useApplication';

interface PrivateRouteProps {
  children: React.ReactNode;
  exact?: boolean;
  path: string;
  resource: AppAbilities[1];
}

export const PrivateRoute = ({
  path,
  exact,
  children,
  resource,
}: PrivateRouteProps) => {
  const dispatch = useDispatch();

  const isAuthenticated = useAuth();
  const appType = useApplication();
  const status = useSelector(
    appType === 'candidateApp' ? selectCandidateUserStatus : selectStatus,
  );
  const error = useSelector(selectError);

  useEffect(() => {
    if (isAuthenticated && status === 'idle') {
      dispatch(appType === 'candidateApp' ? getCandidateUser() : getUser());
    }
  }, [dispatch, isAuthenticated, status, appType]);

  return (
    <Route path={path} exact={exact}>
      {(() => {
        if (!isAuthenticated) {
          return null;
        }

        if (status === 'idle' || status === 'loading') {
          return (
            <Box display="flex" justifyContent="center" pt="40">
              <CircularProgress size={50} />
            </Box>
          );
        }

        if (status === 'failed') {
          return (
            <PageError
              status={error?.status || 500}
              traceId={error?.traceId}
              errorMessage="Произошла ошибка получения данных пользователя"
            />
          );
        }

        return (
          <>
            <Can do="read" on={resource}>
              {children}
            </Can>
            <Can not do="read" on={resource}>
              <PageError status={403} />
            </Can>
          </>
        );
      })()}
    </Route>
  );
};
