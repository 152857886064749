interface UseScrollProps {
  topOffset: number;
  behaviorType?: ScrollBehavior;
}

export const useScrollTo = ({
  topOffset,
  behaviorType = 'smooth',
}: UseScrollProps) => {
  const scrollBlock = document.querySelector('#scrollable-target');

  scrollBlock?.scrollTo({ top: topOffset, behavior: behaviorType });
};
