import { injectable } from 'inversify';

import { UnmarshallerService } from '../unmarshaller';
import { HttpService } from '../http';

import { ChatSupportCredentials } from './gen/chat';

@injectable()
export class ChatService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getSupportCredentials() {
    return this.unmarshaller.unmarshall(
      await this.http.get('/chat/support/credentials'),
      ChatSupportCredentials,
    );
  }
}
