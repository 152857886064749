import React from 'react';

import {
  GridContentAlignment,
  GridDirection,
  GridItemsAlignment,
  GridJustification,
  GridWrap,
} from '@material-ui/core';
import classNames from 'classnames';

import { Spacing } from '../Grid.types';

import { useStyles } from './GridContainer.styles';

interface GridContainerProps {
  alignContent?: GridContentAlignment;
  alignItems?: GridItemsAlignment;
  children?: React.ReactNode;
  direction?: GridDirection;
  justifyContent?: GridJustification;
  spacing?: Spacing;
  wrap?: GridWrap;
}

export const GridContainer = ({ children, ...props }: GridContainerProps) => {
  const classes = useStyles(props);

  return (
    <div
      className={classNames(classes.container, {
        [classes.spacing]: !!props.spacing,
      })}
    >
      {children}
    </div>
  );
};
