import React from 'react';

import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  TextInput,
} from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';
import { Redo } from '@vk-hr-tek/ui/Redo';

import { useDispatch, useSelector } from '@app/hooks';

import {
  registerAli,
  registerAliInit,
  selectNotification,
  selectPhone,
  selectSnils,
  selectStatus,
  redirectToQr,
  selectAuthInstance,
} from '../../../slice';
import { RegisterAliDto } from '../../../dto';
import { Button } from '../../../ui/Button';

export const RegisterFinish = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const snils = useSelector(selectSnils);
  const phone = useSelector(selectPhone);
  const status = useSelector(selectStatus);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: RegisterAliDto) =>
    new Promise((resolve) => {
      dispatch(
        registerAli({
          values,
          actions: { resolve },
        }),
      );
    });

  const resendCode = () => {
    return new Promise((resolve) => {
      dispatch(
        registerAliInit({
          values: { phone: phone || '' },
          actions: { resolve },
        }),
      );
    });
  };

  return (
    <div>
      <Form
        validate={(values) =>
          validator.validate(values, RegisterAliDto, ['code-verify'])
        }
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormKnownValues>
              <FormKnownValue label="СНИЛС" value={snils} />
              <FormKnownValue label="Номер телефона" value={phone} />
            </FormKnownValues>
            <Box mt="24">
              <TextInput
                name="code"
                label="СМС-код"
                placeholder="Введите СМС-код"
                type="number"
                inputMode="numeric"
                autocomplete="one-time-code"
                id="qaAuthLoginFinishCodeInput"
              />
            </Box>
            <Redo label="Отправить код повторно" action={resendCode} />
            <Box mt="24">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Подтвердить
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
            <Box
              mt="8"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link
                onClick={() => dispatch(redirectToQr())}
                size="small"
                variant="tertiary"
              >
                Выход к QR-коду
              </Link>
            </Box>
          </form>
        )}
      />
    </div>
  );
};
