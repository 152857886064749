import React, { ChangeEvent } from 'react';

import InputMask from 'react-input-mask';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { Preloader } from '../common';

import useStyles from './SuggestedInput.styles';

interface TextFieldMaskedProps {
  mask: string;
  loading?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, nextValue: string) => void;
}

export const TextFieldMasked = ({
  mask,
  name,
  error,
  helperText,
  loading,
  onChange,
  ...rest
}: TextFieldMaskedProps & TextFieldProps) => {
  const classes = useStyles();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e, e.target.value);
  };

  return (
    <InputMask
      mask={mask}
      name={name}
      value={rest.inputProps?.value}
      onChange={handleChange}
      onFocus={rest.inputProps?.onFocus}
      onBlur={rest.inputProps?.onBlur}
      onKeyPress={rest.inputProps?.onKeyPress}
      disabled={rest.disabled}
      inputRef={rest?.inputProps?.ref}
    >
      <TextField
        {...rest}
        name={name}
        classes={{ root: classes.inputRoot }}
        autoComplete="off"
        variant="outlined"
        fullWidth
        error={error}
        helperText={helperText}
        placeholder={rest.placeholder}
        {...(loading
          ? {
              InputProps: {
                endAdornment: <Preloader />,
              },
            }
          : {})}
      />
    </InputMask>
  );
};
