import { useEffect, useState } from 'react';

/** Хук, который отложенно обновляет значение
 * @template {any} T
 * @param {T} value Обновляемое значение
 * @param {number} delay Время в милисекундах
 * @returns {T}
 *  */
export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};
