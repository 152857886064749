import { IsString, IsPhoneNumber, IsNotEmpty } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

export class ChangePasswordInitDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
  })
  phone: string;

  @IsSnils({
    message: 'Неверный СНИЛС',
  })
  @IsString({
    message: 'Вы забыли ввести СНИЛС',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести СНИЛС',
  })
  snils: string;
}
