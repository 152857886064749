import {
  IsNotEmpty,
  IsString,
  IsBoolean,
  IsOptional,
  IsUUID,
} from 'class-validator';

export class UpdatePolicyVersionDto {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsUUID()
  @IsNotEmpty()
  versionId: string;

  @IsString({
    message: 'Вы указали неверную дату',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли указать дату',
    groups: ['form'],
  })
  activeFrom: string;

  @IsOptional({
    groups: ['form'],
  })
  @IsString({
    message: 'Вы указали неверную дату',
    groups: ['form'],
  })
  activeTo?: string;

  @IsOptional({
    groups: ['form'],
  })
  @IsBoolean({
    groups: ['form'],
  })
  endNow?: boolean;

  @IsNotEmpty({
    message: 'Вы забыли указать дату',
    groups: ['form'],
  })
  documentDate: string;

  @IsOptional()
  documentNumber?: string;

  @IsNotEmpty()
  signatureType: 'pep' | 'unep';

  @IsNotEmpty()
  withOrder: string;
}
