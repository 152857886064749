import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';

import { CompanyPolicyListItem, EmployeePolicyListItem } from '@app/gen/policy';

export class GetPoliciesDto {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  policies: CompanyPolicyListItem[] | EmployeePolicyListItem[];
}
