import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '@vk-hr-tek/core/ioc';
import { UnitNodeLabeled } from '@vk-hr-tek/core/units';

import {
  CompanyPolicyListItem as CompanyPolicyListEntity,
  EmployeePolicyListItem as EmployeePolicyListEntity,
} from '@app/gen/policy';
import { UnitNode } from '@app/gen/settings';
import { RootState } from '@app/store';

import { CreationMapper, PolicyDetailMapper, PolicyMapper } from '../mappers';

export const selectPoliciesCompany = createSelector(
  [
    (state: RootState) => state.policy.currentIds,
    (state: RootState) => state.policy.entities,
    (_: RootState, inject: Inject) => inject(PolicyMapper),
  ],
  (currentIds, entities, format) =>
    currentIds
      .map((id) => entities[id])
      .filter((x): x is CompanyPolicyListEntity => x !== undefined)
      .map((policy) => format.processCompanyListEntity(policy)),
);

export const selectPoliciesEmployee = createSelector(
  [
    (state: RootState) => state.policy.currentIds,
    (_: RootState, inject: Inject, variant: 'desktop' | 'mobile' = 'desktop') =>
      variant,
    (state: RootState) => state.policy.entities,
    (_: RootState, inject: Inject) => inject(PolicyMapper),
  ],
  (currentIds, variant, entities, format) =>
    currentIds
      .map((id) => entities[id])
      .filter((x): x is EmployeePolicyListEntity => x !== undefined)
      .map((policy) => format.processEmployeeListEntity(policy, variant)),
);

export const selectIsNotLastItems = (state: RootState) =>
  state.policy.isNotLastItems;

export const selectFilters = createSelector(
  [
    (state: RootState) => state.policy.filters,
    (_: RootState, inject: Inject) => inject(PolicyMapper),
  ],
  (filters, format) => filters && format.processListFilters(filters),
);

export const selectError = (state: RootState) => state.policy.error;

export const selectStatus = (state: RootState) => state.policy.status;

export const selectCreationDataStatus = (state: RootState) =>
  state.policy.creationData.status;

export const selectCreationData = createSelector(
  [
    (state: RootState) => state.policy.creationData.companies,
    (state: RootState) => state.policy.creationData.status,
    (_: RootState, inject: Inject) => inject(CreationMapper),
  ],
  (companies, status, format) => ({
    companyList: companies
      ? format.processCreatePolicyCompany(companies)
      : null,
    companyListStatus: status,
  }),
);

export const selectCreationDataPositions = (state: RootState) =>
  state.policy.creationData.positions;

export const selectCreationDataUnitTypes = createSelector(
  [
    (state: RootState) => state.policy.creationData.unitTypes.values,
    (state: RootState) => state.policy.creationData.unitTypes.status,
  ],
  (values, status) => ({
    unitTypesOptions: values.types.map(({ name, type }) => ({
      value: type,
      label: name,
    })),
    unitTypesStatus: status,
  }),
);

export const selectCreationDataUnitsTree = createSelector(
  (state: RootState) => state.policy.creationData.unitsTree,
  (unitsTree) => {
    const processUnitsTree = (node: UnitNode): UnitNodeLabeled => ({
      id: node.id,
      label: node.name,
      count: node.employee_count || 0,
      hasChildrenToLoad: node.is_parent,
      children: node.children
        ? node.children.map(processUnitsTree).filter((unit) => unit)
        : [],
    });

    return {
      root_unit: unitsTree?.root_unit
        ? processUnitsTree(unitsTree.root_unit)
        : null,
    };
  },
);

export const selectGovDocumentOptions = createSelector(
  [
    (state: RootState) => state.policy.creationData.govDocumentOptions,
    (_: RootState, inject: Inject) => inject(CreationMapper),
  ],
  (docs, format) => (docs ? format.processGovDocumentOptions(docs) : []),
);

export const selectPolicy = (state: RootState) => state.policy.detail.entity;

export const selectUnitTypes = (state: RootState) =>
  state.policy.detail.unitTypes;

export const selectPolicyEmployees = createSelector(
  [
    (state: RootState) => state.policy.detail.employees.entities,
    (_: RootState, inject: Inject) => inject(PolicyDetailMapper),
  ],
  (employees, format) => (employees ? format.processEmployees(employees) : []),
);

export const selectPolicyEmployeesHasMore = (state: RootState) =>
  state.policy.detail.employees.hasMore;

export const selectPolicyEmployeesStatus = (state: RootState) =>
  state.policy.detail.employees.status;

export const selectPolicyEmployeesError = (state: RootState) =>
  state.policy.detail.employees.error;

export const selectPolicyVersions = createSelector(
  [
    (state: RootState) => state.policy.detail.entity,
    (_: RootState, inject: Inject) => inject(PolicyDetailMapper),
  ],
  (entity, format) =>
    entity ? format.processVersions(entity.versions, entity.policy_id) : [],
);

export const selectPolicyDocVersionCode = (state: RootState) =>
  state.policy.detail.entity?.gov_document_type_code;

export const selectPolicyDocVersionName = (state: RootState) =>
  state.policy.detail.entity?.gov_document_type_name;

export const selectActivePolicyVersion = createSelector(
  [
    (state: RootState) => state.policy.detail.entity,
    (_: RootState, inject: Inject) => inject(PolicyDetailMapper),
  ],
  (entity, format) => (entity ? format.findActiveVersion(entity.versions) : ''),
);

export const selectPrintPolicyStatus = (state: RootState) =>
  state.policy.printPolicy.status;

export const selectPoliciesTotalCount = (state: RootState) =>
  state.policy.totalCount;

export const selectPoliciesTotalVersionCount = (state: RootState) =>
  state.policy.totalVersionCount;
