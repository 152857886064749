import React, { createContext, ReactNode } from 'react';

import { SizeMe } from 'react-sizeme';

export const RootWidthContext = createContext<number | null>(null);

interface RootWidthContextProviderProps {
  children: ReactNode | ReactNode[];
}

export const RootWidthContextProvider = ({
  children,
}: RootWidthContextProviderProps) => (
  <SizeMe>
    {({ size }) => (
      <div id="sizeme" style={{ width: '100%' }}>
        <div
          style={
            {
              containerType: 'inline-size',
              width: size.width,
            } as Record<string, string | number>
          }
        >
          <RootWidthContext.Provider value={size.width}>
            {children}
          </RootWidthContext.Provider>
        </div>
      </div>
    )}
  </SizeMe>
);
