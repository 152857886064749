import { injectable } from 'inversify';

@injectable()
export class LocalStorageService {
  private namespace: string;

  constructor(namespace?: string) {
    this.namespace = namespace ?? '';
  }

  setItem(key: string, value: string) {
    localStorage.setItem(`${this.namespace}${key}`, value);
  }

  getItem(key: string) {
    return localStorage.getItem(`${this.namespace}${key}`);
  }

  removeItem(key: string) {
    localStorage.removeItem(`${this.namespace}${key}`);
  }
}
