import React from 'react';

import { InputAdornment } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';

import { Box } from '../../Box';

interface ShowPasswordButtonProps {
  isShow: boolean;
  disabled: boolean;
  error: boolean;
  onClick: () => void;
}

const getIconColor = (disabled: boolean, error: boolean) => {
  if (disabled) return 'disabled';
  if (error) return 'error';
  return 'primary';
};

export const ShowPasswordButton = ({
  isShow,
  disabled,
  error,
  onClick,
}: ShowPasswordButtonProps) => (
  <InputAdornment position="end">
    <Box height={24} onClick={onClick}>
      {isShow ? (
        <VisibilityIcon color={getIconColor(disabled, error)} />
      ) : (
        <VisibilityOffIcon color={getIconColor(disabled, error)} />
      )}
    </Box>
  </InputAdornment>
);
