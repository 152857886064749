import {
  GridContentAlignment,
  GridDirection,
  GridItemsAlignment,
  GridJustification,
  GridWrap,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { Spacing } from '../Grid.types';

interface StylesProps {
  alignContent?: GridContentAlignment;
  alignItems?: GridItemsAlignment;
  direction?: GridDirection;
  justifyContent?: GridJustification;
  spacing?: Spacing;
  wrap?: GridWrap;
}

export const useStyles = makeStyles<Theme, StylesProps>(({ tokens }) => ({
  container: ({
    alignContent = 'stretch',
    alignItems = 'stretch',
    direction = 'row',
    justifyContent = 'flex-start',
    wrap = 'wrap',
  }) => ({
    display: 'flex',
    alignContent,
    alignItems,
    justifyContent,
    flexDirection: direction,
    flexWrap: wrap,
    width: '100%',
  }),
  spacing: ({ spacing }) => {
    const sp = spacing ? tokens.spacing[spacing] : 0;

    return {
      width: `calc(100% + ${sp}px)`,
      margin: `-${sp / 2}px`,

      '& > .MuiGrid-item': {
        padding: `${sp / 2}px`,
      },
    };
  },
}));
