import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

import { useStyles } from './TrivioLogo.styles';

export const TrivioLogo = (props: SvgIconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      {...props}
      viewBox="0 0 96 40"
      fill="none"
      className={classes.icon}
      width="96"
      height="40"
    >
      <path
        d="M18.3358 24.456V29.8027H15.9595C11.5837 29.8027 9.39622 27.6243 9.39622 23.2678V19.0809H7.41602V13.8474H9.39622V10H15.6766V13.8474H18.2792V19.0809H15.6766V23.381C15.6766 23.7584 15.766 24.0317 15.9452 24.2014C16.1244 24.3711 16.4213 24.456 16.8364 24.456H18.3358Z"
        fill="#4285F4"
      />
      <path
        d="M29.3688 14.5688C30.2925 14.0126 31.2545 13.7344 32.2542 13.7344V20.4389H30.472C29.284 20.4389 28.4116 20.6467 27.8554 21.0614C27.2987 21.4764 27.0207 22.2118 27.0207 23.2678V29.8027H20.7404V13.8474H27.0207V16.7895C27.6619 15.8657 28.4445 15.1254 29.3688 14.5688Z"
        fill="#4285F4"
      />
      <path
        d="M51.8173 23.8336L54.3633 13.8474H61.0397L55.7213 29.8027H47.885L42.5665 13.8474H49.2428L51.8173 23.8336Z"
        fill="#4285F4"
      />
      <path
        d="M81.536 23.8753C81.9319 23.4134 82.1299 22.7297 82.1299 21.8244C82.1299 20.9191 81.9319 20.2356 81.536 19.7733C81.1394 19.3114 80.6488 19.0802 80.0644 19.0802C79.48 19.0802 78.9894 19.3114 78.5935 19.7733C78.1976 20.2356 77.9996 20.9191 77.9996 21.8244C77.9996 22.7297 78.1928 23.4134 78.5793 23.8753C78.9658 24.3378 79.4604 24.5683 80.0644 24.5683C80.6488 24.5683 81.1394 24.3378 81.536 23.8753ZM75.7361 29.01C74.4534 28.3499 73.4492 27.4023 72.7234 26.1669C71.9971 24.9317 71.6342 23.4841 71.6342 21.8244C71.6342 20.165 71.9971 18.7173 72.7234 17.4819C73.4492 16.2469 74.4534 15.2991 75.7361 14.6388C77.0185 13.9787 78.4611 13.6488 80.0644 13.6488C81.6677 13.6488 83.1102 13.9787 84.3926 14.6388C85.675 15.2991 86.6791 16.2469 87.4054 17.4819C88.1317 18.7173 88.4945 20.165 88.4945 21.8244C88.4945 23.4841 88.1317 24.9317 87.4054 26.1669C86.6791 27.4023 85.675 28.3499 84.3926 29.01C83.1102 29.6703 81.6677 30 80.0644 30C78.4611 30 77.0185 29.6703 75.7361 29.01Z"
        fill="#4285F4"
      />
      <path
        d="M36.3221 29.8172C36.3221 29.0963 36.9065 28.5118 37.6274 28.5118C38.3484 28.5118 38.9329 29.0963 38.9329 29.8172H41.3043V13.8472H38.9329C38.9329 14.5681 38.3484 15.1523 37.6274 15.1523C36.9065 15.1523 36.3221 14.5681 36.3221 13.8472H33.9507V29.8172H36.3221Z"
        fill="#4285F4"
      />
      <path
        d="M67.6904 13.8326C67.6904 14.5536 67.1059 15.138 66.385 15.138C65.6641 15.138 65.0796 14.5536 65.0796 13.8326H62.7082V29.8027H65.0796C65.0796 29.0818 65.6641 28.4975 66.385 28.4975C67.1059 28.4975 67.6904 29.0818 67.6904 29.8027H70.0618V13.8326H67.6904Z"
        fill="#4285F4"
      />
    </SvgIcon>
  );
};
