import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { TokenService } from '@vk-hr-tek/core/http';
import { AppError } from '@vk-hr-tek/core/error';

import { UserLoginResponse as AuthResponse } from '@app/gen/users';
import { PassVerifyResponse } from '@app/gen/pass';

import {
  AuthAliLoginService,
  AuthAliPassCodeService,
  AuthRouterService,
} from '../../../services';
import {
  AuthState,
  startLoading,
  completeLoading,
  resetState,
  setError,
  ThunkExtra,
} from '../../auth.state';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const loginAli = createAsyncThunk<
  AuthResponse,
  {
    values: { code: string };
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/loginAli',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { passCode } = state;

      const result = await inject(AuthAliLoginService).login({
        code: values.code,
        passCode: passCode || undefined,
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(1000);

      actions.resolve(null);

      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const sendAliPhoneCode = createAsyncThunk<
  PassVerifyResponse,
  {
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/sendAliPhoneCode',
  async ({ actions }, { rejectWithValue, getState, extra: { inject } }) => {
    const router = inject(AuthRouterService);

    try {
      const state = getState().auth;

      const { passCode } = state;

      const result = await inject(AuthAliPassCodeService).verifyPassCode({
        passCode: passCode || '',
      });

      if (result.status === 'registration') {
        router.redirectToRegister();
      } else if (result.status === 'phone_change') {
        router.redirectToPhoneChange();
      }

      actions.resolve(null);

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const loginAliReducers = (
  builder: ActionReducerMapBuilder<AuthState>,
) => {
  builder.addCase(loginAli.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(loginAli.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(loginAli.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(sendAliPhoneCode.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(sendAliPhoneCode.fulfilled, (state) => {
    completeLoading(state);
  });
  builder.addCase(sendAliPhoneCode.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
};
