/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { injectable } from 'inversify';
import { parse, stringify } from 'query-string';
import { escape } from 'lodash';

@injectable()
export class QueryService {
  /** Преобразовать объект в query string (ключи предварительно сортируются)
   * При наличии у объекта свойства 'filters' типа object, развернуть каждое его поле дополнительно
   * @param query Объект
   * @returns {string}
   * */
  stringify(query: { [key: string]: any }): string {
    const { filters, ...rest } = query;

    if (!filters || typeof filters !== 'object' || Array.isArray(filters)) {
      return stringify(rest, { arrayFormat: 'bracket' });
    }

    return stringify(
      {
        ...rest,
        ...Object.entries(filters).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [`filter_${key}`]: value,
          };
        }, {}),
      },
      { arrayFormat: 'bracket' },
    );
  }

  /** Распарсить query string и преобразовать ее к типу объект
   * При этом поля, начинающиеся с 'filter_', будут объедены в поле 'filters' результативного объекта.
   * При этом символы в исходной строке “&“, “", ">“, '“', “'“, “`” будут конвертированы к соответствующим HTML-эквивалентам
   * @param {string} query Строка, ожидающая преобразования
   * @return {Object}
   * */
  parse(query: string): { [key: string]: any } {
    const entries = parse(query, { arrayFormat: 'bracket' });
    const PROPERTY_SEPARATOR = '.';

    const result: { [key: string]: any } = {};

    if (entries) {
      Object.entries(entries).forEach(([key, value]) => {
        let escapedValue: string | string[] = '';

        if (value && Array.isArray(value)) {
          escapedValue = value.map((fragment) => escape(fragment));
        } else if (value && typeof value === 'string') {
          escapedValue = escape(value);
        }

        if (!escapedValue) {
          return;
        }

        if (key.startsWith('filter_')) {
          key = key.substring('filter_'.length);
          if (!result.filters) result.filters = {};

          if (key.includes(PROPERTY_SEPARATOR)) {
            const props = key.split(PROPERTY_SEPARATOR);

            if (props.length > 2 || !['start', 'end'].includes(props[1])) {
              return;
            }

            let leaf = result.filters;

            for (let i = 0; i < props.length - 1; i++) {
              if (!leaf[props[i]]) leaf[props[i]] = {};
              leaf = leaf[props[i]];
            }

            leaf[props[props.length - 1]] = escapedValue;
          } else {
            result.filters[key] = value;
          }
        } else {
          result[key] = value;
        }
      });
    }

    return result;
  }
}
