import { uniqBy } from 'lodash';

import { selectUserGroups, selectUserEmployees } from '../../user/slice';
import { useSelector } from '../hooks';

/** Определить, что у пользователя только одна компания
 * @returns {boolean}
 *  */
export const useIsOnlyOneCompany = (): boolean => {
  const userGroups = useSelector(selectUserGroups);
  const userEmployees = useSelector(selectUserEmployees);

  const result = uniqBy(
    [...userGroups, ...userEmployees],
    (item) => item.company.id,
  );

  return result.length === 1;
};
