import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsEventTypesRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsEventTypesRouterService
  implements SettingsEventTypesRouter
{
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToDetail({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(
      `/${this.role.get()}/${
        modules.settings.basename
      }/event-types/${id}?companyId=${companyId}`,
    );
  }
}
