import React from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import { Check as CheckIcon } from '@material-ui/icons';
import { Checkbox as MUICheckbox } from '@material-ui/core';

import { Box } from '../Box';

import useStyles from './Checkbox.styles';

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (value: boolean) => void;
  stopPropagation?: boolean;
  verticalClickArea?: number;
  testId?: string;
  withoutMargin?: boolean;
}

export const Checkbox = ({
  checked,
  disabled,
  indeterminate,
  onChange = () => {},
  stopPropagation,
  verticalClickArea = 0,
  testId,
  withoutMargin = false,
  ...rest
}: CheckboxProps) => {
  const classes = useStyles({ verticalClickArea });

  const checkbox = (
    <MUICheckbox
      {...rest}
      checked={checked}
      data-testid={testId}
      checkedIcon={
        <Box
          className={classNames(classes.iconChecked, {
            [classes.iconDisabled]: disabled,
          })}
          fontSize={16}
        >
          <CheckIcon fontSize="inherit" />
        </Box>
      }
      className={classNames(
        'aqa_checkbox',
        classes.input,
        classes.verticalClickArea,
        withoutMargin && classes.withoutMargin,
      )}
      color="primary"
      disabled={disabled}
      indeterminate={indeterminate}
      onChange={(event) => {
        event.stopPropagation();
        onChange(event.target.checked);
      }}
    />
  );

  if (stopPropagation) {
    return (
      <FormControlLabel
        classes={{
          root: classes.controlLabel,
        }}
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={checkbox}
        label=""
      />
    );
  }

  return checkbox;
};
