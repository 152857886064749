import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { EventListToPaperResponse } from '@app/gen/events';

import { ConvertEventsToPaperDto, GetDocumentsDto, GetEventsDto } from '../dto';

@injectable()
export class EventsPaperService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getPaperEvents(getEventsDto: GetEventsDto) {
    await this.validator.validateOrReject(getEventsDto, GetEventsDto);

    const {
      limit = 50,
      offset = 0,
      sortBy = 'created_at',
      sortOrder = 'desc',
    } = getEventsDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/list/to_paper',
        {
          pagination: {
            limit,
            offset,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      EventListToPaperResponse,
    );

    return {
      data: result.events,
      total: result.total_count,
    };
  }

  async convertToPaper(convertEventsToPaperDto: ConvertEventsToPaperDto) {
    const dto = plainToClass(ConvertEventsToPaperDto, convertEventsToPaperDto);
    await this.validator.validateOrReject(dto);

    await this.http.post(
      '/event/batch/to_paper',
      {
        events: dto.eventIds,
      },
      { withSide: true },
    );
  }

  async getDocuments(getDocumentsDto: GetDocumentsDto) {
    const dto = plainToClass(GetDocumentsDto, getDocumentsDto);
    await this.validator.validateOrReject(dto);

    return Promise.all(
      dto.documents.map(({ eventId, documentId }) =>
        this.http.getFile(`/event/${eventId}/document/${documentId}/file`, {
          withSide: true,
        }),
      ),
    );
  }
}
