import {
  IsString,
  IsPhoneNumber,
  IsNotEmpty,
  Length,
  IsNumberString,
  Matches,
} from 'class-validator';

import { IsEqualTo, IsSnils } from '@vk-hr-tek/core/validation';

import { REQUIRED_FIELD_VALIDATION_MESSAGE } from '../../auth.constants';

export class ChangePasswordDto {
  @Length(8, 128, {
    message: 'Должен содержать не менее 8 символов',
    groups: ['form'],
  })
  @Matches(/^[A-Za-z0-9!#{}@()%^\[\]~$\-_]+$/, {
    message: 'Должен содержать только буквы, цифры или символы !#{}@$()%^[]~-_',
    groups: ['form'],
  })
  @Matches(/[A-Z]/, {
    message: 'Должен содержать не менее 1 заглавной буквы',
    groups: ['form'],
  })
  @Matches(/[a-z]/, {
    message: 'Должен содержать не менее 1 строчной буквы',
    groups: ['form'],
  })
  @Matches(/[0-9\^\[\]~!#{}\/@\$()`%]/, {
    message: 'Должен содержать не менее 1 цифры либо 1 символа',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  password: string;

  @IsEqualTo('password', {
    message: 'Пароли не совпадают',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  passwordRepeat: string;

  @Length(6, 6, {
    message: 'Неверный СМС-код',
    groups: ['code-verify'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный СМС-код',
      groups: ['code-verify'],
    },
  )
  @IsString({
    groups: ['code-verify'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести СМС-код',
    groups: ['code-verify'],
  })
  code: string;

  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
  })
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  phone?: string;

  @IsSnils({
    message: 'Неверный СНИЛС',
  })
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  snils?: string;
}
