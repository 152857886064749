import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const FileIcon = () => (
  <SvgIcon viewBox="0 0 56 56" fontSize="inherit">
    <path
      d="M14.35 8.35C16.7 6 19.5 6 20 6C20.1903 6 31.711 6 36 6C36.5 6 39.3 6 41.65 8.35C44 10.7 44 13.5 44 14C44 24 43.9999 30.5 44 42C44 42.5 44 45.3 41.65 47.65C39.3 50 36.5 50 36 50C32.5802 50 23.035 50 20 50C19.5 50 16.7 50 14.35 47.65C12 45.3 12 42.5 12 42C12 31.4991 12 14.3465 12 14C12 13.5 12 10.7 14.35 8.35Z"
      fill="#EDF3FF"
    />
    <path
      d="M28 14C28 9.58172 31.5817 6 36 6C40.4183 6 44 9.58172 44 14V42C44 46.4183 40.4183 50 36 50C31.5817 50 28 46.4183 28 42V14Z"
      fill="#D1E1FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 26C16 25.4477 16.4477 25 17 25H39C39.5523 25 40 25.4477 40 26C40 26.5523 39.5523 27 39 27H17C16.4477 27 16 26.5523 16 26ZM16 30C16 29.4477 16.4477 29 17 29H39C39.5523 29 40 29.4477 40 30C40 30.5523 39.5523 31 39 31H17C16.4477 31 16 30.5523 16 30ZM17 33C16.4477 33 16 33.4477 16 34C16 34.5523 16.4477 35 17 35H24C24.5523 35 25 34.5523 25 34C25 33.4477 24.5523 33 24 33H17Z"
      fill="#BFCAE0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39 25H28V27H39C39.5523 27 40 26.5523 40 26C40 25.4477 39.5523 25 39 25ZM39 29H28V31H39C39.5523 31 40 30.5523 40 30C40 29.4477 39.5523 29 39 29Z"
      fill="#7393D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.5 17.75C42.5 18.1642 42.1642 18.5 41.75 18.5H38.75C35.5744 18.5 33 15.9256 33 12.75V9.75C33 9.33579 33.3358 9 33.75 9C34.1642 9 34.5 9.33579 34.5 9.75V12.75C34.5 15.0972 36.4028 17 38.75 17H41.75C42.1642 17 42.5 17.3358 42.5 17.75Z"
      fill="white"
    />
  </SvgIcon>
);
