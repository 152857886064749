import { useInject } from '@vk-hr-tek/core/ioc';
import { LocalStorage } from '@vk-hr-tek/core/local-storage';

export const ModeEnum = {
  VK: 'vk',
  OTP: 'otp',
  GENERAL: 'general',
  GENERAL_DARK: 'general-dark',
  VK_DARK: 'vk-dark',
} as const;

export type ModeType = typeof ModeEnum[keyof typeof ModeEnum];

export const useTheme = (): ModeType => {
  const themeLocalStorage =
    useInject<LocalStorage>(LocalStorage).getItem('theme');

  if (themeLocalStorage === 'vk') {
    return 'vk';
  } else if (themeLocalStorage === 'otp') {
    return 'otp';
  } else if (themeLocalStorage === 'general') {
    return 'general';
  } else if (themeLocalStorage === 'general-dark') {
    return 'general-dark';
  } else if (themeLocalStorage === 'vk-dark') {
    return 'vk-dark';
  } else if (window.REACT_APP_VKHRTEK_THEME === 'vk') {
    return themeLocalStorage === 'dark' ? 'vk-dark' : 'vk';
  } else if (window.REACT_APP_VKHRTEK_THEME === 'otp') {
    return 'otp';
  } else {
    return themeLocalStorage === 'dark' ? 'general-dark' : 'general';
  }
};
