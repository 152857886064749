import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core';

export const ScheduleEyeIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      {...props}
      htmlColor={theme.tokens.colors.text.dark.primary}
      viewBox="0 0 16 16"
      fontSize="inherit"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 5C10.7 5 12.5 7.4 12.5 8C12.5 8.6 10.7 11 8 11C5.3 11 3.5 8.6 3.5 8C3.5 7.4 5.3 5 8 5ZM8 10.25C9.24264 10.25 10.25 9.24264 10.25 8C10.25 6.75736 9.24264 5.75 8 5.75C6.75736 5.75 5.75 6.75736 5.75 8C5.75 9.24264 6.75736 10.25 8 10.25ZM8 9C8.55229 9 9 8.55229 9 8C9 7.44772 8.55229 7 8 7C7.44772 7 7 7.44772 7 8C7 8.55229 7.44772 9 8 9Z"
        fill={theme.tokens.colors.accent.text.review}
      />
    </SvgIcon>
  );
};
