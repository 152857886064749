import React from 'react';

import { Button } from '../../../Button';
import { DownloadFileIcon } from '../../../icons';
import { useStyles } from '../Preview.styles';

interface DownloadButtonProps {
  onClick?: () => Promise<void>;
}

export const DownloadButton = ({ onClick }: DownloadButtonProps) => {
  const classes = useStyles();

  return (
    <div className={classes.collapseBox}>
      {onClick && (
        <Button
          onClick={onClick}
          size="small"
          icon={<DownloadFileIcon />}
          variant="tertiaryLight"
          disableRipple
        >
          Скачать
        </Button>
      )}
    </div>
  );
};
