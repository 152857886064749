import { useDispatch } from '../../app/hooks';
import { logout } from '../slice';

export function useLogout(): () => void {
  const dispatch = useDispatch();

  return () => {
    dispatch(logout());
  };
}
