import { injectable } from 'inversify';

const ROTATION_ANGLE = -90;
const IMAGE_QUALITY = 1;

@injectable()
export class ImageRotator {
  /** Вращать изображение на 90° против часовой стрелки
   * При этом изображение отрисовыается на canvas,
   * чей 2-мерный контекстный метод rotate принимает значение в радианах (1 радиан = градусы * Пи/180).
   * Вращаем контекст и затем рисуем изображение
   * @param {Blob} blob
   * @return {Promise<Blob | null>}
   * */
  async rotate(blob: Blob): Promise<Blob | null> {
    return new Promise((resolve) => {
      const img = new Image();
      img.setAttribute('crossorigin', 'anonymous');

      img.src = URL.createObjectURL(blob);

      img.onload = function () {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        ctx.canvas.width = img.height;
        ctx.canvas.height = img.width;

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((ROTATION_ANGLE * Math.PI) / 180);
        ctx.translate(-canvas.height / 2, -canvas.width / 2);

        ctx.drawImage(img, 0, 0, img.width, img.height);

        canvas.toBlob(
          (updatedBlob: Blob | null) => {
            resolve(updatedBlob);
          },
          blob.type,
          IMAGE_QUALITY,
        );
      };
    });
  }
}
