import { injectable, inject } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';

import { AuthRouterService } from './auth.router.service';

@injectable()
export class AuthConfigService {
  private readonly key = 'authType';

  constructor(
    private authRouterService: AuthRouterService,
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
  ) {}

  getAuthType(): 'default' | 'candidate' | 'vk' | 'internalVpnOff' | 'unknown' {
    if (
      window.location.host === window.REACT_APP_VKHRTEK_CANDIDATE_DOMAIN ||
      this.localStorage.getItem(this.key) === 'candidate'
    ) {
      return 'candidate';
    }

    if (
      window.REACT_APP_VKHRTEK_INTERNAL_AUTH &&
      window.location.host === window.REACT_APP_VKHRTEK_GENERAL_DOMAIN
    ) {
      if (this.localStorage.getItem(this.key) === 'general') {
        return this.authRouterService.isVKUser() ? 'internalVpnOff' : 'default';
      }

      return 'vk';
    }

    if (
      window.location.host === window.REACT_APP_VKHRTEK_GENERAL_DOMAIN &&
      !window.REACT_APP_VKHRTEK_INTERNAL_AUTH &&
      this.authRouterService.isVKUser()
    ) {
      return 'internalVpnOff';
    }

    if (this.localStorage.getItem(this.key) === 'general') {
      return 'default';
    }

    if (this.localStorage.getItem(this.key) === 'vk') {
      return 'vk';
    }

    return 'unknown';
  }

  getApiUrl() {
    return window.REACT_APP_VKHRTEK_API_URL;
  }
}
