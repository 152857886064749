import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@app/store';

export const selectAuthInstance = createSelector(
  [(state: RootState) => state.auth.authInstances.instances],
  (instances) => instances[0] || {},
);

export const selectAuthInstanceStatus = (state: RootState) =>
  state.auth.authInstances.status;

export const selectAuthInstanceError = (state: RootState) =>
  state.auth.authInstances.error;

export const selectNotification = (state: RootState) => state.auth.notification;

export const selectStage = (state: RootState) => state.auth.stage;

export const selectPhone = (state: RootState) => state.auth.values.phone;

export const selectSnils = (state: RootState) => state.auth.values.snils;

export const selectInviteCode = (state: RootState) => state.auth.inviteCode;

export const selectAuthCode = (state: RootState) => state.auth.authCode;

export const selectOpenIdToken = (state: RootState) => state.auth.openIdToken;

export const selectPassCode = (state: RootState) => state.auth.passCode;

export const selectStatus = (state: RootState) => state.auth.status;

export const selectPassword = (state: RootState) => state.auth.values.password;
