export const SIDEBAR_OPENED_WIDTH = 280;

export const SIDEBAR_CLOSED_WIDTH = 64;

export const SIDEBAR_EXPAND_BUTTON_CLOSED_WIDTH = 40;

export const SIDEBAR_EXPAND_BUTTON_OPENED_WIDTH = 256;

export const TIMEOUT_FOR_FADE = 400;

export const TIMEOUT_FOR_SUBMENU_HOVER = 50;

export const ITEM_HEIGHT = 40;
