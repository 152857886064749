export type UnepType =
  | 'disabled'
  | 'goskey'
  | 'kontur'
  | 'cryptopro_simple'
  | 'cryptopro_local';

export enum UnepTypes {
  disabled = 'Без ЭЦП',
  goskey = 'Госключ',
  kontur = 'СКБ Контур',
  cryptopro_simple = 'КриптоПро',
  cryptopro_local = 'КриптоПро цифровой носитель',
}
