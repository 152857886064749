import { IsNotEmpty, IsNumber, IsUUID, IsArray } from 'class-validator';

export class BatchDownloadDto {
  @IsNotEmpty()
  @IsNumber()
  download_id: string;
}

export class BatchDownloadStartDto {
  @IsNotEmpty()
  @IsArray()
  @IsUUID(undefined, { each: true })
  event_ids: string[];
}
