import React, { ReactNode, useCallback, useMemo } from 'react';

import { Box } from '../Box';
import { Stage, StageStatus } from '../Stage';

export type StagesSize = 'small' | 'medium';

interface StagesProps {
  active: string;
  showHeader?: boolean;
  size?: StagesSize;
  steps: {
    name: string;
    content: ReactNode;
    label: string;
  }[];
  fullWidth?: boolean;
}

export const Stages = ({
  active,
  showHeader = false,
  size = 'medium',
  steps,
  fullWidth = false,
}: StagesProps) => {
  const completedSteps = useMemo(() => {
    if (!active) {
      return [];
    }

    let result: string[] = [];

    const activeIndex = steps.findIndex(({ name }) => name === active);
    if (activeIndex > -1) {
      result = steps
        .filter((_, index) => index < activeIndex)
        .map(({ name }) => name);
    }

    return result;
  }, [active, steps]);

  const getStatus = useCallback(
    (step: string): StageStatus => {
      if (completedSteps.includes(step)) {
        return 'completed';
      }

      if (step === active) {
        return 'active';
      }

      return 'initial';
    },
    [active, completedSteps],
  );

  if (!steps?.length) {
    return null;
  }

  const items = steps.map((step) => step);
  const first = items.shift();
  const last = items.pop();

  return (
    <>
      {showHeader && (
        <Box mb="32" className="aqa_stages" display="flex">
          {first && (
            <Stage
              first
              key={first.name}
              label={first.label}
              fullWidth={fullWidth}
              last={steps.length === 1}
              size={size}
              status={getStatus(first.name)}
            />
          )}
          {items.map((step) => (
            <Stage
              key={step.name}
              fullWidth={fullWidth}
              label={step.label}
              size={size}
              status={getStatus(step.name)}
            />
          ))}
          {last && (
            <Stage
              key={last.name}
              fullWidth={fullWidth}
              label={last.label}
              last
              size={size}
              status={getStatus(last.name)}
            />
          )}
        </Box>
      )}
      {steps.find(({ name }) => name === active)?.content || ''}
    </>
  );
};
