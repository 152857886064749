import { Attribute } from '@app/gen/events';

export type TextAttribute = Attribute & { value: string };

export const isTextAttribute = (
  attribute: Attribute,
): attribute is TextAttribute =>
  attribute.type !== 'file' && attribute.type !== 'file_multiple';

export type MultipleDocumentTextAttribute = Attribute & { value: string[] };

export const isMultipleDocumentTextAttribute = (
  attribute: Attribute,
): attribute is MultipleDocumentTextAttribute =>
  attribute.type !== 'file' && attribute.type !== 'file_multiple';

export type AttributeValueBase = Pick<Attribute, 'id' | 'type' | 'value'>;
