import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ tokens, px }) => ({
  listBox: {
    margin: 0,
    padding: 0,
  },

  scrollableWrapper: {
    margin: `${tokens.spacing['8']}px 0`,
    maxHeight: px(264),
    overflow: 'auto',
  },

  longScrollableWrapper: {
    maxHeight: px(352),
  },
}));

export default useStyles;
