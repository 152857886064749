import { IsString, IsUUID, IsPhoneNumber, IsNotEmpty } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

import { REQUIRED_FIELD_VALIDATION_MESSAGE } from '../../auth.constants';

export class RegisterInitDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
    groups: ['form'],
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
    groups: ['form'],
  })
  phone: string;

  @IsUUID()
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  inviteCode?: string;

  @IsSnils({
    message: 'Неверный СНИЛС',
  })
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  snils?: string;
}
