export interface TokenService {
  save(token: string): void;
  get(): string | null;
  delete(): void;
  restore(): Promise<void>;
  restoreToken(): Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TokenService = Symbol('TokenService');
