import { Attribute, AttributeFile } from '@app/gen/events';

export type FileAttribute = Attribute & { value: AttributeFile };
export type FileMultipleAttribute = Attribute & { value: AttributeFile[] };
export type TextAttribute = Attribute & { value: string };

export const isFileAttribute = (
  attribute: Attribute,
): attribute is FileAttribute => attribute.type === 'file';

export const isFileMultipleAttribute = (
  attribute: Attribute,
): attribute is FileMultipleAttribute => attribute.type === 'file_multiple';

export const isTextAttribute = (
  attribute: Attribute,
): attribute is TextAttribute =>
  attribute.type !== 'file' && attribute.type !== 'file_multiple';

export type MultipleDocumentFileAttribute = Attribute & {
  value: AttributeFile[];
};
export type MultipleDocumentFileMultipleAttribute = Attribute & {
  value: AttributeFile[][];
};
export type MultipleDocumentTextAttribute = Attribute & { value: string[] };

export const isMultipleDocumentFileAttribute = (
  attribute: Attribute,
): attribute is MultipleDocumentFileAttribute => attribute.type === 'file';

export const isMultipleDocumentFileMultipleAttribute = (
  attribute: Attribute,
): attribute is MultipleDocumentFileMultipleAttribute =>
  attribute.type === 'file_multiple';

export const isMultipleDocumentTextAttribute = (
  attribute: Attribute,
): attribute is MultipleDocumentTextAttribute =>
  attribute.type !== 'file' && attribute.type !== 'file_multiple';

export type AttributeValueBase = Pick<Attribute, 'id' | 'type' | 'value'>;
