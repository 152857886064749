import React from 'react';

import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Box } from '@vk-hr-tek/ui/Box';
import { List, ListItem } from '@vk-hr-tek/ui/List';
import { ChipColorUnion, ColoredChip } from '@vk-hr-tek/ui/ColoredChip';

interface UnitSectionLabelProps {
  settingList: {
    id: string;
    name: string;
  }[];
  label: string;
  chipColor: ChipColorUnion;
}

export const UnitSectionLabel = ({
  settingList,
  label,
  chipColor,
}: UnitSectionLabelProps) => (
  <Tooltip
    title={
      <>
        {settingList.length === 1 && settingList[0].name}
        {settingList.length > 1 && (
          <Box ml="16" fontSize={12}>
            <List listStyleType="disc">
              {settingList.map((item) => (
                <ListItem key={item.id}>{item.name}</ListItem>
              ))}
            </List>
          </Box>
        )}
      </>
    }
    placement="bottom"
  >
    <span>
      <ColoredChip
        color={chipColor}
        label={label}
        counter={settingList.length}
      />
    </span>
  </Tooltip>
);
