import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  menu: {
    padding: 0,
  },
  link: {
    padding: theme.spacing(1.8),
    minWidth: 178,
    backgroundColor: 'transparent !important',
    borderBottom: `1px solid ${theme.tokens.colors.stroke.primary}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  link_type_logout: {
    color: theme.tokens.colors.accent.text.error,
    borderBottom: 'none',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    color: theme.tokens.colors.stroke.secondary,
  },
  dialog: {
    minHeight: 'auto',
  },
}));
