import 'reflect-metadata';

import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { container, IocProvider } from '@vk-hr-tek/core/ioc';
import { history } from '@vk-hr-tek/core/history';
import { monitoringInit } from '@vk-hr-tek/core/monitoring/monitoringInit';

import { createStore } from '@app/store';

import { serviceWorkerRegister } from './serviceWorkerRegister';
import App from './App';

const { store, injectReducer } = createStore();
monitoringInit(history);

ReactDOM.render(
  <Provider store={store}>
    <IocProvider container={container}>
      <App injectReducer={injectReducer} />
    </IocProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorkerRegister();
