import { createEntityAdapter } from '@reduxjs/toolkit';

export interface LayoutState {
  isServiceUnavailable: boolean;
  sidebarState: boolean;
}

export const initialState: LayoutState = {
  isServiceUnavailable: false,
  sidebarState: false,
};

export const layoutAdapter = createEntityAdapter();
