import { GetFilterOptionsByUrlDto } from '../dto';

export interface ListService {
  getFilterOptionsByUrl(
    getFilterOptionsByUrlDto: GetFilterOptionsByUrlDto,
  ): Promise<unknown>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListService = Symbol('ListService');
