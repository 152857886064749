import { makeStyles, Theme } from '@material-ui/core';

interface StylesProps {
  rows: number;
  wordBreak: 'normal' | 'break-all' | 'keep-all' | 'break-word';
  hyphens?: boolean;
}

const useStyles = makeStyles<Theme, StylesProps>(() => ({
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box !important',
    ['-webkit-line-clamp']: ({ rows }) => rows,
    ['-webkit-box-orient']: 'vertical',
    overflowWrap: 'break-word',
    wordBreak: ({ wordBreak }) => wordBreak,
    whiteSpace: 'normal',
    hyphens: ({ hyphens }) => (hyphens ? 'auto' : 'none'),
  },
}));

export default useStyles;
