import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { ValidationService } from '@vk-hr-tek/core/validation';
import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { FilterService, Filter } from '@vk-hr-tek/core/filter';

import {
  PolicyAcceptedZipResponse,
  CompanyPolicyXlsxResponse,
} from '@app/gen/policy';

import {
  DownloadPolicyVersionDto,
  DownloadEmployeePolicyDto,
  GetPolicyDto,
} from '../dto';

@injectable()
export class PolicyDownloadService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private filterService: FilterService,
  ) {}

  async startDownloadAllAcceptedPolicies(
    downloadDto: DownloadPolicyVersionDto,
  ) {
    const { id, versionId } = downloadDto;

    return this.unmarshaller.unmarshall(
      await this.http.post(
        `/policy/${id}/document/${versionId}/accepted/zip`,
        {},
        {
          withSide: true,
        },
      ),
      PolicyAcceptedZipResponse,
    );
  }

  async startDownloadEmployeeAcceptedPolicies(
    downloadDto: DownloadEmployeePolicyDto,
  ) {
    const { id, versionId, employeeId } = downloadDto;

    return this.unmarshaller.unmarshall(
      await this.http.post(
        `/policy/${id}/document/${versionId}/accepted/zip?employee_id=${employeeId}`,
        {},
        { withSide: true },
      ),
      PolicyAcceptedZipResponse,
    );
  }

  async startDownloadXlsx(
    getPolicyDto: GetPolicyDto,
    availableFilters: Filter[],
  ) {
    const policyDto = plainToClass(
      this.filterService.buildDto(GetPolicyDto, availableFilters),
      getPolicyDto,
    );
    await this.validator.validateOrReject(policyDto);

    /* istanbul ignore next */
    const { filters } = policyDto;

    let combineFilters;

    if (filters) {
      combineFilters = { ...filters };
    } else if (filters) {
      combineFilters = filters;
    } else {
      combineFilters = {};
    }

    return this.unmarshaller.unmarshall(
      await this.http.post(
        `/policy/company/xlsx`,
        {
          filters: combineFilters,
        },
        { withSide: true },
      ),
      CompanyPolicyXlsxResponse,
    );
  }
}
