import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MoreHorizontalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="8"
      viewBox="0 0 18 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.281615 0.459998C0.579849 0.062353 1.14397 -0.0182357 1.54162 0.279998L9.00162 5.875L16.4616 0.279998C16.8593 -0.0182357 17.4234 0.062353 17.7216 0.459998C18.0198 0.857643 17.9393 1.42176 17.5416 1.72L9.54162 7.72C9.22162 7.96 8.78162 7.96 8.46162 7.72L0.461616 1.72C0.0639704 1.42176 -0.0166183 0.857643 0.281615 0.459998Z"
        fill="#BAC6D1"
      />
    </SvgIcon>
  );
};
