import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { CandidatesRouter } from '../../../candidates/types';
import { modules } from '../../modules';

@injectable()
export class CandidatesRouterService implements CandidatesRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToCreate(values: { companyId: string; profileType: string }) {
    this.history.push({
      pathname: `/${this.role.get()}/${modules.candidates.basename}/create`,
      search: `?${new URLSearchParams(values).toString()}`,
    });
  }

  goToDetail(id: string) {
    this.history.push(
      `/${this.role.get()}/${modules.candidates.basename}/${id}`,
    );
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(`/${this.role.get()}/${modules.candidates.basename}`);
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.candidates.basename}`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
