import { injectable } from 'inversify';

import { FormatService } from '@vk-hr-tek/core/format';
import { TypographyColor } from '@vk-hr-tek/ui/Typography';
import { ChipColorUnion } from '@vk-hr-tek/ui/ColoredChip';

import {
  UserGroup,
  UserResponse,
  ManagerSubstitutesEventType,
  CompanyEmployee,
  UkepCertificate,
  Attorney,
  ManageableCompanyItem,
} from '@app/gen/users';
import { ProfileItem } from '@app/gen/settings';
import {
  AttorneyType,
  AttorneyStatus,
  AttorneyReadableStatus,
} from '@app/types';
import { ATTORNEYS_POWERS_DEFAULT } from '@app/constants';

import { CompanyPositionSubstitutes } from '../slice/user.state';

@injectable()
export class UserMapper {
  constructor(private format: FormatService) {}

  process(
    user: Omit<UserResponse, 'certificate'> & {
      certificate: Omit<UserResponse['certificate'], 'status'> & {
        status:
          | 'waiting_to_create'
          | 'need_verify'
          | 'waiting_confirmation'
          | 'verifying'
          | 'released'
          | 'expired'
          | 'error'
          | 'need_verify_code'
          | 'release_paused';
      };
    },
  ) {
    return {
      id: user.id,
      name: `${user.last_name} ${user.first_name} ${user.middle_name}`,
      birthday: this.format.toDate(user.birth_date + 'T00:00:00'),
      avatar: `${user.last_name[0]}${user.first_name[0]}`.toUpperCase(),
      snils: this.format.toSnils(user.snils),
      phone: this.format.toPhone(user.phone),
      certificate: {
        exists: user.certificate.exists,
        type: 'УНЭП',
        description: 'УНЭП (Усиленная неквалифицированная электронная подпись',
        activity:
          user.certificate.valid_from && user.certificate.valid_to
            ? `Действителен: с ${this.format.toDate(
                user.certificate.valid_from,
              )} по ${this.format.toDate(user.certificate.valid_to)}`
            : '',
      },
      notifications: {
        telegram: user.telegram_enabled,
      },
      groups: this.processGroups(user.groups, user.attorneys),
      employees: user.employees.map((employee) => ({
        ...employee,
        dismissed_at: employee.dismissed_at
          ? this.format.toDate(employee.dismissed_at)
          : '',
        editSubstitutes: employee.permissions.edit_substitutes,
        vacation_days: employee.vacation_days,
      })),
      has_company_side: user.has_company_side,
      permissions: {
        editSubstitutes: user.employees.some(
          (employee) => employee.permissions.edit_substitutes,
        ),
      },
      ukepCertificates: this.processUkepCertificates(user?.ukep_certificates),
      attorneys: user.attorneys.map((attorney) =>
        this.processAttorney(attorney),
      ),
    };
  }

  processUkepCertificates(certificates: UkepCertificate[] | undefined) {
    if (!certificates) return;

    const uniqueCertificates = new Map();

    certificates.forEach((certificate) => {
      if (uniqueCertificates.has(certificate.serial_number)) {
        const current = uniqueCertificates.get(certificate.serial_number);
        uniqueCertificates.set(certificate.serial_number, {
          ...current,
          company_name: `${current.company_name}, ${certificate.company_name}`,
        });
      } else {
        uniqueCertificates.set(certificate.serial_number, certificate);
      }
    });

    return Array.from(uniqueCertificates.values()).map((certificate) => ({
      activity:
        certificate.valid_from && certificate.valid_to
          ? `Действителен: с ${this.format.toDate(
              certificate.valid_from,
            )} по ${this.format.toDate(certificate.valid_to)}`
          : '',
      companyId: certificate.company_id,
      companyName: `Загружен для: ${certificate.company_name}`,
      description: 'УКЭП (Усиленная квалифицированная электронная подпись',
      issuedBy: `Выдан: ${certificate.issued_by}`,
      owner: `Владелец: ${certificate.owner}`,
      serialNumber: `Сертификат: ${certificate.serial_number}`,
      type: 'УКЭП',
    }));
  }

  processAttorneyStatus(
    status: AttorneyStatus,
    isDefault: boolean,
  ): AttorneyReadableStatus {
    if (status === 'new') {
      return 'На подписании';
    }

    if (status === 'expired') {
      return 'Истекла';
    }

    if (status === 'revoked') {
      return 'Отозвана';
    }

    if (status === 'canceled') {
      return 'Отключена';
    }

    if (isDefault && (status === 'signed' || status === 'revoking')) {
      return 'Активна';
    }

    return status === 'signed' ? 'Действующая' : 'В процессе отзыва';
  }

  processAttorneyStatusToTypographyColor(
    attorneyStatus: AttorneyStatus,
    attorneyIsDefault: boolean,
  ): TypographyColor {
    if (attorneyStatus === 'new') {
      return 'warning';
    }

    if (
      attorneyIsDefault &&
      (attorneyStatus === 'signed' || attorneyStatus === 'revoking')
    ) {
      return 'success';
    }

    if (attorneyStatus === 'revoking') {
      return 'warning';
    }

    return 'textTertiary';
  }

  processAttorneyStatusToChipColor(
    attorneyStatus: AttorneyStatus,
  ): ChipColorUnion {
    if (attorneyStatus === 'new') {
      return 'blue';
    }

    if (attorneyStatus === 'signed') {
      return 'green';
    }

    if (attorneyStatus === 'revoking') {
      return 'orange';
    }

    if (attorneyStatus === 'revoked') {
      return 'red';
    }

    if (attorneyStatus == 'canceled') {
      return 'purple';
    }

    return 'gray';
  }

  processAttorney(attorney: Attorney): AttorneyType {
    return {
      id: attorney.id,
      UID: attorney.id,
      status: attorney.status,
      readableStatus: this.processAttorneyStatus(
        attorney.status,
        attorney.is_default,
      ),
      snils: this.format.toSnils(attorney.snils),
      issuer: attorney.issuer,
      isDefault: attorney.is_default,
      creationDate: this.format.toDate(attorney.creation_date),
      validFrom: this.format.toDate(attorney.valid_from),
      validTo: this.format.toDate(attorney.valid_to),
      companyId: attorney.company.id,
      companyName: attorney.company.name,
      powers: attorney.powers || ATTORNEYS_POWERS_DEFAULT,
      revocationReason: attorney.revocation_reason || '',
      imported: attorney.imported,
      fullName: attorney.full_name,
      typographyColor: this.processAttorneyStatusToTypographyColor(
        attorney.status,
        attorney.is_default,
      ),
      chipColor: this.processAttorneyStatusToChipColor(attorney.status),
    };
  }

  processGroups(groups: UserGroup[], attorneys: Attorney[]) {
    const companies: Record<
      string,
      {
        id: string;
        name: string;
        roles: { id: number; name: string; profiles?: ProfileItem[] }[];
        attorneys: AttorneyType[];
      }
    > = {};

    groups.forEach((group) => {
      if (group.company.id in companies) {
        companies[group.company.id].roles.push({
          id: group.id,
          name: group.name,
          profiles: group.profiles,
        });
      } else {
        companies[group.company.id] = {
          id: group.company.id,
          name: group.company.name,
          roles: [{ id: group.id, name: group.name, profiles: group.profiles }],
          attorneys: attorneys
            .filter(
              (attorney) =>
                attorney.company.id === group.company.id &&
                (attorney.status === 'new' ||
                  attorney.status === 'signed' ||
                  attorney.status === 'revoking'),
            )
            .map((attorney) => this.processAttorney(attorney)),
        };
      }
    });

    return Object.keys(companies).map((id) => companies[id]);
  }

  processEmployees(employee: CompanyEmployee) {
    return {
      id: employee.id,
      name: employee.name,
      personnelNumber: employee.personnel_number,
      email: employee.email,
      position: employee.position,
      phone: employee.phone,
      company: {
        id: employee.company.id,
        name: employee.company.name,
      },
    };
  }

  processManagerSubstitutesEventType(
    event_types: ManagerSubstitutesEventType[],
  ) {
    return event_types.map(({ substitutes, event_type }) => ({
      substitutes: substitutes.map((substitute) => ({
        ...this.processEmployees(substitute),
      })),
      eventType: {
        id: event_type.id,
        name: event_type.name,
      },
    }));
  }

  processSubstitutes(
    items: ManageableCompanyItem[],
  ): CompanyPositionSubstitutes[] {
    return items.map(
      ({ id, name, employee, substitutes, functional_manageable_units }) => ({
        company: { id, name },
        employee: {
          id: employee.id,
          personalNumber: employee.personnel_number,
          position: employee.position || '',
          showPosition: items.filter((item) => item.id === id).length > 1,
        },
        eventTypeGroup: substitutes.map(
          ({ event_type, substitutes: eventTypeSubstitutes }) => ({
            eventType: {
              id: event_type.id,
              name: event_type.name,
            },
            substitutes: eventTypeSubstitutes
              .map(
                ({
                  id: substituteId,
                  name: substituteName,
                  personnel_number,
                }) => ({
                  id: substituteId,
                  name: substituteName,
                  personalNumber: personnel_number,
                }),
              )
              .sort((a, b) => a.name.localeCompare(b.name)),
          }),
        ),
        manageableUnits: functional_manageable_units?.map((item) => ({
          unit: {
            id: item.unit.id,
            name: item.unit.name,
          },
          allSetting: item.all_setting_event_types,
          directSetting: item.direct_setting_event_types,
        })),
      }),
    );
  }

  processToken1CExpiresAtDate(expiresAt: string) {
    return this.format.toDate(expiresAt);
  }
}
