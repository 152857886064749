import { createSlice } from '@reduxjs/toolkit';

import { initialState, eventsAdapter } from './events.state';
import {
  eventsListReducers,
  eventDetailReducers,
  eventActionsReducers,
  creationReducers,
  eventsPaperReducers,
  eventTypeReducers,
  flowReducers,
} from './actions';

export const events = createSlice({
  name: 'events',
  initialState: eventsAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    eventsListReducers(builder);
    eventDetailReducers(builder);
    eventActionsReducers(builder);
    creationReducers(builder);
    eventsPaperReducers(builder);
    eventTypeReducers(builder);
    flowReducers(builder);
  },
});

export const eventsReducer = events.reducer;
