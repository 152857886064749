import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

const SVGCalendarIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95887 3.88524H3.78271C4.03277 3.88524 4.23552 3.68279 4.23552 3.43273V0.788611C4.23552 0.538525 4.03277 0.335938 3.78271 0.335938H2.95887C2.70879 0.335938 2.50606 0.538525 2.50606 0.788611V3.43273C2.50606 3.68279 2.70879 3.88524 2.95887 3.88524Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19127 7.69207C5.19127 7.52504 5.0558 7.38973 4.88864 7.38973H3.83254C3.66557 7.38973 3.53007 7.52504 3.53007 7.69207V8.74802C3.53007 8.91527 3.66557 9.05063 3.83254 9.05063H4.88864C5.0558 9.05063 5.19127 8.91527 5.19127 8.74802V7.69207Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83044 7.69207C7.83044 7.52504 7.69493 7.38973 7.5281 7.38973H6.47187C6.3049 7.38973 6.16939 7.52504 6.16939 7.69207V8.74802C6.16939 8.91527 6.3049 9.05063 6.47187 9.05063H7.5281C7.69493 9.05063 7.83044 8.91527 7.83044 8.74802V7.69207Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1672 7.38973C10.3342 7.38973 10.4697 7.52504 10.4697 7.69207V8.74802C10.4697 8.91527 10.3342 9.05063 10.1672 9.05063H9.11111C8.94395 9.05063 8.80847 8.91527 8.80847 8.74802V7.69207C8.80847 7.52504 8.94395 7.38973 9.11111 7.38973H10.1672Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19127 10.3313C5.19127 10.164 5.0558 10.0288 4.88864 10.0288H3.83254C3.66557 10.0288 3.53007 10.164 3.53007 10.3313V11.3871C3.53007 11.5542 3.66557 11.6895 3.83254 11.6895H4.88864C5.0558 11.6895 5.19127 11.5542 5.19127 11.3871V10.3313Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5281 10.0288C7.69493 10.0288 7.83044 10.164 7.83044 10.3313V11.3871C7.83044 11.5542 7.69493 11.6895 7.5281 11.6895H6.47187C6.3049 11.6895 6.16939 11.5542 6.16939 11.3871V10.3313C6.16939 10.164 6.3049 10.0288 6.47187 10.0288H7.5281Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4697 10.3313C10.4697 10.164 10.3342 10.0288 10.1673 10.0288H9.11111C8.94395 10.0288 8.80847 10.164 8.80847 10.3313V11.3871C8.80847 11.5542 8.94395 11.6895 9.11111 11.6895H10.1673C10.3342 11.6895 10.4697 11.5542 10.4697 11.3871V10.3313Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3582 3.43354V1.82065C12.9953 1.83985 13.5187 2.36698 13.5187 3.0149V12.4672C13.5187 13.1262 12.9827 13.6694 12.3236 13.6694H1.67608C1.01598 13.6694 0.480957 13.1273 0.480957 12.4672V3.0149C0.480957 2.36698 1.00443 1.83985 1.64147 1.82065V3.43354C1.64147 4.16255 2.23284 4.75001 2.96171 4.75001H3.79476C4.5236 4.75001 5.12282 4.16255 5.12282 3.43354V1.81486H8.87684V3.43354C8.87684 4.16255 9.47608 4.75001 10.205 4.75001H11.0379C11.7668 4.75001 12.3582 4.16255 12.3582 3.43354ZM11.4547 12.3953C11.7401 12.3953 11.9714 12.1638 11.9714 11.8786L11.9713 6.99612C11.9713 6.71074 11.74 6.47931 11.4547 6.47931H2.52221C2.2368 6.47931 2.00554 6.71074 2.00554 6.99612V11.8786C2.00554 12.1638 2.23683 12.3953 2.52221 12.3953H11.4547Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0181 3.88524H10.1943C9.94425 3.88524 9.7415 3.68279 9.7415 3.43273V0.788611C9.7415 0.538525 9.94425 0.335938 10.1943 0.335938H11.0181C11.268 0.335938 11.4708 0.538525 11.4708 0.788611V3.43273C11.4708 3.68279 11.268 3.88524 11.0181 3.88524Z"
    />
  </SvgIcon>
);

export const CalendarIcon = ({
  size = 'normal',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGCalendarIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
