import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { PrintService } from '@vk-hr-tek/core/print';
import { QueryService } from '@vk-hr-tek/core/query';
import { showNotification, showError } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import { EventListItem as Event } from '@app/gen/events';
import { ThunkExtra } from '@app/store';

import { EventsPaperService } from '../../services';
import {
  GetEventsDto,
  GetEventDto,
  GetDocumentDto,
  ConvertEventsToPaperDto,
} from '../../dto';
import {
  EventsState,
  EventsWithRootState,
  startLoading,
  completeLoading,
  setError,
  eventsAdapter,
} from '../events.state';

import { getEvent } from './detail.actions';

export const getPaperEvents = createAsyncThunk<
  {
    data: Event[];
    total: number;
  },
  GetEventsDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getPaperEvents',
  async (getEventsDto, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(EventsPaperService).getPaperEvents(
        getEventsDto,
      );
      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAdditionalPaperEvents = createAsyncThunk<
  {
    data: Event[];
    total: number;
  },
  GetEventsDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/getAdditionalPaperEvents',
  async (getEventsDto, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(EventsPaperService).getPaperEvents(
        getEventsDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const convertOneToPaper = createAsyncThunk<
  void,
  GetEventDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/convertOneToPaper',
  async (getEventDto, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const eventService = inject(EventsPaperService);
      await eventService.convertToPaper({ eventIds: [getEventDto.id] });

      dispatch(getEvent(getEventDto));
    } catch (err) {
      dispatch(showError('При изменении статуса заявки произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const convertToPaper = createAsyncThunk<
  void,
  ConvertEventsToPaperDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/convertToPaper',
  async (
    convertEventsToPaperDto,
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      const queryService = inject(QueryService);
      const eventService = inject(EventsPaperService);
      await eventService.convertToPaper(convertEventsToPaperDto);

      dispatch(
        getPaperEvents(
          plainToClass(
            GetEventsDto,
            queryService.parse(history.location.search),
          ),
        ),
      );

      dispatch(
        showNotification(
          `Заявки (${convertEventsToPaperDto.eventIds.length}) успешно переведены в бумажный ДО`,
        ),
      );
    } catch (err) {
      dispatch(showError('При изменении статуса заявки произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const printAllDocumentsInEvent = createAsyncThunk<
  void,
  GetEventDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/printAllDocumentsInEvent',
  async (
    getEventDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const event = getState().events.detail.data;

      if (
        !event ||
        event.id !== getEventDto.id ||
        !event.documents ||
        !event.documents.length
      ) {
        throw new AppError('client', {
          code: 400,
          message: 'Invalid request',
        });
      }

      const documents = event.documents.map(({ id }) => ({
        documentId: id,
        eventId: event.id,
      }));

      event.nodes?.forEach((node) => {
        node.actions.forEach((action) => {
          if (action.documents) {
            action.documents.forEach((document) =>
              documents.push({
                documentId: document.id,
                eventId: event.id,
              }),
            );
          }
        });
      });

      const res = await inject(EventsPaperService).getDocuments({
        documents,
      });
      const files = await Promise.all(res.map((item) => item.file));

      await inject(PrintService).print(files);
    } catch (err) {
      dispatch(showError('При печати документов произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const printDocument = createAsyncThunk<
  void,
  GetDocumentDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/printDocument',
  async (
    getDocumentDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const event = getState().events.detail.data;

      if (!event || event.id !== getDocumentDto.eventId) {
        throw new AppError('client', {
          code: 400,
          message: 'Invalid request',
        });
      }

      const res = await inject(EventsPaperService).getDocuments({
        documents: [getDocumentDto],
      });
      const files = await Promise.all(res.map((item) => item.file));

      await inject(PrintService).print(files);
    } catch (err) {
      dispatch(showError('При печати документов произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const printDocuments = createAsyncThunk<
  void,
  ConvertEventsToPaperDto,
  ThunkExtra<EventsWithRootState>
>(
  'events/printDocuments',
  async (
    convertEventsToPaperDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const entities = getState().events.entities;

      const events = convertEventsToPaperDto.eventIds
        .map((id) => entities[id])
        .filter((event): event is Event => event !== undefined);

      const initialValue: { eventId: string; documentId: string }[] = [];

      const res = await inject(EventsPaperService).getDocuments({
        documents: events.reduce((acc, { id: eventId, documents }) => {
          return [
            ...acc,
            ...(documents
              ? documents.map(({ id }) => ({ documentId: id, eventId }))
              : []),
          ];
        }, initialValue),
      });

      const files = await Promise.all(res.map((item) => item.file));

      await inject(PrintService).print(files);
    } catch (err) {
      dispatch(showError('При печати документов произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const eventsPaperReducers = (
  builder: ActionReducerMapBuilder<EntityState<Event> & EventsState>,
) => {
  builder.addCase(getPaperEvents.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(convertToPaper.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(convertOneToPaper.pending, (state) => {
    state.detail.status = 'loading';
    state.detail.error = null;
  });
  builder.addCase(printDocuments.pending, (state) => {
    state.status = 'loading';
    state.error = null;
  });
  builder.addCase(printAllDocumentsInEvent.pending, (state) => {
    state.detail.status = 'loading';
    state.detail.error = null;
  });
  builder.addCase(printDocuments.fulfilled, (state) => {
    completeLoading(state);
  });
  builder.addCase(printAllDocumentsInEvent.fulfilled, (state) => {
    state.detail.status = 'complete';
    state.detail.error = null;
  });
  builder.addCase(getAdditionalPaperEvents.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.total = payload.total;
    state.currentIds = [
      ...state.currentIds,
      ...payload.data.map(({ id }) => id),
    ];
    eventsAdapter.upsertMany(state, payload.data);
  });
  builder.addCase(getPaperEvents.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.total = payload.total;
    state.currentIds = payload.data.map(({ id }) => id);
    eventsAdapter.upsertMany(state, payload.data);
  });
  builder.addCase(
    getPaperEvents.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        setError(state, { payload, error });
      }
    },
  );
  builder.addCase(convertToPaper.rejected, (state) => {
    completeLoading(state);
  });
  builder.addCase(convertOneToPaper.rejected, (state) => {
    completeLoading(state);
  });
  builder.addCase(printDocuments.rejected, (state) => {
    completeLoading(state);
  });
  builder.addCase(printAllDocumentsInEvent.rejected, (state) => {
    state.detail.status = 'complete';
    state.detail.error = null;
  });
};
