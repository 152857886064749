import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const UsersIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99999 5C3.99999 5.55229 4.44771 6 4.99999 6C5.55228 6 5.99999 5.55229 5.99999 5C5.99999 4.44772 5.55228 4 4.99999 4C4.44771 4 3.99999 4.44772 3.99999 5ZM4.99999 2.5C3.61928 2.5 2.49999 3.61929 2.49999 5C2.49999 6.38071 3.61928 7.5 4.99999 7.5C6.3807 7.5 7.49999 6.38071 7.49999 5C7.49999 3.61929 6.3807 2.5 4.99999 2.5Z"
      fill="#99A2AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99999 5C9.99999 5.55229 10.4477 6 11 6C11.5523 6 12 5.55229 12 5C12 4.44772 11.5523 4 11 4C10.4477 4 9.99999 4.44772 9.99999 5ZM11 2.5C9.61928 2.5 8.49999 3.61929 8.49999 5C8.49999 6.38071 9.61928 7.5 11 7.5C12.3807 7.5 13.5 6.38071 13.5 5C13.5 3.61929 12.3807 2.5 11 2.5Z"
      fill="#99A2AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.69466 10.4625C2.27852 10.6828 1.99961 11.0154 2 11.5C3.99999 11.5016 5.99999 11.5016 7.99998 11.5C8.00038 11.0063 7.74467 10.6951 7.30532 10.4625C6.76996 10.179 5.96404 10 4.99999 10C4.03594 10 3.23002 10.179 2.69466 10.4625ZM1.99282 9.13679C2.81163 8.70331 3.88071 8.5 4.99999 8.5C6.11927 8.5 7.18835 8.70331 8.00716 9.13679C8.83442 9.57476 9.49999 10.3126 9.49999 11.3529C9.49999 11.5142 9.50305 11.6881 9.49069 11.8395C9.48009 11.9692 9.45385 12.1407 9.36375 12.3175C9.24391 12.5527 9.05268 12.7439 8.81748 12.8638C8.64065 12.9539 8.46922 12.9801 8.33946 12.9907C8.22482 13.0001 8.09237 13 7.9702 13C5.9968 12.9995 4.02339 13 2.04999 13C1.92235 13 1.78152 13.0006 1.66052 12.9907C1.53076 12.9801 1.35933 12.9539 1.1825 12.8638C0.9473 12.7439 0.756074 12.5527 0.636232 12.3175C0.546133 12.1407 0.519895 11.9692 0.509293 11.8395C0.496084 11.6778 0.49999 11.515 0.49999 11.3529C0.49999 10.3126 1.16556 9.57476 1.99282 9.13679Z"
      fill="#99A2AD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9536 10.0001C10.5394 10.0026 10.2016 9.66886 10.1992 9.25465C10.1967 8.84044 10.5304 8.50265 10.9447 8.50017C11.9856 8.49705 13.0761 8.64389 14.0072 9.13679C14.8344 9.57476 15.5 10.3126 15.5 11.3529C15.5 11.5142 15.5031 11.6881 15.4907 11.8395C15.4801 11.9692 15.4539 12.1407 15.3638 12.3175C15.2439 12.5527 15.0527 12.7439 14.8175 12.8638C14.6407 12.9539 14.4692 12.9801 14.3395 12.9907C14.2185 13.0006 14.0776 13 13.95 13H11.4531C11.0389 13 10.7031 12.6642 10.7031 12.25C10.7031 11.8358 11.0389 11.5 11.4531 11.5C12.3021 11.5 13.151 11.5007 14 11.5C14.0004 11.0063 13.7447 10.6951 13.3053 10.4625C12.5959 10.0869 11.7438 9.99777 10.9536 10.0001Z"
      fill="#99A2AD"
    />
  </SvgIcon>
);
