import {
  IsString,
  IsNotEmpty,
  IsOptional,
  IsUUID,
  IsNumber,
} from 'class-validator';

export class CancelEventDto {
  @IsNotEmpty()
  @IsUUID()
  eventId: string;

  @IsNotEmpty({
    groups: ['form'],
  })
  @IsNumber(undefined, {
    groups: ['form'],
  })
  reasonId: number;

  @IsOptional({
    groups: ['form'],
  })
  @IsString({
    groups: ['form'],
  })
  comment?: string;
}
