import { injectable } from 'inversify';

import { GetFileRequestStateResponse } from '../gen/tools';
import { HttpService } from '../../http';
import { UnmarshallerService } from '../../unmarshaller';
import { DownloadDto } from '../dto';

@injectable()
export class NotificationDownloadService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getStatus(downloadDto: DownloadDto) {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/file_request',
        {
          download_id: downloadDto.download_id,
        },
        { withSide: true },
      ),
      GetFileRequestStateResponse,
    );
  }

  async getArchive(downloadDto: DownloadDto) {
    const result = this.http.getFile(
      `/file_request/download?download_id=${downloadDto.download_id}`,
      { withSide: true },
    );

    return result;
  }
}
