import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  notifications: {
    position: 'relative',
  },
  stack: {
    position: 'fixed',
    top: 92,
    right: 20,
    zIndex: 10000,
    width: 'calc(100% - 40px)',
    maxWidth: 450,

    [theme.breakpoints.up('xs')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      width: 360,
    },
    [theme.breakpoints.up('md')]: {
      width: 450,
    },
  },

  content: {
    color: theme.tokens.colors.text.dark.primary,
  },
}));
