import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens }) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: tokens.spacing['8'],
    borderRadius: tokens.radius.s,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },
  },

  active: {
    backgroundColor: tokens.colors.accent.bg.blue,

    '&:hover': {
      backgroundColor: tokens.colors.accent.bg.blue,
    },
  },
}));
