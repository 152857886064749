import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  {
    sidebarIconWidth: number;
    vkTheme: boolean;
  }
>(({ px, tokens, transitions }) => ({
  sidebar: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: 64,
    minWidth: 64,
    height: ({ vkTheme }) => (vkTheme ? 'calc(100vh - 37px)' : '100vh'),
    overflowX: 'hidden',
    overflowY: 'auto',
    backgroundColor: tokens.colors.bg.light.primary,
    borderRight: `1px solid ${tokens.colors.stroke.primary}`,
    padding: tokens.spacing['12'],
    transition: `all ${transitions.easing.easeInOut} 0.5s`,
    willChange: 'width',

    '&::-webkit-scrollbar': {
      width: 0,
    },
  },

  sidebarOpened: {
    width: 280,
    minWidth: 280,
  },

  sidebarMenu: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacing['16'],
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: tokens.spacing['40'],
    paddingBottom: tokens.spacing['32'],

    '&::-webkit-scrollbar': {
      width: 0,
    },
  },

  overlayTop: {
    position: 'absolute',
    top: 50,
    left: 0,
    height: 36,
    width: '100%',
    background: `linear-gradient(180deg, ${tokens.colors.bg.light.primary} 50%, rgba(0, 0, 0, 0) 100%)`,
    zIndex: 1,
  },

  overlayBottom: {
    position: 'absolute',
    bottom: 31,
    left: 0,
    height: 36,
    width: '100%',
    background: `linear-gradient(0deg, ${tokens.colors.bg.light.primary} 50%, rgba(0, 0, 0, 0) 100%)`,
  },

  sidebarLogo: {
    display: 'flex',
    alignItems: 'center',
    width: 'auto',
    minHeight: 40,
    height: 40,
    overflow: 'hidden',
    transition: `width ${transitions.easing.easeInOut} 0.5s`,
    willChange: 'width',
  },

  sideBarExpandButton: {
    display: 'flex',
    alignItems: 'center',
    width: ({ sidebarIconWidth }) => `${px(sidebarIconWidth)}px`,
    minWidth: ({ sidebarIconWidth }) => `${px(sidebarIconWidth)}px`,
    height: 40,
    minHeight: 40,
    marginTop: 'auto',
    gap: tokens.spacing['16'],
    padding: tokens.spacing['8'],
    borderRadius: tokens.radius.s,
    background: tokens.colors.bg.light.primary,
    overflow: 'hidden',
    cursor: 'pointer',
    zIndex: 1,

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },
  },
}));
