import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  multipleTextInput: {
    width: '100%',
    marginBottom: theme.tokens.spacing['16'],
  },

  inputWrapper: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.px(-76), // TODO - fix нет в токенах
    },
  },

  input: {
    '& .textFieldInput': {
      backgroundColor: theme.tokens.colors.bg.light.primary,

      '&.Mui-focused': {
        '& fieldset': {
          borderColor: theme.tokens.colors.original.brand.primary,
          borderWidth: theme.tokens.border.s,
        },
      },

      '&.Mui-error': {
        backgroundColor: theme.tokens.colors.accent.bg.error,

        '& fieldset': {
          borderColor: theme.tokens.colors.accent.stroke.error,
        },

        '&.Mui-focused': {
          '& fieldset': {
            borderColor: theme.tokens.colors.accent.text.error,
          },
        },

        '&:hover': {
          '& fieldset': {
            borderColor: theme.palette.error.hover,
          },
        },
      },

      '&.Mui-disabled': {
        backgroundColor: theme.tokens.colors.bg.greyscale.secondary,

        '& fieldset': {
          borderColor: theme.tokens.colors.stroke.primary,
        },
      },

      '& input': {
        padding: theme.tokens.spacing['16'],
        height: theme.px(24),
        fontSize: theme.tokens.typography.variant.body2.fontSize,
        lineHeight: theme.tokens.typography.variant.body2.lineHeight,
      },

      '& fieldset': {
        borderColor: theme.tokens.colors.stroke.primary,
      },

      '&:hover': {
        '& fieldset': {
          borderColor: theme.tokens.colors.stroke.secondary,
        },
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  adornedEnd: {
    cursor: 'pointer',
  },
}));

export default useStyles;
