import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  OAuth2VerifyResponse,
  UserLoginResponse as AuthResponse,
} from '@app/gen/users';

import {
  RegisterOAuth2InitDto,
  RegisterOAuth2Dto,
  VerifyOAuth2CodeDto,
} from '../../dto';

@injectable()
export class OAuth2Service {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async verifyOAuth2Code(verifyOAuth2CodeDto: VerifyOAuth2CodeDto) {
    await this.validator.validateOrReject(
      verifyOAuth2CodeDto,
      VerifyOAuth2CodeDto,
    );

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/oauth2/auth_code/verify',
        {
          auth_code: verifyOAuth2CodeDto.authCode,
        },
        { withAuth: false },
      ),
      OAuth2VerifyResponse,
    );

    return result;
  }

  async registerInitOAuth2(registerInitDto: RegisterOAuth2InitDto) {
    await this.validator.validateOrReject(
      registerInitDto,
      RegisterOAuth2InitDto,
    );

    await this.http.post(
      '/oauth2/user/register/init',
      {
        phone: registerInitDto.phone,
        auth_code: registerInitDto.authCode,
      },
      { withAuth: false },
    );
  }

  async registerOAuth2(registerDto: RegisterOAuth2Dto) {
    await this.validator.validateOrReject(registerDto, RegisterOAuth2Dto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/oauth2/user/register',
        {
          auth_code: registerDto.authCode,
          phone_code: registerDto.code,
        },
        { withAuth: false },
      ),
      AuthResponse,
    );

    return result;
  }
}
