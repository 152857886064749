import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core';

export const RotateLeftIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      {...props}
      htmlColor={theme.tokens.colors.text.dark.primary}
      viewBox="0 0 17 16"
      fontSize="inherit"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0746 7.6615H6.38032C5.56154 7.6615 5.03287 7.66255 4.6305 7.69389C4.2447 7.72394 4.10167 7.77495 4.03145 7.80906C3.76424 7.93886 3.54699 8.14597 3.41084 8.40071C3.37506 8.46765 3.32156 8.60401 3.29004 8.9718C3.25716 9.35539 3.25606 9.8594 3.25606 10.64V11.001C3.25606 11.7815 3.25716 12.2856 3.29004 12.6691C3.32156 13.0369 3.37506 13.1733 3.41084 13.2402C3.54699 13.495 3.76424 13.7021 4.03145 13.8319C4.10167 13.866 4.2447 13.917 4.6305 13.9471C5.03287 13.9784 5.56154 13.9794 6.38032 13.9794H7.0746C7.89337 13.9794 8.42204 13.9784 8.82442 13.9471C9.21021 13.917 9.35324 13.866 9.42346 13.8319C9.69067 13.7021 9.90792 13.495 10.0441 13.2402C10.0798 13.1733 10.1334 13.0369 10.1649 12.6691C10.1978 12.2856 10.1989 11.7815 10.1989 11.001V10.64C10.1989 9.8594 10.1978 9.35539 10.1649 8.9718C10.1334 8.60401 10.0798 8.46765 10.0441 8.40071C9.90792 8.14597 9.69067 7.93886 9.42346 7.80906C9.35324 7.77495 9.21021 7.72394 8.82442 7.69389C8.42204 7.66255 7.89337 7.6615 7.0746 7.6615ZM2.1455 7.78608C1.83594 8.36528 1.83594 9.12351 1.83594 10.64V11.001C1.83594 12.5174 1.83594 13.2757 2.1455 13.8549C2.41781 14.3644 2.85231 14.7786 3.38673 15.0382C3.99429 15.3333 4.78963 15.3333 6.38032 15.3333H7.0746C8.66528 15.3333 9.46062 15.3333 10.0682 15.0382C10.6026 14.7786 11.0371 14.3644 11.3094 13.8549C11.619 13.2757 11.619 12.5174 11.619 11.001V10.64C11.619 9.12351 11.619 8.36528 11.3094 7.78608C11.0371 7.27659 10.6026 6.86237 10.0682 6.60277C9.46062 6.30765 8.66528 6.30765 7.0746 6.30765H6.38032C4.78963 6.30765 3.99429 6.30765 3.38673 6.60277C2.85231 6.86237 2.41781 7.27659 2.1455 7.78608Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1743 0.864892C11.4516 1.12925 11.4516 1.55785 11.1743 1.82221L10.493 2.47175H10.6722C13.1559 2.47175 15.1693 4.39119 15.1693 6.75893V9.01534C15.1693 9.3892 14.8514 9.69227 14.4592 9.69227C14.0671 9.69227 13.7492 9.3892 13.7492 9.01534V6.75893C13.7492 5.1389 12.3716 3.8256 10.6722 3.8256H10.493L11.1743 4.47515C11.4516 4.7395 11.4516 5.16811 11.1743 5.43246C10.897 5.69682 10.4474 5.69682 10.1701 5.43246L8.27665 3.62733C7.99936 3.36298 7.99936 2.93438 8.27665 2.67002L10.1701 0.864892C10.4474 0.600537 10.897 0.600537 11.1743 0.864892Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
    </SvgIcon>
  );
};
