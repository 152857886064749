import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { DashboardState } from '@vk-hr-tek/app/dashboard/slice';
import { AppError } from '@vk-hr-tek/core/error';
import { DashboardService } from '@vk-hr-tek/app/dashboard/services';

import { ThunkExtra } from '@app/store';
import { WidgetTasksResponse } from '@app/gen/dashboard';

export const getTaskList = createAsyncThunk<
  WidgetTasksResponse['tasks'],
  undefined,
  ThunkExtra
>(
  'dashboard/getTaskList',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(DashboardService).getTaskList();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const taskListReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(getTaskList.pending, (state) => {
    state.taskList.status = 'loading';
  });
  builder.addCase(getTaskList.fulfilled, (state, { payload }) => {
    state.taskList.status = 'complete';
    state.taskList.items = payload;
  });
  builder.addCase(getTaskList.rejected, (state) => {
    state.taskList.status = 'failed';
  });
};
