import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  layout: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    transform: 'translateY(50%)',
  },
}));
