import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 766,
    margin: '0 auto',
  },
  container_theme_notification: {
    minHeight: '100vh',
  },
  content: {
    width: '100%',
  },
});
