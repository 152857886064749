import React from 'react';

import classNames from 'classnames';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { IconProps } from '@vk-hr-tek/ui/icons/Icon.types';
import { useStyles } from '@vk-hr-tek/ui/icons/Icon.styles';

const SVGBasketIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 13 14">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.97125 2.19995H0.701562C0.370192 2.19995 0.101562 2.46858 0.101562 2.79995C0.101562 3.13132 0.370192 3.39995 0.701562 3.39995H1.13792L1.86323 10.7981C1.90429 11.2171 1.93847 11.5657 1.98891 11.8497C2.04158 12.1462 2.11905 12.4185 2.27089 12.6721C2.50674 13.0658 2.85409 13.3808 3.26899 13.5771C3.53611 13.7035 3.8147 13.754 4.11492 13.7775C4.40246 13.8 4.75276 13.8 5.1737 13.8H7.82945C8.25039 13.8 8.6007 13.8 8.88824 13.7775C9.18846 13.754 9.46704 13.7035 9.73417 13.5771C10.1491 13.3808 10.4964 13.0658 10.7323 12.6721C10.8841 12.4185 10.9616 12.1462 11.0142 11.8497C11.0647 11.5657 11.0989 11.2171 11.1399 10.7981L11.8652 3.39995H12.3016C12.6329 3.39995 12.9016 3.13132 12.9016 2.79995C12.9016 2.46858 12.6329 2.19995 12.3016 2.19995H9.03188C8.76081 1.05306 7.73112 0.199951 6.50156 0.199951C5.272 0.199951 4.24231 1.05306 3.97125 2.19995ZM5.23616 2.19995H7.76696C7.54228 1.72708 7.0599 1.39995 6.50156 1.39995C5.94322 1.39995 5.46084 1.72708 5.23616 2.19995ZM10.6595 3.39995H2.34367L3.05524 10.6579C3.09915 11.1059 3.12916 11.4076 3.17042 11.6398C3.21038 11.8648 3.25368 11.9775 3.30036 12.0555C3.41422 12.2455 3.5819 12.3976 3.7822 12.4924C3.86431 12.5312 3.98071 12.5633 4.20851 12.5811C4.44371 12.5995 4.74687 12.6 5.19697 12.6H7.80619C8.25629 12.6 8.55945 12.5995 8.79464 12.5811C9.02244 12.5633 9.13884 12.5312 9.22096 12.4924C9.42125 12.3976 9.58894 12.2455 9.7028 12.0555C9.74947 11.9775 9.79277 11.8648 9.83274 11.6398C9.874 11.4076 9.904 11.1059 9.94792 10.6579L10.6595 3.39995ZM8.35135 5.00192C8.68158 5.02943 8.92698 5.31943 8.89948 5.64965L8.49948 10.4519C8.47197 10.7821 8.18197 11.0276 7.85174 11C7.52152 10.9725 7.27611 10.6825 7.30362 10.3523L7.70362 5.55005C7.73113 5.21982 8.02113 4.97442 8.35135 5.00192ZM4.65177 5.00192C4.982 4.97442 5.272 5.21982 5.29951 5.55005L5.69951 10.3523C5.72701 10.6825 5.48161 10.9725 5.15138 11C4.82115 11.0276 4.53115 10.7822 4.50365 10.4519L4.10365 5.64966C4.07614 5.31943 4.32155 5.02943 4.65177 5.00192Z"
    />
  </SvgIcon>
);

export const BasketIcon = ({
  size = 'normal',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGBasketIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
