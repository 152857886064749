import { IsNotEmpty, IsUUID } from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class GetNodeValidatorsDto {
  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  eventId: string;

  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  nodeId: string;
}
