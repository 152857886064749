import { makeStyles } from '@material-ui/core';

const styles = makeStyles(({ tokens }) => ({
  message: {
    fontSize: tokens.typography.variant.body3.fontSize,
    lineHeight: tokens.typography.variant.body3.lineHeight,
  },
}));

export default styles;
