import React from 'react';

import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ArrowDownIcon = (props: SvgIconProps) => {
  const { tokens } = useTheme();

  return (
    <SvgIcon {...props} htmlColor="#ffffff" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6665 6.66699L7.99984 10.0003L11.3332 6.66699H4.6665Z"
        fill={tokens.colors.original.brand.primary}
      />
    </SvgIcon>
  );
};
