import { injectable, inject } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';
import {
  GroupingStrategy,
  Notification,
  NotificationActionTypeEnum,
} from '@vk-hr-tek/core/notifications/types';

interface MergedNotificationsByType {
  errorNotifications?: Notification;
  infoNotifications?: Notification;
}

@injectable()
export class EventNotificationService implements GroupingStrategy {
  constructor(
    @inject(RoleService) private userRole: RoleService, // TODO - Add side to the notification response
    private history: History,
  ) {}

  groupNotifications(notifications: Notification[]): Notification[] {
    const role = this.userRole.get();

    if (notifications.length === 1 || role === 'employee') {
      return notifications;
    }

    const location = this.history.location.pathname;
    const mergedNotificationsByType: MergedNotificationsByType = {};

    const countEvents = (arr: Notification[]) =>
      arr[0].count ? arr[0].count + arr.length - 1 : arr.length;

    const notificationsWithEventGroup = notifications.filter(
      (notification) => notification.group === 'eventSigning',
    );

    const notificationsWithoutGroup = notifications.filter(
      (notification) => notification.group !== 'eventSigning',
    );

    const infoNotifications = notificationsWithEventGroup.filter(
      (notification) => notification.type === 'info',
    );

    if (infoNotifications.length) {
      mergedNotificationsByType.infoNotifications =
        infoNotifications.length > 1
          ? {
              id: infoNotifications[0].id,
              title: 'Подписано!',
              type: 'info',
              count: countEvents(infoNotifications),
              message: `Заявок (${countEvents(infoNotifications)} шт)`,
              group: 'eventSigning',
            }
          : infoNotifications[0];
    }

    const errorNotifications = notificationsWithEventGroup.filter(
      (notification) => notification.type === 'error',
    );

    if (errorNotifications.length) {
      mergedNotificationsByType.errorNotifications =
        errorNotifications.length > 1
          ? {
              id: errorNotifications[0].id,
              title: 'Не подписано!',
              type: 'error',
              count: countEvents(errorNotifications),
              message: `Заявок (${countEvents(errorNotifications)} шт)`,
              group: 'eventSigning',
              action: {
                link: `/${role}/events`,
                actionType: location.endsWith('/events')
                  ? NotificationActionTypeEnum.Refresh
                  : NotificationActionTypeEnum.Redirect,
                text: `Посмотреть ${
                  location.endsWith('/events') ? 'все ' : ''
                }заявки на мне`,
              },
            }
          : errorNotifications[0];
    }

    return [
      ...Object.values(mergedNotificationsByType),
      ...notificationsWithoutGroup,
    ];
  }
}
