import React from 'react';

import {
  Typography as MUITypography,
  TypographyProps as MUITypographyProps,
} from '@material-ui/core';
import classNames from 'classnames';

import { useStyles } from './Typography.styles';

export type TypographyColor =
  | 'inherit'
  | 'primary'
  | 'contrast'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary'
  | 'error'
  | 'success'
  | 'review'
  | 'warning';

type TypographyProps = Omit<
  MUITypographyProps,
  'variant' | 'color' | 'component'
> & {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'body3'
    | 'caption'
    | 'button'
    | 'overline'
    | 'inherit';
  color?: TypographyColor;
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
};

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'div',
  body2: 'div',
};

export const Typography = ({
  variant = 'body1',
  color = 'textPrimary',
  className,
  ...rest
}: TypographyProps) => {
  const {
    colorPrimary,
    colorSecondary,
    colorTextPrimary,
    colorTextSecondary,
    colorTextTertiary,
    colorError,
    colorSuccess,
    colorReview,
    colorWarning,
    body3,
    ...classes
  } = useStyles();

  return (
    <MUITypography
      {...rest}
      variant={variant !== 'body3' ? variant : 'body2'}
      color="inherit"
      variantMapping={variantMapping}
      classes={classes}
      className={classNames(className, {
        [colorPrimary]: color === 'primary',
        [colorSecondary]: color === 'contrast',
        [colorTextPrimary]: color === 'textPrimary',
        [colorTextSecondary]: color === 'textSecondary',
        [colorTextTertiary]: color === 'textTertiary',
        [colorError]: color === 'error',
        [colorSuccess]: color === 'success',
        [colorReview]: color === 'review',
        [colorWarning]: color === 'warning',
        [body3]: variant === 'body3',
        [`aqa_${variant}`]: true,
      })}
    />
  );
};
