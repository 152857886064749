import { merge, cloneDeep } from 'lodash';

import { Tokens } from '../types';

import { generalTokens } from './general.tokens';

export const vkTokens: Tokens = merge(cloneDeep(generalTokens), {
  name: 'vk',
  colors: {
    original: {
      brand: {
        primary: '#2688eb',
        secondary: '#edf3ff',
        tertirary: '#167de4',
        quaternary: '#d8e5ff', // Not present in figma!
      },
    },
    accent: {
      stroke: {
        blue: '#b8cefa',
      },
    },
  },
});
