import React from 'react';

import { SvgIcon } from '@material-ui/core';

export const VkIcon = () => (
  <SvgIcon viewBox="0 0 16 16" fontSize="inherit" fill="none">
    <rect width="16" height="16" rx="3.84" fill="#0077FF"></rect>
    <path
      d="M8.56331 11.66C4.91665 11.66 2.83667 9.16 2.75 5H4.57666C4.63666 8.05333 5.9833 9.34333 7.04997 9.61V5H8.77002V7.63C9.82335 7.51667 10.9299 6.32 11.3032 5H13.0233C12.7366 6.62667 11.5366 7.82667 10.6833 8.32C11.5366 8.72 12.9033 9.76667 13.4233 11.66H11.5299C11.1233 10.3933 10.11 9.41333 8.77002 9.28V11.66H8.56331Z"
      fill="white"
    ></path>
  </SvgIcon>
);
