import { injectable } from 'inversify';

import { Logger } from '../logger';

import initializeCades from './cadesplugin_api';
import {
  CadesPlugin,
  CadesStore,
  CertificateObject,
  CPSigner,
  CadesSignedData,
  Attribute,
  CadesHashedData,
} from './types';

@injectable()
export class Crypto {
  private plugin: CadesPlugin | null = null;

  private store: CadesStore | null = null;

  private certificates: CertificateObject[] | null = null;

  constructor(private readonly logger: Logger) {}

  private async getCertificates() {
    if (this.certificates && this.certificates.length) {
      return this.certificates;
    }

    this.certificates = [];

    const plugin = this.getPlugin();
    await this.init(plugin);

    const store = await this.getStore();

    try {
      store.Open(
        plugin.CAPICOM_CURRENT_USER_STORE,
        plugin.CAPICOM_MY_STORE,
        plugin.CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
      );
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'store.open',
        },
        context: {
          crypto_error: error,
        },
      });

      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка открытия хранилища',
      );
    }

    try {
      const certificates = await store.Certificates;

      const validCertificates = await certificates.Find(
        plugin.CAPICOM_CERTIFICATE_FIND_TIME_VALID,
      );

      const count = await validCertificates.Count;

      if (count !== undefined) {
        for (let i = 1; i <= count; i += 1) {
          this.certificates.push(await validCertificates.Item(i));
        }
      }

      await store.Close();
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'store.certificates',
        },
        context: {
          crypto_error: error,
        },
      });

      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка получения списка сертификатов',
      );
    }

    if (!this.certificates.length) {
      this.logger.info('[crypto] No certificates', {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'certificates.empty',
        },
      });
      /* istanbul ignore next */
      throw new Error('Сертификаты не найдены');
    }

    return this.certificates;
  }

  /** Создать hash переданного файла по алгоритму CP_GOST_3411_2012_256
   * @param {Blob} blob Файл
   * @returns {Promise<string>}
   *  */
  async createHash(blob: Blob) {
    const plugin = this.getPlugin();
    await this.init(plugin);

    const blobToBase64 = (): Promise<string> => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () =>
          resolve((reader.result as string).split('base64,')[1]);
        reader.readAsDataURL(blob);
      });
    };

    try {
      const hashed: CadesHashedData =
        await plugin.CreateObjectAsync<CadesHashedData>('CAdESCOM.HashedData');

      await hashed.propset_Algorithm(
        plugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
      );

      await hashed.propset_DataEncoding(plugin.CADESCOM_BASE64_TO_BINARY);

      await hashed.Hash(await blobToBase64());

      return await hashed.Value;
    } catch (error) {
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при хешировании',
      );
    }
  }

  /** Подготовить массив объектов, которые отображают информацию о сертификатах
   * @returns {Promise<{id: string, name: string, user: string, company: string, activeFrom: string, activeTo: string}[]>}
   * */
  async getSerializedCertificates() {
    const certificates = await this.getCertificates();

    try {
      const result = await Promise.all(
        certificates.map(async (certificate) => {
          const subjectNameObj: { [key: string]: string } = {};
          (await certificate.SubjectName)
            .split(/, (?=[a-zA-Zа-яА-ЯёЁ0-9\.]+=)/)
            .map((item) => item.split('='))
            .forEach((item) => {
              subjectNameObj[item[0].trim()] = item[1];
            });

          return {
            id: await certificate.SerialNumber,
            name: subjectNameObj.CN,
            user:
              subjectNameObj.G || subjectNameObj.SN
                ? `${subjectNameObj.G} ${subjectNameObj.SN}`
                : '-',
            company: subjectNameObj.O || '-',
            activeFrom: await certificate.ValidFromDate,
            activeTo: await certificate.ValidToDate,
          };
        }),
      );

      return result;
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'certificate.serialization',
        },
        context: {
          crypto_error: error,
        },
      });

      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка сериализации сертификатов',
      );
    }
  }

  private async extractMeaningfulErrorMessage(error: unknown) {
    if (!(error instanceof Error)) {
      return null;
    }

    const plugin = this.getPlugin();
    await this.init(plugin);

    let errorMessage = plugin.getLastError ? plugin.getLastError(error) : '';

    if (!errorMessage) {
      if (!error.message) {
        return null;
      }

      errorMessage = error.message;
    }

    const containsRussianLetters = /[а-яА-Я]/.test(errorMessage);

    if (!containsRussianLetters) {
      return null;
    }

    const searchResult = errorMessage.match(
      /^(.*?)(?:(?:\.?\s?\(?0x)|(?:\.?$))/,
    );

    return searchResult ? searchResult[1] : null;
  }

  private async createSigner(certificate: CertificateObject, tsp?: string) {
    const plugin = this.getPlugin();
    await this.init(plugin);

    let timeAttr: Attribute;
    let documentNameAttr: Attribute;
    let signer: CPSigner;

    try {
      signer = await plugin.CreateObjectAsync<CPSigner>('CAdESCOM.CPSigner');
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'plugin.signer',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при инициализации подписи',
      );
    }

    try {
      timeAttr = await plugin.CreateObjectAsync<Attribute>(
        'CADESCOM.CPAttribute',
      );
      await timeAttr.propset_Name(
        plugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME,
      );
      await timeAttr.propset_Value(new Date());
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'plugin.time_attribute',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при установке времени подписи',
      );
    }

    try {
      documentNameAttr = await plugin.CreateObjectAsync<Attribute>(
        'CADESCOM.CPAttribute',
      );
      await documentNameAttr.propset_Name(
        plugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME,
      );
      await documentNameAttr.propset_Value('');
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'plugin.name_attribute',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при установке имени файла',
      );
    }

    try {
      await signer.propset_Certificate(certificate);
      await signer.propset_CheckCertificate(true);

      if (tsp) {
        await signer.propset_TSAAddress(tsp);
      }

      const authAttrs = await signer.AuthenticatedAttributes2;
      await authAttrs.Add(timeAttr);
      await authAttrs.Add(documentNameAttr);

      await signer.propset_Options(
        plugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN,
      );
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'signer.certificate',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при установке сертификата подписи',
      );
    }

    return signer;
  }

  /** Подписать hash документа указанным сертификатом
   * @param certificate Сертификат, используемый для подписания
   * @param {string} hash  Hash документа
   * @param {string} [tsp] Сервис меток точного времени
   * @returns {Promise<string>}
   * */
  async signHash(certificate: CertificateObject, hash: string, tsp?: string) {
    const signer = await this.createSigner(certificate, tsp);

    const plugin = this.getPlugin();
    await this.init(plugin);

    let hashedData: CadesHashedData;
    let signedData: CadesSignedData;

    try {
      signedData = await plugin.CreateObjectAsync<CadesSignedData>(
        'CAdESCOM.CadesSignedData',
      );
      await signedData.propset_ContentEncoding(
        plugin.CADESCOM_BASE64_TO_BINARY,
      );
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'signedData.encoding',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при создании усовершенствованной подписи',
      );
    }

    try {
      hashedData = await plugin.CreateObjectAsync<CadesHashedData>(
        'CAdESCOM.HashedData',
      );

      await hashedData.propset_Algorithm(
        plugin.CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256,
      );
      await hashedData.SetHashValue(hash);
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'hashedData.value',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при установке хэша подписи',
      );
    }

    try {
      const signature = await signedData.SignHash(
        hashedData,
        signer,
        !!tsp ? plugin.CADESCOM_CADES_X_LONG_TYPE_1 : plugin.CADESCOM_CADES_BES,
      );

      return signature;
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'signedData.sign',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при подписании данных',
      );
    }
  }

  /** Найти сертификат по id среди имеющихся в системе
   * @param {string} id Id сертификата тоже самое, что и серийный номер сертификата
   * @return {Promise<Object>}
   * */
  async getSertificateById(id: string) {
    const certificates = await this.getCertificates();

    let result: { certificate: CertificateObject; id: string } | undefined;

    try {
      result = (
        await Promise.all(
          certificates.map(async (certificate) => ({
            id: await certificate.SerialNumber,
            certificate,
          })),
        )
      ).find((certificate) => certificate.id === id);
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'certificate.serial_number',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при подписании данных',
      );
    }

    if (!result) {
      this.logger.error('[crypto] Unknown certificate', {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'certificate.serial_number',
        },
      });
      /* istanbul ignore next */
      throw new Error('Переданный сертификат не существует');
    }

    return result.certificate;
  }

  private async getStore() {
    if (this.store) {
      return this.store;
    }

    const plugin = this.getPlugin();

    await this.init(plugin);

    try {
      this.store = await plugin.CreateObjectAsync<CadesStore>('CAdESCOM.Store');
      return this.store;
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'plugin.store',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error(
        (await this.extractMeaningfulErrorMessage(error)) ||
          'Ошибка при попытке доступа к хранилищу',
      );
    }
  }

  private async init(plugin: CadesPlugin) {
    try {
      await plugin;
    } catch (error) {
      this.logger.error(error, {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'plugin.initialization',
        },
        context: {
          crypto_error: error,
        },
      });
      /* istanbul ignore next */
      throw new Error('Ошибка инициализации cades плагина');
    }
  }

  private getPlugin() {
    if (this.plugin) {
      return this.plugin;
    }

    initializeCades();

    if (!window.cadesplugin) {
      this.logger.info('[crypto] No plugin', {
        tags: {
          vkdoc_error_type: 'crypto',
          crypto_type: 'plugin.not_intalled',
        },
      });
      /* istanbul ignore next */
      throw new Error('Cades плагин не найден');
    }

    this.plugin = window.cadesplugin as CadesPlugin;

    return this.plugin;
  }
}
