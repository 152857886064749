import React from 'react';

import { AppError } from '@vk-hr-tek/core/error';
import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { useSelector } from '../../../../app/hooks';
import { selectStage, selectPassCode } from '../../../slice';
import { useAuth, useTarget } from '../../../hooks';
import { Page, PageError } from '../../../ui/page';

import { RegisterInit } from './RegisterInit';
import { RegisterSnils } from './RegisterSnils';
import { RegisterFinish } from './RegisterFinish';

const steps = [
  {
    name: 'init',
    content: <RegisterSnils />,
    label: 'Снилс',
  },
  {
    name: 'verification',
    content: <RegisterInit />,
    label: 'Телефон',
  },
  {
    name: 'finish',
    content: <RegisterFinish />,
    label: 'СМС-код',
  },
];

export const Register = () => {
  const step = useSelector(selectStage);
  const passCode = useSelector(selectPassCode);
  const [target] = useTarget();
  const isAuthenticated = useAuth();

  const isDesktop = useIsDesktop();

  if (!isAuthenticated && !passCode) {
    return (
      <PageError
        error={
          new AppError('client', {
            code: 400,
            message: 'Bad request',
            data: {
              error_code: 'invalid_ali_link',
            },
          })
        }
      />
    );
  }

  return (
    <Page title={target === 'registration' ? 'Регистрация' : 'Смена телефона'}>
      <Stages
        active={step}
        steps={steps}
        showHeader
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
