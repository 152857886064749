import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const DoneBorderedIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.22179 16.8893C6.22179 11.4892 11.4883 6.22264 16.8885 6.22264H31.9996C33.4723 6.22264 34.6662 5.47318 34.6662 4.00042C34.6662 2.52766 33.4723 1.7782 31.9996 1.7782H16.8885C8.54282 1.7782 1.77734 8.54367 1.77734 16.8893V47.1115C1.77734 55.4572 8.54282 62.2226 16.8885 62.2226H47.1107C55.4563 62.2226 62.2218 55.4572 62.2218 47.1115V32.0004C62.2218 30.5277 61.4723 29.3337 59.9996 29.3337C58.5268 29.3337 57.7773 30.5277 57.7773 32.0004V47.1115C57.7773 52.5116 52.5108 57.7782 47.1107 57.7782H16.8885C11.4883 57.7782 6.22179 52.5116 6.22179 47.1115V16.8893ZM58.9679 8.70637C59.9158 7.76299 61.4537 7.76462 62.4015 8.71038C63.3486 9.65614 63.347 11.188 62.3968 12.1313L31.476 42.85C31.021 43.3015 30.4035 43.5555 29.7616 43.5555C29.7446 43.5555 29.7275 43.5555 29.7098 43.5547C29.0492 43.5411 28.4228 43.2597 27.9751 42.7751L15.7525 29.5531C14.8443 28.5702 14.9075 27.04 15.8942 26.1353C16.8817 25.2308 18.4172 25.2945 19.3254 26.2765L29.8361 37.6473L58.9679 8.70637Z"
    />
  </SvgIcon>
);
