import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    '&:focus > $content $label': {
      backgroundColor: 'transparent',
    },
    '&$selected > $content $label, &$selected > $content $label:hover, &$selected:focus > $content $label':
      {
        backgroundColor: 'transparent',
        color: theme.tokens.colors.original.brand.primary,
      },
    '&$selected > $content $iconContainer': {
      color: theme.tokens.colors.original.brand.primary,
    },
  },
  selected: {
    '& > $content:after, &:hover > $content:after': {
      display: 'block',
      background: theme.tokens.colors.original.brand.secondary,
    },
  },
  content: {
    paddingLeft: theme.tokens.spacing['12'],
    position: 'relative',
    zIndex: 1,
    '&:after': {
      position: 'absolute',
      display: 'none',
      content: '""',
      left: theme.px(-200), // TODO - fix нет в токенах
      top: 0,
      bottom: 0,
      right: 0,
      background: theme.tokens.colors.bg.greyscale.primary,
      zIndex: -1,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&:after': {
        display: 'block',
        background: theme.tokens.colors.bg.greyscale.primary,
      },
    },
  },
  iconContainer: {
    color: theme.tokens.colors.text.light.tertirary,
  },
  label: {
    fontSize: theme.tokens.typography.variant.subtitle2.fontSize,
    lineHeight: theme.tokens.typography.variant.subtitle2.lineHeight,
    padding: `${theme.tokens.spacing['12']}px ${theme.tokens.spacing['20']}px ${theme.tokens.spacing['12']}px ${theme.tokens.spacing['8']}px`,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  group: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: theme.px(2),
      left: theme.tokens.spacing['2'],
      top: theme.tokens.spacing['2'],
      bottom: theme.tokens.spacing['2'],
      background: theme.tokens.colors.stroke.primary,
    },
  },
  itemLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemCounter: {
    background: theme.tokens.colors.bg.greyscale.primary,
    fontSize: theme.tokens.typography.variant.caption.fontSize,
    lineHeight: theme.tokens.typography.variant.caption.lineHeight,
    padding: `${theme.tokens.spacing['4']}px ${theme.tokens.spacing['8']}px`,
    borderRadius: theme.tokens.radius.s,
  },
}));
