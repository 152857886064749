import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { DotIcon } from '@vk-hr-tek/ui/icons';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface EmployeePositionAndPersonalNumberProps {
  position: string;
  personalNumber: string;
}

export const EmployeePositionAndPersonalNumber = ({
  position,
  personalNumber,
}: EmployeePositionAndPersonalNumberProps) => {
  return (
    <Box display="flex" flexWrap="nowrap" mt="4">
      <Box>
        <EllipsisText rows={1}>
          <Typography variant="body2" color="textTertiary">
            {position}
          </Typography>
        </EllipsisText>
      </Box>
      <Box display="flex" alignItems="center" mx="4">
        <DotIcon color="secondary" />
      </Box>
      <Typography variant="body2" color="textTertiary">
        {personalNumber}
      </Typography>
    </Box>
  );
};
