import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  h1: theme.tokens.typography.variant.h1,
  h2: theme.tokens.typography.variant.h2,
  h3: theme.tokens.typography.variant.h3,
  h4: theme.tokens.typography.variant.h4,
  h5: theme.tokens.typography.variant.h5,
  h6: theme.tokens.typography.variant.h6,
  subtitle1: theme.tokens.typography.variant.subtitle1,
  subtitle2: theme.tokens.typography.variant.subtitle2,
  body1: theme.tokens.typography.variant.body1,
  body2: theme.tokens.typography.variant.body2,
  body3: theme.tokens.typography.variant.body3,
  button: theme.tokens.typography.variant.button,
  caption: theme.tokens.typography.variant.caption,
  overline: theme.tokens.typography.variant.overline,
  colorPrimary: {
    color: theme.tokens.colors.original.brand.primary,
  },
  colorSecondary: {
    color: theme.tokens.colors.text.dark.primary,
  },
  colorTextPrimary: {
    color: theme.tokens.colors.text.light.primary,
  },
  colorTextSecondary: {
    color: theme.tokens.colors.text.light.secondary,
  },
  colorTextTertiary: {
    color: theme.tokens.colors.text.light.tertirary,
  },
  colorError: {
    color: theme.tokens.colors.accent.text.error,
  },
  colorSuccess: {
    color: theme.tokens.colors.accent.text.success,
  },
  colorReview: {
    color: theme.tokens.colors.accent.text.review,
  },
  colorWarning: {
    color: theme.tokens.colors.accent.text.warning,
  },
}));
