import React, { ReactNode } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { useIsDesktop } from '../../hooks';
import { Box } from '../../Box';
import { Typography } from '../../Typography';
import { Label } from '../common';

import { useStyles } from './RadioInput.styles';

interface RadioInputProps {
  items: {
    label: string;
    value: string | number;
  }[];
  label?: string;
  labelPlacement?: 'start' | 'end';
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  required?: boolean;
  row?: boolean;
  tooltip?: ReactNode | null;
  disabled?: boolean;
  testId?: string;
}

export const RadioInput = ({
  items,
  label,
  labelPlacement = 'end',
  value,
  onChange,
  required = false,
  row = false,
  tooltip = null,
  disabled = false,
  testId,
  ...rest
}: RadioInputProps) => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();

  return (
    <FormControl
      className="aqa_radion_input"
      component="fieldset"
      disabled={disabled}
    >
      {label && <Label label={label} required={required} tooltip={tooltip} />}

      <RadioGroup
        {...rest}
        data-testid={testId}
        row={isDesktop || row}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        classes={{ root: classes.root }}
        className="aqa_radioinput_block"
      >
        {items.map((item) => (
          <Box key={item.value}>
            <FormControlLabel
              key={item.value}
              value={item.value}
              classes={{
                root: classes.itemRoot,
                label: classes.label,
                disabled: classes.disabled,
              }}
              control={
                <Radio
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  classes={{
                    root: classes.inputRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={
                <Box
                  mr={labelPlacement === 'start' ? '8' : '0'}
                  ml={labelPlacement === 'end' ? '8' : '0'}
                >
                  <Typography color="textSecondary" variant="body3">
                    {item.label}
                  </Typography>
                </Box>
              }
              labelPlacement={labelPlacement}
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
