import React, { ReactNode } from 'react';

import classNames from 'classnames';

import useStyles from './ListItem.styles';

interface ListItemProps {
  children: ReactNode;
}

export const ListItem = ({ children }: ListItemProps) => {
  const classes = useStyles();

  return (
    <li className={classNames(classes.listItem, 'aqa_list_item')}>
      {children}
    </li>
  );
};
