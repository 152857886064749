import React, { ReactNode } from 'react';

import { Box } from '../../Box';

interface InputDisclaimerProps {
  children: ReactNode;
  top?: number;
  left?: number;
}

export const InputDisclaimer = ({
  children,
  top = 28,
  left = 20,
}: InputDisclaimerProps) => (
  <Box width="100%" position="relative">
    <Box width="100%" position="absolute" left={left} top={top}>
      {children}
    </Box>
  </Box>
);
