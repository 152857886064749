import { IsNotEmpty, IsUUID } from 'class-validator';

export class RemoveSubstituteDto {
  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsUUID()
  @IsNotEmpty()
  eventTypeId: string;

  @IsUUID()
  @IsNotEmpty()
  substituteId: string;
}
