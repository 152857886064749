import { inject, injectable } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';

@injectable()
export class AuthCandidateIdService {
  private readonly key = 'inviteId';

  constructor(
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
  ) {}

  save(id: string): void {
    this.localStorage.setItem(this.key, id);
  }

  get(): string | null {
    return this.localStorage.getItem(this.key) ?? null;
  }

  delete(): void {
    this.localStorage.removeItem(this.key);
  }
}
