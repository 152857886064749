import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

const SVGGridIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 25 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.3116 3.42773C6.21351 3.42773 5.28855 3.61255 4.34972 4.11465C3.51383 4.56168 2.8527 5.22282 2.40566 6.0587C1.90357 6.99754 1.71875 7.92249 1.71875 10.0206V13.9777C1.71875 16.0758 1.90357 17.0008 2.40566 17.9396C2.8527 18.7755 3.51383 19.4366 4.34972 19.8837C5.28855 20.3858 6.21351 20.5706 8.3116 20.5706H15.6973C17.7954 20.5706 18.7204 20.3858 19.6592 19.8837C20.4951 19.4366 21.1562 18.7755 21.6033 17.9396C22.1054 17.0008 22.2902 16.0758 22.2902 13.9777V10.0206C22.2902 7.92249 22.1054 6.99754 21.6033 6.0587C21.1562 5.22282 20.4951 4.56168 19.6592 4.11465C18.7204 3.61255 17.7954 3.42773 15.6973 3.42773H8.3116ZM5.15817 5.62633C5.72451 5.32345 6.31022 5.14202 8.3116 5.14202H11.1473V8.57059H3.47986C3.55206 7.67155 3.7048 7.26459 3.91734 6.86716C4.20461 6.33002 4.62103 5.91359 5.15817 5.62633ZM11.1473 10.2849H3.43304V13.7134H11.1473V10.2849ZM12.8616 13.7134V10.2849H20.5759V13.7134H12.8616ZM11.1473 15.4277H3.47986C3.55206 16.3268 3.7048 16.7337 3.91734 17.1312C4.20461 17.6683 4.62103 18.0847 5.15817 18.372C5.72451 18.6749 6.31022 18.8563 8.3116 18.8563H11.1473V15.4277ZM12.8616 18.8563V15.4277H20.5291C20.4569 16.3268 20.3041 16.7337 20.0916 17.1312C19.8043 17.6683 19.3879 18.0847 18.8508 18.372C18.2844 18.6749 17.6987 18.8563 15.6973 18.8563H12.8616ZM12.8616 8.57059V5.14202H15.6973C17.6987 5.14202 18.2844 5.32345 18.8508 5.62633C19.3879 5.91359 19.8043 6.33002 20.0916 6.86716C20.3041 7.26459 20.4569 7.67155 20.5291 8.57059H12.8616Z"
    />
  </SvgIcon>
);

export const GridIcon = ({ size = 'normal', color = 'primary' }: IconProps) => {
  const classes = useStyles();

  return (
    <SVGGridIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
