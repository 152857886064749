import { useEffect } from 'react';

import { useLocation, useRouteMatch } from 'react-router-dom';

/** Установить заголовок страницы в браузере
 * @param {{ title: string; href: string }[]} metaTitles
 * @returns {void}
 *  */
export const useMetaTitles = (
  metaTitles: { title: { company: string; employee: string }; href: string }[],
  role: 'employee' | 'company',
) => {
  const { pathname } = useLocation();

  const { title = '', href = 'not-found' } =
    metaTitles.find((item) => {
      if (item.href === '/') {
        return pathname === item.href;
      } else {
        return (
          pathname.startsWith(item.href) ||
          pathname.startsWith(`/company${item.href}`) ||
          pathname.startsWith(`/employee${item.href}`)
        );
      }
    }) || {};

  const matchGeneral = useRouteMatch(href);
  const employeeMatch = useRouteMatch(`/employee${href}`);
  const companyMatch = useRouteMatch(`/company${href}`);
  const match = matchGeneral || employeeMatch || companyMatch;

  useEffect(() => {
    if (!match) {
      document.title = `VK HR Tek - Страница не найдена`;
    } else {
      document.title = `VK HR Tek${title ? ` - ${title[role]}` : ''}`;
    }
  }, [pathname, title, href, match, role]);
};
