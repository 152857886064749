import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class AcceptPoliciesResponse {
  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsNotEmpty()
  need_confirm: string[];
}

export class PolicyEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  full_name: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  phone: string;

  @IsIn(['after_expiry'])
  @IsString()
  @IsOptional()
  caption?: 'after_expiry';

  @IsDateString()
  @IsOptional()
  dismissed_at?: string;

  @IsString()
  @IsOptional()
  position?: string;
}

export class GetPolicyEmployeesResponse {
  @ValidateNested({ each: true })
  @Type(() => PolicyEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: PolicyEmployee[];
}

export class CreatePolicyVersionResponse {
  @IsUUID()
  @IsNotEmpty()
  policy_version_id: string;
}

export class PolicyAcceptedZipResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class PolicyFileHash {
  @IsUUID()
  @IsNotEmpty()
  policy_id: string;

  @IsUUID()
  @IsNotEmpty()
  policy_version_id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  hash: string;
}

export class GetPolicyFileHashesResponse {
  @ValidateNested({ each: true })
  @Type(() => PolicyFileHash)
  @IsArray()
  @IsNotEmpty()
  hashes: PolicyFileHash[];
}

export class PolicyFileHashResponse {
  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  hash: string;
}

export class PolicyFilterSelectBadge {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsString()
  @IsOptional()
  description?: string;

  @IsIn(['blue', 'velvet', 'red'])
  @IsString()
  @IsOptional()
  color?: 'blue' | 'velvet' | 'red';

  @IsIn(['left', 'bottom'])
  @IsString()
  @IsOptional()
  position?: 'left' | 'bottom';
}

export class PolicyFilterSelectOption {
  @IsUUID()
  @IsNotEmpty()
  value: string;

  @IsString()
  @IsNotEmpty()
  label: string;

  @ValidateNested()
  @Type(() => PolicyFilterSelectBadge)
  @IsOptional()
  badge?: PolicyFilterSelectBadge;
}

export class GetGovDocumentTypeOptionsResponse {
  @ValidateNested({ each: true })
  @Type(() => PolicyFilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: PolicyFilterSelectOption[];
}

export class PolicyVersion {
  @IsUUID()
  @IsNotEmpty()
  policy_version_id: string;

  @IsDateString()
  @IsNotEmpty()
  active_from: string;

  @IsDateString()
  @IsOptional()
  active_to?: string;

  @IsInt()
  @IsNotEmpty()
  accepted_employees: number;

  @IsInt()
  @IsNotEmpty()
  total_employees: number;

  @IsDateString()
  @IsOptional()
  document_date?: string;

  @IsString()
  @IsOptional()
  document_number?: string;

  @IsIn(['pep', 'unep', 'unep_disabled'])
  @IsString()
  @IsNotEmpty()
  signature_type: 'pep' | 'unep' | 'unep_disabled';

  @IsBoolean()
  @IsNotEmpty()
  with_order: boolean;
}

export class PolicyUnit {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;
}

export class PolicyCompany {
  @IsUUID()
  @IsNotEmpty()
  company_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsOptional()
  tsp_url?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hash_source?: 'api' | 'browser_plugin';
}

export class GetPolicyWithVersionsResponse {
  @IsUUID()
  @IsNotEmpty()
  policy_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => PolicyCompany)
  @IsNotEmpty()
  company: PolicyCompany;

  @ValidateNested({ each: true })
  @Type(() => PolicyUnit)
  @IsArray()
  @IsNotEmpty()
  units: PolicyUnit[];

  @IsString()
  @IsNotEmpty()
  unit_type: string;

  @IsString()
  @IsOptional()
  gov_document_type_name?: string;

  @IsString()
  @IsOptional()
  gov_document_type_code?: string;

  @ValidateNested({ each: true })
  @Type(() => PolicyVersion)
  @IsArray()
  @IsNotEmpty()
  versions: PolicyVersion[];

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  positions?: string[];

  @IsBoolean()
  @IsOptional()
  hide_if_accepted?: boolean;
}

export class CreatePolicyResponse {
  @IsUUID()
  @IsNotEmpty()
  policy_id: string;
}

export class PolicyXlsxByEmployeeFiltersResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class PolicyXlsxByEmployeeIdsResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class CompanyPolicyXlsxResponse {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;
}

export class EmployeePolicyListItem {
  @IsUUID()
  @IsNotEmpty()
  policy_id: string;

  @IsUUID()
  @IsNotEmpty()
  policy_version_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => PolicyCompany)
  @IsNotEmpty()
  company: PolicyCompany;

  @IsIn(['not_signed', 'in_progress', 'signed', 'canceled', 'error'])
  @IsString()
  @IsNotEmpty()
  status: 'not_signed' | 'in_progress' | 'signed' | 'canceled' | 'error';

  @IsString()
  @IsNotEmpty()
  employee_id: string;

  @IsIn([
    'pep',
    'unep_disabled',
    'unep_kontur',
    'unep_goskey',
    'unep_cryptopro_simple',
    'unep_cryptopro_local',
  ])
  @IsString()
  @IsNotEmpty()
  signature_type:
    | 'pep'
    | 'unep_disabled'
    | 'unep_kontur'
    | 'unep_goskey'
    | 'unep_cryptopro_simple'
    | 'unep_cryptopro_local';

  @IsDateString()
  @IsOptional()
  read_at?: string;

  @IsIn(['snils_not_found'])
  @IsString()
  @IsOptional()
  signature_detailed_error?: 'snils_not_found';

  @IsBoolean()
  @IsOptional()
  hide_if_accepted?: boolean;
}

export class EmployeePolicyListResponse {
  @ValidateNested({ each: true })
  @Type(() => EmployeePolicyListItem)
  @IsArray()
  @IsNotEmpty()
  policies: EmployeePolicyListItem[];
}

export class CompanyPolicyListItem {
  @IsUUID()
  @IsNotEmpty()
  policy_id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @Type(() => PolicyCompany)
  @IsNotEmpty()
  company: PolicyCompany;

  @IsDateString()
  @IsNotEmpty()
  active_from: string;

  @IsDateString()
  @IsOptional()
  active_to?: string;

  @IsString()
  @IsOptional()
  gov_document_type_name?: string;

  @IsString()
  @IsOptional()
  gov_document_type_code?: string;

  @IsInt()
  @IsNotEmpty()
  accepted_empoyees: number;

  @IsInt()
  @IsNotEmpty()
  total_employees: number;

  @IsIn(['pep', 'unep', 'unep_disabled'])
  @IsString()
  @IsNotEmpty()
  signature_type: 'pep' | 'unep' | 'unep_disabled';

  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  positions?: string[];

  @IsBoolean()
  @IsOptional()
  hide_if_accepted?: boolean;
}

export class CompanyPolicyListResponse {
  @ValidateNested({ each: true })
  @Type(() => CompanyPolicyListItem)
  @IsArray()
  @IsNotEmpty()
  policies: CompanyPolicyListItem[];

  @IsInt()
  @IsNotEmpty()
  total_count: number;

  @IsInt()
  @IsNotEmpty()
  total_version_count: number;
}

export class PolicyFilterMultipleSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple-search'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple-search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class PolicyFilterSearch {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['search'])
  @IsString()
  @IsNotEmpty()
  type: 'search';

  @IsString()
  @IsNotEmpty()
  url: string;

  @IsString()
  @IsNotEmpty()
  placeholder: string;
}

export class PolicyFilterMultiple {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['multiple'])
  @IsString()
  @IsNotEmpty()
  type: 'multiple';

  @ValidateNested({ each: true })
  @Type(() => PolicyFilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: PolicyFilterSelectOption[];
}

export class PolicyFilterSelect {
  @IsString()
  @IsNotEmpty()
  key: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsIn(['select'])
  @IsString()
  @IsNotEmpty()
  type: 'select';

  @IsBoolean()
  @IsOptional()
  autocomplete?: boolean;

  @ValidateNested({ each: true })
  @Type(() => PolicyFilterSelectOption)
  @IsArray()
  @IsNotEmpty()
  options: PolicyFilterSelectOption[];
}

export class PolicyAvailableFiltersResponse {
  @IsArray()
  @IsNotEmpty()
  filters: (
    | PolicyFilterSelect
    | PolicyFilterMultiple
    | PolicyFilterSearch
    | PolicyFilterMultipleSearch
  )[];
}

export type PolicyListItemsResponse = PolicyFilterSelectOption[];

export class GetPolicyCompanies {
  @ValidateNested({ each: true })
  @Type(() => PolicyCompany)
  @IsArray()
  @IsNotEmpty()
  companies: PolicyCompany[];
}
