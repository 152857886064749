import InterRegular from './Inter-Regular.woff2';
import InterSemiBold from './Inter-SemiBold.woff2';
import InterLight from './Inter-Light.woff2';
import InterMedium from './Inter-Medium.woff2';
import VKSansRegular from './VKSansText-Regular.woff2';

const fonts = [
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 400,
    src: `
      local('Inter Regular'),
      local('Inter-Regular'),
      url(${InterRegular}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 400,
    src: `
      local('Inter Regular'),
      local('Inter-Regular'),
      url(${InterRegular}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'VKSans',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 400,
    src: `
      local('VKSans Regular'),
      local('VKSans-Regular'),
      url(${VKSansRegular}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'VKSans',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 400,
    src: `
      local('VKSans Regular'),
      local('VKSans-Regular'),
      url(${VKSansRegular}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 600,
    src: `
      local('Inter SemiBold'),
      local('Inter-SemiBold'),
      url(${InterSemiBold}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 600,
    src: `
      local('Inter SemiBold'),
      local('Inter-SemiBold'),
      url(${InterSemiBold}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 300,
    src: `
      local('Inter Light'),
      local('Inter-Light'),
      url(${InterLight}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 300,
    src: `
      local('Inter Light'),
      local('Inter-Light'),
      url(${InterLight}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 500,
    src: `
      local('Inter Medium'),
      local('Inter-Medium'),
      url(${InterMedium}) format('woff2')
    `,
    unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
  },
  {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontDisplay: 'swap' as const,
    fontWeight: 500,
    src: `
      local('Inter Medium'),
      local('Inter-Medium'),
      url(${InterMedium}) format('woff2')
    `,
    unicodeRange:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
  },
];

export default fonts;
