import { FORM_ERROR } from 'final-form';
import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { showNotification } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import {
  EventListItem as Event,
  CreateSimpleEventTypeOptionsResponse as EventTypeOptions,
  CreateSimpleEventTypeCompany as EventTypeCompany,
} from '@app/gen/events';
import { ThunkExtra } from '@app/store';

import { EventsTypeService } from '../../services';
import { CreateEventTypeDto } from '../../dto';
import { EventsState, EventsWithRootState } from '../events.state';
import { EventsRouter } from '../../types';

export const getCanCreateEventType = createAsyncThunk<
  boolean,
  undefined,
  ThunkExtra<EventsWithRootState>
>(
  'events/getCanCreateEventType',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const eventsService = inject(EventsTypeService);
      const { companies } = await eventsService.getEventTypeCompanies();

      return !!companies.length;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEventTypeOptions = createAsyncThunk<
  (EventTypeCompany & EventTypeOptions)[],
  undefined | true,
  ThunkExtra<EventsWithRootState>
>(
  'events/getEventTypeOptions',
  async (withCache, { rejectWithValue, getState, extra: { inject } }) => {
    try {
      const eventsService = inject(EventsTypeService);
      const state = getState().events.eventTypes;

      if (withCache && state.companies && state.companies.length) {
        return state.companies;
      }

      const { companies } = await eventsService.getEventTypeCompanies();

      return await Promise.all(
        companies.map(async (company) => {
          return {
            ...company,
            ...(await eventsService.getEventTypeOptionsByCompany({
              companyId: company.id,
            })),
          };
        }),
      );
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createEventType = createAsyncThunk<
  void,
  {
    values: CreateEventTypeDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra<EventsWithRootState>
>(
  'events/createEventType',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      const router = inject<EventsRouter>(EventsRouter);
      await inject(EventsTypeService).createEventType(values);

      dispatch(
        showNotification(
          'Новый тип заявки создан!\nВы можете создавать заявки нового типа',
        ),
      );
      actions.resolve(null);
      const location = history.location as {
        state?: { prev?: { pathname: string; search: string }[] };
      };

      if (location.state?.prev) {
        router.goToList(location.state.prev?.[0]?.search);
      } else {
        router.goToList();
      }
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const eventTypeReducers = (
  builder: ActionReducerMapBuilder<EntityState<Event> & EventsState>,
) => {
  builder.addCase(getCanCreateEventType.pending, (state) => {
    state.canCreateEventType = false;
  });
  builder.addCase(getCanCreateEventType.fulfilled, (state, action) => {
    state.canCreateEventType = action.payload;
  });
  builder.addCase(getCanCreateEventType.rejected, (state) => {
    state.canCreateEventType = false;
  });
  builder.addCase(getEventTypeOptions.pending, (state) => {
    state.eventTypes.error = null;
    state.eventTypes.status = 'loading';
  });
  builder.addCase(getEventTypeOptions.fulfilled, (state, { payload }) => {
    state.eventTypes.error = null;
    state.eventTypes.status = 'complete';
    state.eventTypes.companies = payload;
  });
  builder.addCase(getEventTypeOptions.rejected, (state, { payload, error }) => {
    state.eventTypes.error =
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
    state.eventTypes.status = 'failed';
  });
};
