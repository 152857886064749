import React from 'react';

import classNames from 'classnames';

import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Link } from '@vk-hr-tek/ui/Link';

interface ChatProps {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  loadWebim: () => void;
}

export const Chat = ({ status, loadWebim }: ChatProps) => {
  const isLoading = status === 'loading';
  const isComplete = status === 'complete';

  return (
    <>
      {(isLoading || isComplete) && (
        <Link
          className={classNames(
            'webim_button',
            'hidden',
            'vkdoc_webim_loaded',
            'aqa_chat_link',
          )}
          onClick={() => {}}
        >
          <img
            alt="webim"
            src={`https://${window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME}.webim.ru/button.php`}
          />
        </Link>
      )}

      <Link
        className={classNames(
          'webim_button',
          'webim_button_no_loaded',
          'aqa_chat_link',
          isLoading && 'webim_button_loading',
        )}
        onClick={loadWebim}
      >
        {isLoading && <CircularProgress size={30} />}
      </Link>
    </>
  );
};
