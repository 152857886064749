export const GooglePlayIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68701 22.3129C3.37409 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.3129C24 20.6259 24 17.9106 24 12.48V11.52C24 6.08936 24 3.37408 22.313 1.68701C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37409 0 1.68701 1.68701C6.39578e-07 3.37408 0 6.08936 0 11.52V12.48C0 17.9106 6.39578e-07 20.6259 1.68701 22.3129Z"
      fill="black"
    />
    <path
      d="M12.6835 11.6421L6.06105 18.6708C6.06167 18.672 6.06167 18.6739 6.06229 18.6751C6.26569 19.4383 6.96233 20 7.7896 20C8.1205 20 8.43088 19.9104 8.6971 19.7537L8.71825 19.7412L16.1723 15.4401L12.6835 11.6421Z"
      fill="#EA4335"
    />
    <path
      d="M19.3831 10.4434L19.3769 10.4391L16.1586 8.5737L12.533 11.8L16.1717 15.4375L19.3725 13.5908C19.9336 13.2872 20.3148 12.6957 20.3148 12.014C20.3148 11.336 19.9392 10.7476 19.3831 10.4434Z"
      fill="#FBBC04"
    />
    <path
      d="M6.06096 5.32787C6.02115 5.47466 6 5.62892 6 5.78815V18.2083C6 18.3675 6.02115 18.5218 6.06158 18.668L12.9111 11.8191L6.06096 5.32787Z"
      fill="#4285F4"
    />
    <path
      d="M12.7322 12L16.1594 8.57339L8.71406 4.25668C8.44349 4.09434 8.12751 4.00104 7.78976 4.00104C6.9625 4.00104 6.26461 4.56395 6.06121 5.32777L6.06059 5.32964L12.7322 12Z"
      fill="#34A853"
    />
  </svg>
);
