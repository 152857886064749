import { injectable } from 'inversify';

import { CreateEventItem, CreateEventEmployee } from '@app/gen/events';

@injectable()
export class AbsenceEventCreationMapper {
  findFormRoleFields(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formValuesObj: Record<string, any>,
    getFullFieldName?: boolean,
  ) {
    return Object.keys(formValuesObj)
      .filter((roleId) => roleId)
      .map((key) => {
        const regexpResultArray = key.match(/^roles_([0-9a-zA-Z-]+)$/);

        if (regexpResultArray) {
          return getFullFieldName ? regexpResultArray[0] : regexpResultArray[1];
        }

        return null;
      });
  }

  gatherAssignedRoles(formValuesObj: Record<string, any>) {
    return this.findFormRoleFields(formValuesObj).reduce(
      (acc: { role_id: string; group_id: string }[], roleId) => {
        return !roleId
          ? acc
          : [
              ...acc,
              { role_id: roleId, group_id: formValuesObj[`roles_${roleId}`] },
            ];
      },
      [],
    );
  }

  processCreateEventItem(items: CreateEventItem[]) {
    return items.map((item) => ({
      companyId: item.company_id,
      companyName: item.company_name,
      employees: this.processEmployees(item.employees),
    }));
  }

  processEmployees(employees: CreateEventEmployee[]) {
    return employees
      .map((employee) => ({
        ...employee,
        personnelNumber: employee.personnel_number,
        eventTypes: [...employee.event_types].sort((a, b) =>
          (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
        ),
      }))
      .sort((a, b) =>
        (a.name || '').toLowerCase() > (b.name || '').toLowerCase() ? 1 : -1,
      );
  }
}
