import React, { useCallback } from 'react';

import { Help as HelpIcon } from '@material-ui/icons';

import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Content } from '@vk-hr-tek/ui/Content';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { EllipsisText } from '@vk-hr-tek/ui/EllipsisText';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';
import { UserApiService } from '@vk-hr-tek/app/user/services';

import { useSelector, useDispatch } from '@app/hooks';

import {
  selectUser,
  selectTelegramSubscriptionStatus,
  subscribeOnTelegram,
  cancelTelegramSubscription,
} from '../../slice';
import { Information } from '../../ui/information';

export const GeneralInfo = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const userApiService = useInject(UserApiService);

  const user = useSelector(selectUser);
  const telegramSubscriptionStatus = useSelector(
    selectTelegramSubscriptionStatus,
  );

  const gap = isDesktop ? '20' : '16';
  const tooltipPlacement = isDesktop ? 'right' : 'bottom';
  const headlineVariant = isDesktop ? 'h5' : 'h6';
  const titleVatiant = isDesktop ? 'h6' : 'subtitle1';
  const subtitleVariant = isDesktop ? 'subtitle1' : 'subtitle2';
  const textVariant = isDesktop ? 'body2' : 'body3';

  const loadPhoto = useCallback(async () => {
    const res = await userApiService.getUserPhoto({ user_id: user?.id ?? '' });

    return res.file;
  }, [userApiService, user?.id]);

  const handleCancelSubscribtion = useCallback(
    () => dispatch(cancelTelegramSubscription()),
    [dispatch],
  );

  const handleSubsctibe = useCallback(
    () => dispatch(subscribeOnTelegram()),
    [dispatch],
  );

  const handleTelegramSubscribtion = useCallback(() => {
    if (user?.notifications?.telegram) {
      handleCancelSubscribtion();
    } else {
      handleSubsctibe();
    }
  }, [
    user?.notifications?.telegram,
    handleCancelSubscribtion,
    handleSubsctibe,
  ]);

  if (!user) {
    return null;
  }

  return (
    <Content>
      <Box
        px={{ xs: '16', md: '32' }}
        py={{ xs: '24', md: '32' }}
        display="flex"
        gap={gap}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        flexDirection={isDesktop ? 'row' : 'column'}
      >
        <Avatar
          size={isDesktop ? 'extraLarge' : 'medium'}
          loadPhoto={loadPhoto}
        >
          {user.avatar}
        </Avatar>
        <Box display="flex" gap={isDesktop ? '0' : '8'} flexDirection="column">
          <Typography variant={headlineVariant}>{user.name}</Typography>
          <Typography variant={textVariant} color="textTertiary">
            Дата рождения: {user.birthday}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        p={{ xs: '20', md: '32' }}
        display="flex"
        flexDirection="column"
        gap={gap}
      >
        <Box>
          <Typography variant={titleVatiant}>Общая информация</Typography>
        </Box>
        <Box>
          <Information
            label={
              <Typography variant={textVariant} color="textTertiary">
                СНИЛС
              </Typography>
            }
          >
            <Typography variant={textVariant}>{user.snils}</Typography>
          </Information>
        </Box>
        <Box>
          <Information
            spacing="4"
            label={
              <Typography variant={textVariant} color="textTertiary">
                Телефон
              </Typography>
            }
          >
            <Typography variant={textVariant}>{user.phone}</Typography>
            <Tooltip
              title="Изменить номер телефона можно на странице авторизации, для этого нужно выйти из вашей учетной записи"
              placement={tooltipPlacement}
            >
              <HelpIcon color="disabled" fontSize="small" />
            </Tooltip>
          </Information>
        </Box>
        {user.certificate.exists && (
          <Information
            label={
              <Typography variant={textVariant} color="textTertiary">
                Электронная подпись
              </Typography>
            }
            spacing="4"
            alignItems="flex-start"
            flexDirection="column"
            width="100%"
          >
            <Box display="flex" flexDirection="column" gap="16">
              <Box display="flex" gap="4" flexDirection="column">
                <Box display="flex" alignItems="center" gap="8">
                  <Typography variant={subtitleVariant}>
                    {user.certificate.type}
                  </Typography>
                  <Tooltip
                    title="УНЭП (Усиленная неквалифицированая электронная подпись)"
                    placement={tooltipPlacement}
                  >
                    <HelpIcon color="disabled" fontSize="small" />
                  </Tooltip>
                </Box>
                <Box>
                  <Typography variant={textVariant}>
                    {user.certificate.activity}
                  </Typography>
                </Box>
              </Box>
              {user.ukepCertificates &&
                user.ukepCertificates.map((certificate) => (
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                  >
                    <Box display="flex" alignItems="center" gap="8">
                      <Typography variant={subtitleVariant}>
                        {certificate.type}
                      </Typography>
                      <Tooltip
                        title={certificate.description}
                        placement={tooltipPlacement}
                      >
                        <HelpIcon color="disabled" fontSize="small" />
                      </Tooltip>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      width="100%"
                    >
                      <Typography variant={textVariant}>
                        {certificate.activity}
                      </Typography>
                      <Typography variant={textVariant}>
                        {certificate.issuedBy}
                      </Typography>
                      <Typography variant={textVariant}>
                        <EllipsisText>{certificate.owner}</EllipsisText>
                      </Typography>
                      <Typography variant={textVariant}>
                        <EllipsisText>{certificate.serialNumber}</EllipsisText>
                      </Typography>
                      <Typography variant={textVariant} color="textTertiary">
                        {certificate.companyName}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Information>
        )}
      </Box>
      {window.REACT_APP_VKHRTEK_TELEGRAM_SUBSCRIPTION_ENABLED && (
        <>
          <Divider />
          <Box
            p={{ xs: '20', md: '32' }}
            display="flex"
            flexDirection="column"
            gap={gap}
          >
            <Box>
              <Typography variant={titleVatiant}>Уведомления</Typography>
            </Box>
            <Paper>
              {isDesktop ? (
                <Box
                  p={'20'}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box pb="8">
                      <Typography variant={subtitleVariant}>
                        Telegram
                      </Typography>
                    </Box>
                    <Typography variant={textVariant}>
                      {(telegramSubscriptionStatus === 'idle' &&
                        user.notifications.telegram) ||
                      telegramSubscriptionStatus === 'enabled' ||
                      telegramSubscriptionStatus === 'disablingInProgress' ? (
                        <>
                          Если вы больше не хотите получать уведомления в
                          Telegram, отключите их
                        </>
                      ) : (
                        <>
                          Чтобы получать уведомления в Telegram, подключите бота
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    {telegramSubscriptionStatus === 'idle' && (
                      <Button
                        onClick={handleTelegramSubscribtion}
                        size="small"
                        variant={
                          user.notifications.telegram ? 'quaternary' : 'primary'
                        }
                      >
                        {user.notifications.telegram
                          ? 'Отключить'
                          : 'Подключить'}
                      </Button>
                    )}
                    {(telegramSubscriptionStatus === 'enablingInProgress' ||
                      telegramSubscriptionStatus === 'disablingInProgress') && (
                      <Button
                        variant={
                          telegramSubscriptionStatus === 'enablingInProgress'
                            ? 'primary'
                            : 'quaternary'
                        }
                        size="small"
                        loading
                      />
                    )}
                    {telegramSubscriptionStatus === 'enabled' && (
                      <Button
                        onClick={handleCancelSubscribtion}
                        size="small"
                        variant="quaternary"
                      >
                        Отключить
                      </Button>
                    )}
                    {telegramSubscriptionStatus === 'disabled' && (
                      <Button onClick={handleSubsctibe} size="small">
                        Подключить
                      </Button>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box py="20" px="16">
                  <Box
                    pb="16"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant={subtitleVariant}>Telegram</Typography>
                    <Box>
                      {telegramSubscriptionStatus === 'idle' && (
                        <Button
                          onClick={handleTelegramSubscribtion}
                          variant={
                            user.notifications.telegram
                              ? 'quaternary'
                              : 'primary'
                          }
                          size="small"
                        >
                          {user.notifications.telegram
                            ? 'Отключить'
                            : 'Подключить'}
                        </Button>
                      )}
                      {(telegramSubscriptionStatus === 'enablingInProgress' ||
                        telegramSubscriptionStatus ===
                          'disablingInProgress') && (
                        <Button
                          variant={
                            telegramSubscriptionStatus === 'enablingInProgress'
                              ? 'primary'
                              : 'quaternary'
                          }
                          size="small"
                          disabled
                          icon={<CircularProgress color="inherit" />}
                        >
                          {telegramSubscriptionStatus === 'enablingInProgress'
                            ? 'Подключить'
                            : 'Отключить'}
                        </Button>
                      )}
                      {telegramSubscriptionStatus === 'enabled' && (
                        <Button
                          onClick={handleCancelSubscribtion}
                          variant="quaternary"
                          size="small"
                        >
                          Отключить
                        </Button>
                      )}
                      {telegramSubscriptionStatus === 'disabled' && (
                        <Button onClick={handleSubsctibe} size="small">
                          Подключить
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant={textVariant}>
                      {(telegramSubscriptionStatus === 'idle' &&
                        user.notifications.telegram) ||
                      telegramSubscriptionStatus === 'enabled' ||
                      telegramSubscriptionStatus === 'disablingInProgress' ? (
                        <>
                          Если вы больше не хотите получать уведомления в
                          Telegram, отключите их
                        </>
                      ) : (
                        <>
                          Чтобы получать уведомления в Telegram, подключите бота
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Paper>
          </Box>
        </>
      )}
    </Content>
  );
};
