import { useContext } from 'react';

import { RootWidthContext } from '@vk-hr-tek/ui/rootWidth';

/** Определить, что приложение встроено в сторонний сервис как виджет
 * @returns {boolean}
 *  */
export const useIsWidget = () => {
  const rootWidth = useContext(RootWidthContext);

  return typeof rootWidth === 'number';
};
