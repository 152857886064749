import {
  createAsyncThunk,
  createAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { QueryService } from '@vk-hr-tek/core/query';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { showNotification } from '@vk-hr-tek/core/notifications';
import { History } from '@vk-hr-tek/core/history';

import { EmployeeInviteVerifyResponse as VerifyInviteResponse } from '@app/gen/users';

import { AuthInviteService, AuthRouterService } from '../../../services';
import { VerifyInviteDto, SendInviteDto } from '../../../dto';
import {
  AuthState,
  startLoading,
  completeLoading,
  setError,
  ThunkExtra,
} from '../../auth.state';

const registerWithoutPhoneCodeVerify =
  window.REACT_APP_VKHRTEK_AUTH_SKIP_PHONE_VERIFICATION.toString() === 'true';

export const setInviteCode = createAction<string | null>('auth/setInviteCode');

export const verifyInviteCode = createAsyncThunk<void, void, ThunkExtra>(
  'auth/verifyInviteCode',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const { token: inviteCode } = inject(QueryService).parse(
        history.location.search,
      );

      const validationResult = await inject(ValidationService).validate(
        { inviteCode },
        VerifyInviteDto,
        ['load'],
      );

      if (Object.keys(validationResult).length) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad request',
          data: {
            error_code: 'invite_status_already_completed',
          },
        });
      }

      const result = await inject(AuthInviteService).verifyInvite({
        inviteCode,
      });

      if (result.status !== 'active') {
        throw new AppError('client', {
          code: 400,
          message: 'Bad request',
          data: {
            error_code: 'expired_invite',
          },
        });
      }
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const verifyInvite = createAsyncThunk<
  VerifyInviteResponse,
  {
    values: VerifyInviteDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/verifyInvite',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      const { token: inviteCode } = inject(QueryService).parse(
        history.location.search,
      );

      if (!inviteCode) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad request',
          data: {
            error_code: 'invite_status_already_completed',
          },
        });
      }

      dispatch(setInviteCode(inviteCode));

      const router = inject(AuthRouterService);

      const result = await inject(AuthInviteService).verifyInvite({
        ...values,
        inviteCode,
      });

      actions.resolve(null);

      if (result.status === 'registration') {
        router.redirectToRegister();
      } else if (result.status === 'phone_change') {
        router.redirectToPhoneChange();
      } else {
        router.redirectToLogin();
      }

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const sendInvite = createAsyncThunk<
  void,
  {
    values: SendInviteDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/sendInvite',
  async ({ values, actions }, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(AuthInviteService).sendInvite(values);

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const activateEmployee = createAsyncThunk<void, undefined, ThunkExtra>(
  'auth/activateEmployee',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const history = inject<History>(History);
      const { token: inviteCode } = inject(QueryService).parse(
        history.location.search,
      );

      if (!inviteCode) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad request',
          data: {
            error_code: 'invite_status_already_completed',
          },
        });
      }

      dispatch(setInviteCode(inviteCode));

      const result = await inject(AuthInviteService).activateEmployee({
        inviteCode,
      });

      inject(AuthRouterService).redirectToHome();

      dispatch(showNotification('Сотрудник успешно зарегистрирован!'));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const inviteReducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(setInviteCode, (state, action) => {
    state.inviteCode = action.payload;
  });

  builder.addCase(activateEmployee.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(activateEmployee.fulfilled, (state) => {
    completeLoading(state);

    state.stage = 'init';
  });
  builder.addCase(activateEmployee.rejected, (state, { payload }) => {
    setError(state, { payload });

    state.stage = 'error';
  });

  builder.addCase(verifyInvite.pending, (state, action) => {
    startLoading(state);

    state.values.snils = action.meta.arg.values.snils || '';
  });

  builder.addCase(verifyInvite.fulfilled, (state, action) => {
    completeLoading(state);

    state.stage =
      action.payload.phone && registerWithoutPhoneCodeVerify
        ? 'finish'
        : 'init';
    state.values.phone = action.payload.phone || '';
  });

  builder.addCase(verifyInvite.rejected, (state, { payload }) => {
    setError(state, { payload });

    state.stage =
      payload &&
      [
        'incompatible_authorized_employee_user',
        'invite_status_already_completed',
        'expired_invite',
        'invite_canceled',
        'not_found_invite',
      ].includes(payload.errorCode || '')
        ? 'error'
        : 'init';
  });

  builder.addCase(verifyInviteCode.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(verifyInviteCode.fulfilled, (state) => {
    completeLoading(state);

    state.stage = 'init';
  });
  builder.addCase(verifyInviteCode.rejected, (state, { payload }) => {
    setError(state, { payload });

    state.stage = 'error';
  });

  builder.addCase(sendInvite.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(sendInvite.fulfilled, (state, { meta }) => {
    completeLoading(state);

    state.notification =
      meta.arg.values.target === 'registration'
        ? {
            title: 'Успешно!',
            description:
              'Вам отправлена ссылка для завершения регистрации в сервисе',
          }
        : {
            title: 'Успешно!',
            description: 'Вам отправлена ссылка для изменения номера телефона',
          };

    state.stage = 'error';
  });
  builder.addCase(sendInvite.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
};
