import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';

export const FlowErrorIcon = () => (
  <Box flex="0 0 36px" width={36} height={36} mb="16">
    <svg
      width="18"
      height="36"
      viewBox="0 0 18 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="36" fill="#E5E5E5" />
      <rect
        x="-5253"
        y="-805"
        width="6154"
        height="1822"
        rx="50"
        fill="#F0F0F0"
      />
      <g clipPath="url(#clip0_4671_33096)">
        <rect
          width="1366"
          height="768"
          transform="translate(-665 -409)"
          fill="#FAFAFA"
        />
        <rect
          x="-616.5"
          y="-225.5"
          width="1271"
          height="313"
          rx="16.5"
          fill="white"
        />
        <g clipPath="url(#clip1_4671_33096)">
          <rect x="-616" y="-129" width="1270" height="144" fill="white" />
        </g>
        <path
          d="M-616 15H654V71C654 79.8366 646.837 87 638 87H-600C-608.837 87 -616 79.8366 -616 71V15Z"
          fill="white"
        />
        <line x1="-616" y1="15.5" x2="654" y2="15.5001" stroke="#E2E6EB" />
        <rect
          x="-616.5"
          y="-225.5"
          width="1271"
          height="313"
          rx="16.5"
          stroke="#E2E6EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-665 -409H701V1102H-665V-409Z"
          fill="black"
          fillOpacity="0.4"
        />
        <g filter="url(#filter0_d_4671_33096)">
          <rect
            x="-446"
            y="-341"
            width="928"
            height="630"
            rx="16"
            fill="white"
          />
          <path
            d="M17.9998 18C17.9998 27.4678 17.9998 24.1225 17.9998 35.1429C8.53205 35.1429 0.856934 27.4678 0.856934 18C0.856934 8.5323 8.53205 0.857178 17.9998 0.857178C17.9998 8.20412 17.9998 8.5323 17.9998 18Z"
            fill="#E24141"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1281 23.6324C13.6413 24.1225 12.852 24.1225 12.3651 23.6324C11.8783 23.1422 11.8783 22.3476 12.3651 21.8574L16.237 17.9592L12.4462 14.1426C11.9594 13.6524 11.9594 12.8578 12.4462 12.3676C12.933 11.8775 13.7224 11.8775 14.2092 12.3676L18 16.1842L21.7908 12.3676C22.2776 11.8775 23.0669 11.8775 23.5538 12.3676C24.0406 12.8578 24.0406 13.6524 23.5538 14.1426L19.763 17.9592L23.6348 21.8574C24.1217 22.3476 24.1217 23.1422 23.6348 23.6324C23.148 24.1225 22.3587 24.1225 21.8719 23.6324L18 19.7342L14.1281 23.6324Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4671_33096"
          x="-470"
          y="-361"
          width="976"
          height="678"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4671_33096"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4671_33096"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4671_33096">
          <rect
            width="1366"
            height="768"
            fill="white"
            transform="translate(-665 -409)"
          />
        </clipPath>
        <clipPath id="clip1_4671_33096">
          <path
            d="M-616 -129H654V-0.999999C654 7.83656 646.837 15 638 15H-600C-608.837 15 -616 7.83656 -616 -1V-129Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="18"
      height="36"
      viewBox="0 0 18 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="36" fill="#E5E5E5" />
      <rect
        x="-5271"
        y="-805"
        width="6154"
        height="1822"
        rx="50"
        fill="#F0F0F0"
      />
      <g clipPath="url(#clip0_4671_33096)">
        <rect
          width="1366"
          height="768"
          transform="translate(-683 -409)"
          fill="#FAFAFA"
        />
        <rect
          x="-634.5"
          y="-225.5"
          width="1271"
          height="313"
          rx="16.5"
          fill="white"
        />
        <g clipPath="url(#clip1_4671_33096)">
          <rect x="-634" y="-129" width="1270" height="144" fill="white" />
          <line x1="12.5" y1="15" x2="12.5" y2="-129" stroke="#E2E6EB" />
        </g>
        <path
          d="M-634 15H636V71C636 79.8366 628.837 87 620 87H-618C-626.837 87 -634 79.8366 -634 71V15Z"
          fill="white"
        />
        <line x1="-634" y1="15.5" x2="636" y2="15.5001" stroke="#E2E6EB" />
        <rect
          x="-634.5"
          y="-225.5"
          width="1271"
          height="313"
          rx="16.5"
          stroke="#E2E6EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-683 -409H683V1102H-683V-409Z"
          fill="black"
          fillOpacity="0.4"
        />
        <g filter="url(#filter0_d_4671_33096)">
          <rect
            x="-464"
            y="-341"
            width="928"
            height="630"
            rx="16"
            fill="white"
          />
          <path
            d="M0 35.1429C9.46774 35.1429 17.1429 27.4678 17.1429 18C17.1429 8.5323 9.46774 0.857178 0 0.857178V35.1429Z"
            fill="#B12F2F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-3.87189 23.6324C-4.35872 24.1225 -5.14804 24.1225 -5.63487 23.6324C-6.12171 23.1422 -6.12171 22.3476 -5.63487 21.8574L-1.763 17.9592L-5.55379 14.1426C-6.04063 13.6524 -6.04063 12.8578 -5.55379 12.3676C-5.06696 11.8775 -4.27764 11.8775 -3.79081 12.3676L-1.52588e-05 16.1842L3.79078 12.3676C4.27761 11.8775 5.06693 11.8775 5.55376 12.3676C6.0406 12.8578 6.0406 13.6524 5.55377 14.1426L1.76297 17.9592L5.63484 21.8574C6.12168 22.3476 6.12168 23.1422 5.63484 23.6324C5.14801 24.1225 4.35869 24.1225 3.87186 23.6324L-1.4782e-05 19.7342L-3.87189 23.6324Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4671_33096"
          x="-488"
          y="-361"
          width="976"
          height="678"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4671_33096"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4671_33096"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4671_33096">
          <rect
            width="1366"
            height="768"
            fill="white"
            transform="translate(-683 -409)"
          />
        </clipPath>
        <clipPath id="clip1_4671_33096">
          <path
            d="M-634 -129H636V-0.999999C636 7.83656 628.837 15 620 15H-618C-626.837 15 -634 7.83656 -634 -1V-129Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
