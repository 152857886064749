import React from 'react';

import { useTarget } from '../../../hooks';
import { Page } from '../../../ui/page';

import { SendInviteInit } from './SendInviteInit';

export const SendInvite = () => {
  const [target] = useTarget();

  return (
    <Page title={target === 'registration' ? 'Регистрация' : 'Смена телефона'}>
      <SendInviteInit />
    </Page>
  );
};
