import React from 'react';

import { selectManageableUnitsByCompanyId } from '@vk-hr-tek/app/user';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Divider } from '@vk-hr-tek/ui/Divider';

import { useSelector } from '@app/hooks';

import { CompanyUnit } from './CompanyUnit';

interface CompanyUnitsProps {
  companyId: string;
  employeeId: string;
}

export const CompanyUnits = ({ companyId, employeeId }: CompanyUnitsProps) => {
  const isDesktop = useIsDesktop();

  const manageableUnits = useSelector(
    selectManageableUnitsByCompanyId,
    companyId,
  );

  if (!manageableUnits?.length) {
    return null;
  }

  return (
    <>
      <Box mx="-32" my="32">
        <Divider />
      </Box>
      <Box mb="24">
        <Typography variant={isDesktop ? 'h6' : 'subtitle1'}>
          Согласование по бизнес-процессам
        </Typography>
      </Box>
      <Box mb="16">
        <Typography
          variant={isDesktop ? 'body2' : 'body3'}
          color="textTertiary"
        >
          Руководитель может согласовывать заявки сотрудников своего и дочерних
          подразделений или заявки только прямых подчинённых. Настройте порядок
          согласований в своём подразделении
        </Typography>
      </Box>
      {manageableUnits.map((companyUnit) => (
        <CompanyUnit
          key={companyUnit.unit.id}
          employeeId={employeeId}
          companyId={companyId}
          companyUnit={companyUnit}
        />
      ))}
    </>
  );
};
