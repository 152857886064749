import React from 'react';

import MaterialContainer from '@material-ui/core/Container';
import classNames from 'classnames';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useStyles } from './Container.styles';

export const Container = ({
  title,
  children,
  theme = 'general',
}: {
  title?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  theme?: 'general' | 'notification';
}) => {
  const classes = useStyles();

  return (
    <MaterialContainer>
      <Box
        className={classNames(
          classes.container,
          theme === 'notification' && classes.container_theme_notification,
        )}
        pt={{ xs: '0', md: '48' }}
        pb={'48'}
      >
        {title && (
          <Box pt={{ xs: '32', md: '0' }}>
            <Typography variant="h3">{title}</Typography>
          </Box>
        )}
        <div className={classes.content}>{children}</div>
      </Box>
    </MaterialContainer>
  );
};
