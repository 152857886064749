import React, { useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { AuthRouterService } from '../../services';
import { PageLoading } from '../../../layout';

export const PageRedirect = ({ to }: { to: 'login' }) => {
  const router = useInject(AuthRouterService);

  useEffect(() => {
    if (to === 'login') {
      router.redirectToLogin();
    }
  }, [to, router]);

  return <PageLoading />;
};
