import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class FileDownload {
  @IsUUID()
  @IsNotEmpty()
  download_id: string;

  @IsIn(['in_progress', 'done', 'expired', 'error'])
  @IsString()
  @IsNotEmpty()
  status: 'in_progress' | 'done' | 'expired' | 'error';
}

export class GetFileRequestStateResponse {
  @ValidateNested()
  @Type(() => FileDownload)
  @IsNotEmpty()
  download: FileDownload;
}

export class RecognizedAttribute {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  value: string;
}

export class RecognizeDocumentResponse {
  @ValidateNested({ each: true })
  @Type(() => RecognizedAttribute)
  @IsArray()
  @IsNotEmpty()
  attributes: RecognizedAttribute[];
}
