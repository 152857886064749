import React from 'react';

import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import { FormError, TextInput } from '@vk-hr-tek/ui/form';

import { useDispatch, useSelector } from '@app/hooks';

import { VerifyInviteDto } from '../../../dto';
import {
  selectAuthInstance,
  selectNotification,
  selectStatus,
  verifyInvite,
} from '../../../slice';
import { LegalLinks } from '../../../ui/links';
import { Button } from '../../../ui/Button';

export const VerifyInviteInit = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: VerifyInviteDto) =>
    new Promise((resolve) => {
      dispatch(
        verifyInvite({
          values,
          actions: { resolve },
        }),
      );
    });

  return (
    <div>
      <Form
        validate={(values) =>
          validator.validate(values, VerifyInviteDto, ['form'])
        }
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <TextInput
              name="snils"
              label="СНИЛС"
              placeholder="Введите свой СНИЛС"
              mask="999-999-999 99"
              id="qaAuthInviteInitSnilsInput"
            />
            <Box mt="32" mb="16">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Продолжить
              </Button>
              {!dirtySinceLastSubmit && status && status === 'failed' && (
                <FormError error={error} />
              )}
            </Box>

            <LegalLinks
              policyConfidentialityLink="policy_confidentiality"
              userAggrementLink="user_agreement"
            />
          </form>
        )}
      />
    </div>
  );
};
