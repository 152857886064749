import { IsNotEmpty, IsString, IsUUID } from 'class-validator';

export class UkepBatchSignDto {
  @IsNotEmpty()
  events: CompanyBatchSignEventDto[];
}

class CompanyBatchSignEventDto {
  @IsNotEmpty()
  @IsUUID()
  event_id: string;

  @IsNotEmpty()
  documents: NodeToSignDto[];
}

class NodeToSignDto {
  @IsNotEmpty()
  @IsString()
  signature: string;

  @IsNotEmpty()
  @IsString()
  document_hash: string;
}

export class GetHashesDto {
  @IsNotEmpty()
  hashes: GetHashesHashDto[];
}

class GetHashesHashDto {
  @IsNotEmpty()
  @IsUUID()
  event_id: string;
}
