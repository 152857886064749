import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, tokens, px }) => ({
  autocompleteInput: {
    width: '100%',
    marginBottom: tokens.spacing['16'],

    '& .MuiAutocomplete-popupIndicator.Mui-disabled .MuiSvgIcon-root': {
      color: tokens.colors.text.light.tertirary,
    },
  },

  input: {
    '& .MuiInputBase-root': {
      paddingRight: 0,
      lineHeight: 1.5,
      backgroundColor: tokens.colors.bg.light.primary,

      '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        padding: 0,
      },

      '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot .MuiInputBase-input': {
        height: px(24),
        paddingTop: tokens.spacing['16'],
        paddingRight: tokens.spacing['72'],
        paddingBottom: tokens.spacing['16'],
        paddingLeft: tokens.spacing['16'],
      },

      '& .MuiSelect-iconOutlined': {
        right: tokens.spacing['16'],
      },

      '&.Mui-focused': {
        '& fieldset': {
          borderColor: tokens.colors.original.brand.primary,
          borderWidth: tokens.border.s,
        },
      },

      '&.Mui-error': {
        backgroundColor: tokens.colors.accent.bg.error,

        '& fieldset': {
          borderColor: tokens.colors.accent.stroke.error,
        },

        '&.Mui-focused': {
          '& fieldset': {
            borderColor: tokens.colors.accent.text.error,
          },
        },

        '&:hover': {
          '& fieldset': {
            borderColor: palette.error.hover,
          },
        },
      },

      '&.Mui-disabled': {
        backgroundColor: tokens.colors.bg.greyscale.secondary,

        '& fieldset': {
          borderColor: tokens.colors.stroke.primary,
        },
      },

      '& fieldset': {
        borderColor: tokens.colors.stroke.primary,
      },

      '&:hover': {
        '& fieldset': {
          borderColor: tokens.colors.stroke.secondary,
        },
      },

      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiAutocomplete-endAdornment': {
        right: tokens.spacing['16'],

        '& .MuiAutocomplete-clearIndicator': {
          backgroundColor: 'transparent',

          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  list: {
    boxShadow: tokens.shadow.l,
    overflowY: 'hidden',
  },

  option: {
    fontSize: tokens.typography.variant.body3.fontSize,
    lineHeight: tokens.typography.variant.body3.lineHeight,
    paddingTop: 0,
    paddingRight: tokens.spacing['16'],
    paddingBottom: 0,
    paddingLeft: tokens.spacing['16'],

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },

    '&[aria-selected=true]': {
      backgroundColor: tokens.colors.bg.light.primary,

      '&:hover': {
        backgroundColor: tokens.colors.bg.greyscale.secondary,
      },

      '& .MuiTypography-root[class*=body3]': {
        color: tokens.colors.original.brand.primary,
      },
    },
  },

  defaultOption: {
    paddingRight: tokens.spacing['16'],
    paddingLeft: tokens.spacing['16'],
    paddingTop: tokens.spacing['12'] + px(2),
    paddingBottom: tokens.spacing['12'] + px(2),
  },
}));
