import {
  createAsyncThunk,
  ActionReducerMapBuilder,
  createAction,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { TokenService } from '@vk-hr-tek/core/http';
import { AppError } from '@vk-hr-tek/core/error';

import { UserLoginResponse as AuthResponse } from '@app/gen/users';

import { AuthRegisterService, AuthRouterService } from '../../../services';
import { RegisterInitDto, RegisterDto } from '../../../dto';
import {
  AuthState,
  startLoading,
  completeLoading,
  resetState,
  setError,
  ThunkExtra,
} from '../../auth.state';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const registerInit = createAsyncThunk<
  void,
  {
    values: RegisterInitDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerInit',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { snils } = state.values;
      const { inviteCode } = state;

      await inject(AuthRegisterService).registerInit({
        snils: snils || undefined,
        inviteCode: inviteCode || undefined,
        phone: values.phone,
      });

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const verifyRegisterPhone = createAction<RegisterDto>(
  'auth/verifyRegisterPhone',
);

export const register = createAsyncThunk<
  AuthResponse,
  {
    values: RegisterDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/register',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { phone, code, snils } = state.values;
      const { inviteCode } = state;

      const result = await inject(AuthRegisterService).register({
        password: values.password,
        passwordRepeat: values.passwordRepeat,
        phone: phone || undefined,
        code: code || '',
        snils: snils || undefined,
        inviteCode: inviteCode || undefined,
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(1000);

      actions.resolve(null);
      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const registerWithVerifiedPhone = createAsyncThunk<
  AuthResponse,
  {
    values: { password: string };
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerWithVerifiedPhone',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { snils } = state.values;
      const { inviteCode } = state;

      const result = await inject(
        AuthRegisterService,
      ).registerWithVerifiedPhone({
        password: values.password,
        inviteCode: inviteCode as string,
        snils: snils as string,
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(1000);

      actions.resolve(null);
      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setStage = createAction<
  'init' | 'verification' | 'finish' | 'error'
>('auth/setStage');

export const registerReducers = (
  builder: ActionReducerMapBuilder<AuthState>,
) => {
  builder.addCase(registerInit.pending, (state, action) => {
    startLoading(state);

    state.values.phone = action.meta.arg.values.phone;
  });
  builder.addCase(registerInit.fulfilled, (state) => {
    state.stage = 'verification';

    completeLoading(state);
  });
  builder.addCase(registerInit.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
  builder.addCase(verifyRegisterPhone, (state, action) => {
    state.values.code = action.payload.code;
    state.stage = 'finish';
  });

  builder.addCase(register.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(register.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(register.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(registerWithVerifiedPhone.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(registerWithVerifiedPhone.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(registerWithVerifiedPhone.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(setStage, (state, action) => {
    state.stage = action.payload;
  });
};
