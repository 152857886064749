import React from 'react';

import { Box } from '../../Box';

import useStyles from './FormValidationMessage.styles';

interface FormValidationMessageProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

export const FormValidationMessage = ({
  title,
  children,
}: FormValidationMessageProps) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <Box
        display="flex"
        flexDirection="column"
        p="16"
        bgcolor="bg.greyscale.primary"
        radius="l"
      >
        <Box mb="4">{title}</Box>
        {children}
      </Box>
    </div>
  );
};
