import React, { useEffect } from 'react';

import classNames from 'classnames';
import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { Portal } from '@material-ui/core';

import { useStyles } from './Snackbar.styles';

interface SnackbarProps {
  open: boolean;
  message: React.ReactNode | React.ReactNode[];
  action: React.ReactNode | React.ReactNode[];
  variant?: 'static' | 'sticky';
  className?: string;
  zPosition?: 'top' | 'bottom';
}

export const Snackbar = ({
  open,
  message,
  action,
  className,
  variant = 'static',
  zPosition = 'top',
}: SnackbarProps) => {
  const classes = useStyles();

  useEffect(() => {
    const webimButtonCorner = Array.from(
      document.getElementsByClassName('webim-button-corner'),
    )[0];

    if (webimButtonCorner) {
      if (open) {
        webimButtonCorner.classList.add('webim-button-corner-button');
      } else {
        webimButtonCorner.classList.remove('webim-button-corner-button');
      }
    }

    const webimChat = document.getElementById('webim_chat');
    if (webimChat) {
      if (open) {
        webimChat.classList.add('webim-chat-button');
      } else {
        webimChat.classList.remove('webim-chat-button');
      }
    }

    return () => {
      if (webimButtonCorner) {
        webimButtonCorner.classList.remove('webim-button-corner-button');
      }

      if (webimChat) {
        webimChat.classList.remove('webim-chat-button');
      }
    };
  }, [open]);

  let contentClassName = classes.snackbarContent;

  if (variant === 'sticky') {
    contentClassName = classes.snackbarContentSticky;
  }

  return (
    <Portal>
      <MuiSnackbar
        className={classNames(classes.snackbar, 'aqa_snackbar', className, {
          [classes.snackbar_hideMessage]: !message,
          [classes.snackbar_zPosition_bottom]: zPosition === 'bottom',
        })}
        open={open}
        message={message}
        action={action}
        ContentProps={{
          className: contentClassName,
        }}
      />
    </Portal>
  );
};
