import { IsNotEmpty, IsUUID, IsOptional } from 'class-validator';

export class GetCreateEventTypeOptionsDto {
  @IsUUID()
  @IsNotEmpty()
  event_type_id: string;

  @IsUUID()
  @IsOptional()
  parent_event_id?: string;
}
