/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { injectable } from 'inversify';

@injectable()
export class UpdatorService {
  private version: string;

  private shouldReload = false;

  /** Перезагрузить страницу
   * @returns {void}
   *  */
  protected pageReload() {
    window.location.reload();
  }

  /** Обновить версию приложения, если новая отличается от сохраненной ранее
   * @param {string | null} version Новая версия
   * @returns {void}
   *  */
  updateVersion(version: string | null) {
    if (version) {
      if (this.version) {
        if (this.version !== version) {
          this.version = version;
          this.shouldReload = true;
        }
      } else {
        this.version = version;
      }
    }
  }

  /** Проверить необходимо ли перезагрузить приложение, и в случае необходимости выполнить перезагрузку
   * @returns {void}
   * */
  run() {
    if (this.shouldReload) {
      this.shouldReload = false;
      this.pageReload();
    }
  }
}
