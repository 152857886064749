import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsBoolean,
  IsArray,
  IsUUID,
  ValidateNested,
} from 'class-validator';

export class ManagerSettings {
  @IsUUID(undefined, {
    message: 'Вы указали неверные настройки согласования',
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
  })
  id: string;

  @IsBoolean({
    message: 'Вы указали неверные настройки согласования',
  })
  @IsNotEmpty({
    message: 'Вы указали неверные настройки согласования',
  })
  value: boolean;
}

export class UpdateApprovalSettingsDto {
  @ValidateNested({ each: true })
  @Type(() => ManagerSettings)
  @IsArray({
    message: 'Вы указали неверные настройки согласования',
  })
  @IsNotEmpty({
    message: 'Вы забыли указать настройки согласования',
  })
  managerSettings: ManagerSettings[];
}
