export interface SettingsVacationsRouter {
  goToCreate(companyId: string): void;
  goToScheduleDetail({
    id,
    companyId,
  }: {
    id: string;
    companyId: string;
  }): void;
  goToStartSchedule({ id, companyId }: { id: string; companyId: string }): void;
  redirectToHome(): void;
  goToList(query?: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsVacationsRouter = Symbol('SettingsVacationsRouter');
