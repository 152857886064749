import { injectable } from 'inversify';

import {
  FormMetaObjectStep,
  FormMetaObjectDocument,
  FormMetaObjectBlock,
  FormMetaObjectRow,
  FormMetaObjectAttribute,
} from '@app/gen/events';

import {
  FormAttribute,
  FileFormAttribute,
  FormMetaObjectLayoutExtended,
  FormMetaObjectDocumentExtended,
  FormAttributeFiles,
  AttributeBase,
} from '../types';

@injectable()
export class EventsFormMetaMapper {
  getMetaAttributeIds(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
      | FormMetaObjectRow
      | FormMetaObjectAttribute
    )[],
  ): Record<string, boolean> {
    return metas.reduce((result, meta) => {
      if (meta.type === 'attribute') {
        return {
          ...result,
          [meta.id]: true,
        };
      }

      return {
        ...result,
        ...this.getMetaAttributeIds(meta.objects),
      };
    }, {});
  }

  findUnusedAttributes<T extends AttributeBase = FormAttribute>(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
      | FormMetaObjectRow
      | FormMetaObjectAttribute
    )[],
    formAttributes: T[],
  ) {
    const metaAttributeIds = this.getMetaAttributeIds(metas);

    return formAttributes.filter(({ id }) => !metaAttributeIds[id]);
  }

  findUsedAttributes<T extends AttributeBase = FormAttribute>(
    metas: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectAttribute
    )[],
    formAttributes: T[],
  ) {
    const metaAttributeIds = this.getMetaAttributeIds(metas);

    return formAttributes.filter(({ id }) => metaAttributeIds[id]);
  }

  findFileAttributes<T extends AttributeBase = FormAttribute>(
    formAttributes: T[],
  ) {
    return formAttributes.filter(
      (attribute): attribute is FileFormAttribute<T> =>
        attribute.type === 'file' || attribute.type === 'file_multiple',
    );
  }

  findNoneFileAttributes<T extends AttributeBase = FormAttribute>(
    formAttributes: T[],
  ) {
    return formAttributes.filter(
      (attribute): attribute is FileFormAttribute<T> =>
        attribute.type !== 'file' && attribute.type !== 'file_multiple',
    );
  }

  processMetaStep<T extends AttributeBase = FormAttribute>(
    step: FormMetaObjectStep,
    formAttributes: T[],
  ) {
    return {
      ...step,
      objects: step.objects.map((meta) => {
        if (meta.type === 'block') {
          return this.processMetaBlock(meta, formAttributes);
        }

        return this.processMetaDocument(meta, formAttributes);
      }),
    };
  }

  processMetaDocument<T extends AttributeBase = FormAttribute>(
    document: FormMetaObjectDocument,
    formAttributes: T[],
  ): FormMetaObjectLayoutExtended<T> {
    const result: (
      | FormMetaObjectDocumentExtended<T>
      | FormAttributeFiles<T>
    )[] = [];

    const usedAttributes = this.findUsedAttributes([document], formAttributes);
    const fileAttributes = this.findFileAttributes(usedAttributes);
    const noneFileAttributes = this.findNoneFileAttributes(usedAttributes);

    result.push({
      ...document,
      id: usedAttributes.map((attribute) => attribute.id).join('_'),
      objects: document.objects.map((meta) => {
        if (meta.type === 'block') {
          return this.processMetaBlock(meta, noneFileAttributes);
        }

        if (meta.type === 'row') {
          return this.processMetaRow(meta, noneFileAttributes);
        }

        return this.processMetaAttribute(meta, noneFileAttributes);
      }),
    });

    if (fileAttributes.length) {
      result.push({
        type: 'files',
        attributes: fileAttributes,
      });
    }

    return {
      type: 'layout',
      id: usedAttributes.map((attribute) => attribute.id).join('_'),
      title: document.title,
      is_multiple: document.is_multiple || false,
      objects: result,
    };
  }

  processMetaBlock<T extends AttributeBase = FormAttribute>(
    block: FormMetaObjectBlock,
    formAttributes: T[],
  ) {
    return {
      ...block,
      objects: block.objects.map((meta) => {
        if (meta.type === 'row') {
          return this.processMetaRow(meta, formAttributes);
        }

        return this.processMetaAttribute(meta, formAttributes);
      }),
    };
  }

  processMetaRow<T extends AttributeBase = FormAttribute>(
    row: FormMetaObjectRow,
    formAttributes: T[],
  ) {
    return {
      ...row,
      objects: row.objects
        .map((meta) => {
          return this.processMetaAttribute(meta, formAttributes);
        })
        .filter(
          ({ attribute }) =>
            attribute &&
            attribute.type !== 'file' &&
            attribute.type !== 'file_multiple',
        ),
    };
  }

  processMetaAttribute<T extends AttributeBase = FormAttribute>(
    attribute: FormMetaObjectAttribute,
    formAttributes: T[],
  ) {
    return {
      ...attribute,
      attribute: formAttributes.find(({ id }) => id === attribute.id) || null,
    };
  }

  removeStepsFromMeta(
    formMeta: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
    )[],
  ) {
    let result: (FormMetaObjectDocument | FormMetaObjectBlock)[] = [];

    formMeta.forEach((meta) => {
      if (meta.type === 'step') {
        result = [...result, ...meta.objects];
        return;
      }

      result = [...result, meta];
    });

    return result;
  }

  processFormMeta<T extends AttributeBase = FormAttribute>(
    formMeta: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
    )[],
    formAttributes: T[],
    skipSteps = true,
  ) {
    const metas = skipSteps ? this.removeStepsFromMeta(formMeta) : formMeta;

    return metas.map((meta) => {
      if (meta.type === 'step') {
        return this.processMetaStep(meta, formAttributes);
      }

      if (meta.type === 'document') {
        return this.processMetaDocument(meta, formAttributes);
      }

      return this.processMetaBlock(meta, formAttributes);
    });
  }

  getMultipleDocs<T extends AttributeBase = FormAttribute>(
    formMeta: (
      | FormMetaObjectStep
      | FormMetaObjectDocument
      | FormMetaObjectBlock
    )[],
    formAttributes: T[],
  ): { id: string; attributes: string[] }[] {
    const metas = this.removeStepsFromMeta(formMeta);
    const initialValue: { id: string; attributes: string[] }[] = [];

    return metas.reduce((acc, meta) => {
      if (meta.type === 'block' || !meta.is_multiple) {
        return acc;
      }

      const usedAttributes = this.findUsedAttributes([meta], formAttributes);

      if (!usedAttributes.length) {
        return acc;
      }

      const attributes = usedAttributes.map((attribute) => attribute.id);

      return [
        ...acc,
        {
          id: attributes.join('_'),
          attributes,
        },
      ];
    }, initialValue);
  }
}
