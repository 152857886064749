import React from 'react';

import {
  Tooltip as MUITooltip,
  TooltipProps as MUITooltipProps,
} from '@material-ui/core';

import useStyles from './Tooltip.styles';

interface TooltipProps {
  children: React.ReactElement;
  title: string | React.ReactElement;
  placement?: MUITooltipProps['placement'];
  disabled?: boolean;
  interactive?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  inverted?: boolean;
  enterDelay?: number;
}

export const Tooltip = ({
  children,
  title,
  placement = 'right',
  disabled = false,
  interactive = false,
  onOpen,
  onClose,
  inverted = false,
  enterDelay,
}: TooltipProps) => {
  const classes = useStyles({ inverted });

  if (disabled) {
    return <>{children}</>;
  }

  return (
    <MUITooltip
      classes={classes}
      arrow
      title={title}
      placement={placement}
      enterTouchDelay={0}
      interactive={interactive}
      onOpen={onOpen}
      onClose={onClose}
      enterDelay={enterDelay}
      className="aqa_tooltip"
    >
      {children}
    </MUITooltip>
  );
};
