import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsSubstitutesRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsSubstitutesRouterService
  implements SettingsSubstitutesRouter
{
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToCreate({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.substitutes.basename
      }/new?employeeId=${employeeId}&companyId=${companyId}`,
    );
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(
        `/${this.role.get()}/${modules.settings.basename}/${
          modules.settings.children.substitutes.basename
        }`,
      );
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.substitutes.basename
      }`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
