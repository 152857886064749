import { injectable } from 'inversify';

import { UnitNode } from './gen/units';
import { UnitNodeLabeled } from './unit.types';

@injectable()
export class UnitProcessor {
  constructor() {
    this.processUnitsTree = this.processUnitsTree.bind(this);
  }

  /** Преобразует дерево UnitNode к объекту */
  processUnitsTree(node: UnitNode): UnitNodeLabeled {
    return {
      id: node.id,
      label: node.name,
      count: node.employee_count || 0,
      hasChildrenToLoad: node.is_parent,
      children: node.children ? node.children.map(this.processUnitsTree) : [],
    };
  }
}
