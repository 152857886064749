/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { isValid } from 'date-fns';

import { Filter } from './filter.entity';

@ValidatorConstraint({ name: 'IsFilters' })
export class IsFiltersConstraint implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    const schema = <Filter[]>args.constraints[0];

    if (value === undefined) {
      return true;
    }

    if (typeof value !== 'object' || Array.isArray(value) || value === null) {
      return false;
    }

    return Object.keys(value).every((filterKey) => {
      const activeFilter = schema.find(({ key }) => key === filterKey);

      if (!activeFilter) {
        return false;
      }

      if (
        activeFilter.type === 'text' &&
        typeof value[filterKey] !== 'string'
      ) {
        return false;
      }

      if (
        activeFilter.type === 'select' &&
        !activeFilter.options.find(
          ({ value: option }) => option === value[filterKey],
        )
      ) {
        return false;
      }

      if (
        activeFilter.type === 'multiple' &&
        (!Array.isArray(value[filterKey]) ||
          value[filterKey].some(
            (activeOption: any) =>
              typeof activeOption !== 'string' ||
              !activeFilter.options.find(
                ({ value: option }) => option === activeOption,
              ),
          ))
      ) {
        return false;
      }

      if (
        activeFilter.type === 'bool' &&
        typeof value[filterKey] !== 'boolean'
      ) {
        return false;
      }

      if (activeFilter.type === 'timerange') {
        if (
          !(
            typeof value[filterKey] === 'object' &&
            value[filterKey] !== null &&
            !Array.isArray(value[filterKey])
          )
        ) {
          return false;
        }

        if (
          (value[filterKey].end && !isValid(new Date(value[filterKey].end))) ||
          (value[filterKey].start && !isValid(new Date(value[filterKey].start)))
        ) {
          return false;
        }
      }

      return true;
    });
  }

  defaultMessage(args: ValidationArguments) {
    return `${args.property} are not valid filters`;
  }
}

export function IsFilters(
  filters: Filter[],
  validationOptions?: ValidationOptions,
) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [filters],
      validator: IsFiltersConstraint,
    });
  };
}
