/* istanbul ignore file */

import { Container, interfaces } from 'inversify';

export const container = new Container({
  defaultScope: 'Singleton',
  autoBindInjectable: true,
});

export type Inject = <T>(identifier: interfaces.ServiceIdentifier<T>) => T;

export function inject<T>(
  identifier: interfaces.ServiceIdentifier<T>,
  injectContainer = container,
): T {
  return injectContainer.get<T>(identifier);
}
