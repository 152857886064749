import React from 'react';
import { useCallback } from 'react';

import { useLocation } from 'react-router-dom';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import KeyboardArrowRightOutlined from '@material-ui/icons/KeyboardArrowRightOutlined';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';

import { NotificationActionTypeEnum } from '@vk-hr-tek/core/notifications/types';
import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useStyles } from './Notifications.styles';

interface NotificationActionProps {
  text: string;
  link?: string;
  actionType: NotificationActionTypeEnum;
}

export const NotificationAction = ({
  link,
  actionType,
  text,
}: NotificationActionProps) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const renderIcon = useCallback((type: NotificationActionTypeEnum) => {
    switch (type) {
      case NotificationActionTypeEnum.Redirect:
        return <InfoOutlined color="inherit" />;

      case NotificationActionTypeEnum.Refresh:
        return <RefreshOutlined color="inherit" />;

      default:
        return <></>;
    }
  }, []);

  return (
    <Link variant="simple" to={link || pathname} stroke={false}>
      <Box
        p="16"
        display="flex"
        alignItems="center"
        className={classes.content}
        radius={['0', '0', 'l', 'l']}
        bgcolor="original.brand.primary"
      >
        {renderIcon(actionType)}

        <Box ml="16">
          <Typography variant="subtitle2" color="contrast">
            {text}
          </Typography>
        </Box>

        {link && (
          <Box ml="auto" height={24}>
            <KeyboardArrowRightOutlined color="inherit" />
          </Box>
        )}
      </Box>
    </Link>
  );
};
