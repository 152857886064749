import React from 'react';

import { useTheme } from '@material-ui/core';

export const BannerImage = () => {
  const theme = useTheme();

  return (
    <svg
      width="276"
      height="145"
      viewBox="0 0 276 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4876_4209)">
        <g filter="url(#filter1_i_4876_4209)">
          <path
            d="M107.675 113.184L129.263 103.573C139.615 98.9638 144.792 96.6588 145.546 89.7092C146.3 82.7596 142.31 73.887 134.406 56.1621L132.925 52.8427C125.022 35.1202 121.059 26.2329 115.411 22.1337C109.763 18.0345 104.558 20.3518 94.2061 24.9607L72.6187 34.5721C62.2655 39.1816 57.0896 41.4861 56.3357 48.4356C55.5819 55.3852 59.5418 64.265 67.4484 81.995L68.9287 85.3144C76.833 103.039 80.7952 111.924 86.4708 116.011C92.1463 120.098 97.3222 117.794 107.675 113.184Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter2_i_4876_4209)">
          <path
            d="M106.289 45.4847C109.515 52.7298 106.257 61.2183 99.0117 64.4441C91.7663 67.67 83.2774 64.4117 80.0511 57.1665C76.8248 49.9213 80.0829 41.4329 87.3283 38.207C94.5736 34.9812 103.063 38.2395 106.289 45.4847Z"
            fill={theme.tokens.colors.original.brand.quaternary}
          />
        </g>
        <path
          d="M95.3223 45.5917C94.3557 43.4208 91.8157 42.4448 89.6464 43.4106C87.4772 44.3764 86.5032 46.917 87.4699 49.0878C88.4365 51.2587 90.9765 52.2347 93.1458 51.2689C95.315 50.3031 96.289 47.7625 95.3223 45.5917ZM87.9644 60.7634L88.3417 61.6109C88.5907 62.1701 89.2456 62.4217 89.8044 62.1729L103.486 56.0816C104.045 55.8328 104.296 55.1778 104.047 54.6186L103.669 53.7711C102.22 50.5162 96.5741 51.9155 93.9533 53.0824C91.3325 54.2492 86.5149 57.5085 87.9644 60.7634Z"
          fill="#99BCFF"
        />
        <g filter="url(#filter3_i_4876_4209)">
          <path
            d="M75.8633 85.3267C75.6438 84.831 75.8673 84.2504 76.3626 84.0299L109.004 69.497C109.499 69.2765 110.079 69.4996 110.298 69.9953C110.518 70.491 110.294 71.0716 109.799 71.2921L77.1575 85.825C76.6622 86.0455 76.0828 85.8224 75.8633 85.3267Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
        </g>
        <g filter="url(#filter4_i_4876_4209)">
          <path
            d="M111.733 69.3565C111.514 68.8608 111.737 68.2802 112.233 68.0597L128.486 60.8233C128.981 60.6028 129.561 60.8259 129.78 61.3216C130 61.8173 129.776 62.3979 129.281 62.6184L113.028 69.8548C112.532 70.0753 111.953 69.8522 111.733 69.3565Z"
            fill="#BAC6D1"
          />
        </g>
        <g filter="url(#filter5_i_4876_4209)">
          <path
            d="M79.2022 92.8663C78.9826 92.3706 79.2062 91.79 79.7014 91.5695L95.0829 84.7212C95.5782 84.5007 96.1576 84.7238 96.3771 85.2195C96.5966 85.7152 96.3731 86.2958 95.8778 86.5163L80.4964 93.3646C80.0011 93.5851 79.4217 93.362 79.2022 92.8663Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
        </g>
        <g filter="url(#filter6_i_4876_4209)">
          <path
            d="M97.8115 84.5806C97.592 84.0849 97.8155 83.5044 98.3108 83.2838L131.824 68.3628C132.319 68.1423 132.899 68.3654 133.118 68.8611C133.338 69.3568 133.114 69.9374 132.619 70.1579L99.1057 85.0789C98.6105 85.2994 98.031 85.0763 97.8115 84.5806Z"
            fill="#BAC6D1"
          />
        </g>
        <g filter="url(#filter7_i_4876_4209)">
          <path
            d="M82.541 100.405C82.3215 99.9097 82.545 99.3291 83.0403 99.1086L98.4643 92.2414C98.9595 92.0209 99.539 92.2439 99.7585 92.7396C99.978 93.2353 99.7545 93.8159 99.2592 94.0364L83.8352 100.904C83.34 101.124 82.7605 100.901 82.541 100.405Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
        </g>
        <g filter="url(#filter8_i_4876_4209)">
          <path
            d="M101.193 92.1007C100.974 91.605 101.197 91.0244 101.693 90.8039L135.163 75.9018C135.659 75.6813 136.238 75.9043 136.458 76.4C136.677 76.8957 136.454 77.4763 135.958 77.6968L102.488 92.5989C101.992 92.8194 101.413 92.5964 101.193 92.1007Z"
            fill="#BAC6D1"
          />
        </g>
        <g filter="url(#filter9_i_4876_4209)">
          <path
            d="M85.8789 107.945C85.6594 107.449 85.8829 106.869 86.3782 106.648L119.02 92.1152C119.515 91.8947 120.094 92.1178 120.314 92.6135C120.533 93.1092 120.31 93.6898 119.815 93.9103L87.1731 108.443C86.6779 108.664 86.0984 108.441 85.8789 107.945Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
        </g>
        <path
          d="M121.749 91.9747C121.53 91.479 121.753 90.8984 122.248 90.6779L138.502 83.4415C138.997 83.221 139.576 83.4441 139.796 83.9398C140.015 84.4355 139.792 85.0161 139.296 85.2366L123.043 92.473C122.548 92.6935 121.969 92.4704 121.749 91.9747Z"
          fill="#BAC6D1"
        />
      </g>
      <g filter="url(#filter10_d_4876_4209)">
        <g filter="url(#filter11_i_4876_4209)">
          <path
            d="M132.11 110.002L157.746 121.416C170.039 126.889 176.187 129.626 182.919 124.769C189.651 119.912 194.311 109.341 203.672 88.2814L205.425 84.3374C214.786 63.2804 219.479 52.7209 218.609 44.4791C217.738 36.2374 211.558 33.4855 199.265 28.0123L173.629 16.5986C161.334 11.1247 155.188 8.38807 148.456 13.245C141.724 18.1019 137.034 28.6525 127.67 49.7184L125.917 53.6624C116.555 74.7224 111.863 85.279 112.766 93.5353C113.669 101.792 119.815 104.528 132.11 110.002Z"
            fill="white"
          />
        </g>
        <g filter="url(#filter12_i_4876_4209)">
          <path
            d="M190.731 55.0139C186.88 63.6089 176.791 67.4543 168.196 63.6027C159.602 59.7512 155.756 49.6613 159.607 41.0663C163.458 32.4713 173.547 28.626 182.142 32.4775C190.737 36.329 194.582 46.4189 190.731 55.0139Z"
            fill={theme.tokens.colors.original.brand.quaternary}
          />
        </g>
        <path
          d="M181.939 45.3861C183.086 42.808 181.929 39.7909 179.353 38.644C176.777 37.4971 173.761 38.6563 172.614 41.2343C171.466 43.8124 172.623 46.8295 175.199 47.9765C177.775 49.1234 180.791 47.9642 181.939 45.3861ZM162.703 50.9483L162.255 51.9547C161.959 52.6188 162.257 53.3966 162.921 53.6921L179.168 60.9257C179.832 61.2212 180.609 60.9223 180.905 60.2582L181.353 59.2518C183.074 55.3863 177.352 51.5157 174.24 50.1301C171.128 48.7444 164.424 47.0828 162.703 50.9483Z"
          fill="#99BCFF"
        />
        <g filter="url(#filter13_i_4876_4209)">
          <path
            d="M131.451 59.6981C131.692 59.1595 132.324 58.9175 132.863 59.1575L143.404 63.8507C143.943 64.0907 144.185 64.7218 143.944 65.2604C143.703 65.7989 143.07 66.041 142.531 65.801L131.99 61.1078C131.451 60.8678 131.209 60.2366 131.451 59.6981Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
          <path
            d="M145.505 65.9557C145.746 65.4171 146.379 65.1751 146.918 65.4151L194.938 86.7951C195.477 87.0351 195.719 87.6662 195.478 88.2048C195.236 88.7433 194.604 88.9854 194.065 88.7454L146.045 67.3654C145.506 67.1254 145.264 66.4942 145.505 65.9557Z"
            fill="#BAC6D1"
          />
          <path
            d="M127.784 67.8894C128.025 67.3508 128.657 67.1088 129.196 67.3488L177.217 88.7288C177.756 88.9688 177.997 89.5999 177.756 90.1385C177.515 90.677 176.883 90.9191 176.344 90.6791L128.323 69.2991C127.784 69.0591 127.543 68.4279 127.784 67.8894Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
          <path
            d="M179.318 90.8338C179.559 90.2952 180.191 90.0532 180.73 90.2932L191.271 94.9863C191.81 95.2263 192.052 95.8575 191.811 96.396C191.57 96.9346 190.937 97.1766 190.398 96.9366L179.857 92.2435C179.318 92.0035 179.077 91.3723 179.318 90.8338Z"
            fill="#BAC6D1"
          />
          <path
            d="M124.117 76.0806C124.358 75.5421 124.991 75.3 125.53 75.54L161.057 91.3578C161.596 91.5978 161.838 92.2289 161.597 92.7675C161.355 93.306 160.723 93.5481 160.184 93.3081L124.657 77.4903C124.118 77.2503 123.876 76.6192 124.117 76.0806Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
          <path
            d="M163.158 93.4628C163.399 92.9242 164.032 92.6822 164.571 92.9222L187.605 103.178C188.144 103.418 188.385 104.049 188.144 104.587C187.903 105.126 187.271 105.368 186.732 105.128L163.698 94.8725C163.159 94.6325 162.917 94.0013 163.158 93.4628Z"
            fill="#BAC6D1"
          />
          <path
            d="M120.451 84.2719C120.692 83.7334 121.324 83.4913 121.863 83.7313L157.39 99.549C157.929 99.789 158.171 100.42 157.93 100.959C157.689 101.497 157.056 101.739 156.517 101.499L120.99 85.6816C120.451 85.4416 120.21 84.8105 120.451 84.2719Z"
            fill={theme.tokens.colors.original.brand.primary}
          />
          <path
            d="M159.492 101.654C159.733 101.115 160.365 100.873 160.904 101.113L183.938 111.369C184.477 111.609 184.719 112.24 184.478 112.779C184.237 113.317 183.604 113.559 183.065 113.319L160.031 103.064C159.492 102.824 159.25 102.193 159.492 101.654Z"
            fill="#BAC6D1"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_4876_4209"
          x="45.9904"
          y="9.75405"
          width="116.737"
          height="125.468"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3.41825" dy="3.41825" />
          <feGaussianBlur stdDeviation="6.83649" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.847059 0 0 0 0 0.898039 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4876_4209"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4876_4209"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_i_4876_4209"
          x="56.2451"
          y="20.0088"
          width="89.3906"
          height="98.1221"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.847059 0 0 0 0 0.898039 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter2_i_4876_4209"
          x="78.8057"
          y="36.9619"
          width="28.7285"
          height="28.7275"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.2" dy="-1.2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.737255 0 0 0 0 0.8 0 0 0 0 0.92549 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter3_i_4876_4209"
          x="75.7793"
          y="69.4121"
          width="34.6035"
          height="16.498"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter4_i_4876_4209"
          x="111.649"
          y="60.7383"
          width="18.2148"
          height="9.20166"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter5_i_4876_4209"
          x="79.1182"
          y="84.6362"
          width="17.3438"
          height="8.81348"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter6_i_4876_4209"
          x="97.7275"
          y="68.2778"
          width="35.4746"
          height="16.8862"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter7_i_4876_4209"
          x="82.457"
          y="92.1562"
          width="17.3857"
          height="8.83252"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter8_i_4876_4209"
          x="101.109"
          y="75.8169"
          width="35.4326"
          height="16.8672"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter9_i_4876_4209"
          x="85.7949"
          y="92.0303"
          width="34.6035"
          height="16.498"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.3" dy="-0.3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter10_d_4876_4209"
          x="102.402"
          y="0.470362"
          width="133.402"
          height="143.91"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="3.41825" dy="3.41825" />
          <feGaussianBlur stdDeviation="6.83649" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.847059 0 0 0 0 0.898039 0 0 0 0 1 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4876_4209"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4876_4209"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_i_4876_4209"
          x="112.656"
          y="10.7251"
          width="106.056"
          height="116.564"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.847059 0 0 0 0 0.898039 0 0 0 0 1 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter12_i_4876_4209"
          x="158.112"
          y="30.9819"
          width="34.1143"
          height="34.1162"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1.2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.736176 0 0 0 0 0.79956 0 0 0 0 0.926329 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
        <filter
          id="filter13_i_4876_4209"
          x="120.357"
          y="59.0649"
          width="75.2139"
          height="54.3472"
          filterUnits="userSpaceOnUse"
          colorInterpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_4876_4209"
          />
        </filter>
      </defs>
    </svg>
  );
};
