export const AppStoreIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68701 22.3129C3.37409 24 6.0894 24 11.52 24H12.48C17.9106 24 20.6259 24 22.313 22.3129C24 20.6259 24 17.9106 24 12.48V11.52C24 6.08936 24 3.37408 22.313 1.68701C20.6259 0 17.9106 0 12.48 0H11.52C6.0894 0 3.37409 0 1.68701 1.68701C6.39578e-07 3.37408 0 6.08936 0 11.52V12.48C0 17.9106 6.39578e-07 20.6259 1.68701 22.3129Z"
      fill="black"
    />
    <path
      d="M12.0201 7.0535C12.3874 7.0535 13.5785 6.81972 14.5025 5.47274C15.2929 4.30386 15.2038 3.00141 15.2038 3.00141C15.2038 3.00141 13.8346 2.90122 12.6323 4.39292C11.764 5.48387 11.8308 7.04237 11.8308 7.04237C11.8308 7.04237 11.8976 7.0535 12.0201 7.0535Z"
      fill="white"
    />
    <path
      d="M18.8887 15.6147C18.9555 15.4254 19 15.3141 19 15.3141C19 15.3141 19 15.3141 18.9889 15.3141C18.9889 15.3141 18.9777 15.3141 18.9666 15.303C18.8998 15.2807 18.744 15.2139 18.5436 15.0915C18.1428 14.8577 17.5862 14.4235 17.1855 13.7222C16.9294 13.2769 16.7513 12.7203 16.7068 12.0301C16.6845 11.685 16.7179 11.3511 16.7847 11.0505V11.0394C16.807 10.9392 16.8292 10.8501 16.8626 10.7499C17.2189 9.59221 18.0538 8.83522 18.3877 8.57919C18.4768 8.51239 18.5213 8.47899 18.5213 8.47899C18.5213 8.47899 17.4526 6.82031 15.4377 6.82031C13.7011 6.82031 13.0554 7.59956 12.1315 7.59956C10.996 7.59956 10.8179 6.87597 9.13695 6.87597C8.26864 6.87597 7.05524 7.20994 6.15354 8.37881C5.44109 9.29164 5.17391 10.3715 5.06259 11.1618C4.9958 11.6962 4.97354 12.275 5.04033 12.9207C5.04033 12.9652 5.05146 13.0098 5.05146 13.0654C5.11826 13.7111 5.28524 14.4013 5.51901 15.0915C5.51901 15.1026 5.53014 15.1249 5.54128 15.136C5.56354 15.2139 5.59693 15.303 5.63033 15.3809C5.64146 15.4143 5.66373 15.4588 5.67486 15.4922C5.68599 15.5256 5.69713 15.5702 5.71939 15.6035C5.94203 16.1824 6.19807 16.739 6.49864 17.2622C7.36694 18.7985 8.49129 19.9562 9.54884 19.9562C10.7511 19.9562 11.1296 19.177 12.3319 19.1881C13.857 19.1992 14.28 20.1677 15.5602 19.9117C16.4174 19.7447 17.4415 18.5313 18.0983 17.3736C18.1206 17.329 18.1428 17.2956 18.1651 17.2622C18.4768 16.5943 18.744 15.9709 18.8887 15.6147Z"
      fill="white"
    />
  </svg>
);
