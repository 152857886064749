import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { Button } from '@vk-hr-tek/ui/Button';
import { Link } from '@vk-hr-tek/ui/Link';
import { DisabledLKIcon } from '@vk-hr-tek/ui/icons/DisabledLKIcon';

import { selectDisableLKInfo } from '../../user';
import { useSelector } from '../../app/hooks';

import { useStyles } from './DisabledLKLayout.styles';

export const DisabledLKLayout = () => {
  const styles = useStyles();

  const data = useSelector(selectDisableLKInfo);

  return (
    <Box className={styles.layout}>
      <Box mb={{ xs: '56', md: '48' }}>
        <DisabledLKIcon />
      </Box>

      <Box
        width={248}
        display="flex"
        flexDirection="column"
        gap="32"
        alignItems="center"
      >
        <Box textAlign="center">
          <Typography variant="subtitle1" color="textSecondary">
            {data?.text || 'Работа с КЭДО доступна в личном кабинете компании'}
          </Typography>
        </Box>

        {data?.link && (
          <Link href={data?.link as string} stroke={false}>
            <Button>
              <Typography color="contrast" variant="subtitle1">
                {data?.linktext}
              </Typography>
            </Button>
          </Link>
        )}
      </Box>
    </Box>
  );
};
