import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ tokens }) => ({
  fillPrimary: {
    fill: tokens.colors.original.brand.primary,
  },

  fillDark: {
    fill: tokens.colors.original.brand.tertirary,
  },
}));
