import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, spacing, tokens, px }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: tokens.colors.bg.light.secondary,
  },

  rootMobile: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: tokens.colors.bg.light.primary,
  },

  header: {
    backgroundImage: 'url("/bg/header-bg-pattern.svg")',
    backgroundPosition: 'center',
    backgroundSize: 'auto 100%, contain',
    backgroundRepeat: 'repeat-x',
    '& > div': {
      paddingLeft: tokens.spacing['16'],
    },
  },
  logoContainer: {
    display: 'flex',
    padding: `${tokens.spacing['12']}px 0`,
    [breakpoints.up('md')]: {
      padding: spacing(4.4, 0), // TODO - fix нет в токенах
    },
  },
  logo: {
    maxWidth: px(115),
    [breakpoints.up('md')]: {
      maxWidth: px(200),
    },
  },
  container: {
    flexGrow: 1,
    padding: 0,
    [breakpoints.up('md')]: {
      paddingTop: tokens.spacing['40'],
    },
  },
}));
