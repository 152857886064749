import React from 'react';

import { Typography } from '../../../Typography';
import { Box } from '../../../Box';

interface FileErrorProps {
  text: string;
}

export const FileError = ({ text }: FileErrorProps) => {
  return (
    <Box pt="4">
      <Typography variant="caption" color="error">
        {text || 'Что-то пошло не так'}
      </Typography>
    </Box>
  );
};
