import React from 'react';

import { Link } from '@vk-hr-tek/ui/Link';
import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsMobile } from '@vk-hr-tek/ui/hooks/media';

interface LegalLinksProps {
  userAggrementLink: string;
  policyConfidentialityLink: string;
}

export const LegalLinks = ({
  userAggrementLink,
  policyConfidentialityLink,
}: LegalLinksProps) => {
  const isMobile = useIsMobile();

  return (
    <Box display="flex" justifyContent="center">
      <Typography variant="body3" color="textTertiary" align="center">
        Нажимая «Продолжить», вы принимаете
        <br />
        <Box
          display="flex"
          alignItems="center"
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? '0' : '8'}
          width="100%"
        >
          <Link
            href={`${window.REACT_APP_VKHRTEK_API_URL}/docs/${userAggrementLink}`}
            target="_blank"
            size="small"
            variant="tertiary"
          >
            Пользовательское соглашение
          </Link>{' '}
          и{' '}
          <Link
            href={`${window.REACT_APP_VKHRTEK_API_URL}/docs/${policyConfidentialityLink}`}
            target="_blank"
            size="small"
            variant="tertiary"
          >
            Политику конфиденциальности
          </Link>
        </Box>
      </Typography>
    </Box>
  );
};
