import React, { useMemo } from 'react';

import classNames from 'classnames';
import {
  Dialog as MUIDialog,
  DialogProps as MUIDialogProps,
} from '@material-ui/core';

import { useStyles } from './Dialog.styles';

interface DialogProps extends MUIDialogProps {
  withoutRadius?: boolean;
}

export const Dialog = ({
  fullScreen = false,
  withoutRadius,
  children,
  classes,
  open,
  ...props
}: DialogProps) => {
  const styles = useStyles();

  const modalClasses = useMemo(
    () => ({
      ...classes,
      paper: classNames(
        styles.dialogPaper,
        classes?.paper,
        withoutRadius && styles.dialogWithoutRadius,
      ),
    }),
    [classes, styles, withoutRadius],
  );

  if (open) {
    return (
      <MUIDialog
        classes={modalClasses}
        fullScreen={fullScreen}
        className={classNames('aqa_dialog', props.className)}
        open={open}
        {...props}
      >
        {children}
      </MUIDialog>
    );
  }

  return null;
};
