import React from 'react';

import { AppError } from '@vk-hr-tek/core/error';
import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { useSelector } from '../../../../app/hooks';
import { selectStage, selectAuthCode } from '../../../slice';
import { useAuth } from '../../../hooks';
import { Page, PageError } from '../../../ui/page';

import { RegisterInit } from './RegisterInit';
import { RegisterFinish } from './RegisterFinish';

const steps = [
  {
    name: 'init',
    content: <RegisterInit />,
    label: 'Телефон',
  },
  {
    name: 'finish',
    content: <RegisterFinish />,
    label: 'СМС-код',
  },
];

export const Register = () => {
  const step = useSelector(selectStage);
  const authCode = useSelector(selectAuthCode);

  const isAuthenticated = useAuth();

  const isDesktop = useIsDesktop();

  if (!isAuthenticated && !authCode) {
    return (
      <PageError
        error={
          new AppError('client', {
            code: 400,
            message: 'Bad request',
            data: {
              error_code: 'front_empty_auth_code',
            },
          })
        }
      />
    );
  }

  return (
    <Page
      title={
        <>
          Электронный
          <br />
          документооборот
        </>
      }
    >
      <Stages
        active={step}
        steps={steps}
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
