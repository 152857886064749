import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  label: {
    fontSize: theme.tokens.typography.variant.caption.fontSize,
    marginBottom: theme.px(10), // TODO - fix нет в токенах
  },
  caption: {
    fontSize: theme.tokens.typography.variant.caption.fontSize,
    color: theme.tokens.colors.text.light.tertirary,
  },
}));

export default styles;
