import { injectable } from 'inversify';

import { QuickActionCreateEvent } from '@app/gen/dashboard';

@injectable()
export class FastActionsMapper {
  processFastActions(fastActions: QuickActionCreateEvent[]) {
    return fastActions.map(
      ({ company_ids, event_type_ids, side, title, type }) => ({
        companyIds: company_ids || [],
        eventTypeIds: event_type_ids || [],
        side,
        title,
        type,
      }),
    );
  }
}
