import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './notifications.state';
import { notificationsReducers } from './notifications.actions';

export const notifications = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    notificationsReducers(builder);
  },
});

export const notificationReducer = notifications.reducer;
