import React, { useCallback } from 'react';

import {
  FilterNone as FileCopyIcon,
  Help as HelpIcon,
} from '@material-ui/icons';

import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { Content } from '@vk-hr-tek/ui/Content';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { VacationIcon } from '@vk-hr-tek/ui/icons';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { AuthEmployee } from '@app/gen/users';
import { useSelector } from '@app/hooks';
import { UnepTypes } from '@app/types';

import { selectManageableUnitsByCompanyId, selectUser } from '../../slice';
import { Information } from '../../ui/information';

import { CompanyUnits } from './CompanyUnits';

export const Company = ({
  employee,
  expanded = false,
}: {
  employee: AuthEmployee;
  expanded?: boolean;
}) => {
  const isDesktop = useIsDesktop();

  const tooltipPlacement = isDesktop ? 'right' : 'bottom';
  const headlineVariant = isDesktop ? 'h5' : 'subtitle1';
  const titleVariant = isDesktop ? 'h6' : 'subtitle1';
  const subtitleVariant = isDesktop ? 'subtitle1' : 'subtitle2';
  const textVariant = isDesktop ? 'body2' : 'body3';

  const user = useSelector(selectUser);

  const manageableUnits = useSelector(
    selectManageableUnitsByCompanyId,
    employee.company.id,
  );

  const manageableUnitNames =
    manageableUnits?.map((item) => {
      return item.unit.name;
    }) || [];

  const units = !!manageableUnitNames.length
    ? manageableUnitNames
    : [employee.unit];

  const handlePersonalNumberClick = useCallback(() => {
    navigator.clipboard.writeText(employee.personnel_number);
  }, [employee.personnel_number]);

  if (!user) {
    return null;
  }

  return (
    <Content>
      <Accordion
        title={
          <Box
            display="flex"
            flexDirection={isDesktop ? 'row' : 'column'}
            alignItems={isDesktop ? 'center' : 'flex-start'}
          >
            <Box pr={{ xs: '0', md: '20' }} pb={{ xs: '8', md: '0' }}>
              <Typography variant={headlineVariant}>
                {employee.company.name}
              </Typography>
            </Box>
            <Box mr={{ xs: '0', md: '16' }} pb={{ xs: '8', md: '0' }}>
              <ColoredChip
                label={
                  employee.dismissed_at
                    ? `Дата увольнения ${employee.dismissed_at}`
                    : 'Работаю'
                }
                color={employee.dismissed_at ? 'gray' : 'green'}
              />
            </Box>
            {employee.vacation_days !== undefined && !employee.dismissed_at && (
              <ColoredChip
                label="Накоплено дней отпуска"
                startIcon={<VacationIcon />}
                counter={employee.vacation_days}
                color="blue"
              />
            )}
          </Box>
        }
        defaultExpanded={expanded}
      >
        <>
          <Typography variant={titleVariant}>Общая информация</Typography>
          <Box mt="20">
            <Information
              label={
                <Typography variant={textVariant} color="textTertiary">
                  Табельный №
                </Typography>
              }
              spacing="16"
            >
              <Typography variant={textVariant}>
                {employee.personnel_number}
              </Typography>
              <Button
                icon={<FileCopyIcon fontSize="small" />}
                onClick={handlePersonalNumberClick}
                size="extraSmall"
                variant="tertiaryLight"
              />
            </Information>
          </Box>
          {employee.email && (
            <Box mt="20">
              <Information
                label={
                  <Typography variant={textVariant} color="textTertiary">
                    Электронная почта
                  </Typography>
                }
                spacing="4"
              >
                <Typography variant={textVariant}>{employee.email}</Typography>
              </Information>
            </Box>
          )}
          <Box mt="20">
            <Information
              label={
                <Typography variant={textVariant} color="textTertiary">
                  {units.length > 1 ? 'Подразделения' : 'Подразделение'}
                </Typography>
              }
              spacing="4"
            >
              <Typography variant={textVariant}>{units.join(', ')}</Typography>
            </Information>
          </Box>
          <Box mt="20">
            <Information
              label={
                <Typography variant={textVariant} color="textTertiary">
                  Должность
                </Typography>
              }
              spacing="4"
            >
              <Typography variant={textVariant}>{employee.position}</Typography>
            </Information>
          </Box>
          {employee.manager && (
            <Box mt="20">
              <Information
                label={
                  <Typography variant={textVariant} color="textTertiary">
                    Руководитель
                  </Typography>
                }
                spacing="4"
              >
                <Typography variant={textVariant}>
                  {employee.manager.name}
                </Typography>
              </Information>
            </Box>
          )}
          <Box mt="20">
            <Information
              label={
                <Typography variant={textVariant} color="textTertiary">
                  Используется УНЭП
                </Typography>
              }
              spacing="4"
            >
              <Typography variant={textVariant}>
                {UnepTypes[employee.unep_type]}
              </Typography>
              <Tooltip
                title={
                  employee.unep_type === 'cryptopro_local'
                    ? 'Усиленная неквалифицированная электронная подпись на локальном цифровом носителе'
                    : 'Установлен по умолчанию для подписания документов в заявках'
                }
                placement={tooltipPlacement}
              >
                <HelpIcon color="disabled" fontSize="small" />
              </Tooltip>
            </Information>
          </Box>
        </>
        <CompanyUnits
          companyId={employee.company.id}
          employeeId={employee.id}
        />
        <Box mx="-32" my="32">
          <Divider />
        </Box>
        <>
          <Box pb="20">
            <Typography variant={titleVariant}>Уведомления</Typography>
          </Box>
          <Paper>
            <Box p="20">
              <Box pb="8">
                <Typography variant={subtitleVariant}>
                  Корпоративная почта и телефон
                </Typography>
              </Box>
              <Box pb="8">
                <Typography variant={textVariant} color="textTertiary">
                  Самые важные уведомления мы отправляем вам в смс на номер
                  телефона{' '}
                  <Typography variant={textVariant} component="span">
                    {user.phone}
                  </Typography>
                  {employee.email && (
                    <>
                      {' '}
                      и почту{' '}
                      <Typography variant={textVariant} component="span">
                        {employee.email}
                      </Typography>
                    </>
                  )}
                  .
                </Typography>
              </Box>
              {window.REACT_APP_VKHRTEK_TELEGRAM_SUBSCRIPTION_ENABLED && (
                <Box>
                  <Typography variant={textVariant} color="textTertiary">
                    Для удобства вы можете подключать уведомления в Telegram в
                    настройках выше.
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </>
      </Accordion>
    </Content>
  );
};
