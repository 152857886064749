import {
  createBrowserHistory,
  History as HistoryInterface,
  createMemoryHistory,
} from 'history';

import { removeBasename } from '../path';

const PREVHISTORY_STACK_LIMIT = 10;

export const createHistoryWidthPrev =
  (
    historyCreator: typeof createBrowserHistory | typeof createMemoryHistory,
    basename?: string,
  ) =>
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  (...args: any) => {
    const historyInitiated = historyCreator(...args);
    const prevPush = historyInitiated.push;

    const prevStateStack: Array<{ pathname: string; search: string }> = [];

    const pushToPrevHistoryStack = (prevState: {
      pathname: string;
      search: string;
    }) => {
      if (prevStateStack.length === PREVHISTORY_STACK_LIMIT) {
        prevStateStack.pop();
      }
      prevStateStack.unshift(prevState);
    };

    historyInitiated.push = (location) => {
      pushToPrevHistoryStack({
        pathname: historyInitiated.location.pathname,
        search: historyInitiated.location.search,
      });
      if (typeof location === 'string') {
        prevPush(removeBasename(location, basename), {
          prev: prevStateStack,
        });
      } else {
        prevPush({
          ...location,
          ...(location.pathname
            ? { pathname: removeBasename(location.pathname, basename) }
            : {}),
          state: {
            ...Object.assign({}, location?.state),
            prev: prevStateStack,
          },
        });
      }
    };

    return historyInitiated as HistoryInterface;
  };

export type History = HistoryInterface;
export const history = createHistoryWidthPrev(createBrowserHistory)();
export const memoryHistory = createHistoryWidthPrev(createMemoryHistory)();
/* eslint-disable-next-line @typescript-eslint/no-redeclare */
export const History = Symbol('History');
