import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  preloader: {
    position: 'absolute',
    top: theme.px(28), // TODO - fix нет в токенах
    right: theme.tokens.spacing['16'],
  },
}));

export default useStyles;
