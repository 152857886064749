import { IsNotEmpty, IsUUID } from 'class-validator';

export class MarkReadDto {
  @IsUUID()
  @IsNotEmpty()
  policyId: string;

  @IsUUID()
  @IsNotEmpty()
  policyVersionId: string;
}
