import { IsString, IsNotEmpty, IsIn } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

const targetValidationOptions = {
  groups: ['query'],
  message: 'Неверная операция',
};

export class SendInviteDto {
  @IsSnils({
    groups: ['form'],
    message: 'Неверный СНИЛС',
  })
  @IsString({
    groups: ['form'],
    message: 'Вы забыли ввести СНИЛС',
  })
  @IsNotEmpty({
    groups: ['form'],
    message: 'Вы забыли ввести СНИЛС',
  })
  snils: string;

  @IsString({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
    groups: ['form'],
  })
  password: string;

  @IsIn(['registration', 'phone_change'], targetValidationOptions)
  @IsString(targetValidationOptions)
  @IsNotEmpty(targetValidationOptions)
  target: 'registration' | 'phone_change' = 'registration';
}
