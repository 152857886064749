import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { AppError } from '@vk-hr-tek/core/error';
import { showError, showNotification } from '@vk-hr-tek/core/notifications';

import { CompanyEventTypeItem } from '@app/gen/users';
import { ThunkExtra, ThunkValuesAndActions } from '@app/store';

import { CompanyPositionSubstitutes } from '../../slice';
import {
  CreateSubstituteDto,
  GetCompanyEventTypesDto,
  RemoveSubstituteDto,
} from '../../dto';
import { UserApiService } from '../../services';
import { UserState } from '../../slice';
import { UserMapper } from '../../mappers';

export const getCompanyEventTypes = createAsyncThunk<
  CompanyEventTypeItem[],
  GetCompanyEventTypesDto,
  ThunkExtra
>(
  'user/getCompanyEventTypes',
  async (getCompanyEventTypesDto, { rejectWithValue, extra: { inject } }) => {
    try {
      const detailService = await inject(UserApiService);
      return await detailService.getCompanyEventTypes(getCompanyEventTypesDto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getSubstitutes = createAsyncThunk<
  CompanyPositionSubstitutes[],
  undefined,
  ThunkExtra
>('user/getSubstitutes', async (_, { rejectWithValue, extra: { inject } }) => {
  try {
    const detailService = await inject(UserApiService);
    const result = await detailService.getSubstitutes();

    const mapper = await inject(UserMapper);
    return mapper.processSubstitutes(result);
  } catch (err) {
    return rejectWithValue(classToPlain(err) as AppError);
  }
});

export const removeSubstitute = createAsyncThunk<
  void,
  ThunkValuesAndActions<RemoveSubstituteDto>,
  ThunkExtra
>(
  'user/removeSubstituteEmployee',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(UserApiService).removeSubstitute(
        plainToClass(RemoveSubstituteDto, values),
      );

      dispatch(showNotification('Заместитель успешно удален'));
      actions.resolve(null);
      dispatch(getSubstitutes());
    } catch (err) {
      dispatch(showError('При удалении заместителя произошла ошибка'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const addSubstitute = createAsyncThunk<
  void,
  ThunkValuesAndActions<CreateSubstituteDto>,
  ThunkExtra
>(
  'user/addSubstitute',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(UserApiService).addSubstitute(
        plainToClass(CreateSubstituteDto, values),
      );

      dispatch(showNotification('Заместитель успешно добавлен'));
      actions.resolve(null);
      dispatch(getSubstitutes());
    } catch (err) {
      dispatch(showError('При добавлении заместителя произошла ошибка'));
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const userSubstitutesReducers = (
  builder: ActionReducerMapBuilder<UserState>,
) => {
  builder.addCase(getCompanyEventTypes.pending, (state) => {
    state.companyEventTypes = {
      status: 'loading',
      items: [],
      error: null,
    };
  });
  builder.addCase(getCompanyEventTypes.fulfilled, (state, { payload }) => {
    state.companyEventTypes = {
      status: 'complete',
      items: payload,
      error: null,
    };
  });
  builder.addCase(
    getCompanyEventTypes.rejected,
    (state, { payload, error }) => {
      state.companyEventTypes = {
        status: 'failed',
        items: [],
        error:
          payload ||
          ({
            info: (error && error.message) || 'Unknown error',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError),
      };
    },
  );
  builder.addCase(getSubstitutes.pending, (state) => {
    state.substitutes = {
      status: 'loading',
      error: null,
      items: [],
    };
  });
  builder.addCase(getSubstitutes.fulfilled, (state, { payload }) => {
    state.substitutes = {
      status: 'complete',
      error: null,
      items: payload,
    };
  });
  builder.addCase(getSubstitutes.rejected, (state, { payload, error }) => {
    state.substitutes = {
      status: 'failed',
      error:
        payload ||
        ({
          info: (error && error.message) || 'Unknown error',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError),
      items: [],
    };
  });
};
