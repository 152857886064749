import { injectable } from 'inversify';

import { HttpService } from '../http';
import { FileType } from '../file-type';

@injectable()
export class ImageConverter {
  constructor(private http: HttpService, private fileType: FileType) {}

  async convert(image: Blob) {
    const imageType = await this.fileType.fileTypeFromBlob(image);

    if (
      ![
        'image/heic',
        'image/heif',
        'image/tiff',
        'image/dng',
        'image/x-adobe-dng',
      ].includes(imageType)
    ) {
      throw new Error('Неподдерживаемый тип изображения');
    }

    const result = await this.http.generateFile('/tools/image/convert', {
      image,
    });

    return result;
  }
}
