/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { GetBirthdaysDto, GetCalendarDto } from '@vk-hr-tek/app/dashboard/dto';

import { ThunkExtra } from '@app/store';
import {
  CalendarBirthdaysResponse,
  CalendarWidgetResponse,
} from '@app/gen/dashboard';

import { CalendarService } from '../../services';
import { DashboardState } from '../dashboard.state';

export const setCompanyId = createAction<{
  companyId: string | undefined;
}>('dashboard/calendar/setCompanyId');

export const setIncludedEmployees = createAction<{
  employees: string[];
}>('dashboard/calendar/setIncludedEmployees');

export const getCalendarData = createAsyncThunk<
  CalendarWidgetResponse,
  GetCalendarDto,
  ThunkExtra
>(
  'dashboard/calendar/getCalendarData',
  async (getCalendarDto, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const service = inject(CalendarService);

      const result = await service.getCalendar(getCalendarDto);

      const company = result.companies[0];

      dispatch(setCompanyId({ companyId: company?.id }));
      dispatch(
        setIncludedEmployees({
          employees: company?.employees.map((employee) => employee.id) || [],
        }),
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getBirthdays = createAsyncThunk<
  CalendarBirthdaysResponse,
  GetBirthdaysDto,
  ThunkExtra
>(
  'dashboard/calendar/getBirthdays',
  async (getBirthdaysDto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(CalendarService);

      const result = await service.getBirthdays(getBirthdaysDto);

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const calendarReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(setCompanyId, (state, { payload }) => {
    state.calendar.companyId = payload.companyId;
    state.calendar.includedEmployees =
      state.calendar.companies
        .find((company) => company.id === payload.companyId)
        ?.employees.map((employee) => employee.id) || [];
  });
  builder.addCase(setIncludedEmployees, (state, { payload }) => {
    state.calendar.includedEmployees = payload.employees;
  });
  builder.addCase(getCalendarData.pending, (state) => {
    state.calendar.status = 'loading';
    state.calendar.companies = [];
  });
  builder.addCase(getCalendarData.fulfilled, (state, { payload }) => {
    state.calendar.status = 'complete';
    state.calendar.companies = payload.companies;
  });
  builder.addCase(getCalendarData.rejected, (state) => {
    state.calendar.status = 'failed';
    state.calendar.companies = [];
  });
  builder.addCase(getBirthdays.pending, (state) => {
    state.calendar.birthdays = [];
  });
  builder.addCase(getBirthdays.fulfilled, (state, { payload }) => {
    state.calendar.birthdays = payload.employees;
  });
};
