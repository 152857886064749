export interface SettingsSubstitutesRouter {
  goToCreate({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }): void;
  goToList(query?: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsSubstitutesRouter = Symbol('SettingsSubstitutesRouter');
