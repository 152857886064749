import { makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles<Theme, { iconVerticalMargin: number }>((theme) => ({
  accordion: {
    border: 'none',
    borderRadius: 0,
  },
  AccordionSummaryRootSmall: {
    padding: `0 ${theme.tokens.spacing['24']}px`,
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  AccordionSummaryContentSmall: {
    margin: `${theme.tokens.spacing['24']}px 0 !important`,
  },
  AccordionSummaryRootMedium: {
    padding: `0 ${theme.tokens.spacing['20']}px`,
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      padding: `0 ${theme.tokens.spacing['24']}px`,
      alignItems: 'center',
    },
  },
  AccordionSummaryContentMedium: {
    margin: `${theme.tokens.spacing['20']}px 0 !important`,
    [theme.breakpoints.up('md')]: {
      margin: `${theme.tokens.spacing['24']}px 0 !important`,
    },
  },
  AccordionSummaryExpandIcon: ({ iconVerticalMargin }) => ({
    marginTop: theme.tokens.spacing['16'] + theme.px(2),
    [theme.breakpoints.up('md')]: {
      marginTop: iconVerticalMargin,
    },
  }),
  AccordionDetailsRoot: {
    borderTop: `${theme.tokens.border.s}px solid ${theme.tokens.colors.stroke.primary}`,
    padding: theme.tokens.spacing['20'],
    display: 'block',
    [theme.breakpoints.up('md')]: {
      padding: theme.tokens.spacing['24'],
    },
  },
  WithoutPaddings: { padding: 0 },
}));

export default styles;
