import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { PageError } from '../../../layout';

import { Greeting } from './Greeting';
import { Qr } from './Qr';
import { VerifyPassCode } from './VerifyPassCode';
import { Login } from './Login';
import { Register } from './Register';

export const AliAuth = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/login`}>
        <Greeting />
      </Route>
      <Route exact path={`${match.url}/qr`}>
        <Qr />
      </Route>
      <Route exact path={`${match.url}/pass`}>
        <VerifyPassCode />
      </Route>
      <Route exact path={`${match.url}/ali`}>
        <Login />
      </Route>
      <Route exact path={`${match.url}/register`}>
        <Register />
      </Route>
      <Route path={`${match.url}`}>
        <div>
          <PageError status={404} />
        </div>
      </Route>
    </Switch>
  );
};
