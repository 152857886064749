import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  ColorScheme,
  LinkColor,
  LinkSize,
  LinkStroke,
  SizeScheme,
} from './Link.types';

const sizeScheme: (tokens: Theme['tokens'], px: Theme['px']) => SizeScheme = (
  tokens,
  px,
) => ({
  [LinkSize.large]: {
    underlineOffset: `${px(5)}px`,
    icon: {
      fontSize: tokens.typography.variant.h5.fontSize,
      height: px(24),
      width: px(24),
      marginLeft: px(6), // TODO - fix нет в токенах
      marginRight: px(6), // TODO - fix нет в токенах
    },
  },
  [LinkSize.medium]: {
    underlineOffset: `${px(5)}px`,
    icon: {
      fontSize: tokens.typography.variant.body2.fontSize,
      height: px(16),
      width: px(16),
      marginLeft: tokens.spacing['4'],
      marginRight: tokens.spacing['4'],
    },
  },
  [LinkSize.small]: {
    underlineOffset: `${px(3)}px`,
    icon: {
      fontSize: tokens.typography.variant.body2.fontSize,
      height: px(16),
      width: px(16),
      marginLeft: tokens.spacing['4'],
      marginRight: tokens.spacing['4'],
    },
  },
  [LinkSize.extraSmall]: {
    underlineOffset: `${px(2)}px`,
    icon: {
      fontSize: tokens.typography.variant.caption.fontSize,
      height: px(12),
      width: px(12),
      marginLeft: tokens.spacing['4'],
      marginRight: tokens.spacing['4'],
    },
  },
});

const colorScheme: ColorScheme = {
  [LinkColor.primary]: {
    color: (p) => p.primary.main,
    hoveredColor: (p) => p.primary.dark,
  },
  [LinkColor.secondary]: {
    color: (p) => p.text.secondary,
    hoveredColor: (p) => p.text.primary,
  },
  [LinkColor.tertiary]: {
    color: (p) => p.text.tertiary,
    hoveredColor: (p) => p.text.primary,
  },
  [LinkColor.success]: {
    color: (p) => p.success.main,
    hoveredColor: (p) => p.success.dark,
  },
  [LinkColor.error]: {
    color: (p) => p.error.main,
    hoveredColor: (p) => p.error.dark,
  },
  [LinkColor.simple]: {
    color: (p) => p.primary.main,
    hoveredColor: (p) => p.primary.dark,
  },
};

export const useStyles = makeStyles<
  Theme,
  { size: LinkSize; color: LinkColor; disabled: boolean; stroke: LinkStroke }
>(({ palette, tokens, px }) => ({
  link: ({ size, color, stroke, disabled }) => ({
    textDecoration: stroke !== true || disabled ? 'none' : 'underline',
    textUnderlineOffset:
      stroke !== true || disabled
        ? 'auto'
        : sizeScheme(tokens, px)[size].underlineOffset,

    color: disabled
      ? tokens.colors.text.light.tertirary
      : colorScheme[color].color(palette),
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',

    '&:hover': {
      textDecoration: stroke !== false ? 'underline' : 'none',
      textUnderlineOffset:
        stroke !== false
          ? sizeScheme(tokens, px)[size].underlineOffset
          : 'auto',

      color: colorScheme[color].hoveredColor(palette),
    },
  }),

  linkSimple: {
    textDecoration: 'none',
  },

  linkLikeButton: {
    background: 'none',
    border: 'none',
    outline: 'none',
    padding: 0,
    cursor: 'pointer',

    '&:hover': {
      background: 'inherit',
    },
  },

  icon: ({ size }) => ({
    height: sizeScheme(tokens, px)[size].icon.height,
    width: sizeScheme(tokens, px)[size].icon.width,
    alignSelf: size === 'extraSmall' ? 'flex-start' : 'center',

    '& .MuiSvgIcon-root': {
      fontSize: sizeScheme(tokens, px)[size].icon.fontSize,
      margin: 0,
    },
  }),

  startIcon: ({ size }) => ({
    marginRight: sizeScheme(tokens, px)[size].icon.marginRight,
  }),

  endIcon: ({ size }) => ({
    marginLeft: sizeScheme(tokens, px)[size].icon.marginLeft,
  }),
}));
