import { IsString, IsNotEmpty, IsUUID } from 'class-validator';

import { REQUIRED_FIELD_VALIDATION_MESSAGE } from '../../auth.constants';

export class ActivateEmployeeDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  inviteCode: string;
}
