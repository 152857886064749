import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

export const ArrowIcon = () => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fontSize="inherit"
      fill="none"
    >
      <path
        d="M7.23881 14.182C6.8561 14.6714 6.94262 15.3785 7.43208 15.7612C7.92153 16.1439 8.62857 16.0574 9.01129 15.5679L13.0154 10.4471C13.3336 10.0401 13.3337 9.46866 13.0156 9.06153L9.01156 3.93657C8.62903 3.44697 7.92203 3.36016 7.43243 3.74269C6.94282 4.12521 6.85602 4.83221 7.23854 5.32182L10.7013 9.75387L7.23881 14.182Z"
        fill={theme.palette.stroke.secondary}
      />
    </SvgIcon>
  );
};
