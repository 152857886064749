import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '../../error';
import { Crypto } from '../crypto';
import { ThunkExtra } from '../../utils';

import { CryptoState } from './crypto.state';

interface CryptoStateInterface {
  crypto: CryptoState;
}

export const getCertificates = createAsyncThunk<
  {
    id: string;
    name: string;
    user: string;
    company: string;
    activeFrom: string;
    activeTo: string;
  }[],
  undefined,
  ThunkExtra<CryptoStateInterface>
>(
  'events/getCertificates',
  async (_, { rejectWithValue, getState, extra: { inject } }) => {
    try {
      const { hasCertificates, items } = getState().crypto;

      if (hasCertificates && items && items.length) {
        return items;
      }

      const certificates = await inject(Crypto).getSerializedCertificates();

      return certificates;
    } catch (err) {
      const appError = new AppError('client', {
        code: 500,
        message: 'Ошибка при извлечении сертификатов',
      });

      /* istanbul ignore next */
      if (err instanceof Error && err.message) {
        appError.title = err.message;
      }

      return rejectWithValue(classToPlain(appError) as AppError);
    }
  },
);

export const cryptoReducers = (
  builder: ActionReducerMapBuilder<CryptoState>,
) => {
  builder.addCase(getCertificates.pending, (state) => {
    state.hasCertificates = state.status === 'complete' && !!state.items.length;
    state.status = 'loading';
    state.error = null;
  });
  builder.addCase(getCertificates.fulfilled, (state, { payload }) => {
    state.status = 'complete';
    state.error = null;
    state.hasCertificates = !!payload.length;
    state.items = payload;
  });
  builder.addCase(getCertificates.rejected, (state, { payload }) => {
    state.status = 'failed';
    state.items = [];
    state.hasCertificates = false;
    /* istanbul ignore next */
    state.error = payload
      ? payload.title
      : 'Ошибка при извлечении сертификатов';
  });
};
