import React, { useEffect, useCallback } from 'react';

import { Typography } from '@vk-hr-tek/ui/Typography';
import { Box } from '@vk-hr-tek/ui/Box';
import { Idle } from '@vk-hr-tek/core/idle';
import { useInject } from '@vk-hr-tek/core/ioc';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useTheme } from '@vk-hr-tek/ui/Theme';

import { useSelector } from '@app/hooks';

import { useAuth } from '../../hooks';
import { selectStage, selectNotification, selectStatus } from '../../slice';
import { AuthRouterService } from '../../services';
import { PageLoading } from '../../../layout';
import hrTekLogo from '../../assets/hr-tek-logo.svg';
import aliLogo from '../../assets/aliexpress-logo.svg';
import vkHrTekLogo from '../../assets/vk-hr-tek-logo.svg';
import { Container } from '../container';

import { PageError } from './PageError';

export const Page = ({
  title,
  children,
  notification,
  theme = 'general',
}: {
  title: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  theme?: 'general' | 'vk' | 'ali' | 'oauth2';
  notification?: React.ReactNode;
}) => {
  const step = useSelector(selectStage);
  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const router = useInject(AuthRouterService);
  const isDesktop = useIsDesktop();
  const isAuthenticated = useAuth();
  const themeVariant = useTheme();

  const handleIdle = useCallback(() => {
    router.redirectToLogin();
  }, [router]);

  useEffect(() => {
    if (isAuthenticated) {
      router.redirectToSaved();
    }
  }, [isAuthenticated, router]);

  if (status === 'loading') {
    return <PageLoading />;
  }

  if (step === 'error') {
    return (
      <PageError theme={theme !== 'ali' ? theme : 'general'} error={error} />
    );
  }

  if (theme === 'ali') {
    return (
      <Container theme={theme}>
        <Idle onIdle={handleIdle} />
        <Box>
          <Box
            pb="56"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box mr="32">
              <img src={hrTekLogo} />
            </Box>
            <Box>
              <img src={aliLogo} />
            </Box>
          </Box>
          <Box
            fontWeight="bold"
            pb="32"
            fontSize={48}
            textAlign="center"
            className="aqa_reg_header"
          >
            {title}
          </Box>
          {children}
        </Box>
      </Container>
    );
  }

  if (theme === 'vk') {
    return (
      <Container theme={theme}>
        <Box
          bgcolor="bg.light.primary"
          borderColor="stroke.primary"
          border={1}
          px={{ xs: '20', md: '32' }}
          py={{ xs: '20', md: '32' }}
          radius="l"
        >
          {notification}
          <Box mb="32">
            <Typography variant="h4" className="aqa_reg_header">
              {title}
            </Typography>
          </Box>
          {children}
        </Box>
      </Container>
    );
  }

  if (theme === 'oauth2') {
    return (
      <Container theme={theme}>
        <Box
          bgcolor="bg.light.primary"
          borderColor="stroke.primary"
          border={1}
          px={isDesktop ? '144' : '16'}
          py={isDesktop ? '48' : '40'}
          radius="l"
        >
          {notification}
          {children}
        </Box>
      </Container>
    );
  }

  if (!isDesktop) {
    return (
      <Container>
        <Box pt="48">
          {notification}
          <Box mb="32">
            <Typography variant="h4" className="aqa_reg_header">
              {title}
            </Typography>
          </Box>
          {children}
        </Box>
      </Container>
    );
  }

  return (
    <Container
      title={
        themeVariant !== 'otp' && (
          <Box mb="48">
            <img src={vkHrTekLogo} />
          </Box>
        )
      }
    >
      <Box
        bgcolor="bg.light.primary"
        borderColor="stroke.primary"
        border={1}
        px="144"
        py="48"
        radius="l"
      >
        {notification}
        <Box mb="32">
          <Typography variant="h4" className="aqa_reg_header">
            {title}
          </Typography>
        </Box>
        {children}
      </Box>
    </Container>
  );
};
