import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useSelector } from '@app/hooks';

import { selectStatus } from '../../slice';
import { PageLoading } from '../../../layout';
import { Container } from '../container';

export const Page = ({
  title,
  children,
  theme = 'general',
}: {
  title?: string;
  theme?: 'general' | 'notification';
  children: React.ReactNode | React.ReactNode[];
}) => {
  const status = useSelector(selectStatus);
  const isDesktop = useIsDesktop();

  if (status === 'loading') {
    return <PageLoading />;
  }

  if (!isDesktop) {
    if (theme === 'notification') {
      return (
        <Container theme={theme}>
          <Box
            bgcolor="bg.light.primary"
            borderColor="stroke.primary"
            border={1}
            py="48"
            px="20"
            radius="l"
          >
            {title && (
              <Box mb="16">
                <Typography variant="h5">{title}</Typography>
              </Box>
            )}
            {children}
          </Box>
        </Container>
      );
    }

    return (
      <Container theme={theme}>
        <Box pt="48">
          {title && (
            <Box mb="16">
              <Typography variant="h5">{title}</Typography>
            </Box>
          )}
          {children}
        </Box>
      </Container>
    );
  }

  return (
    <Container theme={theme}>
      <Box
        bgcolor="bg.light.primary"
        borderColor="stroke.primary"
        border={1}
        py="48"
        px="144"
        radius="l"
      >
        {title && (
          <Box mb="16">
            <Typography variant="h4">{title}</Typography>
          </Box>
        )}
        {children}
      </Box>
    </Container>
  );
};
