/* istanbul ignore file */
import { inject, injectable } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';
import { History } from '@vk-hr-tek/core/history';

import { UserRoleEnum } from '@app/types';

@injectable()
export class UserRoleService {
  private readonly key = 'userRole';

  constructor(
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
    @inject(History) private readonly history: History,
  ) {}

  save(role: UserRoleEnum): void {
    this.localStorage.setItem(this.key, role);
  }

  get(): UserRoleEnum {
    return this.history.location.pathname.startsWith('/company')
      ? UserRoleEnum.Company
      : UserRoleEnum.Employee;
  }
}
