export interface UserRouter {
  goBack(prevState: { pathname: string; search: string }): void;
  redirectToHome(): void;
  goToCreateCertificate(): void;
  goToProfile(sectionId?: string): void;
  goToProfileAttorney({
    id,
    companyId,
  }: {
    id: string;
    companyId: string;
  }): void;
  goToProfileAttorneySettings(companyId: string): void;
  goToProfileSubstitutes({
    employeeId,
    companyId,
  }: {
    employeeId: string;
    companyId: string;
  }): void;

  goToProfileApprovalSettings({
    employeeId,
    companyId,
    unitId,
  }: {
    employeeId: string;
    companyId: string;
    unitId: string;
  }): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRouter = Symbol('UserRouter');
