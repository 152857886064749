import React, { useState, useEffect } from 'react';

import { TreeItem as MUITreeItem } from '@material-ui/lab';
import classNames from 'classnames';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';
import { Callout } from '../Callout';

import { Item } from './Item.interface';
import { useStyles } from './TreeItem.styles';

export interface TreeItemProps {
  item: Item;
  showRulers?: boolean;
  showCounters?: boolean;
  expanded?: string[];
  onSelect: (value: string, label?: string) => void;
  onExpand: (value: string) => void;
  onChildrenLoad?: (unitId: string) => Promise<Item[]>;
}

export const TreeItem = ({
  item,
  showRulers = true,
  showCounters = true,
  expanded,
  onSelect,
  onExpand,
  onChildrenLoad,
}: TreeItemProps) => {
  const [loadedChildren, setLoadedChildren] = useState<Item[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const isExpanded = expanded?.some(
    (expandedItems) => expandedItems === item.id,
  );

  const { itemLabel, itemCounter, group, label, ...classes } = useStyles();

  useEffect(() => {
    if (
      isExpanded &&
      item.hasChildrenToLoad &&
      !item.children?.length &&
      !loadedChildren.length
    ) {
      setIsLoading(true);
      setIsError(false);
      onChildrenLoad?.(item.id)
        .then((result) => {
          setIsLoading(false);
          setLoadedChildren(result);
        })
        .catch(() => {
          setIsLoading(false);
          setIsError(true);
          setLoadedChildren([]);
        });
    }
  }, [
    isExpanded,
    item.children?.length,
    item.hasChildrenToLoad,
    item.id,
    loadedChildren.length,
    onChildrenLoad,
  ]);

  const handleLabelClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onSelect(item.id, item.label);
  };

  const handleIconClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    onExpand(item.id);
  };

  const childrenToShow =
    item?.children && !!item.children.length ? item.children : loadedChildren;
  const isShowChildren = !!childrenToShow.length || item.hasChildrenToLoad;

  return (
    <MUITreeItem
      nodeId={item.id}
      classes={{
        ...classes,
        ...(showRulers ? { group } : {}),
        label: classNames(label, 'aqa_tree_item'),
      }}
      onLabelClick={(event) => handleLabelClick(event)}
      onIconClick={(event) => handleIconClick(event)}
      label={
        <div className={itemLabel}>
          <div>{item.label}</div>
          {showCounters && Number.isInteger(item.count) && (
            <div className={itemCounter}>{item.count}</div>
          )}
        </div>
      }
    >
      {isShowChildren && (
        <>
          {childrenToShow &&
            childrenToShow.map((child) => (
              <TreeItem
                key={child.id}
                item={child}
                showRulers={showRulers}
                showCounters={showCounters}
                onSelect={onSelect}
                onExpand={onExpand}
                onChildrenLoad={onChildrenLoad}
                expanded={expanded}
              />
            ))}
          {isLoading && (
            <Box display="flex" justifyContent="center" py="16">
              <CircularProgress size={20} />
            </Box>
          )}
          {isError && (
            <Box py="24" px="16">
              <Callout variant="error" showIcon>
                Не удалось загрузить оргструктуру. Обновите страницу и
                попробуйте еще раз
              </Callout>
            </Box>
          )}
        </>
      )}
    </MUITreeItem>
  );
};
