import React, { ReactNode } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

interface StepProps {
  label?: string;
  text: string;
  children: ReactNode;
}

export const Step = ({ label, text, children }: StepProps) => {
  const isDesktop = useIsDesktop();

  return (
    <Box
      display="flex"
      flexDirection={isDesktop ? 'row' : 'column'}
      justifyContent="space-between"
      gap={isDesktop ? '0' : '16'}
      py={isDesktop ? '24' : '16'}
      px="16"
      radius="m"
      bgcolor="bg.greyscale.secondary"
    >
      <Box display="flex" flexDirection="column" gap="8" width={200}>
        {label && (
          <Box>
            <Typography variant="subtitle1">{label}</Typography>
          </Box>
        )}
        <Box>
          <Typography variant="body2" color="textTertiary">
            {text}
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  );
};
