import { IsString, IsNotEmpty, IsUUID } from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class ReturnEventDto {
  @IsNotEmpty()
  @IsUUID()
  eventId: string;

  @IsNotEmpty()
  @IsUUID()
  nodeId: string;

  @IsNotEmpty({
    ...notEmptyOptions,
    groups: ['form'],
  })
  @IsString({
    ...notEmptyOptions,
    groups: ['form'],
  })
  comment: string;
}
