import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, spacing, tokens }) => ({
  documentCursor: {
    cursor: 'pointer',
  },

  dialogPaper: {
    '&, &.MuiDialog-paperFullScreen.MuiDialog-paperScrollBody': {
      [breakpoints.up('xs')]: {
        marginTop: spacing(5.2), // TODO - fix нет в токенах
        marginBottom: spacing(6.4), // TODO - fix нет в токенах
      },

      [breakpoints.up('sm')]: {
        marginTop: 0,
      },

      [breakpoints.up('md')]: {
        marginTop: tokens.spacing['32'],
        marginBottom: spacing(9.8), // TODO - нет в токенах
      },
    },

    '&.MuiDialog-paperFullScreen': {
      [breakpoints.up('xs')]: {
        maxHeight: `calc(100% - 116px)`,
      },

      [breakpoints.up('sm')]: {
        maxHeight: `calc(100% - 64px)`,
      },
    },
  },
}));
