import React from 'react';

import { Switch, useRouteMatch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { PageError } from '../../../layout';
import { PageRedirect } from '../../ui/page';

import { Login } from './Login';
import { VerifyAuthCode } from './VerifyAuthCode';
import { Register } from './Register';

export const VKAuth = (): JSX.Element => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/login`}>
        <Login />
      </Route>
      <Route exact path={`${match.url}/register`}>
        <Register />
      </Route>
      <Route exact path={`${match.url}/result`}>
        <VerifyAuthCode />
      </Route>
      <Route exact path={`${match.url}/invite`}>
        <PageRedirect to="login" />
      </Route>
      <Route path={`${match.url}`}>
        <div>
          <PageError status={404} />
        </div>
      </Route>
    </Switch>
  );
};
