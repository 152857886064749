import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { EmployeeInviteVerifyResponse as VerifyInviteResponse } from '@app/gen/users';

import { ActivateEmployeeDto, VerifyInviteDto, SendInviteDto } from '../../dto';

@injectable()
export class AuthInviteService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async verifyInvite(verifyInviteDto: VerifyInviteDto) {
    await this.validator.validateOrReject(verifyInviteDto, VerifyInviteDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/employee/invite/verify',
        {
          invite_code: verifyInviteDto.inviteCode,
          snils:
            verifyInviteDto.snils &&
            verifyInviteDto.snils.replace(/(-| )/g, ''),
        },
        { withAuth: false },
      ),
      VerifyInviteResponse,
    );

    return result;
  }

  async sendInvite(sendInviteDto: SendInviteDto) {
    await this.validator.validateOrReject(sendInviteDto, SendInviteDto);

    const result = await this.http.post(
      '/user/invite/send',
      {
        target: sendInviteDto.target,
        snils: sendInviteDto.snils.replace(/(-| )/g, ''),
        password: sendInviteDto.password,
      },
      { withAuth: false },
    );

    return result;
  }

  async activateEmployee(activateEmployeeDto: ActivateEmployeeDto) {
    await this.validator.validateOrReject(
      activateEmployeeDto,
      ActivateEmployeeDto,
    );

    await this.http.post('/employee/activate', {
      invite_code: activateEmployeeDto.inviteCode,
    });
  }
}
