export const pageErrorDictionary: {
  [key: string]: {
    title: string;
    caption: string;
  };
} = {
  '400': {
    title: 'Произошла ошибка',
    caption: 'Не удалось выполнить указанное действие. Попробуйте еще раз',
  },
  '401': {
    title: 'Неавторизованный запрос',
    caption: 'Похоже, вы неавторизованы',
  },
  '403': {
    title: 'Неавторизованный запрос',
    caption: 'У вас нет прав на выполнение операции',
  },
  '404': {
    title: 'Страница не найдена',
    caption:
      'Страница, на которую вы пытаетесь попасть, не существуют или была удалена',
  },
  '500': {
    title: 'Произошла ошибка',
    caption: 'Выполнение данной операции на текущий момент недоступно.',
  },
  '503': {
    title: 'Сервис временно недоступен',
    caption:
      'На текущий момент сервис временно недоступен. Мы уже работаем над исправлением и в ближайшее время восстановим работоспособность',
  },
};
