/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';

import {
  Field,
  FieldProps,
  FieldRenderProps,
  FieldInputProps,
} from 'react-final-form';

import { ChipsInput as Input } from '../../input';

export const ChipsInput = ({
  type,
  label,
  name,
  onChange,
  items,
  required,
  disabled = false,
  size = 'large',
  value,
  showError = true,
  loading = false,
  tooltip,
  testId,
  ...rest
}: {
  type?: 'number' | 'string';
  items: { label: string; value: string | number }[];
} & FieldProps<
  string | number | undefined,
  FieldRenderProps<string | number | undefined>
>) => {
  const handleChange = useCallback(
    (input: FieldInputProps<string | number | undefined, HTMLElement>) =>
      (nextValue: string | number | undefined) => {
        const convertedVal =
          type === 'number' && typeof nextValue === 'string'
            ? parseInt(nextValue, 10)
            : nextValue;

        input.onChange(convertedVal);

        if (onChange) {
          onChange(convertedVal);
        }
      },
    [type, onChange],
  );

  return (
    <Field name={name} {...rest} disabled={disabled}>
      {({ input, meta }) => {
        return (
          <Input
            {...input}
            testId={testId}
            items={items}
            tooltip={tooltip}
            value={input.value}
            onChange={handleChange(input)}
            size={size}
            required={required}
            error={meta.touched && !!meta.error}
            helperText={showError && meta.touched && meta.error}
            label={label}
            loading={loading}
            disabled={disabled}
            activeSingleOption={false}
          />
        );
      }}
    </Field>
  );
};
