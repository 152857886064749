import {
  createAsyncThunk,
  createAction,
  ActionReducerMapBuilder,
  EntityState,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';
import { Notification as NotificationPaylaod } from '@vk-hr-tek/core/notifications/gen/notifications';
import { Socket } from '@vk-hr-tek/core/websocket';

import {
  CompanyPolicyListItem as CompanyPolicy,
  EmployeePolicyListItem as EmployeePolicy,
} from '@app/gen/policy';

import { ThunkExtra } from '../../../app/store';
import {
  completeLoading,
  policyAdapter,
  PolicyState,
  setError,
  startLoading,
} from '../policy.state';
import { GetPolicyDto, MarkReadDto } from '../../dto';
import { PolicyListService } from '../../services';
import { EmployeePolicyStatus } from '../../types';

export const setFilters = createAction<Filter[] | null>('policy/setFilters');

export const getPolicies = createAsyncThunk<
  {
    data: CompanyPolicy[] | EmployeePolicy[];
    totalCount: number | null;
    totalVersionCount: number | null;
  },
  GetPolicyDto,
  ThunkExtra
>(
  'policy/getPolicies',
  async (
    getPolicyDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().policy;
      const policyService = inject(PolicyListService);

      let filters = state.filters;

      if (!filters) {
        filters = await policyService.getFilters();
        dispatch(setFilters(filters));
      }

      return await policyService.get(getPolicyDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getEmployeeFilteredPolicies = createAsyncThunk<
  {
    data: CompanyPolicy[] | EmployeePolicy[];
  },
  {
    dto: GetPolicyDto;
    policies: {
      policyVersionId: string;
      employeeId: string;
    }[];
  },
  ThunkExtra
>(
  'policy/getFilterdPolicies',
  async (
    { dto: getPolicyDto, policies },
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().policy;
      const policyService = inject(PolicyListService);

      let filters = state.filters;

      if (!filters) {
        filters = await policyService.getFilters();
        dispatch(setFilters(filters));
      }

      const policyVersionIds = policies.map(
        ({ policyVersionId }) => policyVersionId,
      );

      const result = await policyService.get(
        getPolicyDto,
        filters,
        policyVersionIds,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const updateEmployeePoliciesStatus = createAction<{
  status: EmployeePolicyStatus;
  policyId: string;
}>('policy/updateEmployeePoliciesStatus');

export const getAdditionalPolicies = createAsyncThunk<
  {
    data: CompanyPolicy[] | EmployeePolicy[];
  },
  GetPolicyDto,
  ThunkExtra
>(
  'policy/getAdditionalPolicies',
  async (
    getPolicyDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const state = getState().policy;
      const policyListService = inject(PolicyListService);

      let filters = state.filters;

      if (!filters) {
        filters = await policyListService.getFilters();
        dispatch(setFilters(filters));
      }

      return await policyListService.get(getPolicyDto, filters);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const markDocumentRead = createAsyncThunk<void, MarkReadDto, ThunkExtra>(
  'policy/markDocumentRead',
  async (markReadDto, { rejectWithValue, extra: { inject } }) => {
    try {
      await inject(PolicyListService).markRead(markReadDto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const employeePoliciesStatusListener = createAsyncThunk<
  void,
  void,
  ThunkExtra
>(
  'policy/employeePoliciesStatusListener',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    try {
      const socket = inject(Socket);

      socket.on('notification', (notificationPayload: NotificationPaylaod) => {
        const { payload } = notificationPayload;

        if (payload && 'policy_id' in payload) {
          let neededId = payload.policy_id;
          let neededStatus = payload.status;

          if ('policy_version_id' in payload && 'employee_id' in payload) {
            const {
              policy_version_id: policyVersionId,
              employee_id: employeeId,
            } = payload;

            neededId = `${policyVersionId}${employeeId}`;
          }

          if (payload.status === 'completed') {
            neededStatus = 'signed';
          }

          const updateEmployeePolicyData = {
            status: neededStatus as EmployeePolicyStatus,
            policyId: neededId as string,
          };

          return dispatch(
            updateEmployeePoliciesStatus(updateEmployeePolicyData),
          );
        }
      });
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const policyListReducers = (
  builder: ActionReducerMapBuilder<
    EntityState<CompanyPolicy | EmployeePolicy> & PolicyState
  >,
) => {
  builder.addCase(setFilters, (state, action) => {
    state.filters = action.payload;
  });
  builder.addCase(getPolicies.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(getPolicies.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.totalCount = payload.totalCount;
    state.totalVersionCount = payload.totalVersionCount;
    state.isNotLastItems = payload.data.length >= 50;
    state.currentIds = payload.data
      .sort((a, b) => {
        if ('status' in a && 'status' in b) {
          return (
            +(a.status === 'signed') - +(b.status === 'signed') ||
            a.name.localeCompare(b.name)
          );
        }

        return a.name.localeCompare(b.name);
      })
      .map((policy) => {
        if ('policy_version_id' in policy && 'employee_id' in policy) {
          const {
            policy_version_id: policyVersionId,
            employee_id: employeeId,
          } = policy as EmployeePolicy;

          return `${policyVersionId}${employeeId}`;
        }

        return policy.policy_id;
      });

    policyAdapter.upsertMany(state, payload.data);
  });
  builder.addCase(getPolicies.rejected, (state, { payload, error, meta }) => {
    if (!meta.aborted) {
      setError(state, { payload, error });
    }
  });
  builder.addCase(getAdditionalPolicies.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.isNotLastItems = payload.data.length >= 50;
    state.currentIds = [
      ...state.currentIds,
      ...payload.data
        .sort((a, b) => {
          if ('status' in a && 'status' in b) {
            return (
              +(a.status === 'signed') - +(b.status === 'signed') ||
              a.name.localeCompare(b.name)
            );
          }

          return a.name.localeCompare(b.name);
        })
        .map((policy) => {
          if ('policy_version_id' in policy && 'employee_id' in policy) {
            const {
              policy_version_id: policyVersionId,
              employee_id: employeeId,
            } = policy as EmployeePolicy;

            return `${policyVersionId}${employeeId}`;
          }

          return policy.policy_id;
        }),
    ];
    policyAdapter.upsertMany(state, payload.data);
  });

  builder.addCase(
    getEmployeeFilteredPolicies.fulfilled,
    (state, { payload }) => {
      policyAdapter.upsertMany(state, payload.data);
    },
  );

  builder.addCase(
    getEmployeeFilteredPolicies.rejected,
    (state, { payload, error }) => {
      setError(state, { payload, error });
    },
  );

  builder.addCase(markDocumentRead.fulfilled, (state, { meta }) => {
    policyAdapter.updateOne(state, {
      id: meta.arg.policyId,
      changes: { read_at: new Date().toISOString() },
    });
  });

  builder.addCase(updateEmployeePoliciesStatus, (state, { payload }) => {
    policyAdapter.updateOne(state, {
      id: payload.policyId,
      changes: { status: payload.status },
    });
  });
};
