import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { FiltersResponse, Filter, FilterService } from '@vk-hr-tek/core/filter';

import {
  AvailableAbsenceFilters,
  AbsenceListResponse,
  AbsenceCompanyOptionsList,
} from '@app/gen/absences';
import { EventListResponse } from '@app/gen/events';

import { GetEventsDto } from '../dto';
import { AbsencesFilterType } from '../types';
import { GetCompanyAbsencesListDto } from '../dto';

const ABSENCES_TAG = 'отсутствие';

@injectable()
export class AbsencesListService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private validator: ValidationService,
    private filterService: FilterService,
  ) {}

  async getAbsenceList() {
    const companyId = '';
    const year = '';

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/abscenses/${companyId}/${year}`,
        {},
        {
          withSide: true,
        },
      ),
      AbsenceListResponse,
    );

    return result.groups;
  }

  async get(getEventsDto: GetEventsDto, availableFilters: Filter[]) {
    const eventsDto = plainToClass(
      this.filterService.buildDto(GetEventsDto, availableFilters),
      getEventsDto,
    );

    await this.validator.validateOrReject(eventsDto);

    const filters: AbsencesFilterType = {};

    for (const filter in eventsDto.filters) {
      const filterValue = eventsDto.filters[filter];
      this.filterService.sanitizeDates(filterValue);
      filters[filter] = filterValue;
    }

    filters.tags = [ABSENCES_TAG];
    filters.company = [eventsDto.companyId];

    const {
      limit = 50,
      offset = 0,
      sortBy = 'created_at',
      sortOrder = 'desc',
    } = eventsDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/event/list',
        {
          pagination: {
            limit,
            offset,
            sort_by: sortBy,
            sort_order: sortOrder,
          },
          filters,
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      EventListResponse,
    );

    return {
      data: result.events,
      total: result.total_count,
      totalMy: result.total_count_my,
      totalMyTeam: result.total_count_my_group,
      finished: result.finished_count || 0,
    };
  }

  async getFilters(companyId: string) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/available_filters',
        {
          tags: [ABSENCES_TAG],
          company_id: companyId,
        },
        { withSide: true, reloadIfOldVersion: true },
      ),
      FiltersResponse,
    );

    return result.filters;
  }

  async getCompanyAbsencesList(
    getCompanyAbsencesListDto: GetCompanyAbsencesListDto,
  ) {
    const { limit, offset, filters, companyId, year, month } =
      getCompanyAbsencesListDto;

    const requestFilters = { ...filters, company_id: companyId, year, month };

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/absences/company/list',
        {
          pagination: {
            limit,
            offset,
          },
          filters: requestFilters,
        },
        {
          withSide: true,
        },
      ),
      AbsenceListResponse,
    );

    return result;
  }

  async getCompanyAbsenceFilters(companyId: string) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/absence_available_filters/${companyId}`,
        {},
        { withSide: true },
      ),
      AvailableAbsenceFilters,
    );

    return result.filters;
  }

  async getAbsenceCompanies() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get('/absences/companies', {}, { withSide: true }),
      AbsenceCompanyOptionsList,
    );

    return result.items;
  }
}
