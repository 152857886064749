export interface SettingsTemplatesRouter {
  goToDetail({ id, companyId }: { id: string; companyId: string }): void;
  goToDetailPreview({ id, companyId }: { id: string; companyId: string }): void;
  goToDetailPreviewUpload({
    id,
    companyId,
  }: {
    id: string;
    companyId: string;
  }): void;
  goToList(query?: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsTemplatesRouter = Symbol('SettingsTemplatesRouter');
