import React from 'react';

import { useTheme } from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const ArrowUpIcon = (props: SvgIconProps) => {
  const { tokens } = useTheme();

  return (
    <SvgIcon {...props} htmlColor="#ffffff" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6665 9.33333L7.99984 6L11.3332 9.33333H4.6665Z"
        fill={tokens.colors.original.brand.primary}
      />
    </SvgIcon>
  );
};
