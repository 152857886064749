import React from 'react';

import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import { FormButton, FormError, TextInput } from '@vk-hr-tek/ui/form';

import { useSelector } from '@app/hooks';

import { CandidateLoginInitDto } from '../../../dto/candidate';
import {
  candidateLoginInit,
  selectInviteCode,
  selectNotification,
  selectStatus,
} from '../../../slice';
import { LegalLinks } from '../../../ui/links';

export const LoginInit = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const invite = useSelector(selectInviteCode) ?? '';

  const onSubmit = (values: CandidateLoginInitDto) =>
    new Promise((resolve) => {
      dispatch(
        candidateLoginInit({
          candidateLoginInitDto: { ...values, invite },
          actions: {
            resolve,
            reject: resolve,
          },
        }),
      );
    });

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) =>
        validator.validate(values, CandidateLoginInitDto, ['form'])
      }
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mt="8">
            <TextInput
              name="phone"
              label="Номер телефона"
              placeholder="+7"
              mask="+7 (999) 999-99-99"
              id="qaAuthLoginInitPhoneInput"
            />
          </Box>
          <Box mt="32" mb="16">
            <FormButton
              disabled={pristine || hasValidationErrors}
              loading={submitting}
            >
              Продолжить
            </FormButton>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
          </Box>

          <LegalLinks
            policyConfidentialityLink="policy_confidentiality"
            userAggrementLink="user_agreement"
          />
        </form>
      )}
    />
  );
};
