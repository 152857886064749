import { IsBoolean, IsNotEmpty, IsOptional, IsUUID } from 'class-validator';

export class UpdatePolicyDto {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsBoolean()
  @IsOptional()
  hideIfAccepted?: boolean;
}
