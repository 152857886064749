import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { showNotification } from '@vk-hr-tek/core/notifications';
import {
  GetUnitTypesDto,
  GetCompanyUnitTreeDto,
  UnitService,
  UnitType,
} from '@vk-hr-tek/core/units';
import { awaitDispatch } from '@vk-hr-tek/core/redux';

import { GetUnitsTreeResponse, GetUnitTypesResponse } from '@app/gen/settings';
import {
  CreatePolicyResponse,
  GetGovDocumentTypeOptionsResponse,
  PolicyListItemsResponse,
} from '@app/gen/policy';
import { ThunkExtra } from '@app/store';

import { PolicyCreateService } from '../../services';
import { CreatePolicyDto, GetPolicyPositionsDto } from '../../dto';
import { PolicyState } from '../policy.state';
import { CreationMapper } from '../../mappers';
import { PolicyRouter } from '../../types';

export const getCompanyUnitTree = createAsyncThunk<
  GetUnitsTreeResponse,
  GetCompanyUnitTreeDto,
  ThunkExtra
>(
  'createPolicy/getCompanyUnitTree',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const unitService = inject(UnitService);

      return await unitService.getUnitTree(dto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCompanyUnitTypes = createAsyncThunk<
  GetUnitTypesResponse,
  GetUnitTypesDto,
  ThunkExtra
>(
  'createPolicy/getCompanyUnitTypes',
  async (dto, { rejectWithValue, extra: { inject }, dispatch }) => {
    try {
      const unitService = inject(UnitService);
      const unitTypes = await unitService.getUnitTypes(dto);
      const unitTypesTransfomed =
        inject(CreationMapper).policyUnitTypeSorting(unitTypes);

      dispatch(
        getCompanyUnitTree({
          ...dto,
          unitType:
            (unitTypesTransfomed.types[0].type as UnitType) || 'operational',
        }),
      );
      return unitTypesTransfomed;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getGovDocumentOptions = createAsyncThunk<
  GetGovDocumentTypeOptionsResponse,
  undefined,
  ThunkExtra
>(
  'createPolicy/getGovDocumentOptions',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const res = await inject(PolicyCreateService).getGovDocumentOptions();

      return res;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCreationData = createAsyncThunk<
  {
    companies: {
      company_id: string;
      name: string;
    }[];
  },
  undefined,
  ThunkExtra
>(
  'createPolicy/getCompanies',
  async (_, { rejectWithValue, extra: { inject }, dispatch }) => {
    try {
      const res = await inject(PolicyCreateService).getCompanies();

      Promise.all([
        awaitDispatch(
          dispatch(
            getCompanyUnitTypes({ companyId: res.companies[0].company_id }),
          ),
        ),
        awaitDispatch(dispatch(getGovDocumentOptions())),
      ]);

      return { companies: res.companies };
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCompanyHasPositions = createAsyncThunk<
  PolicyListItemsResponse,
  GetPolicyPositionsDto,
  ThunkExtra
>(
  'createPolicy/getIsCompanyPositionsDisabled',
  async (getPolicyPositionsDto, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(PolicyCreateService);

      const result = await service.getPolicyPositions({
        ...getPolicyPositionsDto,
        limit: 1,
        offset: 0,
        query: '',
      });

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createPolicy = createAsyncThunk<
  CreatePolicyResponse,
  {
    values: CreatePolicyDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'createPolicy',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const router = inject<PolicyRouter>(PolicyRouter);
      const policyCreateService = inject(PolicyCreateService);
      const result = await policyCreateService.createPolicy(values);
      actions.resolve(null);

      router.goToDetail(result.policy_id);

      dispatch(showNotification('Документ опубликован!'));

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const creationReducers = (
  builder: ActionReducerMapBuilder<PolicyState>,
) => {
  builder.addCase(getCreationData.pending, (state) => {
    state.creationData.status = 'loading';
    state.creationData.error = null;
  });
  builder.addCase(getCreationData.fulfilled, (state, { payload }) => {
    state.creationData.status = 'complete';
    state.creationData.companies = payload.companies;
    state.creationData.error = null;
  });
  builder.addCase(getCreationData.rejected, (state, { payload, error }) => {
    state.creationData.status = 'failed';
    state.creationData.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
    state.creationData.unitTypes.values = {
      types: [],
    };
    state.creationData.unitsTree = {};
  });

  builder.addCase(getCompanyHasPositions.pending, (state) => {
    state.creationData.positions.status = 'loading';
  });
  builder.addCase(getCompanyHasPositions.fulfilled, (state, { payload }) => {
    state.creationData.positions.status = 'complete';
    state.creationData.positions.hasPositions = !!payload.length;
  });
  builder.addCase(getCompanyHasPositions.rejected, (state) => {
    state.creationData.positions.status = 'failed';
    state.creationData.positions.hasPositions = false;
  });

  builder.addCase(getCompanyUnitTypes.pending, (state) => {
    state.creationData.unitTypes.status = 'loading';
  });
  builder.addCase(getCompanyUnitTypes.fulfilled, (state, { payload }) => {
    state.creationData.unitTypes = {
      values: Array.isArray(payload?.types) ? payload : { types: [] },
      status: 'complete',
    };
  });
  builder.addCase(getCompanyUnitTypes.rejected, (state) => {
    state.creationData.unitTypes = {
      values: { types: [] },
      status: 'failed',
    };
  });

  builder.addCase(getGovDocumentOptions.fulfilled, (state, { payload }) => {
    state.creationData.govDocumentOptions = payload.options;
  });

  builder.addCase(getCompanyUnitTree.pending, (state) => {
    state.creationData.unitsTree = {};
  });
  builder.addCase(getCompanyUnitTree.fulfilled, (state, { payload }) => {
    state.creationData.unitsTree = payload ?? {};
  });
  builder.addCase(getCompanyUnitTree.rejected, (state) => {
    state.creationData.unitsTree = {};
  });

  builder.addCase(createPolicy.pending, (state) => {
    state.creation.status = 'loading';
    state.creation.error = null;
  });
  builder.addCase(createPolicy.fulfilled, (state) => {
    state.creation.status = 'complete';
    state.creation.error = null;
  });
  builder.addCase(createPolicy.rejected, (state, { payload, error }) => {
    state.creation.status = 'failed';
    state.creation.error =
      payload ||
      ({
        info: (error && error.message) || 'Что-то пошло не так',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};
