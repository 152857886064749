import { useInject } from '@vk-hr-tek/core/ioc';

import { AuthConfigService } from '../services/common/auth.config.service';

export function useApplication(): string {
  const config = useInject(AuthConfigService);

  if (config.getAuthType() === 'candidate') {
    return 'candidateApp';
  }

  return 'generalApp';
}
