import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';

import { ThunkExtra } from '@app/store';
import { WidgetPartition } from '@app/gen/dashboard';

import { PartitionsService } from '../../services';
import { DashboardState } from '../dashboard.state';

export const getPartitionsEmployee = createAsyncThunk<
  WidgetPartition[],
  undefined,
  ThunkExtra
>(
  'dashboard/getPartitionsEmployee',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(PartitionsService);

      const result = await service.getPartitions('employee');

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getPartitionsCompany = createAsyncThunk<
  WidgetPartition[],
  undefined,
  ThunkExtra
>(
  'dashboard/getPartitionsCompany',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(PartitionsService);

      const result = await service.getPartitions('company');

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const partitionsReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(getPartitionsEmployee.pending, (state) => {
    state.partitionsEmployee.status = 'loading';
  });
  builder.addCase(getPartitionsEmployee.fulfilled, (state, { payload }) => {
    state.partitionsEmployee.status = 'complete';
    state.partitionsEmployee.items = payload;
  });
  builder.addCase(getPartitionsEmployee.rejected, (state) => {
    state.partitionsEmployee.status = 'failed';
  });
  builder.addCase(getPartitionsCompany.pending, (state) => {
    state.partitionsCompany.status = 'loading';
  });
  builder.addCase(getPartitionsCompany.fulfilled, (state, { payload }) => {
    state.partitionsCompany.status = 'complete';
    state.partitionsCompany.items = payload;
  });
  builder.addCase(getPartitionsCompany.rejected, (state) => {
    state.partitionsCompany.status = 'failed';
  });
};
