export const SUBMIT_ERROR_TEXT =
  'Ошибка. Попробуйте еще раз и обратитесь в службу поддержки, если ошибка повторится.';
export const VACATION_START_ATTR_ID = 'b702174b-f9e1-4ec4-97a3-78642eb46e3a';
export const VACATION_END_ATTR_ID = 'b6678658-e673-4782-9d0d-7eb15394d7e5';
export const VACATION_TYPE_ATTR_ID = '68747600-82df-45b2-b434-52bde1cce4e7';
export const VACATION_DAYS_COUNT = 'b14325bb-508d-4fa9-b70e-35b36f48e3f8';

export const FIRST_VACATION_START_ATTR_ID =
  '618b4c3d-b117-411f-b8e0-768ccddcf98a';
export const FIRST_VACATION_END_ATTR_ID =
  '11c46dd8-69c7-4fcb-8d61-f1b998223d7a';
export const FIRST_VACATION_TYPE_ATTR_ID =
  'dffee9c1-960e-40d1-a0b4-2103dfeee570';
export const FIRST_VACATION_DAYS_COUNT = '9cda84b2-fc6c-40dc-a534-6a0b99684b47';

export const SECOND_VACATION_START_ATTR_ID =
  'df831f36-2887-4e38-b821-5da41cc79ef2';
export const SECOND_VACATION_END_ATTR_ID =
  '75cf7a13-93cb-4ac0-a404-85baad279f3f';
export const SECOND_VACATION_TYPE_ATTR_ID =
  '0b1ee7ac-4a55-4da4-9a06-9c4e4b94482e';
export const SECOND_VACATION_DAYS_COUNT =
  '26d44350-8069-43a5-ade9-6ab9e732f72d';

export const THIRD_VACATION_START_ATTR_ID =
  '164c8694-a803-486e-881c-70e718a39279';
export const THIRD_VACATION_END_ATTR_ID =
  '5e8f489f-7308-4aca-99b9-0e64ad3fdf05';
export const THIRD_VACATION_TYPE_ATTR_ID =
  'a6eba3d1-a2f2-4b4d-a98a-42ea2d702e42';
export const THIRD_VACATION_DAYS_COUNT = '4e3ac7e3-64a1-439b-8cbb-3bbde7ea917b';

export const FOURTH_VACATION_START_ATTR_ID =
  '7e5bb0f1-5201-4f36-9b47-9e7d261dd792';
export const FOURTH_VACATION_END_ATTR_ID =
  '1bf5abc2-b941-4958-9ca7-3d002dbd382b';
export const FOURTH_VACATION_TYPE_ATTR_ID =
  '831bdc0d-abd3-44f3-993b-f1a89691342a';
export const FOURTH_VACATION_DAYS_COUNT =
  '2640eb42-cc52-4495-8db7-28572c1257eb';

export const FIFTH_VACATION_START_ATTR_ID =
  '007119c9-770f-4ec0-ae08-b924101f448f';
export const FIFTH_VACATION_END_ATTR_ID =
  '4a67438e-024e-4dc9-9a38-4c212ebfdf40';
export const FIFTH_VACATION_TYPE_ATTR_ID =
  '942b3b4c-9430-4b80-ba50-e4ffa986cf95';
export const FIFTH_VACATION_DAYS_COUNT = '7ea9d077-77ca-4640-9d41-ecb3169aa6a0';

export const BUSINESS_TRIP_START_ATTR_ID =
  '293864ad-fcba-4bb6-90d9-1c9ee01e53fc';

export const BUSINESS_TRIP_END_ATTR_ID = '139ce23d-99be-4d2f-923b-e5738dfb6961';
export const TRIVIO_FORM_ATTR_ID = '1191e303-31dc-48c2-98fd-3a37fac7efbd';
export const VACATION_PLACE_ID = 'c80ffeea-4544-486b-964a-95930caceb1a';
export const ABSENCES_TYPE_ID = '68747600-82df-45b2-b434-52bde1cce4e7';
export const COMPENSATION_ID = '6f796e54-8a3a-41ab-a0e8-e579e787f23f';

export const ABSENCES_TAG = 'отсутствие';
