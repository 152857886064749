import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsGroupsRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsGroupsRouterService implements SettingsGroupsRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToAddRole(companyId: string) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.groups.basename
      }/${companyId}/add-role`,
    );
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(
        `/${this.role.get()}/${modules.settings.basename}/${
          modules.settings.children.groups.basename
        }`,
      );
      return;
    }

    if (query.startsWith('#')) {
      this.history.push({
        pathname: `/${this.role.get()}/${modules.settings.basename}/${
          modules.settings.children.groups.basename
        }`,
        hash: query,
      });
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.groups.basename
      }`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
