/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';

import { ThunkExtra } from '@app/store';
import { Widget } from '@app/gen/dashboard';

import { DashboardService } from '../../services';
import { DashboardState } from '../dashboard.state';

const MARGIN_BOTTOM = 24;

export const getDashboardLayout = createAsyncThunk<Widget[], void, ThunkExtra>(
  'dashboard/getDashboard',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(DashboardService).getMainLayout();
      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const resetDashboardLayout = createAction<void>(
  'dashboard/resetDashboard',
);

export const setNewHeight = createAction<{
  name: string;
  height: number;
}>('dashboard/setNewHeight');

export const dashboardDetailReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(getDashboardLayout.fulfilled, (state, { payload }) => {
    state.layout = payload;
    state.status = 'complete';
  });
  builder.addCase(getDashboardLayout.pending, (state) => {
    state.status = 'loading';
  });
  builder.addCase(resetDashboardLayout, (state) => {
    state.status = 'idle';
  });
  builder.addCase(setNewHeight, (state, { payload }) => {
    state.layout = state.layout.map((item) =>
      item.type === payload.name
        ? {
            ...item,
            position: {
              ...item.position,
              height: payload.height + MARGIN_BOTTOM,
            },
          }
        : item,
    );
  });
};
