import React, { useState, useCallback } from 'react';

import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { AttachFile as AttachFileIcon, Close } from '@material-ui/icons';

import { AppError } from '@vk-hr-tek/core/error';
import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { FilePreview } from '@vk-hr-tek/ui/FilePreview';
import { FilePreviewWithSnackbar } from '@vk-hr-tek/ui/FilePreviewWithSnackbar';
import { FormError, TextInput } from '@vk-hr-tek/ui/form';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { Link } from '@vk-hr-tek/ui/Link';
import { Redo } from '@vk-hr-tek/ui/Redo';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useDispatch, useSelector } from '@app/hooks';

import { useLogout } from '../../../auth';
import {
  initCertificateCreation,
  verifyCertificateCreation,
} from '../../slice';
import { VerifyCodeDto } from '../../dto';
import { UserApiService } from '../../services';
import { Page } from '../../ui/page';

export const CertificateVerifying = () => {
  const dispatch = useDispatch();
  const logout = useLogout();
  const service = useInject(UserApiService);
  const validator = useInject(ValidationService);
  const isDesktop = useIsDesktop();

  const authInstance = useSelector(selectAuthInstance);

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [resendError, setResendError] = useState<AppError | null>(null);

  const loadUnep = useCallback(async () => {
    const res = await service.getUnep();
    return res.file;
  }, [service]);

  const handleClose = () => {
    setIsDialogOpened(false);
  };

  const resendCode = () => {
    setResendError(null);

    dispatch(
      initCertificateCreation({
        actions: {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          resolve: (value: any) => {
            if (value[FORM_ERROR]) {
              setResendError(value[FORM_ERROR] as AppError);
            }
          },
        },
      }),
    );
  };

  const onSubmit = (values: VerifyCodeDto) =>
    new Promise((resolve) => {
      setResendError(null);

      dispatch(
        verifyCertificateCreation({
          values,
          actions: { resolve },
        }),
      );
    });

  return (
    <Page title="Выпуск электронной подписи">
      <Form
        validate={(values) =>
          validator.validate(values, VerifyCodeDto, ['code-verify'])
        }
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              mb="8"
              px="24"
              py="16"
              bgcolor="bg.greyscale.primary"
              radius="l"
            >
              <FilePreview
                onLoad={loadUnep}
                view="link"
                isClickable
                showSnackbarOnDesktop
                snackbarMessage="Заявление на выдачу УНЭП"
                snackbarAction="Ok"
                linkText={
                  <Box display="flex" alignItems="center">
                    <AttachFileIcon />
                    <Box ml="8" display="inline">
                      Заявление на выдачу электронной подписи
                    </Box>
                  </Box>
                }
              />
            </Box>
            <Box mt="20">
              <TextInput
                name="code"
                label="СМС-код"
                placeholder="Введите СМС-код"
                type="number"
                inputMode="numeric"
                autocomplete="one-time-code"
                id="qaUserCreateCertificateVerifyCodeInput"
              />
            </Box>
            <Redo label="Отправить код повторно" action={resendCode} />
            <Box mt="20">
              <Button
                className="qaSubmitButton"
                disabled={pristine || hasValidationErrors}
                fullWidth
                loading={submitting}
                id="qaUserCreateCertificateVerifyCodeSubmit"
                onClick={() => setIsDialogOpened(true)}
                size="large"
              >
                Подтвердить
              </Button>
            </Box>
            {!dirtySinceLastSubmit && submitError && (
              <FormError error={submitError} />
            )}
            {!!resendError && <FormError error={resendError} />}
            {authInstance.auth_type === 'pass' && (
              <Box mt="20">
                <Link onClick={logout} size="small" variant="tertiary">
                  Выход к QR-коду
                </Link>
              </Box>
            )}

            <FilePreviewWithSnackbar
              mt={{ xs: 5.2, md: 3.2 }}
              mb={{ xs: 6.4, sm: 6.4, md: 9.8 }}
              open={isDialogOpened}
              onClose={handleClose}
              fullScreen={!isDesktop}
              loadDocument={loadUnep}
              snackbarMessage="Заявление на выдачу УНЭП"
              action={
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    disabled={submitting || pristine || hasValidationErrors}
                    loading={submitting}
                    onClick={handleSubmit}
                    size="small"
                  >
                    Ок
                  </Button>
                </Box>
              }
              closeIcon={
                !isDesktop && (
                  <Box position="absolute" p="20" top={0} right={0}>
                    <Button
                      icon={<Close />}
                      onClick={handleClose}
                      size="large"
                    />
                  </Box>
                )
              }
            />
          </form>
        )}
      />
    </Page>
  );
};
