import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './downloadNotification.state';
import { downloadNotificationReducers } from './downloadNotification.actions';

export const downloadNotification = createSlice({
  name: 'downloadNotification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    downloadNotificationReducers(builder);
  },
});

export const downloadNotificationReducer = downloadNotification.reducer;
