import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './chat.state';
import { chatReducers } from './chat.actions';

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    chatReducers(builder);
  },
});

export const chatReducer = chat.reducer;
