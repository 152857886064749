import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsCompanyUnepTypesRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsCompanyUnepTypesRouterService
  implements SettingsCompanyUnepTypesRouter
{
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToUnepTypes() {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.unep_types.basename
      }`,
    );
  }

  goToUnepTypeEdit(id: string) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.unep_types.basename
      }/${id}/edit`,
    );
  }
}
