import { makeStyles, Theme } from '@material-ui/core';

import { SpacingParams } from './FilePreviewWithSnackbar.types';

interface StylesProps {
  mt: SpacingParams;
  mb: SpacingParams;
}

const useStyles = makeStyles<Theme, StylesProps>(
  ({ breakpoints, spacing }) => ({
    paper: ({ mt, mb }) => ({
      '&, &.MuiDialog-paperFullScreen.MuiDialog-paperScrollBody': {
        [breakpoints.up('xs')]: {
          marginTop: spacing(mt.xs ?? 0),
          marginBottom: spacing(mb.xs ?? 0),
        },

        [breakpoints.up('sm')]: {
          marginTop: spacing(mt.sm ?? 0),
          marginBottom: spacing(mb.sm ?? 0),
        },

        [breakpoints.up('md')]: {
          marginTop: spacing(mt.md ?? 0),
          marginBottom: spacing(mb.md ?? 0),
        },
      },

      '&.MuiDialog-paperFullScreen': {
        [breakpoints.up('xs')]: {
          maxHeight: `calc(100% - ${
            (mt.xs ? mt.xs * 10 : 0) + (mb.xs ? mb.xs * 10 : 0)
          }px)`,
        },

        [breakpoints.up('sm')]: {
          maxHeight: `calc(100% - ${
            (mt.sm ? mt.sm * 10 : 0) + (mb.sm ? mb.sm * 10 : 0)
          }px)`,
        },

        [breakpoints.up('md')]: {
          maxHeight: `calc(100% - ${
            (mt.md ? mt.md * 10 : 0) + (mb.md ? mb.md * 10 : 0)
          }px)`,
        },
      },
    }),
  }),
);

export default useStyles;
