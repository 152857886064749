import { IsNotEmpty, IsUUID, IsString } from 'class-validator';

import type { UnitType } from '@vk-hr-tek/core/units';

export class GetEventCompanyUnitTreeDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsString()
  @IsNotEmpty()
  unitType: UnitType;
}
