import { IsString, IsNotEmpty, IsUUID, IsOptional } from 'class-validator';

import { IsSnils } from '@vk-hr-tek/core/validation';

const invitCodeValidationOptions = {
  groups: ['load'],
  message: 'Неверный инвайт-код',
};

export class VerifyInviteDto {
  @IsOptional()
  @IsSnils({
    groups: ['form'],
    message: 'Неверный СНИЛС',
  })
  @IsString({
    groups: ['form'],
    message: 'Вы забыли ввести СНИЛС',
  })
  snils?: string;

  @IsUUID(undefined, invitCodeValidationOptions)
  @IsString(invitCodeValidationOptions)
  @IsNotEmpty(invitCodeValidationOptions)
  inviteCode?: string;
}
