/* eslint-disable @typescript-eslint/no-explicit-any */
import { injectable } from 'inversify';
import { validate, ValidationError } from 'class-validator';
import { plainToClass, classToPlain } from 'class-transformer';

import { AppError } from '../error';
import { Logger } from '../logger';

@injectable()
export class UnmarshallerService {
  constructor(private readonly logger: Logger) {}

  parseErrors(
    validationErrors: ValidationError[],
    path = '',
  ): Record<string, Record<string, string>>[] {
    return validationErrors.reduce((acc, error) => {
      if (!error.children?.length) {
        return [
          ...acc,
          {
            [`${path}.${error.property}`]: error.constraints as Record<
              string,
              string
            >,
          },
        ];
      }

      return [
        ...acc,
        ...this.parseErrors(error.children, `${path}.${error.property}`),
      ];
    }, [] as Record<string, Record<string, string>>[]);
  }

  /** Выполнить приведение объекта свободной формы (поступающего из ответа сервера) к указанному типу конструктора
   * Валидация и логирование - это инструменты для этого приведения к типу конструктора.
   * @template T
   * @param {unknown} dto Объект, проходящий проверку на соответствие классу
   * @param {new () => T} constructor Класс для сравнения
   * @returns {Promise<T>}
   * */
  async unmarshall<T extends { [key: string]: any }>(
    dto: unknown,
    constructor: new () => T,
  ): Promise<T> {
    if (typeof dto !== 'object' || Array.isArray(dto) || dto === null) {
      if (window.REACT_APP_VKHRTEK_VALIDATE_RESPONSES) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      try {
        const tags = (dto as any).__context__ || null;

        this.logger.error('[validation] Invalid response dto', {
          ...(tags ? { tags } : {}),
          context: {
            unmarshaller_dto: {
              value: dto,
            },
          },
        });
      } catch (e) {
        this.logger.error(e);
      }

      this.logger.fatal('[validation] Invalid ');
    } else {
      try {
        const value = constructor
          ? plainToClass(constructor, dto as T)
          : (dto as T);

        const errors = await validate(value);

        if (errors?.length) {
          const tags = (dto as any).__context__ || null;

          this.logger.warning(
            `[validation] Invalid response schema ${
              tags?.http_method ? `[${tags?.http_method}]` : ''
            } ${tags?.http_url || ''}`,
            {
              ...(tags ? { tags } : {}),
              context: {
                unmarshaller_errors: {
                  value: this.parseErrors(errors),
                },
                ...(constructor
                  ? {
                      unmarshaller_value:
                        constructor &&
                        classToPlain(
                          plainToClass(constructor, dto as T, {
                            groups: ['sensitive'],
                          }),
                        ),
                    }
                  : {}),
              },
            },
          );
        }
      } catch (e) {
        this.logger.fatal(e);
      }
    }

    return dto as T;
  }
}
