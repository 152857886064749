import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ tokens }) => ({
  multipleRadioInput: {
    width: '100%',
    marginBottom: tokens.spacing['16'],

    '& .MuiFormGroup-row': {
      marginBottom: 0,
    },
  },
}));

export default useStyles;
