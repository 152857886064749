import React from 'react';

import {
  Field,
  FieldInputProps,
  FieldProps,
  FieldRenderProps,
} from 'react-final-form';

import { SuggestedInput as Input } from '../../input';

import { SuggestedInputProps } from './SuggestedInput.types';

const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange?: (value: string | undefined) => void,
  ) =>
  (value: string | undefined) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

export const SuggestedInput = (
  props: SuggestedInputProps &
    FieldProps<string | undefined, FieldRenderProps<string | undefined>>,
) => {
  if (props.type === 'load') {
    const {
      type,
      name,
      label,
      onChange,
      placeholder,
      disabled = false,
      required = false,
      clearable = false,
      loading = false,
      tooltip,
      helperText,
      renderOption,
      limit = 50,
      minInputValueLength = 1,
      loadItems,
      mask,
      ...rest
    } = props;

    return (
      <Field name={name} {...rest}>
        {({ input, meta }) => {
          return (
            <Input
              {...input}
              type={type}
              label={label}
              onChange={handleChange(input, onChange)}
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              clearable={clearable}
              loading={loading}
              tooltip={tooltip}
              error={meta.touched && !!meta.error}
              helperText={(meta.touched && meta.error) || helperText}
              renderOption={renderOption}
              loadItems={props.loadItems}
              limit={limit}
              mask={mask}
              minInputValueLength={minInputValueLength}
            />
          );
        }}
      </Field>
    );
  }

  const {
    type,
    name,
    label,
    onChange,
    placeholder,
    disabled = false,
    required = false,
    clearable = false,
    loading = false,
    tooltip,
    helperText,
    renderOption,
    limit = 50,
    minInputValueLength = 1,
    externalItems,
    ...rest
  } = props;

  return (
    <Field name={name} {...rest}>
      {({ input, meta }) => (
        <Input
          {...input}
          type={type}
          label={label}
          externalItems={externalItems}
          onChange={handleChange(input, onChange)}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          clearable={clearable}
          loading={loading}
          tooltip={tooltip}
          error={meta.touched && !!meta.error}
          helperText={(meta.touched && meta.error) || helperText}
          renderOption={renderOption}
          limit={limit}
          minInputValueLength={minInputValueLength}
        />
      )}
    </Field>
  );
};
