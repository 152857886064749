import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsTemplatesRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsTemplatesRouterService implements SettingsTemplatesRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToDetail({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.templates.basename
      }/${id}?companyId=${companyId}`,
    );
  }

  goToDetailPreview({ id, companyId }: { id: string; companyId: string }) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.templates.basename
      }/${id}/preview?companyId=${companyId}`,
    );
  }

  goToDetailPreviewUpload({
    id,
    companyId,
  }: {
    id: string;
    companyId: string;
  }) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.templates.basename
      }/${id}/preview-upload?companyId=${companyId}`,
    );
  }

  goToList(query?: string) {
    if (!query || query === '?') {
      this.history.push(
        `/${this.role.get()}/${modules.settings.basename}/${
          modules.settings.children.templates.basename
        }`,
      );
      return;
    }

    this.history.push({
      pathname: `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.templates.basename
      }`,
      search: query.startsWith('?') ? query : `?${query}`,
    });
  }
}
