import React from 'react';

import { SvgIcon, SvgIconProps, useTheme } from '@material-ui/core';

export const EducationIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      fontSize="inherit"
      {...props}
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H36C42.6274 0 48 5.37258 48 12V36C48 42.6274 42.6274 48 36 48H12C5.37258 48 0 42.6274 0 36V12Z"
        fill={theme.tokens.colors.bg.greyscale.primary}
      />
      <path
        d="M24.7208 14.5852L34.0209 19.6849C34.5179 19.9574 34.7994 20.4703 34.8 20.9998L34.7996 21.0001V27.3001C34.7996 27.7972 34.3967 28.2001 33.8996 28.2001C33.4025 28.2001 32.9996 27.7972 32.9996 27.3001L32.9992 22.8748L31.1992 23.8612L31.1996 27.6001C31.1996 30.9607 27.9391 33.6001 23.9996 33.6001C20.0601 33.6001 16.7996 30.9607 16.7996 27.6001L16.7992 23.8612L13.9783 22.3153C13.2519 21.917 12.9859 21.0053 13.3843 20.2789C13.5216 20.0283 13.7277 19.8223 13.9783 19.6849L23.2784 14.5852C23.7276 14.3388 24.2716 14.3388 24.7208 14.5852ZM29.3992 24.8488L24.7208 27.4151C24.2716 27.6614 23.7276 27.6614 23.2784 27.4151L18.5992 24.8488L18.5996 27.6001C18.5996 29.8728 20.9803 31.8001 23.9996 31.8001C27.0189 31.8001 29.3996 29.8728 29.3996 27.6001L29.3992 24.8488ZM23.9996 16.2425L15.3234 21.0001L23.9996 25.7577L32.6757 21.0001L23.9996 16.2425Z"
        fill={theme.tokens.colors.stroke.tertirary}
      />
    </SvgIcon>
  );
};
