import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

export const LinkIcon = () => {
  const { tokens } = useTheme();

  return (
    <SvgIcon viewBox="0 0 20 20" fontSize="inherit">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 3.75C4.75 3.19772 5.19772 2.75 5.75 2.75H12.25C12.8023 2.75 13.25 3.19772 13.25 3.75V10.25C13.25 10.8023 12.8023 11.25 12.25 11.25C11.6977 11.25 11.25 10.8023 11.25 10.25V4.75H5.75C5.19772 4.75 4.75 4.30228 4.75 3.75Z"
        fill={tokens.colors.stroke.secondary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7071 3.29289C13.0976 3.68342 13.0976 4.31658 12.7071 4.70711L4.45711 12.9571C4.06658 13.3476 3.43342 13.3476 3.04289 12.9571C2.65237 12.5666 2.65237 11.9334 3.04289 11.5429L11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289Z"
        fill={tokens.colors.stroke.secondary}
      />
    </SvgIcon>
  );
};
