import { createTheme as MUICreateTheme } from '@material-ui/core/styles';
import { merge, cloneDeep } from 'lodash';

import { Tokens, Theme } from '../types';
import {
  generalTokens,
  generalDarkTokens,
  otpTokens,
  vkTokens,
  vkDarkTokens,
} from '../tokens';
import fonts from '../fonts';

import { createPalette } from './createPalette';

export const createTheme = (options: Theme = {}) => {
  const {
    portal,
    useContainerQuery = false,
    theme = 'general',
    tokens: tokensOverride = {},
  } = options;
  let baseTokens: Tokens = generalTokens;

  if (theme === 'vk') {
    baseTokens = vkTokens;
  } else if (theme === 'otp') {
    baseTokens = otpTokens;
  } else if (theme === 'general-dark') {
    baseTokens = { ...generalDarkTokens, name: 'general' };
  } else if (theme === 'vk-dark') {
    baseTokens = { ...vkDarkTokens, name: 'vk' };
  }

  const mergedTokens: Tokens = merge(cloneDeep(baseTokens), tokensOverride);
  const tokens: Tokens = merge(cloneDeep(mergedTokens), {
    spacing: {
      '0': mergedTokens.spacing['0'] * mergedTokens.density,
      '2': mergedTokens.spacing['2'] * mergedTokens.density,
      '4': mergedTokens.spacing['4'] * mergedTokens.density,
      '8': mergedTokens.spacing['8'] * mergedTokens.density,
      '12': mergedTokens.spacing['12'] * mergedTokens.density,
      '16': mergedTokens.spacing['16'] * mergedTokens.density,
      '20': mergedTokens.spacing['20'] * mergedTokens.density,
      '24': mergedTokens.spacing['24'] * mergedTokens.density,
      '32': mergedTokens.spacing['32'] * mergedTokens.density,
      '40': mergedTokens.spacing['40'] * mergedTokens.density,
      '48': mergedTokens.spacing['48'] * mergedTokens.density,
      '56': mergedTokens.spacing['56'] * mergedTokens.density,
      '72': mergedTokens.spacing['72'] * mergedTokens.density,
      '96': mergedTokens.spacing['96'] * mergedTokens.density,
      '144': mergedTokens.spacing['144'] * mergedTokens.density,
    },
  });

  const px = (pixels: number) => {
    return pixels * tokens.density;
  };

  const typeCssQuery = useContainerQuery ? `@container` : '@media';

  return MUICreateTheme({
    props: {
      MuiUseMediaQuery: {
        noSsr: true,
      },
      ...(portal?.current
        ? {
            MuiModal: {
              container: portal.current,
              disableEnforceFocus: true,
            },
            MuiPopover: {
              container: portal.current,
            },
            MuiPopper: {
              container: portal.current,
            },
            MuiPortal: {
              container: portal.current,
            },
          }
        : {}),
    },
    tokens,
    px,
    breakpoints: {
      keys: ['xs', 'sm', 'md', 'lg', 'xl'],
      up: (key) =>
        `${typeCssQuery} (min-width:${
          typeof key === 'number' ? key : tokens.breakpoints[key]
        }px)`,
      down: (key) =>
        `${typeCssQuery} (max-width:${
          typeof key === 'number' ? key : tokens.breakpoints[key]
        }px)`,
    },
    typography: {
      fontFamily: tokens.typography.fontFace,
    },
    spacing: 10,
    palette: createPalette(tokens),
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': fonts,
          a: {
            color: tokens.colors.text.light.primary,
            '&:hover': {
              textDecoration: 'none',
            },
          },
          canvas: {
            border: `${tokens.border.s}px solid ${tokens.colors.stroke.secondary}`,
          },
          b: {
            color: tokens.colors.text.light.primary,
          },
        },
      },
      MuiSvgIcon: {
        colorDisabled: {
          color: tokens.colors.stroke.secondary,
        },
      },
      MuiButtonGroup: {
        root: {
          borderRadius: tokens.radius.l,
        },
      },
      MuiButton: {
        root: {
          boxShadow: 'none',
          fontWeight: tokens.typography.variant.h6.fontWeight,
          borderRadius: tokens.radius.l,
          fontSize: tokens.typography.variant.h6.fontSize,
          textTransform: 'none',
          padding: `${tokens.spacing['12']}px ${tokens.spacing['24']}px`,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          '&:hover, &:active': {
            boxShadow: 'none',
          },
          '&:disabled': {
            background: tokens.colors.bg.greyscale.primary,
            color: tokens.colors.text.light.tertirary,
          },
        },
        sizeLarge: {
          padding: `${tokens.spacing['16']}px ${tokens.spacing['24']}px`,
        },
        sizeSmall: {
          padding: `${tokens.spacing['4']}px ${tokens.spacing['12']}px`,
          fontSize: tokens.typography.variant.body3.fontSize,
          fontWeight: tokens.typography.variant.body3.fontWeight,
          borderRadius: tokens.radius.m,
        },
      },
      MuiTypography: {
        h3: {
          fontWeight: tokens.typography.variant.h5.fontWeight,
          fontSize: tokens.typography.variant.h5.fontSize,
          lineHeight: tokens.typography.variant.h5.lineHeight,
          letterSpacing: tokens.typography.variant.h5.letterSpacing,
          [`@media (min-width: ${tokens.breakpoints.md}px)`]: {
            fontSize: tokens.typography.variant.h4.fontSize,
            lineHeight: tokens.typography.variant.h4.lineHeight,
          },
        },
        h4: {
          letterSpacing: tokens.typography.variant.h4.letterSpacing,
        },
        h5: {
          fontWeight: tokens.typography.variant.h5.fontWeight,
        },
        h6: {
          fontWeight: tokens.typography.variant.h6.fontWeight,
          fontSize: tokens.typography.variant.h6.fontSize,
          lineHeight: tokens.typography.variant.h6.lineHeight,
        },
        body1: {
          fontSize: tokens.typography.variant.body2.fontSize,
          lineHeight: tokens.typography.variant.body3.lineHeight,
          [`@media (min-width: ${tokens.breakpoints.md}px)`]: {
            fontSize: tokens.typography.variant.body1.fontSize,
            lineHeight: tokens.typography.variant.body1.lineHeight,
          },
        },
        body2: {
          color: tokens.colors.text.light.tertirary,
          fontSize: tokens.typography.variant.body2.fontSize,
          lineHeight: tokens.typography.variant.body3.lineHeight,
          [`@media (min-width: ${tokens.breakpoints.md}px)`]: {
            fontSize: tokens.typography.variant.body1.fontSize,
            lineHeight: tokens.typography.variant.body1.lineHeight,
          },
        },
      },
      MuiInputLabel: {
        root: {
          textTransform: 'none',
          fontSize: tokens.typography.variant.body2.fontSize,
          lineHeight: tokens.typography.variant.body3.lineHeight,
          color: tokens.colors.text.light.tertirary,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: tokens.radius.l,
        },
      },
      MuiAppBar: {
        root: {
          padding:
            tokens.name === 'vk'
              ? `0 0 ${tokens.spacing['2']}px`
              : `${tokens.spacing['2']}px 0`,
          boxShadow: tokens.shadow.xs,
        },
        colorPrimary: {
          backgroundColor: tokens.colors.bg.light.tertirary,
          color: 'inherit',
        },
      },
      MuiListItem: {
        button: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiTable: {
        root: {
          borderCollapse: 'separate',
        },
      },
      MuiTableCell: {
        root: {
          background: tokens.colors.bg.light.primary,
          borderBottom: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
          '&:first-child': {
            borderLeft: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
          },
          '&:last-child': {
            borderRight: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
          },
        },
        head: {
          color: tokens.colors.text.light.tertirary,
          borderTop: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
          '&:first-child': {
            borderTopLeftRadius: tokens.radius.m,
          },
          '&:last-child': {
            borderTopRightRadius: tokens.radius.m,
          },
        },
      },
      MuiTooltip: {
        tooltip: {
          background: tokens.colors.bg.light.primary,
          fontSize: tokens.typography.variant.body3.fontSize,
          color: 'inherit',
          fontWeight: tokens.typography.variant.body3.fontWeight,
          boxShadow: tokens.shadow.s,
        },
      },
      MuiAvatar: {
        colorDefault: {
          backgroundColor: tokens.colors.original.brand.primary,
        },
      },
      MuiPaper: {
        elevation0: {
          border: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
        },
        elevation1: {
          border: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
          boxShadow: 'none',
        },
        elevation2: {
          boxShadow: tokens.shadow.l,
        },
        rounded: {
          borderRadius: tokens.radius.l,
          overflow: 'hidden',
        },
      },
      ...{
        MuiAutocomplete: {
          endAdornment: {
            '& svg': {
              color: tokens.colors.text.light.secondary,
            },
          },
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          color: tokens.colors.text.light.secondary,
        },
      },
      MuiFormControlLabel: {
        labelPlacementStart: {
          margin: 0,
        },
      },
      MuiSwitch: {
        root: {
          width: px(48),
          height: px(24),
          padding: 0,
          borderRadius: tokens.radius.m,
          marginLeft: px(10), // TODO - fix нет в токенах
        },
        colorPrimary: {
          '&.Mui-checked': {
            color: tokens.colors.text.dark.primary,
            transform: `translateX(${px(24)}px)`,
          },
        },
        switchBase: {
          padding: tokens.spacing['4'],
          '&.Mui-checked': {
            transform: `translateX(${px(24)}px)`,
            '&+.MuiSwitch-track': {
              opacity: 1,
              backgroundColor: tokens.colors.original.brand.primary,
            },
          },
        },
        thumb: {
          width: px(16),
          height: px(16),
          borderRadius: tokens.radius.m,
        },
        track: {
          opacity: 1,
          backgroundColor: tokens.colors.text.light.tertirary,
        },
      },
      MuiCheckbox: {
        root: {
          color: tokens.colors.original.brand.quaternary,
          '&$disabled': {
            color: tokens.colors.bg.greyscale.primary,
          },
        },
        indeterminate: {
          color: tokens.colors.original.brand.primary,
        },
      },
      MuiChip: {
        root: {
          borderRadius: tokens.radius.m,
        },
      },
      ...{
        MuiPickersCalendarHeader: {
          switchHeader: {
            padding: tokens.spacing['16'],
            background: tokens.colors.bg.greyscale.primary,
            marginTop: 0,
          },
          iconButton: {
            padding: 0,
            color: tokens.colors.original.brand.primary,
            borderRadius: tokens.radius.s,
            border: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
            backgroundColor: 'none',
          },
          daysHeader: {
            padding: `0 ${tokens.spacing['16']}px`,
          },
          dayLabel: {
            textTransform: 'uppercase',
            color: tokens.colors.text.light.tertirary,
            width: px(32),
            '&:nth-child(6), &:nth-child(7)': {
              color: tokens.colors.accent.text.error,
            },
          },
        },
        MuiPickersCalendar: {
          week: {
            padding: `0 ${tokens.spacing['16']}px`,
          },
          transitionContainer: {
            minHeight: px(178),
            marginTop: px(5), // TODO fix - нет в токенах
          },
        },
        MuiPickersDay: {
          day: {
            margin: 0,
          },
        },
      },
    },
  });
};
