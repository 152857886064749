import { IsNotEmpty, IsUUID } from 'class-validator';

import type { FileInputValue } from '@vk-hr-tek/ui/input';

import { FormAttributesRequest } from '../../types';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class UploadDocumentDto {
  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  eventId: string;

  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  nodeId: string;

  attributes?: FormAttributesRequest;

  @IsNotEmpty(notEmptyOptions)
  document: FileInputValue;

  documentDate?: string;

  documentNumber?: string;
}
