import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import {
  ExitToApp as LogoutIcon,
  KeyboardArrowDown as DownIcon,
} from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Avatar } from '@vk-hr-tek/ui/Avatar';
import { Box } from '@vk-hr-tek/ui/Box';
import { UserApiService } from '@vk-hr-tek/app/user/services';

import { useSelector } from '@app/hooks';

import { selectFullName, selectUser } from '../../../user';
import { UserCard } from '../common/UserCard';
import { UserRouter } from '../../../user/types';

import { LogoutConfirmation } from './LogoutConfirmation';
import { useStyles } from './User.styles';

const stringAvatar = (name: string) =>
  `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`;

interface UserProps {
  userRepresentative: boolean;
}

export const User = ({ userRepresentative = false }: UserProps) => {
  const classes = useStyles();
  const router = useInject<UserRouter>(UserRouter);
  const userApiService = useInject(UserApiService);

  const userFullName = useSelector(selectFullName);
  const user = useSelector(selectUser);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const loadPhoto = useCallback(async () => {
    const res = await userApiService.getUserPhoto({ user_id: user?.id ?? '' });

    return res.file;
  }, [userApiService, user?.id]);

  const handleProfileMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuProfileClick = useCallback(() => {
    router.goToProfile();
    handleMenuClose();
  }, [router]);

  const handleMenuQuitClick = useCallback(() => {
    handleMenuClose();
    setIsConfirmationDialogOpen(true);
  }, []);

  const handleLogoutConfirmationClose = useCallback(
    () => setIsConfirmationDialogOpen(false),
    [],
  );

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  return (
    <Box display="flex" alignItems="center">
      <Box display="inline" ml="8">
        <div className={classes.avatar} onClick={handleProfileMenuOpen}>
          <Avatar
            loadPhoto={loadPhoto}
            children={stringAvatar(userFullName || '')}
          />
          <DownIcon className={classes.icon} />
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 60,
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        getContentAnchorEl={null}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ list: classes.menu }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {(!window.REACT_APP_VKHRTEK_LK_DISABLED || userRepresentative) && (
          <UserCard
            onClick={handleMenuProfileClick}
            userFullName={userFullName || ''}
          />
        )}

        <MenuItem
          onClick={handleMenuQuitClick}
          className={classNames(classes.link, classes.link_type_logout)}
        >
          <Box display="inline" mr="8" lineHeight={1}>
            <LogoutIcon />
          </Box>
          Выйти
        </MenuItem>
      </Menu>
      <LogoutConfirmation
        onClose={handleLogoutConfirmationClose}
        open={isConfirmationDialogOpen}
      />
    </Box>
  );
};
