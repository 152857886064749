import React, { ReactElement, Children } from 'react';

import { ButtonGroup as MUIButtonGroup } from '@material-ui/core';
import classNames from 'classnames';

import { ButtonSize } from '../Button';

import { useStyles } from './ButtonGroup.styles';

interface ButtonGroupProps {
  className?: string;
  id?: string;
  children: ReactElement[] | ReactElement;
  orientation?: 'horizontal' | 'vertical';
  variant?: 'contained' | 'silver';
  separated?: boolean;
  size?: ButtonSize;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export const ButtonGroup = ({
  className,
  children,
  orientation = 'horizontal',
  variant = 'contained',
  separated = false,
  size = 'medium',
  disabled = false,
  ...props
}: ButtonGroupProps) => {
  const classes = useStyles();

  const newChildren = Children.map(children, (childElement: ReactElement) => {
    const { className: classNameElement, ...propsElement } = childElement.props;

    const buttonType = childElement.type as { name?: string };
    const isButton = buttonType?.name === 'Button';

    return {
      ...childElement,
      props: {
        ...propsElement,
        size,
        className: classNames(
          classNameElement,
          classes.element,
          variant === 'contained' && classes.elementContained,
          variant === 'silver' && classes.elementSilver,
          separated && classes.elementSeparated,
          isButton && variant === 'contained' && classes.buttonContainedHover,
          isButton && variant === 'silver' && classes.buttonSilverHover,
        ),
      },
    };
  });

  const buttonGroupStyles = classNames(
    classes.buttonGroup,
    separated && classes.buttonGroupSeparated,
    variant === 'silver' && classes.buttonGroupSilver,
    variant === 'silver' && disabled && classes.buttonGroupSilverDisabled,
    size === 'large' && classes.sizeLarge,
    className,
    'aqa_button_group',
  );

  return (
    <MUIButtonGroup
      {...props}
      className={buttonGroupStyles}
      children={newChildren}
      disabled={disabled}
      orientation={orientation}
      variant="contained"
    />
  );
};
