import { injectable } from 'inversify';

import { FormatService } from '../../format';
import { CryptoCertificate } from '../types';

@injectable()
export class CryptoMapper {
  constructor(private format: FormatService) {}

  processCertificates(certificates: CryptoCertificate[]) {
    return certificates.map((certificate) => ({
      ...certificate,
      activeFrom: this.format.toDate(certificate.activeFrom),
      activeTo: this.format.toDate(certificate.activeTo),
    }));
  }
}
