import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  IsBoolean,
  IsUUID,
} from 'class-validator';

export class GetOptionsDto {
  @IsString()
  @IsOptional()
  eventTypeGroup?: string;

  @IsUUID()
  @IsString()
  @IsOptional()
  parentEventId?: string;
}

export class GetOptionsEmployees {
  @IsNotEmpty()
  @IsString()
  companyId: string;

  @IsOptional()
  @IsNumber()
  limit?: number;

  @IsOptional()
  @IsNumber()
  offset?: number;

  @IsOptional()
  @IsString()
  query?: string;

  @IsBoolean()
  @IsOptional()
  noEmployee?: boolean = false;

  @IsBoolean()
  @IsOptional()
  batchEnabled?: boolean = true;

  @IsString()
  @IsOptional()
  parentEventId?: string;
}
