import React, { useEffect } from 'react';

import HelpIcon from '@material-ui/icons/Help';

import { Accordion } from '@vk-hr-tek/ui/Accordion';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { ColoredChip } from '@vk-hr-tek/ui/ColoredChip';
import { Content } from '@vk-hr-tek/ui/Content';
import { ContentCopy } from '@vk-hr-tek/ui/ContentCopy';
import { Divider } from '@vk-hr-tek/ui/Divider';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { UsersIcon } from '@vk-hr-tek/ui/icons';
import { List, ListItem } from '@vk-hr-tek/ui/List';
import { Paper } from '@vk-hr-tek/ui/Paper';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { useDispatch, useSelector } from '@app/hooks';

import {
  selectUser,
  selectToken1CStatus,
  selectToken1CExpiresAt,
  selectToken1CExpiresAtStatus,
  selectToken1C,
  generate1CToken,
  get1CTokenStatus,
  reset1CToken,
} from '../../slice';

import { Attorney } from './Attorney';
import { AttorneySettingsNotification } from './AttorneySettingsNotification';

export const Roles = () => {
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();

  const user = useSelector(selectUser);
  const token1CStatus = useSelector(selectToken1CStatus);
  const token1C = useSelector(selectToken1C);
  const token1CExpiresAt = useSelector(selectToken1CExpiresAt);
  const token1CExpiresAtStatus = useSelector(selectToken1CExpiresAtStatus);

  const subtitleVariant = isDesktop ? 'subtitle1' : 'subtitle2';
  const textVariant = isDesktop ? 'body2' : 'body3';

  const isRenderTokenStatusLabel =
    token1CExpiresAtStatus === 'expired' || token1CExpiresAtStatus === 'active';

  const token1CStatusLabelColor =
    token1CExpiresAtStatus === 'expired' ? 'red' : 'gray';

  const handleClick = () => {
    if (token1CStatus !== 'loading') {
      dispatch(generate1CToken());
    }
  };

  useEffect(() => {
    if (token1CStatus !== 'loading') dispatch(get1CTokenStatus());
  }, [dispatch, token1CStatus]);

  useEffect(() => {
    return () => {
      dispatch(reset1CToken());
    };
  }, [dispatch]);

  const setTokenColorChipLabel = (status: string) => {
    switch (status) {
      case 'active':
        return `Последний день работы: ${token1CExpiresAt}`;

      case 'expired':
        return 'Истек срок годности';

      default:
        return '';
    }
  };

  if (!user) {
    return null;
  }

  return (
    <Content>
      <Accordion
        title={
          <Box display="flex">
            <Typography variant={isDesktop ? 'h5' : 'subtitle1'}>
              Роли и МЧД в компании
            </Typography>
            <Box display="flex" alignItems="center" ml="8">
              <Tooltip title="МЧД – машиночитаемая доверенность">
                <HelpIcon color="disabled" fontSize="medium" />
              </Tooltip>
            </Box>
          </Box>
        }
        defaultExpanded
      >
        <>
          <Box>
            {user.groups.map((group, index) => (
              <>
                <Box key={group.id}>
                  <Box mt="8" mb={{ xs: '16', md: '24' }}>
                    <Typography variant={isDesktop ? 'h6' : 'subtitle2'}>
                      {group.name}
                    </Typography>
                  </Box>
                  <Box mb={{ xs: '16', md: '32' }}>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <Box mb={{ xs: '8', md: '24' }}>
                          <Typography
                            variant={isDesktop ? 'body2' : 'body3'}
                            color="textTertiary"
                          >
                            Роль
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          gap="16"
                          mb={{ xs: '16', md: '24' }}
                        >
                          {group.roles.map(({ id, name, profiles }) => (
                            <Tooltip
                              disabled={!profiles}
                              title={
                                <Box fontSize={12}>
                                  <Box>
                                    <Typography
                                      color="inherit"
                                      variant="subtitle2"
                                    >
                                      Профили:
                                    </Typography>
                                  </Box>
                                  <Box mt="8" ml="24">
                                    <List listStyleType="disc">
                                      {profiles?.map((profile) => (
                                        <ListItem key={profile.id}>
                                          {profile.name}
                                        </ListItem>
                                      ))}
                                    </List>
                                  </Box>
                                </Box>
                              }
                              placement="right"
                            >
                              <Box key={id}>
                                <ColoredChip
                                  label={name}
                                  color="gray"
                                  endIcon={
                                    !!profiles && (
                                      <UsersIcon fontSize="inherit" />
                                    )
                                  }
                                />
                              </Box>
                            </Tooltip>
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} md={3}>
                        <Box mb={{ xs: '8', md: '0' }}>
                          <Typography
                            variant={isDesktop ? 'body2' : 'body3'}
                            color="textTertiary"
                          >
                            Список МЧД
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Box display="flex" flexWrap="wrap" gap="8">
                          {group.attorneys.length
                            ? group.attorneys.map((attorney) => (
                                <Box
                                  flexBasis={isDesktop ? 350 : '100%'}
                                  flexGrow={0}
                                  flexShrink={0}
                                >
                                  <Attorney key={attorney.id} {...attorney} />
                                </Box>
                              ))
                            : '-'}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {!!group.attorneys.filter(
                    (attorney) =>
                      attorney.status === 'signed' ||
                      attorney.status === 'revoking',
                  ).length && (
                    <AttorneySettingsNotification companyId={group.id} />
                  )}
                </Box>
                {user.groups.length - 1 !== index && (
                  <Box mx="-32" mb="32">
                    <Divider />
                  </Box>
                )}
              </>
            ))}
          </Box>
          <Box mx="-32" mt="0" mb="32">
            <Divider />
          </Box>
          <Box>
            <Box
              pb="20"
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              gap={isDesktop ? '16' : '8'}
            >
              <Typography variant="h6">Токен</Typography>

              {isRenderTokenStatusLabel && (
                <Box>
                  <ColoredChip
                    label={setTokenColorChipLabel(token1CExpiresAtStatus)}
                    color={token1CStatusLabelColor}
                  />
                </Box>
              )}
            </Box>
            <Paper>
              <Box p="20">
                <Box
                  display={isDesktop ? 'flex' : 'block'}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Box pb="8">
                      <Typography variant={subtitleVariant}>
                        Генерация токена для 1С
                      </Typography>
                    </Box>
                    <Typography variant={textVariant} color="textTertiary">
                      После генерации нового токена нужно будет заново
                      авторизоваться во всех 1С базах, где вы работаете с КЭДО
                    </Typography>
                  </Box>
                  <Box mt={!isDesktop ? '8' : '0'}>
                    <Button
                      onClick={handleClick}
                      size="small"
                      loading={token1CStatus === 'loading'}
                    >
                      {(token1CStatus === 'idle' ||
                        token1CStatus === 'failed') &&
                        'Сгенерировать'}
                      {token1CStatus === 'complete' && 'Сгенерировать еще раз'}
                    </Button>
                  </Box>
                </Box>
                {token1C && (
                  <>
                    <Box display="flex" alignItems="center" mt="16">
                      <Box mr="8">Токен</Box>
                      <Tooltip title="Токен виден, пока вы не обновите страницу (по требованиям безопасности)">
                        <HelpIcon color="disabled" fontSize="small" />
                      </Tooltip>
                    </Box>
                    <Box mt="8">
                      <ContentCopy content={token1C} />
                    </Box>
                  </>
                )}

                {token1CStatus === 'failed' && (
                  <Box mt="8">
                    <Typography color="error" variant="body3">
                      Во время генерации токена произошла ошибка. Попробуйте еще
                      раз.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
        </>
      </Accordion>
    </Content>
  );
};
