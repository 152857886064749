import React, { useCallback } from 'react';

import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { Box } from '../Box';
import { Typography } from '../Typography';

import { LinkSize, LinkColor, LinkStroke } from './Link.types';
import { useStyles } from './Link.styles';

export interface LinkBaseProps {
  size?: keyof typeof LinkSize;
  variant?: keyof typeof LinkColor;
  stroke?: LinkStroke;
  disabled?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

type RouterLinkProps = LinkBaseProps & {
  to: string;
};

type HrefLinkProps = LinkBaseProps & {
  href: string;
  target?: string;
};

type ButtonLinkProps = LinkBaseProps & {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
};

type LinkProps = RouterLinkProps | HrefLinkProps | ButtonLinkProps;

const isRouterProps = (props: LinkProps): props is RouterLinkProps =>
  Object.prototype.hasOwnProperty.call(props, 'to');
const isHrefProps = (props: LinkProps): props is HrefLinkProps =>
  Object.prototype.hasOwnProperty.call(props, 'href');
const isButtonProps = (props: LinkProps): props is ButtonLinkProps =>
  Object.prototype.hasOwnProperty.call(props, 'onClick');

const typographyMapping: Record<
  'large' | 'medium' | 'small' | 'extraSmall',
  'subtitle1' | 'subtitle2' | 'body3' | 'caption'
> = {
  large: 'subtitle1',
  medium: 'subtitle2',
  small: 'body3',
  extraSmall: 'caption',
};

export const Link = (props: LinkProps) => {
  const {
    size = 'medium',
    variant = 'primary',
    disabled = false,
    stroke = true,
    startIcon,
    endIcon,
    children,
  } = props;

  const classes = useStyles({
    size: LinkSize[size],
    color: LinkColor[variant],
    stroke: variant === 'simple' ? false : stroke,
    disabled,
  });

  const buttonOnClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      if (isButtonProps(props)) {
        props.onClick(e);
      }
    },
    [props],
  );

  const content = (
    <Box
      {...(variant === 'simple'
        ? { display: 'inline' }
        : { display: 'flex', alignItems: 'center' })}
    >
      {startIcon && (
        <Box
          className={classNames(classes.icon, children && classes.startIcon)}
        >
          {startIcon}
        </Box>
      )}
      {children && (
        <Typography
          color="inherit"
          component="span"
          variant={typographyMapping[size]}
          data-testid="link-typography"
        >
          {children}
        </Typography>
      )}
      {endIcon && (
        <Box className={classNames(classes.icon, children && classes.endIcon)}>
          {endIcon}
        </Box>
      )}
    </Box>
  );

  const styles = classNames(
    classes.link,
    isButtonProps(props) && props.className,
    // isButtonProps(props) && classes.linkLikeButton,
  );

  if (isRouterProps(props)) {
    return (
      <RouterLink to={props.to} className={styles} data-testid="router-link">
        {content}
      </RouterLink>
    );
  }

  if (isHrefProps(props)) {
    return (
      <a
        href={props.href}
        target={props.target}
        className={styles}
        data-testid="link-a"
      >
        {content}
      </a>
    );
  }

  return (
    <a
      href="#"
      className={styles}
      onClick={buttonOnClick}
      data-testid="link-btn"
    >
      {content}
    </a>
  );
};
