import {
  IsNotEmpty,
  IsNumber,
  IsUUID,
  IsString,
  IsOptional,
} from 'class-validator';

export class GetCompanyEmployeeSubstitutesDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsNumber()
  @IsNotEmpty()
  limit: number;

  @IsNumber()
  @IsNotEmpty()
  offset: number;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsString()
  @IsOptional()
  query: string;
}
