/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';

import { FieldInputProps, useField } from 'react-final-form';

import { MultipleAutocompleteInput as Input } from '../../input';

import {
  ValidateType,
  MultipleAutocompleteInputProps,
} from './MultipleAutocompleteInput.types';

const handleChange =
  (
    input: FieldInputProps<
      string | string[] | number | number[] | undefined,
      HTMLElement
    >,
    onChange: ((value: string[] | undefined) => void) | undefined,
  ) =>
  (value: string[] | undefined) => {
    input.onChange(value);

    onChange?.(value);
  };

export const MultipleAutocompleteInput = (
  props: MultipleAutocompleteInputProps,
) => {
  const {
    label,
    onChange,
    tooltip,
    name,
    placeholder,
    required,
    loading,
    limit,
    disabledTooltip,
    disabled,
    clearable,
    validate = [],
    helperText,
    minInputValueLength,
    renderTags,
    testId,
  } = props;

  const composeValidators = useCallback(
    (validators: ValidateType[]) =>
      (value: Record<string, any> | any, allValues: Record<string, any>) =>
        validators.reduce(
          (error: any, validator) => error || validator(value, allValues),
          undefined,
        ),
    [],
  );
  const { input, meta } = useField(name, {
    validate: composeValidators(validate),
  });

  if (props.type === 'load') {
    return (
      <Input
        {...input}
        type={props.type}
        label={label}
        onChange={handleChange(input, onChange)}
        loading={loading}
        tooltip={tooltip}
        placeholder={placeholder}
        required={required}
        limit={limit}
        disabledTooltip={disabledTooltip}
        disabled={disabled}
        clearable={clearable}
        error={meta.touched && !!meta.error}
        helperText={(meta.touched && meta.error) || helperText}
        loadItems={props.loadItems}
        minInputValueLength={minInputValueLength}
        renderTags={renderTags}
        testId={testId}
      />
    );
  }

  return (
    <Input
      {...input}
      type={props.type}
      label={label}
      onChange={handleChange(input, onChange)}
      loading={loading}
      tooltip={tooltip}
      placeholder={placeholder}
      required={required}
      limit={limit}
      disabledTooltip={disabledTooltip}
      disabled={disabled}
      clearable={clearable}
      error={meta.touched && !!meta.error}
      helperText={(meta.touched && meta.error) || helperText}
      externalItems={props.externalItems}
      selectAllLabel={props.selectAllLabel}
      allowSelectAll={props.allowSelectAll}
      minInputValueLength={minInputValueLength}
      renderTags={renderTags}
      testId={testId}
    />
  );
};
