import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';

export const PageLoading = () => (
  <Box display="flex" justifyContent="center" pt="40">
    <CircularProgress size={50} />
  </Box>
);
