import { useEffect, useRef, useState } from 'react';

/**
 * @typedef IntersectionObserverInitDocs
 * @type {object}
 * @property [root]
 * @property {string} [rootMargin]
 * @property {number|number[]} [threshold]
 */

/**
 * @typedef MutableRefObjectDocs
 * @type {object}
 * @property current
 */

/** Хук определяет явлется элемент позиционированным со значением "sticky"
 * @param {IntersectionObserverInitDocs} [options={}]
 * @returns {[MutableRefObjectDocs, boolean]}
 *  */
export const useSticky = (
  options: IntersectionObserverInit = {},
): [React.MutableRefObject<null>, boolean] => {
  const ref = useRef(null);

  const [isSticky, setIsSticky] = useState(false);

  const {
    threshold = 1,
    root = null,
    rootMargin = '0%',
  }: IntersectionObserverInit = options;

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      ([event]) => {
        setIsSticky(event.intersectionRatio < 1);
      },
      { threshold, root, rootMargin },
    );
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [threshold, root, rootMargin]);

  return [ref, isSticky];
};
