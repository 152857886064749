import { IsNotEmpty, IsUUID, IsString, IsOptional } from 'class-validator';

import type { UnitType } from '../unit.types';

export class GetCompanyUnitTreeDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsString()
  @IsOptional()
  @IsNotEmpty()
  unitType?: UnitType;

  @IsUUID()
  @IsOptional()
  unitId?: string;
}
