import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';

export class GetCreatableEventTypesForEmployeeDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  id?: string;

  @IsUUID()
  @IsString()
  @IsOptional()
  parentEventId?: string;
}
