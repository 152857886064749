import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listBox: {
    margin: 0,
    padding: 0,
  },

  scrollableWrapper: {
    margin: '8px 0',
    maxHeight: 220,
    overflow: 'auto',
  },

  longScrollableWrapper: {
    maxHeight: 260,
  },
}));

export default useStyles;
