import React from 'react';

import { Box, SpacingValue } from '@vk-hr-tek/ui/Box';
import { Grid } from '@vk-hr-tek/ui/Grid';

interface InformationProps {
  children: React.ReactNode | React.ReactNode[];
  label: React.ReactNode | React.ReactNode[];
  spacing?: SpacingValue;
  alignItems?: 'center' | 'flex-start';
  flexDirection?: 'row' | 'column';
  width?: string;
}

export const Information = ({
  children,
  label,
  spacing = '2',
  alignItems = 'center',
  flexDirection = 'row',
  width = 'inherit',
}: InformationProps) => (
  <Grid container alignItems={alignItems}>
    <Grid item xs={12} md={3}>
      <Box mb={{ xs: '4', md: '0' }}>{label}</Box>
    </Grid>
    <Grid item xs={12} md={9}>
      <Box display="flex" alignItems={alignItems}>
        {React.Children.map(children, (child) => (
          <Box
            display="flex"
            flexDirection={flexDirection}
            mr={spacing}
            width={width}
          >
            {child}
          </Box>
        ))}
      </Box>
    </Grid>
  </Grid>
);
