import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export type AccessToken = string;

export class PassLoginResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;
}

export class PassRegisterResponse {
  @IsString()
  @IsNotEmpty()
  access_token: string;
}

export type PhoneMask = string;

export class PassVerifyResponse {
  @IsIn(['registration', 'login', 'phone_change'])
  @IsString()
  @IsNotEmpty()
  status: 'registration' | 'login' | 'phone_change';

  @IsString()
  @IsOptional()
  phone_mask?: string;
}
