import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { WidgetTasksResponse, MainLayoutResponse } from '@app/gen/dashboard';

@injectable()
export class DashboardService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getMainLayout() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/user/main_layout',
        {},
        { reloadIfOldVersion: true },
      ),
      MainLayoutResponse,
    );

    return result.widgets;
  }

  async getTaskList() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(`/widget/tasks`),
      WidgetTasksResponse,
    );

    return result.tasks;
  }
}
