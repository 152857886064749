import React from 'react';

import { AppError } from '@vk-hr-tek/core/error';
import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { useSelector } from '../../../../app/hooks';
import { selectStage, selectSnils, selectInviteCode } from '../../../slice';
import { useAuth, useTarget } from '../../../hooks';
import { Page, PageError } from '../../../ui/page';

import { RegisterInit } from './RegisterInit';
import { RegisterVerifyCode } from './RegisterVerifyCode';
import { RegisterFinish } from './RegisterFinish';

const stepsWithCodeVerification = [
  {
    name: 'snils',
    content: null,
    label: 'СНИЛС',
  },
  {
    name: 'init',
    content: <RegisterInit />,
    label: 'Телефон',
  },
  {
    name: 'verification',
    content: <RegisterVerifyCode />,
    label: 'СМС-код',
  },
  {
    name: 'finish',
    content: <RegisterFinish />,
    label: 'Пароль',
  },
];

const stepsWithoutCodeVerification = [
  {
    name: 'snils',
    content: null,
    label: 'СНИЛС',
  },
  {
    name: 'finish',
    content: <RegisterFinish />,
    label: 'Пароль',
  },
];

const registerWithoutPhoneCodeVerify =
  window.REACT_APP_VKHRTEK_AUTH_SKIP_PHONE_VERIFICATION.toString() === 'true';

const steps = registerWithoutPhoneCodeVerify
  ? stepsWithoutCodeVerification
  : stepsWithCodeVerification;

export const Register = () => {
  const step = useSelector(selectStage);
  const snils = useSelector(selectSnils);
  const inviteCode = useSelector(selectInviteCode);
  const [target] = useTarget();

  const isDesktop = useIsDesktop();

  const isAuthenticated = useAuth();

  if (!isAuthenticated && !(snils && inviteCode)) {
    return (
      <PageError
        error={
          new AppError('client', {
            code: 400,
            message: 'Bad request',
            data: {
              error_code: 'invite_status_already_completed',
            },
          })
        }
      />
    );
  }

  return (
    <Page title={target === 'registration' ? 'Регистрация' : 'Смена телефона'}>
      <Stages
        steps={steps}
        active={step}
        fullWidth={window.REACT_APP_VKHRTEK_AUTH_SKIP_PHONE_VERIFICATION}
        showHeader
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
