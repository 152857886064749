import { injectable } from 'inversify';

import { User as UserService } from '@vk-hr-tek/core/logger';

import { UserResponse as User } from '@app/gen/users';

@injectable()
export class UserEntityService implements UserService {
  private user: User | null = null;

  getId() {
    return this.user?.id || null;
  }

  save(user: User | null) {
    this.user = user;
  }
}
