import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  CreatePolicyResponse,
  GetGovDocumentTypeOptionsResponse,
  GetPolicyCompanies as CompaniesResponse,
  PolicyListItemsResponse,
} from '@app/gen/policy';

import { CreatePolicyDto } from '../dto';
import { GetPolicyPositionsDto } from '../dto/create/get-policy-positions.dto';

@injectable()
export class PolicyCreateService {
  constructor(
    private validator: ValidationService,
    private calendar: Calendar,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getCompanies() {
    return this.unmarshaller.unmarshall(
      await this.http.get('/policy/companies', {}, { withSide: true }),
      CompaniesResponse,
    );
  }

  async getGovDocumentOptions() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/policy/gov_document_type/options',
        {},
        {
          withSide: true,
        },
      ),
      GetGovDocumentTypeOptionsResponse,
    );
    return result;
  }

  async getPolicyPositions(getPolicyPositionsDto: GetPolicyPositionsDto) {
    await this.validator.validateOrReject(
      getPolicyPositionsDto,
      GetPolicyPositionsDto,
    );

    const { companyId, unitId, limit, offset, query } = getPolicyPositionsDto;

    const result = (await this.http.get(
      `/policy/companies/${companyId}/positions`,
      { limit, offset, query, ...(unitId ? { unit_id: unitId } : {}) },
      { withSide: true },
    )) as PolicyListItemsResponse;

    return result;
  }

  async createPolicy(createPolicyDto: CreatePolicyDto) {
    await this.validator.validateOrReject(createPolicyDto);

    const response = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/policy',
        {
          name: createPolicyDto.name,
          file: createPolicyDto.file.value,
          company_id: createPolicyDto.companyId,
          ...(createPolicyDto.unitType
            ? { unit_type: createPolicyDto.unitType }
            : {}),
          ...(createPolicyDto.unitType
            ? { unit_ids: [createPolicyDto.unitId] }
            : {}),
          ...(createPolicyDto.activeTo
            ? {
                active_to: this.calendar.formatISO(
                  this.calendar.endOfDay(new Date(createPolicyDto.activeTo)),
                ),
              }
            : {}),
          active_from: this.calendar.formatISO(
            this.calendar.startOfDay(new Date(createPolicyDto.activeFrom)),
          ),
          document_date: createPolicyDto.documentDate,
          ...(createPolicyDto.documentNumber && {
            document_number: createPolicyDto.documentNumber,
          }),
          signature_type: createPolicyDto.signatureType,
          with_order: createPolicyDto.withOrder,
          gov_document_type_code: createPolicyDto.govDocumentTypeCode,
          positions: createPolicyDto.positions,
          ...(createPolicyDto.hideIfAccepted && {
            hide_if_accepted: createPolicyDto.hideIfAccepted,
          }),
        },
        {
          withSide: true,
          isJson: false,
        },
      ),
      CreatePolicyResponse,
    );

    return {
      policy_id: response.policy_id,
    };
  }
}
