import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';

import { useSelector } from '@app/hooks';

import { useLogout } from '../../../auth';
import { Page } from '../../ui/page';

export const CertificateError = () => {
  const logout = useLogout();

  const authInstance = useSelector(selectAuthInstance);

  return (
    <Page title="Не удалось выпустить УНЭП" theme="notification">
      <Box mb="20">
        <Typography variant="body3" color="textSecondary">
          Ваши данные не прошли проверку Удостоверяющего центра.
          <br />
          Для проверки данных, пожалуйста, обратитесь к своему работодателю.
        </Typography>
      </Box>
      <Typography variant="body3" color="textSecondary">
        Список данных: СНИЛС, ИНН, ФИО, паспортные данные
      </Typography>
      {authInstance.auth_type === 'pass' && (
        <Box mt="20">
          <Link onClick={logout} size="small" variant="tertiary">
            Выход к QR-коду
          </Link>
        </Box>
      )}
    </Page>
  );
};
