import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  icon24: {
    fontSize: 24,
  },
  icon32: {
    fontSize: 32,
  },
  icon72: {
    fontSize: 72,
  },
  icon80: {
    fontSize: 80,
  },
}));
