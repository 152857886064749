import { useQuery } from '@vk-hr-tek/core/query';

import { SendInviteDto } from '../dto';

export function useTarget() {
  const [query] = useQuery(SendInviteDto);
  let target: 'registration' | 'phone_change' = 'registration';

  if (query.target.toString() === 'phone_change') {
    target = 'phone_change';
  }

  return [target];
}
