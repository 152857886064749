import { IsNotEmpty, IsPhoneNumber, IsString, IsUUID } from 'class-validator';

export class CandidateLoginInitDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
    groups: ['form', 'submit'],
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
    groups: ['form', 'submit'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
    groups: ['form', 'submit'],
  })
  phone: string;

  @IsNotEmpty({ groups: ['submit'] })
  @IsUUID(undefined, { groups: ['submit'] })
  invite: string;
}
