import {
  IsNotEmpty,
  IsString,
  IsUUID,
  IsNumber,
  IsOptional,
} from 'class-validator';

export class GetUnitTreeOptionsDto {
  @IsNotEmpty()
  @IsString()
  query: string;

  @IsNotEmpty()
  @IsNumber()
  limit: number;

  @IsNotEmpty()
  @IsNumber()
  offset: number;

  @IsUUID()
  company_id?: string;

  @IsOptional()
  @IsString()
  unit_type?: string;
}
