import { createEntityAdapter, SerializedError } from '@reduxjs/toolkit';

import { AppError } from '@vk-hr-tek/core/error';
import { Filter } from '@vk-hr-tek/core/filter';

import {
  CompanyPolicyListItem as CompanyPolicy,
  EmployeePolicyListItem as EmployeePolicy,
  GetPolicyWithVersionsResponse as Policy,
  PolicyEmployee as Employee,
  PolicyCompany,
  PolicyFilterSelectOption as FilterSelectOption,
} from '@app/gen/policy';
import { GetUnitsTreeResponse, GetUnitTypesResponse } from '@app/gen/settings';

export type NeedConfirmPoliciesInfo = {
  policyVersionId: string;
  employeeId: string;
  name: string;
  status: 'not_signed' | 'in_progress' | 'signed' | 'canceled' | 'error';
};

export interface PolicyState {
  status: 'idle' | 'loading' | 'complete' | 'failed';
  currentIds: string[];
  isNotLastItems: boolean;
  error: AppError | null;
  filters: Filter[] | null;
  totalCount: number | null;
  totalVersionCount: number | null;
  creation: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
  };
  printPolicy: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    error: AppError | null;
  };
  creationData: {
    status: 'idle' | 'loading' | 'complete' | 'failed';
    companies: PolicyCompany[] | null;
    positions: {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      hasPositions: boolean;
    };
    unitTypes: {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      values: GetUnitTypesResponse;
    };
    unitsTree: Partial<GetUnitsTreeResponse>;
    govDocumentOptions: FilterSelectOption[];
    error: AppError | null;
  };
  detail: {
    entity: Policy | null;
    employees: {
      status: 'idle' | 'loading' | 'complete' | 'failed';
      entities: Employee[];
      error: AppError | null;
      hasMore: boolean;
    };
    unitTypes: GetUnitTypesResponse;
  };
}

export const initialState: PolicyState = {
  status: 'idle',
  currentIds: [],
  error: null,
  isNotLastItems: true,
  filters: null,
  totalCount: null,
  totalVersionCount: null,
  creation: {
    status: 'idle',
    error: null,
  },
  printPolicy: {
    status: 'idle',
    error: null,
  },
  creationData: {
    status: 'idle',
    error: null,
    govDocumentOptions: [],
    companies: null,
    positions: {
      status: 'idle',
      hasPositions: false,
    },
    unitTypes: {
      values: {
        types: [],
      },
      status: 'idle',
    },
    unitsTree: {},
  },
  detail: {
    entity: null,
    employees: {
      status: 'idle',
      entities: [],
      error: null,
      hasMore: true,
    },
    unitTypes: {
      types: [],
    },
  },
};

export const startLoading = (state: PolicyState) => {
  state.status = 'loading';
  state.error = null;
  state.detail = {
    entity: null,
    employees: {
      status: 'loading',
      entities: [],
      error: null,
      hasMore: true,
    },
    unitTypes: {
      types: [],
    },
  };
  state.currentIds = [];
};

export const completeLoading = (state: PolicyState) => {
  state.status = 'complete';
  state.error = null;
};

export const setError = (
  state: PolicyState,
  { payload, error }: { payload?: AppError; error: SerializedError },
) => {
  state.status = 'failed';
  state.error =
    payload ||
    ({
      info: (error && error.message) || 'Unknown error',
      status: 500,
      source: 'client',
      title: 'Internal client error',
    } as AppError);
};

export const policyAdapter = createEntityAdapter<
  CompanyPolicy | EmployeePolicy
>({
  selectId: (policy) => {
    if ('policy_version_id' in policy && 'employee_id' in policy) {
      return `${policy.policy_version_id}${policy.employee_id}`;
    }

    return policy.policy_id;
  },
});
