import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ tokens }) => ({
  label: {
    color: tokens.colors.text.light.tertirary,
    fontSize: tokens.typography.variant.body3.fontSize,
  },
  countdown: {
    fontSize: tokens.typography.variant.body3.fontSize,
    color: tokens.colors.text.light.primary,
  },
  countdownDisabled: {
    color: tokens.colors.text.light.tertirary,
  },
}));

export default styles;
