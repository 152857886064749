import { IsNotEmpty, IsUUID } from 'class-validator';

import { FormAttributesRequest } from '../../types';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class AcceptDto {
  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  eventId: string;

  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  nodeId: string;

  attributes?: FormAttributesRequest;
}
