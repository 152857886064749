import { injectable } from 'inversify';

import { NodeAction } from '@app/gen/events';

import { Validator } from '../responses';
import { VACATION_END_ATTR_ID } from '../events.constants';

@injectable()
export class EventsFormAttributesMapper {
  process(action: NodeAction, validators: Validator[], companyName: string) {
    return action.form_attributes.map((attribute) => {
      const rangeValidators = validators.filter(
        (validator) =>
          ('from_attribute_id' in validator &&
            validator.from_attribute_id === attribute.id) ||
          ('to_attribute_id' in validator &&
            validator.to_attribute_id === attribute.id),
      );

      const relatedAttribute =
        rangeValidators
          .map((validator) => {
            if (
              'from_attribute_id' in validator &&
              validator.from_attribute_id === attribute.id
            ) {
              return validator.to_attribute_id;
            }
            if (
              'to_attribute_id' in validator &&
              validator.to_attribute_id === attribute.id
            ) {
              return validator.from_attribute_id;
            }
          })
          .find((value) => value) || '';

      const attributeType =
        rangeValidators
          .map((validator) => {
            if (
              'from_attribute_id' in validator &&
              validator.from_attribute_id === attribute.id
            ) {
              return 'rangeStart';
            }
            if (
              'to_attribute_id' in validator &&
              validator.to_attribute_id === attribute.id
            ) {
              return 'rangeEnd';
            }

            return 'single';
          })
          .find((value) => value) || 'single';

      const startValidator = validators.find(
        (validator) =>
          'from_attribute_id' in validator &&
          validator.from_attribute_id === attribute.id,
      );

      return {
        ...attribute,
        relatedAttribute,
        companyName,
        attributeType,
        nameValidating: action.form_attributes.find(
          (attr) =>
            (startValidator &&
              'to_attribute_id' in startValidator &&
              attr.id === startValidator.to_attribute_id) ||
            attr.id === VACATION_END_ATTR_ID,
        )?.name,
        validators: validators.filter(
          (validator) =>
            ('attribute_id' in validator &&
              validator.attribute_id === attribute.id) ||
            ('from_attribute_id' in validator &&
              validator.from_attribute_id === attribute.id) ||
            ('to_attribute_id' in validator &&
              validator.to_attribute_id === attribute.id),
        ),
      };
    });
  }
}
