import React from 'react';

import { AppError } from '@vk-hr-tek/core/error';

import { Box } from '../../Box';
import { SubmitError } from '../SubmitError';
import { SUBMIT_ERROR_TEXT } from '../form.constants';

interface FormErrorProps {
  error: { description: string; traceId?: string | null } | null;
}

export const FormError = ({ error }: FormErrorProps) => (
  <Box pt="20">
    <SubmitError
      submitError={
        {
          title:
            error && error.description ? error.description : SUBMIT_ERROR_TEXT,
          traceId: error && error.traceId,
        } as AppError
      }
    />
  </Box>
);
