import { Type } from 'class-transformer';
import { IsNotEmpty, ValidateNested, IsArray, IsUUID } from 'class-validator';

export class GetDocumentDto {
  @IsNotEmpty()
  @IsUUID()
  documentId: string;

  @IsNotEmpty()
  @IsUUID()
  eventId: string;
}

export class GetDocumentsDto {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => GetDocumentDto)
  documents: GetDocumentDto[];
}
