import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { EventFlow } from '@app/gen/events';

import { GetEventFlowDto, PrintFlowDto } from '../dto';

@injectable()
export class EventsFlowService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getEventFlow(getEventFlowDto: GetEventFlowDto) {
    await this.validator.validateOrReject(getEventFlowDto, GetEventFlowDto);

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/${getEventFlowDto.id}/flow`,
        {},
        { withSide: true },
      ),
      EventFlow,
    );
  }

  async getPdfFile(printFlowDto: PrintFlowDto) {
    await this.validator.validateOrReject(printFlowDto, PrintFlowDto);
    const { id } = printFlowDto;

    const result = await this.http.getFile(`/event/${id}/flow_pdf`, {
      withSide: true,
    });

    return result.file;
  }
}
