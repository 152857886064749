import { IsString, IsPhoneNumber, IsNotEmpty } from 'class-validator';

export class LoginInitDto {
  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
  })
  @IsString({
    message: 'Вы забыли ввести номер телефона',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести номер телефона',
  })
  phone: string;

  @IsString({
    message: 'Вы забыли ввести пароль',
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести пароль',
  })
  password: string;
}
