import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  CreateSimpleEventTypeCompaniesResponse,
  CreateSimpleEventTypeOptionsResponse,
  CreateSimpleEventTypeResponse,
} from '@app/gen/events';

import { GetEventTypeOptionsDto, CreateEventTypeDto } from '../dto';

@injectable()
export class EventsTypeService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getEventTypeCompanies() {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event_type/simple/create_companies',
        {},
        {
          withSide: true,
        },
      ),
      CreateSimpleEventTypeCompaniesResponse,
    );
  }

  async getEventTypeOptionsByCompany(
    getEventTypeOptionsDto: GetEventTypeOptionsDto,
  ) {
    await this.validator.validateOrReject(
      getEventTypeOptionsDto,
      GetEventTypeOptionsDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/event_type/simple/create_options',
        { company_id: getEventTypeOptionsDto.companyId },
        {
          withSide: true,
        },
      ),
      CreateSimpleEventTypeOptionsResponse,
    );
  }

  async createEventType(createEventTypeDto: CreateEventTypeDto) {
    await this.validator.validateOrReject(
      createEventTypeDto,
      CreateEventTypeDto,
    );

    return this.unmarshaller.unmarshall(
      await this.http.post(
        '/event_type/simple',
        {
          company_id: createEventTypeDto.companyId,
          name: createEventTypeDto.name,
          pattern: createEventTypeDto.pattern,
          documents: createEventTypeDto.documents,
          signature_group_id: createEventTypeDto.signatureGroupId,
          custom_type: createEventTypeDto.customType,
          person_upload: createEventTypeDto.personUpload,
          person_accept: createEventTypeDto.personAccept,
        },
        {
          withSide: true,
        },
      ),
      CreateSimpleEventTypeResponse,
    );
  }
}
