import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';

import { selectCertificateStatus } from '../../slice';
import { PageError } from '../../../layout';
import { UserRouter } from '../../types';

import { CertificateNotExist } from './CertificateNotExist';
import { CertificateVerifying } from './CertificateVerifying';
import { CertificateWaiting } from './CertificateWaiting';
import { CertificateCreation } from './CertificateCreation';
import { CertificateError } from './CertificateError';
import { CertificateExpired } from './CertificateExpired';
import { CertificateConfirmation } from './CertificateConfirmation';
import { CertificateManualVerifying } from './CertificateManualVerifying';

export const CreateCertificate = () => {
  const router = useInject<UserRouter>(UserRouter);

  const status = useSelector(selectCertificateStatus);

  useEffect(() => {
    if (status === 'released') {
      router.redirectToHome();
    }
  }, [status, router]);

  switch (status) {
    case null:
      return (
        <Box display="flex" justifyContent="center" pt="40">
          <CircularProgress size={50} />
        </Box>
      );
    case 'released':
      return null;
    case 'error':
      return <CertificateError />;
    case 'need_verify':
      return <CertificateNotExist />;
    case 'need_verify_code':
      return <CertificateVerifying />;
    case 'waiting_confirmation':
      return <CertificateConfirmation />;
    case 'waiting_to_create':
      return <CertificateCreation />;
    case 'verifying':
      return <CertificateWaiting />;
    case 'expired':
      return <CertificateExpired />;
    case 'release_paused':
      return <CertificateManualVerifying />;
    default:
      return <PageError status={404} />;
  }
};
