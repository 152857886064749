import React from 'react';

import { useTheme } from '@material-ui/core';

export const SigFileIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="95"
      height="42"
      viewBox="0 0 95 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5251 33.331C18.7926 33.331 18.1648 33.0734 17.6416 32.5582C17.1184 32.0431 16.8608 31.4152 16.8688 30.6747C16.8608 29.9503 17.1184 29.3305 17.6416 28.8153C18.1648 28.3002 18.7926 28.0426 19.5251 28.0426C20.2334 28.0426 20.8492 28.3002 21.3724 28.8153C21.9036 29.3305 22.1733 29.9503 22.1813 30.6747C22.1733 31.1657 22.0445 31.6125 21.795 32.0149C21.5535 32.4174 21.2315 32.7393 20.8291 32.9808C20.4346 33.2143 20 33.331 19.5251 33.331Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        d="M39.8253 15.1357C39.7126 14.0812 39.2377 13.2602 38.4006 12.6726C37.5715 12.085 36.4929 11.7912 35.1648 11.7912C34.2311 11.7912 33.4302 11.9321 32.7621 12.2138C32.094 12.4955 31.5829 12.8778 31.2287 13.3608C30.8746 13.8438 30.6935 14.3951 30.6854 15.0149C30.6854 15.5301 30.8021 15.9768 31.0356 16.3551C31.277 16.7334 31.603 17.0554 32.0135 17.321C32.4241 17.5786 32.8788 17.7959 33.3779 17.973C33.8769 18.1501 34.38 18.299 34.8871 18.4197L37.2053 18.9993C38.139 19.2166 39.0365 19.5104 39.8978 19.8807C40.7671 20.2509 41.5438 20.7178 42.228 21.2813C42.9203 21.8447 43.4676 22.5249 43.8701 23.3217C44.2725 24.1186 44.4738 25.0523 44.4738 26.1229C44.4738 27.5717 44.1035 28.8475 43.363 29.9503C42.6224 31.045 41.5519 31.9022 40.1513 32.522C38.7588 33.1338 37.0725 33.4396 35.0924 33.4396C33.1686 33.4396 31.4984 33.1418 30.0817 32.5462C28.6731 31.9505 27.5704 31.0812 26.7735 29.9382C25.9847 28.7952 25.5581 27.4027 25.4937 25.7607H29.9006C29.965 26.6219 30.2306 27.3383 30.6975 27.9098C31.1643 28.4813 31.7721 28.9079 32.5206 29.1896C33.2773 29.4714 34.1224 29.6122 35.0562 29.6122C36.0301 29.6122 36.8833 29.4673 37.6158 29.1776C38.3563 28.8797 38.9359 28.4692 39.3545 27.946C39.773 27.4148 39.9863 26.795 39.9944 26.0866C39.9863 25.4427 39.7972 24.9115 39.4269 24.4929C39.0566 24.0663 38.5375 23.7121 37.8694 23.4304C37.2093 23.1406 36.4366 22.883 35.5512 22.6577L32.738 21.9332C30.7015 21.41 29.0917 20.6172 27.9084 19.5547C26.7332 18.4841 26.1456 17.0634 26.1456 15.2926C26.1456 13.8357 26.5401 12.5599 27.3289 11.4652C28.1258 10.3705 29.2084 9.52131 30.5768 8.91761C31.9451 8.30587 33.4946 8 35.2252 8C36.9799 8 38.5173 8.30587 39.8374 8.91761C41.1655 9.52131 42.2079 10.3625 42.9645 11.4411C43.7212 12.5116 44.1116 13.7431 44.1357 15.1357H39.8253Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        d="M52.4466 8.33807V33.0653H47.9672V8.33807H52.4466Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
      <path
        d="M73.3626 16.2344C73.1614 15.5824 72.8837 14.9988 72.5295 14.4837C72.1834 13.9605 71.7648 13.5137 71.2738 13.1435C70.7909 12.7732 70.2355 12.4955 69.6077 12.3104C68.9798 12.1172 68.2956 12.0206 67.5551 12.0206C66.227 12.0206 65.0437 12.3546 64.0054 13.0227C62.967 13.6908 62.15 14.6728 61.5544 15.9688C60.9668 17.2566 60.673 18.8262 60.673 20.6776C60.673 22.545 60.9668 24.1267 61.5544 25.4226C62.142 26.7185 62.959 27.7045 64.0054 28.3807C65.0518 29.0488 66.2672 29.3828 67.6517 29.3828C68.9074 29.3828 69.994 29.1413 70.9116 28.6584C71.8373 28.1754 72.5496 27.4912 73.0487 26.6058C73.5478 25.7124 73.7973 24.666 73.7973 23.4666L74.8115 23.6236H68.0984V20.1222H78.1318V23.0923C78.1318 25.2093 77.681 27.0405 76.7795 28.5859C75.878 30.1314 74.6384 31.3227 73.0608 32.1598C71.4831 32.9889 69.672 33.4034 67.6275 33.4034C65.3496 33.4034 63.3494 32.8923 61.6268 31.87C59.9123 30.8397 58.5721 29.3788 57.6062 27.4872C56.6484 25.5876 56.1694 23.3338 56.1694 20.7259C56.1694 18.7296 56.4512 16.9467 57.0146 15.3771C57.5861 13.8075 58.383 12.4754 59.4052 11.3807C60.4275 10.2779 61.6268 9.44081 63.0032 8.86932C64.3797 8.28977 65.8768 8 67.4947 8C68.8631 8 70.1389 8.20123 71.3221 8.60369C72.5054 8.99811 73.5558 9.56155 74.4734 10.294C75.3991 11.0265 76.1597 11.8958 76.7554 12.902C77.351 13.9081 77.7414 15.0189 77.9265 16.2344H73.3626Z"
        fill={theme.tokens.colors.original.brand.primary}
      />
    </svg>
  );
};
