import React, { ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';

import { Typography } from '../../Typography';
import { Box } from '../../Box';
import { RadioInput } from '..';
import { Label } from '../common';

import useStyles from './MultipleRadioInput.styles';

interface MultipleTextInputProps {
  name: string;
  groupValue: 'all' | 'none' | 'hand';
  values: {
    id: string | number;
    value: boolean;
  }[];
  label: string;
  options: { label: string; id: string | number }[];
  trueLabel: string;
  falseLabel: string;
  allTrueLabel?: string;
  allFalseLabel?: string;
  allSplitLabel?: string;
  onChangeForAllElements: (optionValue: string | number) => void;
  onChangeForElement: (
    id: string | number,
  ) => (optionValue: string | number) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  tooltip?: ReactNode | null;
  required?: boolean;
  disabled?: boolean;
}

export const MultipleRadioInput = ({
  disabled = false,
  values,
  label,
  tooltip,
  groupValue,
  name,
  options,
  trueLabel,
  falseLabel,
  allTrueLabel = `Везде ${trueLabel.toLowerCase()}`,
  allFalseLabel = `Везде ${falseLabel.toLowerCase()}`,
  allSplitLabel = 'Ручной выбор',
  onChangeForAllElements,
  onChangeForElement,
  required = false,
  ...rest
}: MultipleTextInputProps) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classNames(
        classes.multipleRadioInput,
        'aqa_multiple_radion_input',
      )}
    >
      {label && <Label label={label} tooltip={tooltip} required={required} />}

      <Box pb="20">
        <RadioInput
          {...rest}
          row
          value={groupValue}
          disabled={disabled}
          items={[
            { label: allTrueLabel, value: 'all' },
            { label: allFalseLabel, value: 'none' },
            { label: allSplitLabel, value: 'hand' },
          ]}
          onChange={onChangeForAllElements}
        />
      </Box>
      <Box border={1} borderColor="stroke.primary" radius="l" px="16">
        {options.map(({ id, label: optionLabel }, index) => (
          <Box
            py="8"
            borderTop={0}
            borderRight={0}
            borderLeft={0}
            border={index === options.length - 1 ? 0 : 1}
            key={id}
            borderColor="stroke.primary"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap="24"
          >
            <Typography variant="body2">{optionLabel}</Typography>
            <Box flexShrink={0} py="8">
              <RadioInput
                {...rest}
                row
                disabled={disabled}
                value={String(
                  values
                    ? values.find((opt) => opt.id === id)?.value || false
                    : false,
                )}
                items={[
                  { label: trueLabel, value: 'true' },
                  { label: falseLabel, value: 'false' },
                ]}
                onChange={onChangeForElement(id)}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </FormControl>
  );
};
