import React from 'react';

import { Typography } from '../../../Typography';
import { Box } from '../../../Box';

interface HelperTextProps {
  text: string;
}

export const HelperText = ({ text }: HelperTextProps) => {
  return (
    <Box pt="4">
      <Typography variant="caption" color="textTertiary">
        {text}
      </Typography>
    </Box>
  );
};
