import { Notification } from '../types';

export interface NotificationsState {
  notifications: Notification[];
  queue: Notification[];
  lastTimestamp: number | null;
}

export const initialState: NotificationsState = {
  notifications: [],
  queue: [],
  lastTimestamp: null,
};
