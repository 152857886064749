import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { PassLoginResponse as AuthResponse } from '@app/gen/pass';

import { LoginAliDto } from '../../dto';

@injectable()
export class AuthAliLoginService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async login(loginDto: LoginAliDto) {
    await this.validator.validateOrReject(loginDto, LoginAliDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/pass/login',
        {
          code: loginDto.code,
          pass_code: loginDto.passCode,
        },
        { withAuth: false },
      ),
      AuthResponse,
    );

    return result;
  }
}
