import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  greeting: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    alignItems: 'center',
  },
  mock: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      background: 'url(/mockup.png)',
      overflow: 'hidden',
      maxHeight: 413,
      flexGrow: 1,
      width: 949,
      marginTop: 30,
    },
  },
}));

export default styles;
