import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';

import {
  NoOverlapValidator,
  MinimumDurationValidator,
  WeekendExclusionCheckValidator,
  WorkingDayCheckValidator,
  MinDateValidator,
  MaxDateValidator,
  MaxYearValidator,
  MaximumDurationValidator,
  SameMonthValidator,
  VacationAvailableValidator,
  DivisibleByValidator,
  HolydaysExclusionCheckValidator,
  MinHiringDayValidator,
  MinWeekendDaysValidator,
} from '@app/gen/events';

class SingleWorkingDayCheckValidator {
  type: 'single_working_day';

  attribute_id: string;
}

class SingleHolydaysExclusionCheckValidator {
  type: 'single_holydays_exclusion';

  attribute_id: string;
}

export type Validator =
  | NoOverlapValidator
  | MinimumDurationValidator
  | WeekendExclusionCheckValidator
  | WorkingDayCheckValidator
  | SingleWorkingDayCheckValidator
  | SingleHolydaysExclusionCheckValidator
  | MinDateValidator
  | MaxDateValidator
  | MaximumDurationValidator
  | SameMonthValidator
  | VacationAvailableValidator
  | DivisibleByValidator
  | HolydaysExclusionCheckValidator
  | MinHiringDayValidator
  | MinWeekendDaysValidator
  | MaxYearValidator;

export class NodeValidatorsResponse {
  @IsNotEmpty()
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => class {}, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: NoOverlapValidator, name: 'no_overlap' },
        { value: MinimumDurationValidator, name: 'min_duration' },
        { value: WeekendExclusionCheckValidator, name: 'weekend_exclusion' },
        { value: WorkingDayCheckValidator, name: 'working_day' },
        { value: SingleWorkingDayCheckValidator, name: 'single_working_day' },
        {
          value: SingleHolydaysExclusionCheckValidator,
          name: 'single_holydays_exclusion',
        },
        { value: MinDateValidator, name: 'min_date' },
        { value: MaxDateValidator, name: 'max_date' },
        { value: MaximumDurationValidator, name: 'max_duration' },
        { value: SameMonthValidator, name: 'same_month' },
        { value: VacationAvailableValidator, name: 'vacation_available' },
        { value: DivisibleByValidator, name: 'divisible_by' },
        { value: HolydaysExclusionCheckValidator, name: 'holydays_exclusion' },
        { value: MaxYearValidator, name: 'max_year' },
        {
          value: MinWeekendDaysValidator,
          name: 'min_weekend_days',
        },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  validators: Validator[];
}
