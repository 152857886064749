import React, { useEffect } from 'react';

import classNames from 'classnames';
import { TreeView as MUITreeView } from '@material-ui/lab';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  FiberManualRecord as BulletIcon,
} from '@material-ui/icons';

import { TreeItem } from './TreeItem';
import { Item } from './Item.interface';
import { useStyles } from './TreeView.styles';

export interface TreeViewProps {
  onSelect: (value: string, label?: string) => void;
  onChildrenLoad?: (unitId: string) => Promise<Item[]>;
  selected: string;
  items: Item[];
  selectOnShrink?: boolean;
  showRulers?: boolean;
  showCounters?: boolean;
}

const parseParents: (
  items: Item[],
  parents?: string[],
) => Record<string, string[]> = (items, parents = []) => {
  let result: Record<string, string[]> = {};

  items.forEach((item) => {
    result[item.id] = parents;

    if (item.children && item.children.length) {
      result = {
        ...result,
        ...parseParents(item.children, [...parents, item.id]),
      };
    }
  });

  return result;
};

export const TreeView = ({
  onSelect,
  onChildrenLoad,
  items,
  selected,
  selectOnShrink = true,
  showRulers = true,
  showCounters = true,
}: TreeViewProps) => {
  const classes = useStyles();

  const [expanded, expand] = React.useState<string[]>([]);
  const parentsOfSelected = (selected && parseParents(items)[selected]) || [];

  useEffect(() => {
    expand((previous) => [
      ...previous,
      ...(parseParents(items)[selected] || []),
    ]);
  }, [selected, items]);

  return (
    <MUITreeView
      selected={selected}
      expanded={expanded}
      className={classNames(classes.root, 'aqa_tree_view')}
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      defaultEndIcon={<BulletIcon className={classes.endIcon} />}
    >
      {items.map((item) => (
        <TreeItem
          key={item.id}
          item={item}
          showRulers={showRulers}
          showCounters={showCounters}
          onSelect={onSelect}
          expanded={expanded}
          onExpand={(value) =>
            expand((previous) => {
              if (previous.includes(value)) {
                if (selectOnShrink && parentsOfSelected.includes(value)) {
                  onSelect(value);
                }
                return previous.filter(
                  (previousExpanded) => previousExpanded !== value,
                );
              }

              return [...previous, value];
            })
          }
          onChildrenLoad={onChildrenLoad}
        />
      ))}
    </MUITreeView>
  );
};
