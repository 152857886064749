import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { mb: '0' | '8' }>((theme) => ({
  label: ({ mb }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.tokens.spacing[mb],
    transform: 'translate(0, 1.5px)',
  }),
  required: {
    color: theme.tokens.colors.original.brand.primary,
    marginLeft: theme.px(5), // TODO - fix нет в токенах
  },
}));

export default useStyles;
