import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens }) => ({
  link: {
    color: tokens.colors.accent.text.error,
    marginTop: 'auto',
  },

  dialog: {
    maxWidth: 'min-content',
  },
}));
