import { merge, cloneDeep } from 'lodash';

import { Tokens } from '../types';

import { generalTokens } from './general.tokens';

export const otpTokens: Tokens = merge(cloneDeep(generalTokens), {
  name: 'otp',
  colors: {
    original: {
      brand: {
        primary: '#6aab45',
        secondary: '#f0f7eb',
        tertirary: '#85bb65',
        quaternary: '#d8e5ff', // Not present in figma!
      },
    },
    text: {
      light: {
        primary: '#151617',
        secondary: '#7e828a',
        tertirary: '#9d9d9d',
      },
      dark: {
        primary: '#ffffff',
        secondary: '#9d9d9d',
        tertirary: '#7e828a',
      },
    },
    stroke: {
      primary: '#d1d8e7',
      secondary: '#a7adb9',
      tertirary: '#8c9099',
    },
    bg: {
      light: {
        primary: '#ffffff',
        secondary: '#fafbfd',
      },
      dark: {
        primary: '#1c1c1e',
        secondary: '#333336',
      },
      greyscale: {
        primary: '#f6f7fa',
        secondary: '#f8f8f8',
        tertirary: '#eceff5',
      },
    },
    accent: {
      text: {
        error: '#ec545f',
        success: '#6aab45',
        warning: '#ed6f2d',
        review: '#5c1b8b',
        blue: '#3c82dc',
        violet: '#5c1b8b',
      },
      stroke: {
        error: '#f9dade',
        success: '#c1ddb1',
        warning: '#f6c49f',
        review: '#dfd1e7',
        blue: '#acccf0',
      },
      bg: {
        error: '#fcedef',
        success: '#f0f7eb',
        warning: '#fdf0e7',
        review: '#efe8f3',
        blue: '#eaf3fb',
      },
    },
  },
});
