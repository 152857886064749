import { createAsyncThunk, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';

import { GetCompanyEventTypesResponse, Unit } from '@app/gen/settings';

import { ThunkExtra } from '../../../app/store';
import { UserUnitsService } from '../../services';
import { GetCompanyUnitDto } from '../../dto';
import { UserState } from '../user.state';

export const getCompanyUnit = createAsyncThunk<
  Unit,
  GetCompanyUnitDto,
  ThunkExtra
>(
  'unit/getCompanyUnit',
  async ({ companyId, unitId }, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(UserUnitsService).getCompanyUnit({
        companyId,
        unitId,
      });

      return result.unit;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getCompanyUnitEventTypes = createAsyncThunk<
  GetCompanyEventTypesResponse,
  { companyId: string },
  ThunkExtra
>(
  'unit/getCompanyEventTypes',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(UserUnitsService).getCompanyUnitEventTypes(
        dto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const unitReducers = (builder: ActionReducerMapBuilder<UserState>) => {
  builder.addCase(getCompanyUnit.pending, (state) => {
    state.unit.status = 'loading';
    state.unit.error = null;
    state.unit.entity = null;
  });
  builder.addCase(getCompanyUnit.fulfilled, (state, { payload }) => {
    state.unit.status = 'complete';
    state.unit.error = null;
    state.unit.entity = payload;
  });
  builder.addCase(getCompanyUnit.rejected, (state, { payload, error }) => {
    state.unit.status = 'failed';
    state.unit.entity = null;
    state.unit.error =
      /* istanbul ignore next */
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
  builder.addCase(getCompanyUnitEventTypes.pending, (state) => {
    state.companyEventTypes.status = 'loading';
    state.companyEventTypes.items = [];
    state.companyEventTypes.error = null;
  });
  builder.addCase(getCompanyUnitEventTypes.fulfilled, (state, { payload }) => {
    state.companyEventTypes.status = 'complete';
    state.companyEventTypes.items = payload.event_types;
    state.companyEventTypes.error = null;
  });
  builder.addCase(
    getCompanyUnitEventTypes.rejected,
    (state, { payload, error }) => {
      state.companyEventTypes.status = 'failed';
      state.companyEventTypes.items = [];
      state.companyEventTypes.error =
        /* istanbul ignore next */
        payload ||
        ({
          info: (error && error.message) || 'Unknown error',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
};
