import { SidebarHomePageIcon } from '@vk-hr-tek/ui/icons';

import { Module, ModulesNames, ModulesConfig, modules } from '../modules';
import { Resources } from '../ability';

import { NavigationItem, MetaTitle } from './router.types';

const moduleToNavigation = (
  { basename, title, resource }: Omit<Module, 'icon'>,
  parent?: ModulesNames,
): {
  aqaClass: string;
  title: { company: string; employee: string };
  href: string;
  resource: Resources;
} => ({
  aqaClass: `aqa${
    parent && parent !== basename ? `_${parent.replaceAll('-', '_')}` : ''
  }${basename && '_'}${basename.replaceAll('-', '_')}`,
  title,
  href: `${parent && parent !== basename ? `/${parent}` : ''}${
    basename && '/'
  }${basename}`,
  resource,
});

const createNavigation = (config: ModulesConfig): NavigationItem[] => {
  return Object.keys(config)
    .filter((key: string): key is ModulesNames => !!key)
    .filter((key) => config[key].showInNavigation)
    .map((key) => {
      const module = config[key];
      const Icon = module.icon;

      return {
        ...moduleToNavigation(module),
        icon: Icon ? <Icon /> : <SidebarHomePageIcon />,
        ...(key === 'events' || key === 'settings'
          ? {
              submenu: Object.values(config[key].children).map((child) =>
                moduleToNavigation(child, key),
              ),
            }
          : {}),
      };
    });
};

export const navigationItems = createNavigation(modules);

export const metaTitles: MetaTitle[] = [
  ...navigationItems.map(({ title, href }) => ({ title, href })),
  {
    title: {
      company: 'Вход в личный кабинет',
      employee: 'Вход в личный кабинет',
    },
    href: '/auth',
  },
  {
    title: { company: 'Личный кабинет', employee: 'Личный кабинет' },
    href: '/',
  },
  { title: { company: 'Заявки', employee: 'Заявки' }, href: '/events' },
  { title: { company: 'Профиль', employee: 'Профиль' }, href: '/profile' },
  {
    title: { company: 'Профиль', employee: 'Профиль' },
    href: '/create-certificate',
  },
];
