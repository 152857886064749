import React from 'react';

import classNames from 'classnames';

import useStyles from './FormLinkButton.styles';

interface FormLinkButtonProps {
  children: React.ReactNode;
  variant?: 'primary' | 'tertiary';
}

export const FormLinkButton = ({
  children,
  variant = 'primary',
}: FormLinkButtonProps) => {
  const classes = useStyles();

  return (
    <button
      type="submit"
      className={classNames(
        classes.linkButton,
        variant === 'tertiary' && classes.linkButton_tertiary,
      )}
    >
      {children}
    </button>
  );
};
