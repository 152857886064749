/* istanbul ignore file */
import { inject, injectable } from 'inversify';

import { LocalStorage } from '@vk-hr-tek/core/local-storage';

@injectable()
export class RedirectPageService {
  private readonly key = 'redirectPage';

  constructor(
    @inject(LocalStorage) private readonly localStorage: LocalStorage,
  ) {}

  save(role: string): void {
    this.localStorage.setItem(this.key, role);
  }

  get(): string {
    return this.localStorage.getItem(this.key) || '';
  }

  delete(): void {
    this.localStorage.removeItem(this.key);
  }
}
