import { injectable } from 'inversify';

import { ValidationService } from '../validation';
import { HttpService } from '../http';

import { GetEventDto } from './dto';

@injectable()
export class ActivityLogService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
  ) {}

  /** Логировать на стороне сервиса такую пользовательскую активность, как "просмотреть заявку"
   * @param {Object} getEventDto
   * @param {string} getEventDto.id Идентификатор заявки
   * @returns {Promise<void>}
   * */
  async eventView(getEventDto: GetEventDto) {
    await this.validator.validateOrReject(getEventDto, GetEventDto);

    await this.http.post(
      '/user/activity_log/event_view',
      { event_id: getEventDto.id },
      { withSide: true },
    );
  }

  /** Логировать на стороне сервиса такую пользовательскую активность, как "просмотреть профиль"
   * @returns {Promise<void>}
   * */
  async profileView() {
    await this.http.post(
      '/user/activity_log/profile_view',
      {},
      { withSide: true },
    );
  }

  /** Логировать на стороне сервиса такую пользовательскую активность, как "сменить роль"
   * @param {'employee' | 'company'} userRole Роль пользователя
   * @returns {Promise<void>}
   * */
  async changeSide(userRole: 'employee' | 'company') {
    await this.http.post(
      '/user/activity_log/change_side',
      { to_side: userRole },
      { withSide: true },
    );
  }
}
