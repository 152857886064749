import React from 'react';

import classNames from 'classnames';

import { Typography } from '../Typography';
import { Box } from '../Box';
import { StagesSize } from '../Stages';

import { StageEnd } from './StageEnd';
import { StageStart } from './StageStart';
import { useStyles } from './Stage.styles';

export type StageStatus = 'initial' | 'active' | 'completed';

interface StageProps {
  className?: string;
  first?: boolean;
  label: string;
  last?: boolean;
  size?: StagesSize;
  status?: StageStatus;
  fullWidth?: boolean;
}

export const Stage = ({
  className,
  first = false,
  label,
  last = false,
  fullWidth = false,
  size = 'medium',
  status = 'initial',
}: StageProps) => {
  const classes = useStyles();

  const completed = status === 'completed';
  const active = status === 'active';

  const widthForContainer = fullWidth ? '100%' : 'auto';

  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      width={widthForContainer}
      className={classNames(
        className,
        !first && classes.offsetToLeft,
        !first && size === 'small' && classes.offsetToLeftSmall,
        'aqa_stage',
        `aqa_stage_${status}`,
      )}
    >
      <Box display="flex" width={widthForContainer}>
        <StageStart first={first} status={status} />
        <Box
          className={classNames(
            classes.content,
            fullWidth && classes.fullWidthStageContent,
            completed && classes.completed,
            active && classes.active,
            size === 'medium' && classes.contentMedium,
          )}
        >
          <Typography
            variant="caption"
            color={completed || active ? 'contrast' : 'textPrimary'}
          >
            {label}
          </Typography>
        </Box>
        <StageEnd last={last} status={status} />
      </Box>
    </Box>
  );
};
