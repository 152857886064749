import React from 'react';

import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import {
  FormError,
  FormKnownValue,
  FormKnownValues,
  FormValidationMessage,
  TextInput,
} from '@vk-hr-tek/ui/form';

import { useDispatch, useSelector } from '@app/hooks';

import {
  register,
  registerWithVerifiedPhone,
  selectAuthInstance,
  selectNotification,
  selectPhone,
  selectSnils,
  selectStatus,
} from '../../../slice';
import { RegisterDto } from '../../../dto';
import { Button } from '../../../ui/Button';

export const RegisterFinish = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const snils = useSelector(selectSnils);
  const phone = useSelector(selectPhone);
  const status = useSelector(selectStatus);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor } = authInstance;

  const formatPhone = (phoneString: string) => {
    if (phoneString[0] === '+') {
      return phoneString;
    } else {
      return `+${phoneString[0]} ${phoneString.substring(
        1,
        4,
      )} ${phoneString.substring(4, 7)} ${phoneString.substring(
        7,
        9,
      )} ${phoneString.substring(9, 11)}`;
    }
  };

  const onSubmit = (values: RegisterDto) =>
    new Promise((resolve) => {
      const registerWithoutPhoneCodeVerify =
        window.REACT_APP_VKHRTEK_AUTH_SKIP_PHONE_VERIFICATION.toString() ===
        'true';

      const request = () =>
        registerWithoutPhoneCodeVerify
          ? dispatch(
              registerWithVerifiedPhone({
                values: { password: values.password },
                actions: { resolve },
              }),
            )
          : dispatch(
              register({
                values,
                actions: { resolve },
              }),
            );

      request();
    });

  return (
    <div>
      <Form
        validate={(values) => validator.validate(values, RegisterDto, ['form'])}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          submitting,
          pristine,
          hasValidationErrors,
          dirtySinceLastSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormKnownValues>
              <FormKnownValue label="СНИЛС" value={snils} />
              <FormKnownValue
                label="Номер телефона"
                value={formatPhone(phone as string)}
              />
            </FormKnownValues>
            <Box mt="24">
              <TextInput
                name="password"
                label="Пароль"
                placeholder="Придумайте пароль"
                type="password"
                id="qaAuthRegisterFinishPasswordInput"
                showError={false}
                after={(value: string | undefined) => {
                  const validators = [
                    {
                      check: /^[A-Za-z0-9!#{}@()%^\[\]~$\-_]+$/,
                      message:
                        'Латинские буквы, цифры или символы !#{}@$()%^[]~-_',
                    },
                    {
                      check: /[0-9\^\[\]~!#{}\/@\$()`%]/,
                      message: 'Хотя бы одну цифру или символ !#{}@$()%^[]~-_',
                    },
                    {
                      check: /[A-Z]/,
                      message: 'Хотя бы одну заглавную букву',
                    },
                    {
                      check: /[a-z]/,
                      message: 'Хотя бы одну строчную букву',
                    },
                  ];
                  return (
                    <Box mt="8">
                      <FormValidationMessage title="Пароль должен содержать:">
                        {validators.map(({ check, message }) => (
                          <Box
                            color={
                              check.test(value || '')
                                ? 'accent.text.success'
                                : 'text.light.tertirary'
                            }
                            mb="4"
                            key={message}
                          >
                            {message}
                          </Box>
                        ))}
                        <Box
                          color={
                            value && value.length > 7
                              ? 'accent.text.success'
                              : 'text.light.tertirary'
                          }
                        >
                          Минимум восемь символов
                        </Box>
                      </FormValidationMessage>
                    </Box>
                  );
                }}
              />
            </Box>
            <Box mt="8">
              <TextInput
                name="passwordRepeat"
                label="Повторите пароль"
                placeholder="Повторите пароль"
                type="password"
                id="qaAuthRegisterFinishPasswordRepeatInput"
              />
            </Box>
            <Box mt="32">
              <Button
                color={buttonColor && `#${buttonColor}`}
                disabled={pristine || hasValidationErrors}
                loading={submitting}
              >
                Готово!
              </Button>
            </Box>
            {!dirtySinceLastSubmit && status && status === 'failed' && (
              <FormError error={error} />
            )}
          </form>
        )}
      />
    </div>
  );
};
