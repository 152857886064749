import { AppError } from '../error';

export interface DispatchResult<T> {
  type: string;
  payload: AppError | T | undefined;
}

export default async <T>(promise: Promise<DispatchResult<T>>): Promise<T> => {
  const { type, payload } = await promise;

  if (type.includes('rejected')) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw payload;
  }

  return payload as T;
};
