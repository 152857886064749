import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: 'auto',
    borderRadius: theme.tokens.radius.l,
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      minWidth: theme.px(600),
    },

    '& > form': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  },
  dialogWithoutRadius: {
    borderRadius: 0,
  },
}));
