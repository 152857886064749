import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  AcceptPoliciesResponse,
  GetPolicyEmployeesResponse,
  GetPolicyWithVersionsResponse as PolicyResponse,
} from '@app/gen/policy';
import { GetEventDocumentFileHashResponse as HashResponse } from '@app/gen/events';

import {
  AcceptDto,
  CreatePolicyVersionDto,
  DeletePolicyVersionDto,
  GetPolicyDetailDto,
  GetPolicyVersionEmployeesDto,
  UpdatePolicyVersionDto,
  AcceptConfirmPoliciesDto,
  AcceptCryptoLocalDto,
  GetDocumentDto,
  GetDocumentHashDto,
  UpdatePolicyDto,
} from '../dto';

@injectable()
export class PolicyDetailService {
  constructor(
    private validator: ValidationService,
    private calendar: Calendar,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async accept(acceptDto: AcceptDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        `/policy/accept`,
        { policy_versions: acceptDto.policyVersions },
        { withSide: true },
      ),
      AcceptPoliciesResponse,
    );

    return result;
  }

  async acceptCryptoLocal(acceptCryptoLocalDto: AcceptCryptoLocalDto) {
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        `/policy/accept`,
        { policy_versions: acceptCryptoLocalDto.policyVersions },
        { withSide: true },
      ),
      AcceptPoliciesResponse,
    );

    return result;
  }

  async acceptConfirmPolicies(acceptPoliciesDto: AcceptConfirmPoliciesDto) {
    const { code, policyVersions } = acceptPoliciesDto;

    await this.http.post(
      '/policy/confirm_accept',
      {
        code,
        policy_versions: policyVersions,
      },
      { withSide: true },
    );
  }

  async getPolicyVersionEmployeesFile(
    listAcceptedDto: GetPolicyVersionEmployeesDto,
  ) {
    await this.validator.validateOrReject(listAcceptedDto);

    const { id, filters, versionId } = listAcceptedDto;

    const result = await this.http.getFile(
      `/policy/${id}/document/${versionId}/${
        filters?.accepted ? 'accepted' : 'not_accepted'
      }`,
      { withSide: true },
    );

    return result.file;
  }

  async get(getPolicyDetailDto: GetPolicyDetailDto) {
    await this.validator.validateOrReject(
      getPolicyDetailDto,
      GetPolicyDetailDto,
    );

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/policy/${getPolicyDetailDto.id}`,
        {},
        { withSide: true, reloadIfOldVersion: true },
      ),
      PolicyResponse,
    );

    return result;
  }

  async getFile({ url }: { url: string }) {
    const result = this.http.getFile(url, { withSide: true });

    return result;
  }

  async getDocument(getDocumentsDto: GetDocumentDto) {
    const dto = plainToClass(GetDocumentDto, getDocumentsDto);
    await this.validator.validateOrReject(dto);

    const result = await this.http.getFile(
      `/policy/${dto.policyId}/version/${dto.policyVersionId}/file`,
      {
        withSide: true,
      },
    );

    return result;
  }

  async getDocumentHash(getDocumentHashDto: GetDocumentHashDto) {
    await this.validator.validateOrReject(
      getDocumentHashDto,
      GetDocumentHashDto,
    );

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/policy/${getDocumentHashDto.policyId}/version/${getDocumentHashDto.policyVersionId}/file/hash`,
        {},
        { withSide: true },
      ),
      HashResponse,
    );

    return result;
  }

  async deletePolicyVersion(deletePolicyVersionDto: DeletePolicyVersionDto) {
    await this.validator.validateOrReject(
      deletePolicyVersionDto,
      DeletePolicyVersionDto,
    );

    const result = await this.http.delete(
      `/policy/${deletePolicyVersionDto.id}/version/${deletePolicyVersionDto.versionId}`,
      undefined,
      { withSide: true },
    );

    return result;
  }

  async updatePolicyVersion(updatePolicyVersionDto: UpdatePolicyVersionDto) {
    await this.validator.validateOrReject(
      updatePolicyVersionDto,
      UpdatePolicyVersionDto,
    );
    const now = new Date();

    const activeFrom = this.calendar.isSameDay(
      new Date(updatePolicyVersionDto.activeFrom),
      now,
    )
      ? this.calendar.formatISO(new Date(updatePolicyVersionDto.activeFrom))
      : this.calendar.formatISO(
          this.calendar.startOfDay(new Date(updatePolicyVersionDto.activeFrom)),
        );

    let activeTo = updatePolicyVersionDto.activeTo
      ? this.calendar.endOfDay(new Date(updatePolicyVersionDto.activeTo))
      : null;

    if (
      activeTo &&
      this.calendar.isSameDay(now, activeTo) &&
      updatePolicyVersionDto.endNow
    ) {
      activeTo = now;
    }

    const result = await this.http.put(
      `/policy/${updatePolicyVersionDto.id}/version/${updatePolicyVersionDto.versionId}`,
      {
        ...(activeTo
          ? {
              active_to: this.calendar.formatISO(activeTo),
            }
          : {}),
        active_from: activeFrom,
        document_date: updatePolicyVersionDto.documentDate,
        ...(updatePolicyVersionDto.documentNumber && {
          document_number: updatePolicyVersionDto.documentNumber,
        }),
        signature_type: updatePolicyVersionDto.signatureType,
        with_order: updatePolicyVersionDto.withOrder,
      },
      { withSide: true, isJson: false },
    );

    return result;
  }

  async createPolicyVersion(createPolicyVersionDto: CreatePolicyVersionDto) {
    await this.validator.validateOrReject(
      createPolicyVersionDto,
      CreatePolicyVersionDto,
    );

    const activeFrom = this.calendar.isSameDay(
      new Date(createPolicyVersionDto.activeFrom),
      new Date(),
    )
      ? this.calendar.formatISO(new Date(createPolicyVersionDto.activeFrom))
      : this.calendar.formatISO(
          this.calendar.startOfDay(new Date(createPolicyVersionDto.activeFrom)),
        );

    const result = await this.http.post(
      `/policy/${createPolicyVersionDto.id}/version`,
      {
        ...(createPolicyVersionDto.activeTo
          ? {
              active_to: this.calendar.formatISO(
                this.calendar.endOfDay(
                  new Date(createPolicyVersionDto.activeTo),
                ),
              ),
            }
          : {}),
        active_from: activeFrom,
        document_date: createPolicyVersionDto.documentDate,
        ...(createPolicyVersionDto.documentNumber && {
          document_number: createPolicyVersionDto.documentNumber,
        }),
        signature_type: createPolicyVersionDto.signatureType,
        with_order: createPolicyVersionDto.withOrder,
        file: createPolicyVersionDto.file.value,
      },
      { withSide: true, isJson: false },
    );

    return result;
  }

  async getPolicyVersionEmployees(
    getPolicyVersionEmployeesDto: GetPolicyVersionEmployeesDto,
  ) {
    await this.validator.validateOrReject(
      getPolicyVersionEmployeesDto,
      GetPolicyVersionEmployeesDto,
    );

    const {
      id,
      versionId,
      limit = 20,
      offset = 0,
      filters,
    } = getPolicyVersionEmployeesDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        `/policy/${id}/version/${versionId}/employees`,
        {
          pagination: {
            offset,
            limit,
          },
          filters: {
            ...(filters && filters.employee_query
              ? { employee_query: filters.employee_query }
              : {}),
            ...(filters && filters.accepted === 'true'
              ? { accepted: true }
              : {}),
          },
        },
        { withSide: true },
      ),
      GetPolicyEmployeesResponse,
    );

    return result;
  }

  async updatePolicy(updatePolicyDto: UpdatePolicyDto) {
    await this.validator.validateOrReject(updatePolicyDto, UpdatePolicyDto);

    const result = await this.http.put(
      `/policy/${updatePolicyDto.id}`,
      {
        ...(typeof updatePolicyDto.hideIfAccepted === 'boolean' && {
          hide_if_accepted: updatePolicyDto.hideIfAccepted,
        }),
      },
      { withSide: true, isJson: false },
    );

    return result;
  }
}
