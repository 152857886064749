import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listItem: {
    fontSize: 'inherit',
    color: 'inherit',
  },
}));

export default useStyles;
