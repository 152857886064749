import React from 'react';

import classNames from 'classnames';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

const SVGSidebarEventsPageIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M13.0837 2C14.5177 2 15.2157 2.13478 15.9411 2.52273C16.6014 2.87588 17.1241 3.3986 17.4773 4.05893C17.8652 4.78434 18 5.48226 18 6.91631V13.0837C18 14.5177 17.8652 15.2157 17.4773 15.9411C17.1241 16.6014 16.6014 17.1241 15.9411 17.4773C15.2157 17.8652 14.5177 18 13.0837 18H6.91631C5.48226 18 4.78434 17.8652 4.05893 17.4773C3.3986 17.1241 2.87588 16.6014 2.52273 15.9411C2.13478 15.2157 2 14.5177 2 13.0837V6.91631C2 5.48226 2.13478 4.78434 2.52273 4.05893C2.87588 3.3986 3.3986 2.87588 4.05893 2.52273C4.78434 2.13478 5.48226 2 6.91631 2H13.0837ZM13.0837 3.5H6.91631C5.70871 3.5 5.24472 3.5896 4.76632 3.84545C4.3674 4.0588 4.0588 4.3674 3.84545 4.76632C3.5896 5.24472 3.5 5.70871 3.5 6.91631V13.0837C3.5 14.2913 3.5896 14.7553 3.84545 15.2337C4.0588 15.6326 4.3674 15.9412 4.76632 16.1545C5.24472 16.4104 5.70871 16.5 6.91631 16.5H13.0837C14.2913 16.5 14.7553 16.4104 15.2337 16.1545C15.6326 15.9412 15.9412 15.6326 16.1545 15.2337C16.4104 14.7553 16.5 14.2913 16.5 13.0837V6.91631C16.5 5.70871 16.4104 5.24472 16.1545 4.76632C15.9412 4.3674 15.6326 4.0588 15.2337 3.84545C14.7553 3.5896 14.2913 3.5 13.0837 3.5ZM11.25 12.5C11.6642 12.5 12 12.8358 12 13.25C12 13.6642 11.6642 14 11.25 14H6.75C6.33579 14 6 13.6642 6 13.25C6 12.8358 6.33579 12.5 6.75 12.5H11.25ZM13.25 9.25C13.6642 9.25 14 9.58579 14 10C14 10.4142 13.6642 10.75 13.25 10.75H6.75C6.33579 10.75 6 10.4142 6 10C6 9.58579 6.33579 9.25 6.75 9.25H13.25ZM13.25 6C13.6642 6 14 6.33579 14 6.75C14 7.16421 13.6642 7.5 13.25 7.5H6.75C6.33579 7.5 6 7.16421 6 6.75C6 6.33579 6.33579 6 6.75 6H13.25Z" />
  </SvgIcon>
);

export const SidebarEventsPageIcon = ({
  size = 'default',
  color = 'textPrimary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGSidebarEventsPageIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
