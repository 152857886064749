import React from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';

const MobileUnavailable = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="calc(100vh - 60px)"
    className="aqa_mobile_unavailable"
  >
    <Typography variant="body2" color="textSecondary">
      Недоступно в мобильной версии
    </Typography>
  </Box>
);

export default MobileUnavailable;
