import React from 'react';

import classNames from 'classnames';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

import { IconProps } from '../Icon.types';
import { useStyles } from '../Icon.styles';

const SVGGlobeIcon = (props: SvgIconProps) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.90156 12.0001C3.90156 11.2738 3.99715 10.5698 4.17644 9.9001H8.22712C8.14706 10.5714 8.10156 11.2728 8.10156 12.0001C8.10156 12.7274 8.14706 13.4288 8.22711 14.1001H4.17644C3.99715 13.4304 3.90156 12.7264 3.90156 12.0001ZM8.53331 15.9001H4.90055C5.9426 17.7934 7.71983 19.2251 9.84763 19.8106C9.3718 18.7712 8.87482 17.4447 8.53331 15.9001ZM14.1555 19.8106C16.2833 19.2251 18.0605 17.7934 19.1026 15.9001H15.4698C15.1283 17.4447 14.6313 18.7712 14.1555 19.8106ZM13.6215 15.9001C13.1788 17.703 12.5145 19.1531 12.0016 20.0984C11.4887 19.1531 10.8243 17.703 10.3816 15.9001H13.6215ZM13.9618 14.1001H10.0413C9.95273 13.432 9.90156 12.7301 9.90156 12.0001C9.90156 11.2701 9.95273 10.5682 10.0414 9.9001H13.9618C14.0504 10.5682 14.1016 11.2701 14.1016 12.0001C14.1016 12.7301 14.0504 13.432 13.9618 14.1001ZM15.776 14.1001H19.8267C20.006 13.4304 20.1016 12.7264 20.1016 12.0001C20.1016 11.2738 20.006 10.5698 19.8267 9.9001H15.776C15.8561 10.5714 15.9016 11.2728 15.9016 12.0001C15.9016 12.7274 15.8561 13.4288 15.776 14.1001ZM15.4698 8.1001H19.1026C18.0605 6.20677 16.2833 4.77507 14.1554 4.1896C14.6313 5.22897 15.1283 6.55544 15.4698 8.1001ZM9.84767 4.1896C7.71985 4.77507 5.94261 6.20678 4.90055 8.1001H8.53332C8.87484 6.55544 9.37183 5.22898 9.84767 4.1896ZM10.3816 8.1001H13.6215C13.1788 6.29722 12.5144 4.84716 12.0016 3.90193C11.4887 4.84716 10.8243 6.29722 10.3816 8.1001ZM12.0016 2.1001C6.53394 2.1001 2.10156 6.53248 2.10156 12.0001C2.10156 17.4677 6.53394 21.9001 12.0016 21.9001C17.4692 21.9001 21.9016 17.4677 21.9016 12.0001C21.9016 6.53248 17.4692 2.1001 12.0016 2.1001Z"
    />
  </SvgIcon>
);

export const GlobeIcon = ({
  size = 'default',
  color = 'textPrimary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGGlobeIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
