import { useState, useEffect, useRef } from 'react';

/**
 * @typedef IntersectionObserverInitDocs
 * @type {object}
 * @property [root]
 * @property {string} [rootMargin]
 * @property {number|number[]} [threshold]
 */

/**
 * @typedef MutableRefObjectDocs
 * @type {object}
 * @property current
 */

/**
 * @typedef IntersectionObserverEntryDocs
 * @type {object}
 * @property boundingClientRect
 * @property {number} intersectionRatio
 * @property intersectionRect
 * @property {boolean} isIntersecting
 * @property rootBounds
 * @property target
 * @property time
 */

/** Хук, который отслеживает пересечение элемента с родительским контейнером (по умолчанию window),
 * возвращает массив из рефа (на элемент) и объекта типа IntersectionObserverEntry.
 * IntersectionObserverEntry содержит информацию о пересечении.
 * @param {IntersectionObserverInitDocs} [options={}] Опции условий наблюдения за пересечением
 * @returns {[MutableRefObjectDocs, IntersectionObserverEntryDocs | null]}
 *  */
export function useIntersectionObserver(
  options: IntersectionObserverInit = {},
): [React.MutableRefObject<null>, IntersectionObserverEntry | null] {
  const {
    threshold = 1,
    root = null,
    rootMargin = '0%',
  }: IntersectionObserverInit = options;
  const ref = useRef(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);

  useEffect(() => {
    const node = ref?.current;

    if (!node || typeof IntersectionObserver !== 'function') {
      return;
    }

    const observer = new IntersectionObserver(
      ([entryObserver]) => {
        setEntry(entryObserver);
      },
      { threshold, root, rootMargin },
    );

    observer.observe(node);

    return () => {
      setEntry(null);
      observer.disconnect();
    };
  }, [threshold, root, rootMargin]);

  return [ref, entry];
}
