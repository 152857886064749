/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';

@ValidatorConstraint({ name: 'IsSnils' })
export class IsSnilsConstraint implements ValidatorConstraintInterface {
  validate(value: any) {
    if (
      typeof value !== 'string' ||
      !(
        /[0-9]{11}/.test(value) ||
        /[0-9]{3}-[0-9]{3}-[0-9]{3} [0-9]{2}/.test(value)
      )
    ) {
      return false;
    }

    const snils = value.replace(/(-| )/g, '');
    let sum = 0;

    for (let i = 0; i < 9; i++) {
      sum += parseInt(snils[i], 10) * (9 - i);
    }

    let checkDigit = 0;

    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = sum % 101;

      /* istanbul ignore next */
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }

    return checkDigit === parseInt(snils.slice(-2), 10);
  }

  defaultMessage(args: ValidationArguments) {
    return `${args.property} is not snils`;
  }
}

export function IsSnils(validationOptions?: ValidationOptions) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      target: object.constructor,
      propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsSnilsConstraint,
    });
  };
}
