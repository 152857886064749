export interface SettingsCompanyUnepTypesRouter {
  goToUnepTypes(): void;

  goToUnepTypeEdit(id: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SettingsCompanyUnepTypesRouter = Symbol(
  'SettingsCompanyUnepTypesRouter',
);
