/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { History } from '@vk-hr-tek/core/history';

import { ThunkExtra } from '@app/store';
import { QuickActionCreateEvent } from '@app/gen/dashboard';

import { FastActionsService } from '../../services';
import { DashboardState } from '../dashboard.state';

export const getFastActions = createAsyncThunk<
  QuickActionCreateEvent[],
  void,
  ThunkExtra
>(
  'dashboard/getFastActions',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const result = await inject(FastActionsService).getFastActions();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const setFastActionData = createAction<{
  modalType: string;
  companyIds?: string[];
  eventTypeIds?: string[];
} | null>('dashboard/setFastActionData');

export const fastAction = createAsyncThunk<
  void,
  {
    type: string;
    companyIds?: string[];
    eventTypeIds?: string[];
    side: string;
  },
  ThunkExtra
>(
  'dashboard/fastAction',
  async (
    { companyIds, type, side, eventTypeIds },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    // const router = inject<EventsRouter>(EventsRouter); //TODO: добавить роутер после возможности добавить в него side
    const history = inject<History>(History);

    try {
      if (type === 'create_event') {
        // router.goToList();
        history.push(`/${side}/events`);

        dispatch(
          setFastActionData({ companyIds, eventTypeIds, modalType: type }),
        );
      }
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const fastActionsReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(setFastActionData, (state, { payload }) => {
    state.fastActionData = payload;
  });

  builder.addCase(getFastActions.pending, (state) => {
    state.fastActions.status = 'loading';
  });
  builder.addCase(getFastActions.fulfilled, (state, { payload }) => {
    state.fastActions.status = 'complete';
    state.fastActions.actions = payload;
  });
  builder.addCase(getFastActions.rejected, (state, { payload, error }) => {
    state.fastActions.status = 'failed';
    state.fastActions.error =
      payload ||
      ({
        info: (error && error.message) || 'Unknown error',
        status: 500,
        source: 'client',
        title: 'Internal client error',
      } as AppError);
  });
};
