import React, { useEffect } from 'react';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useQuery } from '@vk-hr-tek/core/query';
import { MobileUnavailable } from '@vk-hr-tek/ui/MobileUnavailable';

import { useDispatch } from '@app/hooks';

import { ApprovalParamsDto } from '../../dto';
import { getUpdateSettingData } from '../../slice';

import { ApprovalSettings as ApprovalSettingsDesktop } from './desktop';

export const ApprovalSettings = () => {
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();

  const [{ companyId, unitId }] =
    useQuery<ApprovalParamsDto>(ApprovalParamsDto);

  useEffect(() => {
    if (isDesktop) {
      dispatch(
        getUpdateSettingData({
          companyId,
          unitId,
        }),
      );
    }
  }, [dispatch, companyId, unitId, isDesktop]);

  return isDesktop ? <ApprovalSettingsDesktop /> : <MobileUnavailable />;
};
