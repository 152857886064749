import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsString,
  IsUUID,
  IsIn,
  IsOptional,
  IsArray,
} from 'class-validator';

import { AssignedRole } from '../types';

export class CreateEventFromAbsenceDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  employeeId: string;

  @IsUUID()
  @IsString()
  @IsNotEmpty()
  eventTypeId: string;

  attributes: Record<string, string | number>;
}

export class CreateEventFromButtonDto {
  @IsString({
    groups: ['form', 'form-single', 'form-batch'],
  })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form', 'form-single', 'form-batch'],
  })
  companyId: string;

  @IsUUID(undefined, {
    groups: ['form', 'form-single', 'form-batch', 'submit'],
  })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form', 'form-single', 'form-batch', 'submit'],
  })
  eventTypeId: string;

  @IsString({ groups: ['form', 'form-single', 'submit'] })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form', 'form-single', 'submit'],
  })
  employeeId: string;

  @IsString({ groups: ['form-single', 'form-batch'] })
  @IsIn(['single', 'batch'], { groups: ['form-single', 'form-batch'] })
  @IsNotEmpty({
    message: 'Обязательное поле',
    groups: ['form-single', 'form-batch'],
  })
  creationType: string;

  @IsOptional()
  @IsArray()
  @Type(() => AssignedRole)
  assigned_roles?: AssignedRole[];

  @IsUUID()
  @IsOptional()
  parentEventId?: string;
}
