import React from 'react';

import { Chat as UIChat } from '@vk-hr-tek/ui/Chat';
import { loadChat, selectStatus } from '@vk-hr-tek/core/chat';

import { useDispatch, useSelector } from '@app/hooks';

export const Chat = () => {
  const dispatch = useDispatch();

  const status = useSelector(selectStatus);

  const loadWebim = () => dispatch(loadChat());

  return <UIChat status={status} loadWebim={loadWebim} />;
};
