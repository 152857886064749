import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, zIndex, tokens, px }) => ({
  myAppBar: {
    zIndex: zIndex.drawer + 1,
    position: 'relative',
    padding: 0,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: tokens.spacing['24'],
    [breakpoints.up('md')]: {
      paddingTop: tokens.spacing['32'],
      paddingBottom: tokens.spacing['20'],
    },
  },
  appBar: {
    paddingLeft: tokens.spacing['20'],
    paddingRight: tokens.spacing['20'],

    [breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  vkHeader: {
    display: 'none',
    ['@media (min-width:820px)']: {
      padding: `0 ${tokens.spacing['12']}px`,
      display: 'flex',
      fontFamily: ['VK Sans Text', 'Inter', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.813rem',
      borderBottom: `${tokens.border.s}px solid ${tokens.colors.stroke.primary}`,
      borderTop: `${tokens.border.s}px solid transparent`,
      height: px(37),
      alignItems: 'center',
    },
  },
  vkHeaderLogo: {
    textDecoration: 'none',
    marginRight: tokens.spacing['8'],
    marginTop: tokens.spacing['4'],
  },
  vkHeaderLink: {
    color: tokens.colors.text.light.tertirary,
    borderRadius: tokens.radius.xs,
    padding: `${tokens.spacing['4']}px ${tokens.spacing['8']}px`,
    textDecoration: 'none',
    '&:hover': {
      background: tokens.colors.bg.greyscale.secondary,
    },
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1200,
    paddingLeft: tokens.spacing['16'],
    paddingRight: tokens.spacing['16'],
    background: tokens.colors.bg.light.primary,
    borderBottom: `1px solid ${tokens.colors.stroke.primary}`,

    [breakpoints.up('md')]: {
      paddingTop: tokens.spacing['24'],
      paddingLeft: tokens.spacing['32'],
      paddingBottom: tokens.spacing['24'],
      paddingRight: tokens.spacing['32'],
    },
  },
  content: {
    width: '100%',
    maxWidth: '1296px',
    margin: '0 auto',

    [breakpoints.up('md')]: {
      maxWidth: 'calc(1296px + 48px)',
      paddingLeft: tokens.spacing['24'],
      paddingRight: tokens.spacing['24'],
    },

    [breakpoints.up('lg')]: {
      maxWidth: 'calc(1296px + 80px)',
      paddingLeft: tokens.spacing['40'],
      paddingRight: tokens.spacing['40'],
    },
  },
  container: {
    flexGrow: 1,
    padding: 0,
    position: 'relative',
  },

  dashboardHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1200,
    paddingTop: tokens.spacing['24'],
    paddingLeft: tokens.spacing['32'],
    paddingRight: tokens.spacing['32'],
    background: tokens.colors.bg.light.primary,
    borderBottom: `1px solid ${tokens.colors.stroke.primary}`,
    borderRadius: `0 0 ${px(tokens.radius.l)}px ${px(tokens.radius.l)}px`,
  },
}));
