import React from 'react';

import { Done as MUIDoneIcon } from '@material-ui/icons';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const DoneIcon = ({ size = 'normal', color = 'primary' }: IconProps) => {
  const classes = useStyles();

  return (
    <MUIDoneIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
