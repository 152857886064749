import { createSelector } from '@reduxjs/toolkit';

import { Inject } from '../../ioc';
import { CryptoMapper } from '../mappers';

import { CryptoState } from './crypto.state';

export const selectCertificates = createSelector(
  [
    (state: { crypto: CryptoState }) => state.crypto.items,
    (_: { crypto: CryptoState }, inject: Inject) => inject(CryptoMapper),
  ],
  (items, format) => format.processCertificates(items),
);

export const selectCertificatesStatus = (state: { crypto: CryptoState }) =>
  state.crypto.status;

export const selectCertificatesError = (state: { crypto: CryptoState }) =>
  state.crypto.error;
