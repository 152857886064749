import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { PassVerifyResponse } from '@app/gen/pass';

import { VerifyPassCodeDto } from '../../dto';

@injectable()
export class AuthAliPassCodeService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async verifyPassCode(verifyPassCodeDto: VerifyPassCodeDto) {
    await this.validator.validateOrReject(
      verifyPassCodeDto,
      VerifyPassCodeDto,
      ['load'],
    );
    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/pass/verify',
        {
          pass_code: verifyPassCodeDto.passCode,
        },
        { withAuth: false },
      ),
      PassVerifyResponse,
    );

    return result;
  }
}
