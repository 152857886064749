/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { injectable } from 'inversify';

import { Logger } from './logger';
import { LogOptions } from './logger.types';

@injectable()
export class ConsoleLogger extends Logger {
  /** Логирует сообщение об ошибке в Web console
   * @param message Сообщение об ошибке
   * @param options Опции содержат данные, которые необходимо залогировать,
   * а так же level, определяющий степень серьезности ошибки и тип сообщения, которое будет выведено в Web console
   * @returns {void}
   *  */
  log(message: any, { level, tags, context }: LogOptions) {
    switch (level) {
      case 'debug':
        console.log(
          'Message: %O\nTags: %O\nContext: %O\n',
          message,
          tags,
          context,
        );
        break;
      case 'info':
        console.info(
          'Message: %O\nTags: %O\nContext: %O\n',
          { message },
          tags,
          context,
        );
        break;
      case 'warning':
        console.warn(
          'Message: %O\nTags: %O\nContext: %O\n',
          { message },
          tags,
          context,
        );
        break;
      case 'error':
      case 'fatal':
        console.error(
          'Message: %O\nTags: %O\nContext: %O\n',
          { message },
          tags,
          context,
        );
        break;
      default:
        console.log(
          'Message: %O\nTags: %O\nContext: %O\n',
          { message },
          tags,
          context,
        );
    }
  }
}
