/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import MUIGrid, {
  GridContentAlignment,
  GridDirection,
  GridItemsAlignment,
  GridJustification,
  GridTypeMap,
  GridWrap,
} from '@material-ui/core/Grid';
import { CommonProps } from '@material-ui/core/OverridableComponent';

import { GridContainer } from './GridContainer';
import { Spacing } from './Grid.types';

export type GridSize =
  | 'auto'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

export const Grid = ({
  spacing,
  container,
  ...props
}: JSX.IntrinsicAttributes & {
  alignContent?: GridContentAlignment;
  alignItems?: GridItemsAlignment;
  children?: React.ReactNode;
  container?: boolean;
  direction?: GridDirection;
  item?: boolean;
  justifyContent?: GridJustification;
  lg?: boolean | GridSize;
  md?: boolean | GridSize;
  sm?: boolean | GridSize;
  spacing?: Spacing;
  wrap?: GridWrap;
  xl?: boolean | GridSize;
  xs?: boolean | GridSize;
  zeroMinWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
} & CommonProps<GridTypeMap<{}, 'div'>> &
  Pick<any, string | number | symbol>) => {
  if (container) return <GridContainer {...props} spacing={spacing} />;

  return <MUIGrid {...props} />;
};
