import React, { ReactNode, useCallback } from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';

import { Box } from '../../Box';
import { CircularProgress } from '../../CircularProgress';
import { Label } from '../common';
import { Typography } from '../../Typography';

import { useStyles } from './ChipsInput.styles';

interface ChipsInputProps {
  items: {
    label: string;
    counter?: string | number;
    value: string | number;
  }[];
  label?: string;
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  tooltip?: ReactNode | null;
  disabled?: boolean;
  loading?: boolean;
  size?: 'large' | 'medium' | 'small';
  activeSingleOption?: boolean;
  testId?: string;
}

export const ChipsInput = ({
  label,
  items,
  value,
  onChange,
  required = false,
  error,
  helperText,
  disabled = false,
  loading = false,
  tooltip = null,
  size = 'large',
  activeSingleOption = true,
  testId,
  ...rest
}: ChipsInputProps) => {
  const classes = useStyles();

  const checkColor = useCallback(
    (chipValue: string | number) => {
      if (error) {
        return 'secondary';
      } else if (chipValue == value) {
        return 'primary';
      } else {
        return 'default';
      }
    },
    [error, value],
  );

  const getCounterBgColor = (itemValue: string | number | undefined) => {
    if (disabled) return 'bg.greyscale.primary';
    if (error) return 'bg.light.primary';
    if (itemValue === value) return 'original.brand.primary';

    return 'bg.greyscale.primary';
  };

  const getCounterTextColor = (itemValue: string | number | undefined) => {
    if (disabled) return 'text.light.tertirary';
    if (error) return 'text.light.primary';
    if (itemValue === value) return 'bg.light.primary';

    return 'text.light.primary';
  };

  const handleChange = useCallback(
    ({
      target: { value: inputValue },
    }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(inputValue);
    },
    [onChange],
  );

  return (
    <FormControl className="aqa_chips_input" component="fieldset">
      {label && (
        <Label
          label={label}
          className="aqa_chips_input_label"
          required={required}
          tooltip={tooltip}
        />
      )}

      <RadioGroup
        row
        data-testid={testId}
        value={value}
        onChange={handleChange}
        className={`${classes.row} aqa_chip_button`}
      >
        {items.map((item) => {
          const active =
            item.value === value || (activeSingleOption && items.length === 1);

          return (
            <Box key={item.value}>
              <FormControlLabel
                key={item.value}
                value={item.value}
                className={classes.label}
                disabled={disabled}
                control={
                  <Radio className={classes.radio} icon="" checkedIcon="" />
                }
                label={
                  <Chip
                    {...rest}
                    label={
                      loading ? (
                        <CircularProgress
                          size={20}
                          color={item.value === value ? 'primary' : 'inherit'}
                        />
                      ) : (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          {item.label}
                          {!!item.counter && (
                            <Box
                              display="inline"
                              ml="4"
                              px="8"
                              py="2"
                              radius="m"
                              fontSize={12}
                              lineHeight="16px"
                              bgcolor={getCounterBgColor(item.value)}
                              color={getCounterTextColor(item.value)}
                            >
                              {item.counter}
                            </Box>
                          )}
                        </Box>
                      )
                    }
                    variant="outlined"
                    disabled={disabled}
                    clickable
                    color={checkColor(item.value)}
                    className={classNames(
                      classes.chip,
                      classes[size],
                      error && classes.error,
                      disabled && classes.disabled,
                      active && classes.active,
                      active && disabled && classes.activeDisabled,
                    )}
                  />
                }
              />
            </Box>
          );
        })}
      </RadioGroup>

      {error && (
        <Box mt="4">
          <Typography variant="caption" color="error">
            {helperText}
          </Typography>
        </Box>
      )}
    </FormControl>
  );
};
