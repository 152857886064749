import { injectable } from 'inversify';
import { plainToClass } from 'class-transformer';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Calendar } from '@vk-hr-tek/core/calendar';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  Event as EventDetailResponse,
  GetEventDocumentFileHashResponse as HashResponse,
  CancelReasonListResponse as CancelReasonsResponse,
  EventAttributeCacheFileResponse,
  EventCatalogAttributes,
  GetEventCompetencyOptionsResponse,
  GetEventStaffPositionEmployeesResponse,
} from '@app/gen/events';

import {
  GetEventDto,
  SignUnepDto,
  UploadDocumentDto,
  AcceptDto,
  GenerateDocumentDto,
  SignUkepDto,
  CancelEventDto,
  DeclineDto,
  DeclineSignDto,
  GetNodeValidatorsDto,
  GetDocumentHashDto,
  ReturnEventDto,
  SignPepDto,
  SignPepBatchDto,
  MarkReadDto,
  CacheFileDto,
  GetCatalogAttributesDto,
  SignUnepLocalDto,
  GetDocumentsDto,
  CompetencyEvalDto,
  GetCompetencyOptionsDto,
  GetStaffPosititionEmployees,
  CompetencyProfileDto,
} from '../dto';
import { NodeValidatorsResponse } from '../responses';
import { FormAttributesRequest } from '../types';

@injectable()
export class EventsDetailService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private calendar: Calendar,
  ) {}

  parseAttributes(obj: FormAttributesRequest, skipFiles = false) {
    const result: FormAttributesRequest = {};

    Object.keys(obj).forEach((key) => {
      if (key.startsWith('_attribute_')) {
        if (skipFiles) {
          if (!(obj[key] instanceof File) && !Array.isArray(obj[key])) {
            result[key.split('_attribute_')[1]] = obj[key];
          }
        } else {
          result[key.split('_attribute_')[1]] = obj[key];
        }
      }
    });

    return result;
  }

  async get(getEventDto: GetEventDto) {
    await this.validator.validateOrReject(getEventDto, GetEventDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(`/event/${getEventDto.id}`, {}, { withSide: true }),
      EventDetailResponse,
    );

    return result;
  }

  async getFile({ url }: { url: string }) {
    const result = this.http.getFile(url, { withSide: true });

    return result;
  }

  async getCancelReasons(getEventDto: GetEventDto) {
    await this.validator.validateOrReject(getEventDto, GetEventDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/event/cancel_reasons',
        getEventDto.isCandidate
          ? { is_candidate: getEventDto.isCandidate }
          : {},
        { withSide: true },
      ),
      CancelReasonsResponse,
    );

    return result.reasons;
  }

  async cancelEvent(cancelEventDto: CancelEventDto) {
    await this.validator.validateOrReject(cancelEventDto);

    const { eventId, reasonId, comment } = cancelEventDto;

    const result = await this.http.post(
      `/event/${eventId}/cancel`,
      {
        reason_id: reasonId,
        ...(comment ? { comment } : {}),
      },
      { withSide: true },
    );

    return result;
  }

  async returnEvent(returnEventDto: ReturnEventDto) {
    await this.validator.validateOrReject(returnEventDto);

    const { eventId, nodeId, comment } = returnEventDto;

    return this.http.post(
      `/event/${eventId}/${nodeId}/return`,
      { comment },
      { withSide: true },
    );
  }

  async signUnep(signUnepDto: SignUnepDto) {
    const result = await this.http.post(
      `/event/${signUnepDto.eventId}/${signUnepDto.nodeId}/unep_sign/code`,
      {},
      { withSide: true, isJson: false },
    );

    return result;
  }

  async verifySignUnep(signUnepDto: SignUnepDto) {
    const result = await this.http.post(
      `/event/${signUnepDto.eventId}/${signUnepDto.nodeId}/unep_sign`,
      {
        attributes: this.parseAttributes(signUnepDto.attributes || {}),
        code: signUnepDto.code,
      },
      { withSide: true, isJson: false },
    );

    return result;
  }

  async signUnepLocal(signUnepLocalDto: SignUnepLocalDto) {
    const result = await this.http.post(
      `/event/${signUnepLocalDto.eventId}/${signUnepLocalDto.nodeId}/unep_sign`,
      {
        attributes: this.parseAttributes(signUnepLocalDto.attributes || {}),
        signatures: signUnepLocalDto.signatures.map(({ signature, hash }) =>
          JSON.stringify({
            signature,
            document_hash: hash,
          }),
        ),
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async signUnepLocalBatch(signUnepLocalDto: SignUnepLocalDto) {
    const result = await this.http.post(
      `/event/${signUnepLocalDto.eventId}/${signUnepLocalDto.nodeId}/unep_sign_batch`,
      {
        attributes: this.parseAttributes(signUnepLocalDto.attributes || {}),
        signatures: signUnepLocalDto.signatures.map(({ signature, hash }) =>
          JSON.stringify({
            signature,
            document_hash: hash,
          }),
        ),
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async signUnepBatch(signEmployeeDto: SignUnepDto) {
    const result = await this.http.post(
      `/event/${signEmployeeDto.eventId}/${signEmployeeDto.nodeId}/unep_sign_batch/code`,
      {
        attributes: this.parseAttributes(signEmployeeDto.attributes || {}),
      },
      { withSide: true, isJson: true, withIndicies: true },
    );

    return result;
  }

  async verifySignUnepBatch(signUnepDto: SignUnepDto) {
    const result = await this.http.post(
      `/event/${signUnepDto.eventId}/${signUnepDto.nodeId}/unep_sign_batch`,
      {
        attributes: this.parseAttributes(signUnepDto.attributes || {}),
        code: signUnepDto.code,
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async signPep(signPepDto: SignPepDto) {
    const result = await this.http.post(
      `/event/${signPepDto.eventId}/${signPepDto.nodeId}/pep_sign`,
      { attributes: this.parseAttributes(signPepDto.attributes || {}) },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async signPepBatch(signPepBatchDto: SignPepBatchDto) {
    const result = await this.http.post(
      `/event/batch/pep_sign`,
      signPepBatchDto,
      { withSide: true, withIndicies: true },
    );

    return result;
  }

  async signUkep(signCompanyDto: SignUkepDto) {
    const result = await this.http.post(
      `/event/${signCompanyDto.eventId}/${signCompanyDto.nodeId}/ukep_sign`,
      {
        attributes: this.parseAttributes(signCompanyDto.attributes || {}),
        hash: signCompanyDto.signature,
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async uploadDocument(uploadDocumentDto: UploadDocumentDto) {
    const result = await this.http.post(
      `/event/${uploadDocumentDto.eventId}/${uploadDocumentDto.nodeId}/upload`,
      {
        attributes: this.parseAttributes(uploadDocumentDto.attributes || {}),
        document: uploadDocumentDto.document.value,
        ...(uploadDocumentDto.documentDate && {
          document_date: this.calendar.formatISO(
            new Date(uploadDocumentDto.documentDate),
            { representation: 'date' },
          ),
        }),
        ...(uploadDocumentDto.documentNumber && {
          document_number: uploadDocumentDto.documentNumber,
        }),
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async accept(acceptDto: AcceptDto) {
    const result = await this.http.post(
      `/event/${acceptDto.eventId}/${acceptDto.nodeId}/accept`,
      {
        attributes: this.parseAttributes(acceptDto.attributes || {}),
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async decline(declineDto: DeclineDto) {
    const result = await this.http.post(
      `/event/${declineDto.eventId}/${declineDto.nodeId}/decline`,
      {
        attributes: this.parseAttributes({}),
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async declineSign(declineSignDto: DeclineSignDto) {
    const result = await this.http.post(
      `/event/${declineSignDto.eventId}/${declineSignDto.nodeId}/decline_sign`,
      {
        comment: declineSignDto.comment,
        attributes: this.parseAttributes({}),
      },
      { withSide: true, isJson: false, withIndicies: true },
    );

    return result;
  }

  async competencyEval(dto: CompetencyEvalDto) {
    const result = await this.http.post(
      `/event/${dto.eventId}/${dto.nodeId}/competency_eval`,
      {
        competency_values: dto.competencies,
        attributes: this.parseAttributes(dto.attributes || {}),
      },
      { withSide: true, isJson: false },
    );

    return result;
  }

  async competencyProfile(dto: CompetencyProfileDto) {
    const result = await this.http.post(
      `/event/${dto.eventId}/${dto.nodeId}/competency_profile`,
      {
        competency_values: dto.competencies,
        attributes: this.parseAttributes(dto.attributes || {}),
      },
      { withSide: true, isJson: false },
    );

    return result;
  }

  async getCompetencyOptions(dto: GetCompetencyOptionsDto) {
    await this.validator.validateOrReject(dto, GetCompetencyOptionsDto);

    const { eventId, staffPositionId } = dto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/${eventId}/competency_options`,
        {
          staff_position_id: staffPositionId,
        },
        { withSide: true },
      ),
      GetEventCompetencyOptionsResponse,
    );

    return result.competencies;
  }

  async getNodeValidators(getNodeValidatorsDto: GetNodeValidatorsDto) {
    await this.validator.validateOrReject(
      getNodeValidatorsDto,
      GetNodeValidatorsDto,
    );

    const result = await this.unmarshaller.unmarshall(
      {
        validators: await this.http.get(
          `/event/${getNodeValidatorsDto.eventId}/${getNodeValidatorsDto.nodeId}/validators`,
          {},
          { withSide: true },
        ),
      },
      NodeValidatorsResponse,
    );

    return result;
  }

  async generateDocumentPreview(generateDocumentDto: GenerateDocumentDto) {
    const result = await this.http.generateFile(
      `/event/${generateDocumentDto.eventId}/${generateDocumentDto.nodeId}/generate_document_preview`,
      {
        attributes: this.parseAttributes(
          generateDocumentDto.attributes || {},
          true,
        ),
      },
      { withSide: true, method: 'put', withIndicies: true },
    );

    return result;
  }

  async generateDocument(generateDocumentDto: GenerateDocumentDto) {
    const result = await this.http.generateFile(
      `/event/${generateDocumentDto.eventId}/${generateDocumentDto.nodeId}/generate_document_from_template`,
      {
        attributes: this.parseAttributes(generateDocumentDto.attributes || {}),
        ...(generateDocumentDto.documentDate && {
          document_date: this.calendar.formatISO(
            new Date(generateDocumentDto.documentDate),
            { representation: 'date' },
          ),
        }),
        ...(generateDocumentDto.documentNumber && {
          document_number: generateDocumentDto.documentNumber,
        }),
      },
      { withSide: true, withIndicies: true },
    );

    return result;
  }

  async getDocuments(getDocumentsDto: GetDocumentsDto) {
    const dto = plainToClass(GetDocumentsDto, getDocumentsDto);
    await this.validator.validateOrReject(dto);

    return Promise.all(
      dto.documents.map(({ eventId, documentId }) =>
        this.http.getFile(`/event/${eventId}/document/${documentId}/file`, {
          withSide: true,
        }),
      ),
    );
  }

  async getDocumentHash(getDocumentHashDto: GetDocumentHashDto) {
    await this.validator.validateOrReject(
      getDocumentHashDto,
      GetDocumentHashDto,
    );

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/${getDocumentHashDto.eventId}/document/${getDocumentHashDto.documentId}/file/hash`,
        {},
        { withSide: true },
      ),
      HashResponse,
    );

    return result;
  }

  async cacheFile(cacheFileDto: CacheFileDto) {
    await this.validator.validateOrReject(cacheFileDto, CacheFileDto);

    const { eventId, attributeId, file } = cacheFileDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        `/event/${eventId}/attribute/${attributeId}/cache_file`,
        { file },
        { withAuth: true, isJson: false, withSide: true },
      ),
      EventAttributeCacheFileResponse,
    );

    return result;
  }

  async markRead(markReadDto: MarkReadDto) {
    await this.validator.validateOrReject(markReadDto);

    const { eventId, documentId } = markReadDto;

    const result = await this.http.post(
      `/event/${eventId}/document/${documentId}/mark_read`,
      {},
      { withSide: true },
    );

    return result;
  }

  async getCatalogAttributes(getCatalogAttributesDto: GetCatalogAttributesDto) {
    await this.validator.validateOrReject(
      getCatalogAttributesDto,
      GetCatalogAttributesDto,
    );

    const { eventId, attributeId, offset, query, limit, id } =
      getCatalogAttributesDto;

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/${eventId}/attribute/${attributeId}/options`,
        {
          limit,
          offset,
          query,
          ...(id && { id }),
        },
        {
          withSide: true,
        },
      ),
      EventCatalogAttributes,
    );
  }

  async getStaffPositionEmployees(
    getStaffPosititionEmployees: GetStaffPosititionEmployees,
  ) {
    await this.validator.validateOrReject(
      getStaffPosititionEmployees,
      GetStaffPosititionEmployees,
    );

    const { eventId, staffPositionId } = getStaffPosititionEmployees;

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/event/${eventId}/staff_position_employees`,
        {
          staff_position_id: staffPositionId,
        },
        {
          withSide: true,
        },
      ),
      GetEventStaffPositionEmployeesResponse,
    );
  }
}
