import { IsArray, IsOptional, IsString, IsUUID } from 'class-validator';

export class GetEventFiltersDto {
  @IsString({ each: true })
  @IsArray()
  @IsOptional()
  tags?: string[];

  @IsUUID()
  @IsString()
  @IsOptional()
  companyId?: string;
}
