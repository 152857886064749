import React from 'react';

import { useTheme } from '@material-ui/core';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

const SVGStatusReviewIcon = (props: SvgIconProps) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <rect
        width="16"
        height="16"
        rx="8"
        fill={theme.tokens.colors.accent.text.review}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5864 5.54073C11.806 5.7604 11.806 6.11655 11.5864 6.33622L7.08637 10.8362C6.8667 11.0559 6.51054 11.0559 6.29087 10.8362L4.41475 8.96011C4.19508 8.74044 4.19508 8.38428 4.41475 8.16461C4.63442 7.94494 4.99058 7.94494 5.21025 8.16461L6.68862 9.64298L10.7909 5.54073C11.0105 5.32106 11.3667 5.32106 11.5864 5.54073Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export const StatusReviewIcon = ({
  size = 'small',
  color = 'primary',
}: IconProps) => {
  const classes = useStyles();

  return (
    <SVGStatusReviewIcon
      className={classNames(
        classes[`icon_size_${size}` as const],
        classes[`icon_color_${color}` as const],
      )}
    />
  );
};
