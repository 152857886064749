import React, { useState, useEffect, useContext } from 'react';

import { Reducer } from '@reduxjs/toolkit';

import { InjectReducerContext } from '@app/context';

import { PageLoading } from '..';

interface DynamicModuleLoaderProps {
  reducerKey: string;
  reducerImport: Reducer;
  children: JSX.Element;
  onInit?: () => void;
}

export const DynamicModuleLoader = ({
  reducerKey,
  reducerImport,
  children,
}: DynamicModuleLoaderProps) => {
  const injectReducer = useContext(InjectReducerContext);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    injectReducer?.(reducerKey, reducerImport);
    setLoaded(true);
  }, [reducerKey, reducerImport, injectReducer]);

  if (!loaded) {
    return <PageLoading />;
  }

  return children;
};
