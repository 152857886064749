import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { MultipleRadioInput as Input } from '../../input';

export const MultipleRadioInput = ({
  disabled = false,
  label,
  tooltip,
  name,
  options,
  trueLabel,
  falseLabel,
  ...rest
}: {
  options: { label: string; id: string | number }[];
} & FieldProps<
  { id: string; value: boolean }[] | undefined,
  FieldRenderProps<{ id: string; value: boolean }[] | undefined>
>) => {
  const [byHand, setByHand] = React.useState(false);

  return (
    <Field name={name} {...rest}>
      {({ input }) => {
        let groupValue: 'all' | 'none' | 'hand' = 'hand';

        const values = options.map((option) => {
          const value =
            (input.value &&
              input.value.find((inputValue) => inputValue.id === option.id)) ||
            null;

          return value || { id: option.id, value: true };
        });

        if (
          values.filter((opt) => opt.value).length === options.length &&
          !byHand
        ) {
          groupValue = 'all';
        } else if (
          values.filter((opt) => !opt.value).length === options.length &&
          !byHand
        ) {
          groupValue = 'none';
        }

        const handleChangeForAllElements = (optionValue: string | number) => {
          if (optionValue === 'all') {
            input.onChange(options.map((opt) => ({ id: opt.id, value: true })));
            setByHand(false);
          } else if (optionValue === 'none') {
            input.onChange(
              options.map((opt) => ({ id: opt.id, value: false })),
            );
            setByHand(false);
          } else {
            setByHand(true);
          }
        };

        const handleChangeForElement =
          (id: string | number) => (optionValue: string | number) => {
            const currentValue = values || [];

            if (optionValue === 'true') {
              input.onChange([
                ...currentValue.filter((opt) => opt.id !== id),
                { id, value: true },
              ]);
              setByHand(false);
            } else if (optionValue === 'false') {
              input.onChange([
                ...currentValue.filter((opt) => opt.id !== id),
                { id, value: false },
              ]);
              setByHand(false);
            }
          };

        return (
          <Input
            {...input}
            falseLabel={falseLabel}
            disabled={disabled}
            groupValue={groupValue}
            label={label}
            onChangeForAllElements={handleChangeForAllElements}
            onChangeForElement={handleChangeForElement}
            options={options}
            trueLabel={trueLabel}
            values={values}
            name={name}
          />
        );
      }}
    </Field>
  );
};
