import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

const SVGDocumentIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1356 8.03815C10.4028 7.78177 10.4028 7.35446 10.1356 7.09809C9.88391 6.85667 9.48676 6.85628 9.23464 7.09722L8.62552 7.67931V1.99967C8.62552 1.63148 8.32704 1.33301 7.95885 1.33301C7.59066 1.33301 7.29218 1.63148 7.29218 1.99967V7.67931L6.68306 7.09721C6.43094 6.85628 6.03379 6.85667 5.78214 7.09809C5.5149 7.35446 5.5149 7.78177 5.78214 8.03815L7.61271 9.79428C7.80616 9.97986 8.11154 9.97986 8.30499 9.79428L10.1356 8.03815ZM2.66927 9.99967C2.66927 9.26329 3.26622 8.66634 4.0026 8.66634C4.37079 8.66634 4.66927 8.36786 4.66927 7.99967C4.66927 7.63148 4.37079 7.33301 4.0026 7.33301C2.52984 7.33301 1.33594 8.52692 1.33594 9.99967V11.9997C1.33594 13.4724 2.52985 14.6663 4.0026 14.6663H12.0026C13.4754 14.6663 14.6693 13.4724 14.6693 11.9997V9.99967C14.6693 8.52692 13.4754 7.33301 12.0026 7.33301C11.6344 7.33301 11.3359 7.63148 11.3359 7.99967C11.3359 8.36786 11.6344 8.66634 12.0026 8.66634C12.739 8.66634 13.3359 9.26329 13.3359 9.99967V11.9997C13.3359 12.7361 12.739 13.333 12.0026 13.333H4.0026C3.26622 13.333 2.66927 12.7361 2.66927 11.9997V9.99967Z"
    />
  </SvgIcon>
);

export const DownloadFileIcon = ({ size, color }: IconProps) => {
  const classes = useStyles();

  return (
    <SVGDocumentIcon
      className={classNames(
        size && classes[`icon_size_${size}` as const],
        color && classes[`icon_color_${color}` as const],
      )}
    />
  );
};
