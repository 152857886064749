import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette, ...theme }) => ({
  treeViewAutocompleteInput: {
    width: '100%',
    marginBottom: theme.tokens.spacing['16'],

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  inputRoot: {
    padding: 0,
    backgroundColor: theme.tokens.colors.bg.light.primary,

    '&.Mui-focused': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.tokens.colors.original.brand.primary,
        borderWidth: theme.tokens.border.s,
      },
    },

    '&.Mui-error': {
      backgroundColor: theme.tokens.colors.accent.bg.error,

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.tokens.colors.accent.stroke.error,
      },

      '&.Mui-focused': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderWidth: 1,
          borderColor: theme.tokens.colors.accent.text.error,
        },
      },

      '&:hover': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.error.hover,
        },
      },
    },

    '&.Mui-disabled': {
      backgroundColor: theme.tokens.colors.bg.greyscale.secondary,

      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.tokens.colors.stroke.primary,
      },
    },

    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.tokens.colors.stroke.primary,
    },

    '&:hover': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.tokens.colors.stroke.secondary,
      },

      '& $clearButton': {
        display: 'block',
      },
    },

    '& $input': {
      paddingTop: theme.tokens.spacing['16'],
      paddingRight: theme.tokens.spacing['72'],
      paddingBottom: theme.tokens.spacing['16'],
      paddingLeft: theme.tokens.spacing['16'],
      width: '100%',
      height: theme.px(24),
    },
  },

  input: {
    textOverflow: 'ellipsis',
  },

  endAdornment: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.tokens.spacing['4'],
    position: 'absolute',
    right: theme.tokens.spacing['16'],
    color: theme.tokens.colors.text.light.secondary,
  },

  clearButton: {
    display: 'none',
    height: theme.px(24),
    cursor: 'pointer',
  },

  arrowButton: {
    height: theme.px(24),
    cursor: 'pointer',
  },

  popupIndicatorOpen: {
    transform: 'rotate(180deg)',
  },

  paper: {
    maxHeight: theme.px(272),
    overflowY: 'auto',
    boxShadow: theme.tokens.shadow.l,
  },

  arrowIcon: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  menuItem: {
    paddingTop: theme.tokens.spacing['12'] + theme.px(2),
    paddingRight: theme.tokens.spacing['16'],
    paddingBottom: theme.tokens.spacing['12'] + theme.px(2),
    paddingLeft: theme.tokens.spacing['16'],
    whiteSpace: 'normal',

    '&:hover': {
      backgroundColor: theme.tokens.colors.bg.greyscale.secondary,
    },
  },

  treeItem: {
    padding: `${theme.tokens.spacing['12']}px ${theme.tokens.spacing['24']}px`,

    '&:hover': {
      backgroundColor: theme.tokens.colors.bg.greyscale.secondary,
    },
  },

  treeItemLabel: {
    fontSize: theme.tokens.typography.variant.subtitle2.fontSize,
    lineHeight: theme.tokens.typography.variant.subtitle2.lineHeight,
    backgroundColor: 'inherit !important',

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },

  treeItemLabelIcon: {
    fontSize: `${theme.tokens.typography.variant.body2.fontSize}px !important`,
  },

  treeItemRoot: {
    padding: 0,
  },

  treeItemGroup: {
    marginLeft: theme.tokens.spacing['24'],
  },
}));
