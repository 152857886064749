export type EmployeePolicyStatus =
  | 'error'
  | 'not_signed'
  | 'in_progress'
  | 'signed'
  | 'canceled';

export interface PolicyRouter {
  goToCreate(): void;
  goToDetail(id: string, query?: string): void;
  goToList(query?: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyRouter = Symbol('PolicyRouter');
