import React, { ReactNode } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper } from '@material-ui/core';

import { Box } from '@vk-hr-tek/ui/Box';
import { Typography } from '@vk-hr-tek/ui/Typography';

interface PageLoadingTextProps {
  text: ReactNode;
}

export const PageLoadingText = ({ text }: PageLoadingTextProps) => (
  <Box
    display="flex"
    flexGrow={1}
    justifyContent="center"
    alignItems="center"
    pt="144"
  >
    <Paper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p="24"
        maxWidth={470}
      >
        <Box>
          <CircularProgress size={20} />
        </Box>
        {text && (
          <Box mt="16">
            <Typography variant="body3" color="textTertiary" align="center">
              {text}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  </Box>
);
