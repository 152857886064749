import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  snackbar: {
    left: 0,
    right: 0,
    bottom: 0,
    transform: 'none',
  },

  snackbar_zPosition_bottom: {
    zIndex: 1200,
  },

  snackbar_hideMessage: {
    '& .MuiSnackbarContent-message': {
      display: 'none',
    },
    '& .MuiSnackbarContent-action': {
      margin: '0 auto',
    },
  },
  snackbarContent: {
    fontSize: theme.tokens.typography.variant.body3.fontSize,
    width: '100%',
    borderRadius: 0,
    padding: theme.tokens.spacing['16'],

    background: theme.tokens.colors.bg.dark.primary,
    color: theme.tokens.colors.text.dark.primary,

    '& .MuiSnackbarContent-message': {
      position: 'fixed',
      width: '100%',
      left: 0,
      top: 0,
      background: 'inherit',
      padding: `${theme.tokens.spacing['16']}px ${theme.tokens.spacing['24']}px`,
    },

    '& .MuiSnackbarContent-action': {
      margin: 0,
      width: '100%',
      justifyContent: 'center',
    },

    [theme.breakpoints.up('sm')]: {
      fontSize: theme.tokens.typography.variant.body2.fontSize,
      padding: `${theme.tokens.spacing['16']}px ${theme.tokens.spacing['48']}px`,
      flexWrap: 'wrap',

      '& .MuiSnackbarContent-message': {
        width: 'unset',
        marginBottom: 'unset',
        position: 'unset',
        padding: 'unset',
      },

      '& .MuiSnackbarContent-action': {
        width: 'unset',
        marginBottom: 'unset',
        marginLeft: 'auto',
        marginRight: 0,
      },
    },

    [theme.breakpoints.up('md')]: {
      width: theme.px(1270),
      margin: `0 ${theme.tokens.spacing['48']}px ${theme.tokens.spacing['32']}px`,
      borderRadius: theme.tokens.radius.l,
      paddingTop: theme.tokens.spacing['16'],
      paddingRight: theme.tokens.spacing['16'],
      paddingBottom: theme.tokens.spacing['16'],
      paddingLeft: theme.tokens.spacing['32'],
    },
  },
  snackbarContentSticky: {
    width: '100%',
    borderRadius: 0,
    padding: `${theme.tokens.spacing['16']}px ${theme.tokens.spacing['48']}px`,
    background: theme.tokens.colors.bg.dark.primary,
    color: theme.tokens.colors.text.dark.primary,

    '& .MuiSnackbarContent-action': {
      marginRight: 0,
    },
  },
}));
