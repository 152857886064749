import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    [theme.breakpoints.up('md')]: {
      border: `${theme.tokens.border.s}px solid ${theme.tokens.colors.stroke.primary}`,
      overflow: 'hidden',
      borderRadius: theme.tokens.radius.l,
    },
    background: theme.tokens.colors.bg.light.primary,
  },
}));
