import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  {
    menuHeight: number | string;
    roleTitleFontSize: number;
    sidebarOpened: boolean;
    allElementsVisible: boolean;
  }
>(({ tokens, transitions }) => ({
  sidebarRoleTitle: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    padding: tokens.spacing['8'],
    fontSize: ({ roleTitleFontSize }) => roleTitleFontSize,
    transition: `font-size ${transitions.easing.easeInOut} 0.5s`,
  },

  sidebarLinks: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: ({ menuHeight }) => menuHeight,
    transition: `height ${transitions.easing.easeInOut} 0.5s`,
    overflow: 'hidden',
    willChange: 'height',
  },

  showAllButton: {
    display: 'flex',
    alignItems: 'center',
    gap: tokens.spacing['8'],
    borderRadius: tokens.radius.s,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },
  },

  expandMoreIcon: {
    transform: ({ allElementsVisible }) =>
      `rotate(${allElementsVisible ? 180 : 0}deg)`,
  },

  showAllButtonText: {
    opacity: ({ sidebarOpened }) => (sidebarOpened ? 1 : 0),
    transition: `opacity ${transitions.easing.easeInOut} 0.5s`,
  },
}));
