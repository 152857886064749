import React, { ReactNode } from 'react';

import { Field, FieldInputProps } from 'react-final-form';

import { RadioInputComplexLabel as Input } from '../../input';

const handleChange =
  (
    input: FieldInputProps<string | undefined, HTMLElement>,
    onChange?: (value: string | number) => void,
  ) =>
  (value: string | number) => {
    input.onChange(value);

    if (onChange) {
      onChange(value);
    }
  };

interface RadioInputComplexLabelProps<T> {
  name: string;
  onChange?: (value: string | number) => void;
  defaultValue?: string;
  options: { value: string | number; label: T }[];
  render: (item: T) => ReactNode;
  after?: (value: string | undefined) => React.ReactNode;
}

export const RadioInputComplexLabel = <T,>({
  name,
  onChange,
  options,
  defaultValue,
  render,
  ...rest
}: RadioInputComplexLabelProps<T>) => {
  if (!options || !options.length) {
    return null;
  }

  return (
    <Field name={name} initialValue={defaultValue} {...rest}>
      {({ input }) => (
        <Input
          {...input}
          items={options}
          onChange={handleChange(input, onChange)}
          render={render}
        />
      )}
    </Field>
  );
};
