import { IsNotEmpty, IsUUID } from 'class-validator';

export class GetCompanyUnitDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsUUID()
  @IsNotEmpty()
  unitId: string;
}
