import React, { ChangeEvent, ReactNode, useCallback, useState } from 'react';

import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add';

import { Label, Preloader } from '../common';
import { Box } from '../../Box';
import { Button } from '../../Button';

import useStyles from './MultipleTextInput.styles';

interface MultipleTextInputProps {
  id?: string;
  testId?: string;
  type?: string;
  label?: string;
  values: string[];
  placeholder?: string;
  max?: number;
  onChange: (index: number, value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  tooltip?: ReactNode | null;
  error?: boolean;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export const MultipleTextInput = ({
  id,
  testId,
  type = 'text',
  label,
  values = [],
  placeholder,
  max = 3,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  tooltip,
  error = false,
  helperText = '',
  required = false,
  disabled = false,
  loading = false,
  ...rest
}: MultipleTextInputProps) => {
  const classes = useStyles();

  const [numberOfInputsShown, setNumberOfInputs] = useState(1);

  const counter = Math.max(numberOfInputsShown, values.length);
  const range = new Array(counter).fill(1);

  const handleChange = useCallback(
    (index: number) =>
      (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
        onChange(index, e.target.value),
    [onChange],
  );

  return (
    <div>
      <FormControl
        data-testid={testId}
        className={classNames(
          classes.multipleTextInput,
          'aqa_multiple_text_input',
        )}
      >
        {label && <Label label={label} required={required} tooltip={tooltip} />}

        {range.map((_, index) => {
          const isButtonShown = index === counter - 1 && index + 1 < max;

          return (
            <Box
              className={classNames(isButtonShown && classes.inputWrapper)}
              mb="16"
              key={index}
              display="flex"
            >
              <Box flexGrow={1}>
                <TextField
                  {...rest}
                  data-testid={`${testId}-index-${index + 1}`}
                  variant="outlined"
                  classes={{ root: classes.input }}
                  className="qaInputWrapper"
                  value={loading ? 'Загрузка...' : values[index] || ''}
                  autoComplete="off"
                  onChange={handleChange(index)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  fullWidth
                  type={type}
                  error={error}
                  helperText={helperText}
                  placeholder={placeholder || label}
                  disabled={disabled || loading}
                  InputProps={{
                    id: id,
                    className: 'textFieldInput',
                    classes: { adornedEnd: classes.adornedEnd },
                    ...(loading ? { endAdornment: <Preloader /> } : {}),
                  }}
                />
              </Box>

              {isButtonShown && (
                <Box ml="20">
                  <Button
                    size="large"
                    onClick={() => setNumberOfInputs((state) => state + 1)}
                    icon={<AddIcon />}
                    disabled={disabled || loading}
                  />
                </Box>
              )}
            </Box>
          );
        })}
      </FormControl>
    </div>
  );
};
