import { injectable } from 'inversify';

import { HttpService } from '../http';

@injectable()
export class FilesService {
  constructor(private http: HttpService) {}

  /** Скачать файл
   * @param {string} url Адрес ресурса
   * @returns {Promise<{file: Promise<Blob>, filename: string}>}
   * */
  async getFile(url: string) {
    const result = await this.http.getFile(url, { withSide: true });

    return result;
  }
}
