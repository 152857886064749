import React from 'react';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';

import { PageErrorVk } from './PageErrorVK';
import { PageErrorGeneral as PageErrorGeneralMobile } from './PageErrorGeneral/mobile';
import { PageErrorGeneral as PageErrorGeneralDesktop } from './PageErrorGeneral/desktop';

export interface PageErrorOptions {
  error: { title: string; description: string } | null;
  theme: 'general' | 'vk' | 'oauth2';
}

export const PageError = ({
  error,
  theme = 'general',
}: Partial<PageErrorOptions>) => {
  const isDesktop = useIsDesktop();

  if (theme === 'vk') {
    return <PageErrorVk error={error} />;
  }

  return isDesktop ? (
    <PageErrorGeneralDesktop error={error} />
  ) : (
    <PageErrorGeneralMobile error={error} />
  );
};
