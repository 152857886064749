import React, { Suspense } from 'react';

import { Switch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { PrivateRoute, PageLoading } from './layout';

const Auth = React.lazy(async () => import('./auth'));
const Candidate = React.lazy(async () => import('./candidate'));

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Switch>
        <Route path="/auth">
          <Auth />
        </Route>
        <PrivateRoute path="/" resource="Candidate">
          <Candidate />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
}

export default App;
