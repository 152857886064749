import { CryptoCertificate } from '../types';

export interface CryptoState {
  items: CryptoCertificate[];
  status: 'idle' | 'loading' | 'complete' | 'failed';
  error: null | string;
  hasCertificates: boolean;
}

export const initialState: CryptoState = {
  items: [],
  status: 'idle',
  error: null,
  hasCertificates: false,
};
