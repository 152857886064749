/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import QrScanner from 'qr-scanner';
// @ts-ignore
import Worker from 'worker-loader!./worker.js';

QrScanner.createQrEngine = async () => {
  return new Worker();
};

export default QrScanner;
