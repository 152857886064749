import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { useInject } from '@vk-hr-tek/core/ioc';

import { UserRouter } from '../../../types';

interface ApprovalSettingsTitleProps {
  companyId?: string;
}

export const ApprovalSettingsTitle = ({
  companyId,
}: ApprovalSettingsTitleProps) => {
  const router = useInject<UserRouter>(UserRouter);

  const handleRedirectToProfile = () =>
    router.goToProfile(`companyId-${companyId}`);

  return (
    <Box>
      <Box mb="4">
        <Link onClick={handleRedirectToProfile} size="small" stroke={false}>
          Назад
        </Link>
      </Box>
      <Box pb="8">Редактирование согласований</Box>
    </Box>
  );
};
