/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { useField } from 'react-final-form';

import { MultipleFileInput as Input } from '../../input';

type ValidateType = (
  value: any,
  allValues?: any,
) => (string | undefined) | Promise<string | undefined>;

interface MultipleFileInputProps {
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  name: string;
  validate?: ValidateType[];
  cache?: (file: File) => Promise<{ file_id: string; expired_at: string }>;
}

export const MultipleFileInput = ({
  label,
  tooltip,
  required,
  name,
  validate,
  disabled = false,
  cache,
}: MultipleFileInputProps) => {
  const composeValidators =
    (validators: ValidateType[]) =>
    async (
      value: Record<string, any> | any,
      allValues: Record<string, any>,
    ) => {
      const result = await validators.reduce(
        (error: any, validator) => error || validator(value, allValues),
        undefined,
      );
      return result;
    };

  const validators = composeValidators(validate || []);

  const { input, meta } = useField(name, {
    validate: validators,
  });

  return (
    <Input
      {...input}
      value={input.value}
      label={label}
      disabled={disabled}
      required={required}
      tooltip={tooltip}
      onChange={input.onChange}
      onBlur={input.onBlur}
      cache={cache}
      error={meta.touched && meta.error}
      helperText={meta.error}
    />
  );
};
