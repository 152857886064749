/* eslint-disable @typescript-eslint/no-explicit-any */
export interface User {
  getId(): string | null;
  save(user: { id: string } | null): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const User = Symbol('User');

export type Tags = Record<string, string | number | null>;

export type Context = Record<string, any>;

export type LoggingLevels = 'debug' | 'info' | 'warning' | 'error' | 'fatal';

export interface LogOptions {
  level: LoggingLevels;
  tags?: Tags;
  context?: Context;
}
