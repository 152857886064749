import React, { ReactNode } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Box } from '../../Box';

import { useStyles } from './RadioInputComplexLabel.styles';

interface RadioInputProps<T> {
  items: {
    label: T;
    value: string | number;
  }[];
  value: string | number | undefined;
  onChange: (value: string | number) => void;
  render: (item: T) => ReactNode;
}

export const RadioInputComplexLabel = <T,>({
  items,
  value,
  onChange,
  render,
  ...rest
}: RadioInputProps<T>) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" classes={{ root: classes.formRoot }}>
      <RadioGroup
        {...rest}
        row={false}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        className="aqa_radioinput_block"
      >
        {items.map((item) => (
          <Box key={item.value} className={classes.itemBox}>
            <FormControlLabel
              key={item.value}
              value={item.value}
              classes={{
                root: classes.itemRoot,
              }}
              control={
                <Radio
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  classes={{
                    root: classes.inputRoot,
                    checked: classes.checked,
                  }}
                />
              }
              label={render(item.label)}
              labelPlacement="end"
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
};
