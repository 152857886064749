export interface AbsencesRouter {
  goToDetail(id: string): void;
  goToList(query?: string): void;
  goToVacationDetail(query?: string): void;
  goToCreatedEvent(event_id: string): void;
  goToCompanyAbsencesDetail(query?: string): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AbsencesRouter = Symbol('AbsencesRouter');
