import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class ChatSupportCredentialsFields {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @Transform(({ value }) => 'hidden *****', { groups: ['sensitive'] })
  @IsString()
  @IsNotEmpty()
  display_name: string;
}

export class ChatSupportCredentials {
  @ValidateNested()
  @Type(() => ChatSupportCredentialsFields)
  @IsNotEmpty()
  fields: ChatSupportCredentialsFields;

  @IsString()
  @IsNotEmpty()
  hash: string;
}
