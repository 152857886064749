import React from 'react';

import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { useSelector } from '../../../../app/hooks';
import { selectStage } from '../../../slice';
import { Page } from '../../../ui/page';

import { LoginInit } from './LoginInit';
import { LoginFinish } from './LoginFinish';

const steps = [
  {
    name: 'init',
    content: <LoginInit />,
    label: 'Пароль',
  },
  {
    name: 'finish',
    content: <LoginFinish />,
    label: 'СМС-код',
  },
];

export const Login = () => {
  const step = useSelector(selectStage);
  const isDesktop = useIsDesktop();

  return (
    <Page title="Вход">
      <Stages
        active={step}
        steps={steps}
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
