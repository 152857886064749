import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, IsUUID, ValidateNested } from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

class PolicyVersion {
  @IsNotEmpty(notEmptyOptions)
  policy_version_id: string;

  @IsNotEmpty(notEmptyOptions)
  @IsUUID()
  employee_id: string;
}

export class AcceptDto {
  @IsNotEmpty(notEmptyOptions)
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => PolicyVersion)
  policyVersions: PolicyVersion[];
}
