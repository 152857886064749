import { Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsString,
  IsOptional,
  IsUUID,
  IsBoolean,
} from 'class-validator';

import type { FileInputValue } from '@vk-hr-tek/ui/input';

export class CreatePolicyVersionDto {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString({
    message: 'Вы указали неверную дату',
    groups: ['form'],
  })
  @IsNotEmpty({
    message: 'Вы забыли указать дату',
    groups: ['form'],
  })
  activeFrom: string;

  @IsString({
    message: 'Вы указали неверную дату',
    groups: ['form'],
  })
  @IsOptional({
    message: 'Вы указали неверную дату',
    groups: ['form'],
  })
  activeTo?: string;

  @IsNotEmpty({
    message: 'Вы забыли прикрепить файл',
    groups: ['form'],
  })
  @Type(() => String)
  file: FileInputValue;

  @IsNotEmpty({
    message: 'Вы забыли указать дату',
    groups: ['form'],
  })
  documentDate: string;

  @IsOptional()
  documentNumber?: string;

  @IsNotEmpty()
  signatureType: 'pep' | 'unep';

  @IsNotEmpty()
  @IsBoolean()
  withOrder: boolean;
}
