import * as Sentry from '@sentry/react';

import { History } from '../history';

export const monitoringInit = (history: History) => {
  const logLevels = ['debug', 'info', 'log', 'warning', 'error', 'fatal'];
  if (window.REACT_APP_VKHRTEK_SENTRY_URL) {
    try {
      Sentry.init({
        dsn: window.REACT_APP_VKHRTEK_SENTRY_URL,
        tunnel: '/sentry-tunnel',
        release: `v${window.REACT_APP_VKHRTEK_RELEASE}`,
        integrations: [
          new Sentry.BrowserTracing({
            tracingOrigins: ['*'],
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
        ],
        environment: window.REACT_APP_VKHRTEK_ENVIRONMENT,
        initialScope: {
          level: 'fatal',
          tags: {
            vkdoc_error_type: 'unknown',
          },
        },
        normalizeDepth: 10,
        tracesSampleRate: 1.0,
        ignoreErrors: [/\[http\] \[404\] GET .*\/company\/logo/],
        beforeSendTransaction: (event) => {
          if (
            event.type === 'transaction' &&
            event.tags?.vkdoc_error_type === 'unknown' &&
            event.level === 'fatal'
          ) {
            delete event.tags.vkdoc_error_type;
            event.level = 'info';
          }

          if (
            logLevels.indexOf(event.level || '') <
            logLevels.indexOf(window.REACT_APP_VKHRTEK_LOG_LEVEL)
          ) {
            return null;
          }

          return event;
        },
      });
    } catch {}
  }
};
