import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ px, palette, tokens }) => ({
  suggestedInput: {
    width: '100%',
    marginBottom: tokens.spacing['16'],
  },

  inputRoot: {
    '& .MuiInputBase-root': {
      paddingRight: 0,
      lineHeight: 1.5,
      backgroundColor: tokens.colors.bg.light.primary,

      '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
        padding: 0,
      },

      '&.MuiOutlinedInput-root.MuiAutocomplete-inputRoot .MuiInputBase-input, &.MuiOutlinedInput-root .MuiSelect-root':
        {
          height: px(24),
          paddingTop: tokens.spacing['16'],
          paddingRight: tokens.spacing['72'],
          paddingBottom: tokens.spacing['16'],
          paddingLeft: tokens.spacing['16'],
        },

      '& .MuiSelect-iconOutlined': {
        right: tokens.spacing['16'],
      },

      '&.Mui-focused': {
        '& fieldset': {
          borderColor: tokens.colors.original.brand.primary,
          borderWidth: tokens.border.s,
        },
      },

      '&.Mui-error': {
        backgroundColor: tokens.colors.accent.bg.error,

        '& fieldset': {
          borderColor: tokens.colors.accent.stroke.error,
        },

        '&.Mui-focused': {
          '& fieldset': {
            borderColor: tokens.colors.accent.text.error,
          },
        },

        '&:hover': {
          '& fieldset': {
            borderColor: palette.error.hover,
          },
        },
      },

      '&.Mui-disabled': {
        backgroundColor: tokens.colors.bg.greyscale.secondary,

        '& fieldset': {
          borderColor: tokens.colors.stroke.primary,
        },
      },

      '& fieldset': {
        borderColor: tokens.colors.stroke.primary,
      },

      '&:hover': {
        '& fieldset': {
          borderColor: tokens.colors.stroke.secondary,
        },
      },

      '& .MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
      },

      '& .MuiAutocomplete-endAdornment': {
        right: tokens.spacing['16'],

        '& .MuiAutocomplete-clearIndicator': {
          marginRight: -tokens.spacing['2'],
          backgroundColor: 'transparent',

          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },

        '& .MuiAutocomplete-popupIndicator .MuiTouchRipple-root': {
          display: 'none',
        },
      },
    },

    '& .MuiFormHelperText-root.Mui-error': {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  list: {
    boxShadow: tokens.shadow.l,
    maxHeight: px(276),

    '&:empty': {
      border: 'none',
      boxShadow: 'none',
    },
  },

  option: {
    paddingTop: tokens.spacing['12'] + px(2),
    paddingRight: tokens.spacing['16'],
    paddingBottom: tokens.spacing['12'] + px(2),
    paddingLeft: tokens.spacing['16'],
    fontSize: tokens.typography.variant.body3.fontSize,
    lineHeight: tokens.typography.variant.body3.lineHeight,

    '&:hover': {
      backgroundColor: tokens.colors.bg.greyscale.secondary,
    },

    '&[aria-selected=true]': {
      color: tokens.colors.original.brand.primary,
      backgroundColor: tokens.colors.bg.light.primary,

      '&:hover': {
        backgroundColor: tokens.colors.bg.greyscale.secondary,
      },
    },
  },
}));

export default useStyles;
