import { createSlice } from '@reduxjs/toolkit';

import {
  certificateReducers,
  getUserReducers,
  userSubstitutesReducers,
  unitReducers,
} from './actions';
import { initialState } from './user.state';

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    certificateReducers(builder);
    getUserReducers(builder);
    userSubstitutesReducers(builder);
    unitReducers(builder);
  },
});

export const userReducer = user.reducer;
