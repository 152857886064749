import { IsNotEmpty, IsUUID, IsOptional, IsBoolean } from 'class-validator';

export class GetEventDto {
  @IsNotEmpty()
  @IsUUID()
  id: string;

  @IsOptional()
  @IsBoolean()
  isCandidate?: boolean;
}
