import React from 'react';

import clsx from 'classnames';
import { IconButton } from '@material-ui/core';

import useStyles from './Day.styles';

export interface DayProps {
  /** Day text */
  children: React.ReactNode;
  className?: string;
  /** Is today */
  current?: boolean;
  /** Disabled? */
  disabled?: boolean;
  /** Hidden? */
  hidden?: boolean;
  /** Selected? */
  selected?: boolean;

  isEnd?: boolean;

  isStart?: boolean;

  holiday?: boolean;

  isInRange?: boolean;
}

export const Day: React.FC<DayProps> = ({
  children,
  disabled,
  hidden,
  current,
  selected,
  isInRange,
  isEnd,
  isStart,
  holiday,
  className,
  ...other
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.dayWrapper,
        isInRange && classes.dayWrapperInRange,
        isStart && classes.dayWrapperStart,
        isEnd && classes.dayWrapperEnd,
      )}
    >
      <IconButton
        className={clsx(className, classes.day, {
          [classes.hidden]: hidden,
          [classes.current]: current,
          [classes.daySelected]: selected,
          [classes.dayDisabled]: disabled,
          [classes.holiday]: holiday,
          aqa_icon_button: true,
        })}
        tabIndex={hidden || disabled ? -1 : 0}
        disableRipple
        {...other}
      >
        {children}
      </IconButton>
    </div>
  );
};
