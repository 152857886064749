import React, { ReactNode, forwardRef } from 'react';

import classNames from 'classnames';
import { Button as MUIButton } from '@material-ui/core';

import { Box } from '../Box';
import { CircularProgress } from '../CircularProgress';

import useStyles from './Button.styles';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiaryLight'
  | 'tertiaryDark'
  | 'tertiaryBlue'
  | 'quaternary'
  | 'quaternaryDark'
  | 'warning';

export type ButtonSize = 'large' | 'medium' | 'small' | 'extraSmall';

export interface ButtonProps {
  className?: string;
  id?: string;
  children?: React.ReactNode | React.ReactNode[];
  type?: 'button' | 'submit';
  disabled?: boolean;
  disableRipple?: boolean;
  icon?: ReactNode;
  startIcon?: ReactNode;
  fullWidth?: boolean;
  loading?: boolean;
  size?: ButtonSize;
  text?: string;
  variant?: ButtonVariant;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  testId?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      type = 'button',
      disabled = false,
      disableRipple = false,
      icon,
      startIcon,
      fullWidth = false,
      loading = false,
      size = 'medium',
      variant = 'primary',
      onClick,
      testId,
      ...rest // we need this for tooltips
    },
    ref,
  ) => {
    const classes = useStyles();

    const buttonStyles = classNames(
      classes.button,
      variant === 'primary' && classes.buttonPrimary,
      variant === 'secondary' && classes.buttonSecondary,
      variant === 'quaternary' && classes.buttonQuaternary,
      variant === 'quaternaryDark' && classes.buttonQuaternaryDark,
      variant === 'warning' && classes.buttonWarning,
      variant === 'tertiaryDark' && classes.tertiaryDark,
      variant === 'tertiaryLight' && classes.tertiaryLight,
      variant === 'tertiaryBlue' && classes.tertiaryBlue,
      size === 'extraSmall' && classes.buttonExtraSmall,
      icon && !children && classes.buttonIconOnly,
      variant === 'primary' &&
        icon &&
        !children &&
        classes.buttonPrimaryIconOnly,
      loading && classes.buttonLoading,
      loading && 'buttonLoading',
      className,
      'aqa_button',
      variant === 'primary' && 'aqa_button_primary',
      variant === 'secondary' && 'aqa_button_secondary',
      variant === 'quaternary' && 'aqa_button_quaternary',
      variant === 'warning' && 'aqa_button_warning',
      variant === 'tertiaryDark' && 'aqa_button_tertiaryDark',
      variant === 'tertiaryBlue' && 'aqa_button_tertiaryBlue',
    );

    let muiVariant: 'text' | 'outlined' | 'contained';

    switch (variant) {
      case 'primary':
      case 'quaternary':
      case 'quaternaryDark':
      case 'warning':
        muiVariant = 'contained';
        break;
      case 'secondary':
        muiVariant = 'outlined';
        break;
      case 'tertiaryLight':
      case 'tertiaryDark':
      case 'tertiaryBlue':
        muiVariant = 'text';
        break;
      default:
        muiVariant = variant;
    }

    return (
      <MUIButton
        data-testid={testId}
        color="primary"
        variant={muiVariant}
        className={buttonStyles}
        disabled={disabled}
        disableRipple={disableRipple}
        fullWidth={fullWidth}
        startIcon={startIcon}
        endIcon={icon}
        size={size === 'extraSmall' ? 'small' : size}
        type={type}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        <Box visibility={loading ? 'hidden' : 'visible'}>{children}</Box>
        {loading && (
          <CircularProgress
            size={size === 'extraSmall' ? 12.6 : 18.8}
            className={classes.loading}
            color="inherit"
          />
        )}
      </MUIButton>
    );
  },
);
