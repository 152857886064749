interface VkLink {
  label: string;
  url: string;
}

export const servicesLiks = ['policy_confidentiality', 'user_agreement '];

export const vkLinks: VkLink[] = [
  {
    label: 'Интранет',
    url: 'https://home.vk.team/',
  },
  {
    label: 'Личный кабинет',
    url: 'https://my.vk.team/',
  },
  {
    label: 'Confluence',
    url: 'https://confluence.vk.team/',
  },
  {
    label: 'Jira',
    url: 'https://jira.vk.team/',
  },
  {
    label: 'HR-портал',
    url: 'https://hr.vk.team/',
  },
  {
    label: 'Платформа Стади',
    url: 'https://study.vk.team/',
  },
  {
    label: 'Feedback',
    url: 'https://feedback.vk.team/',
  },
  {
    label: 'Пароль',
    url: 'https://dp.vk.team/',
  },
  {
    label: 'Data Portal',
    url: 'https://data.vk.team/',
  },
];
