import React from 'react';

import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { useSelector } from '../../../../app/hooks';
import { selectStage } from '../../../slice';
import { Page } from '../../../ui/page';

import { ChangePasswordInit } from './ChangePasswordInit';
import { ChangePasswordFinish } from './ChangePasswordFinish';
import { ChangePasswordVerifyCode } from './ChangePasswordVerifyCode';

const steps = [
  {
    name: 'init',
    content: <ChangePasswordInit />,
    label: 'СНИЛС',
  },
  {
    name: 'verification',
    content: <ChangePasswordVerifyCode />,
    label: 'СМС-код',
  },
  {
    name: 'finish',
    content: <ChangePasswordFinish />,
    label: 'Пароль',
  },
];

export const ChangePassword = () => {
  const step = useSelector(selectStage);

  const isDesktop = useIsDesktop();

  return (
    <Page title="Восстановление пароля">
      <Stages
        active={step}
        steps={steps}
        showHeader
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
