import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 766,
    margin: '0 auto',
  },
  container_theme_vk: {
    width: '100%',
    maxWidth: 542,
    minHeight: '100vh',
  },
  container_theme_oauth2: {
    minHeight: '100vh',
  },

  container_theme_ali: {
    width: '100%',
    maxWidth: 572,
    zIndex: 1000,
    justifyContent: 'space-between',
    position: 'relative',
  },
  container_theme_mobile: {
    minHeight: '100vh',
  },
  content: {
    width: '100%',
  },
  background: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: theme.tokens.colors.original.brand.primary,
  },
}));
