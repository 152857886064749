import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import classNames from 'classnames';

import { IconProps } from './Icon.types';
import { useStyles } from './Icon.styles';

export const DotIcon = ({ color = 'primary', size = 'small' }: IconProps) => {
  const classes = useStyles();

  return (
    <SvgIcon
      viewBox="0 0 16 16"
      fill="none"
      className={classNames(
        color && classes[`icon_color_${color}` as const],
        size && classes[`icon_size_${size}` as const],
      )}
    >
      <circle cx="8" cy="8" r="2" />
    </SvgIcon>
  );
};
