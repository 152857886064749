import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core';

import { useStyles } from './VacationIcon.styles';

export const VacationIcon = ({
  color = 'blue',
}: {
  color?: 'primary' | 'blue';
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const iconColor =
    color === 'primary'
      ? theme.tokens.colors.original.brand.primary
      : theme.tokens.colors.accent.text.blue;

  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" className={classes.icon}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0679 9.38013C10.4022 9.46589 10.6038 9.80646 10.518 10.1408L9.77435 13.0402L8.7231 17.3545C8.64138 17.6899 8.30327 17.8955 7.9679 17.8138C7.63253 17.7321 7.42691 17.394 7.50863 17.0586L8.56256 12.7332L9.3072 9.83026C9.39296 9.4959 9.73353 9.29438 10.0679 9.38013Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4291 2.78209C12.5052 2.47163 12.3152 2.15828 12.0047 2.0822C11.6943 2.00612 11.3809 2.19612 11.3049 2.50658L11.2553 2.70895L11.2541 2.71371C7.46807 1.9102 3.77201 3.48317 2.14234 6.81056C1.76741 7.57606 2.26639 8.43591 3.08866 8.6605L16.3555 12.284C17.1778 12.5086 18.0446 12.0218 18.1108 11.172C18.3982 7.48587 16.0255 4.25826 12.3697 3.01988C12.3733 3.00826 12.3766 2.99645 12.3795 2.98446L12.4291 2.78209ZM3.18189 7.3197C4.3369 4.96145 6.75804 3.59712 9.49911 3.66535C8.14656 4.53138 6.74633 6.02444 6.35124 8.35165L3.39365 7.54386C3.28456 7.51406 3.21609 7.44649 3.1879 7.39362C3.17502 7.36949 3.17337 7.3543 3.17319 7.34933C3.17308 7.34675 3.17273 7.33841 3.18189 7.3197ZM7.47448 8.65844C7.90833 5.80742 10.152 4.41223 11.4934 3.9789C12.4283 5.03395 13.6513 7.37597 12.5756 10.0517L7.47448 8.65844ZM16.6605 11.1674L13.6989 10.3585C14.5419 8.15272 14.0944 6.15451 13.3694 4.72106C15.7676 6.05514 17.161 8.46226 16.9568 11.082C16.9552 11.1028 16.9508 11.1095 16.9494 11.1117C16.9467 11.1159 16.9374 11.1284 16.914 11.1426C16.8629 11.1738 16.7696 11.1972 16.6605 11.1674Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00606 17.5373C4.00606 17.1921 4.28588 16.9123 4.63106 16.9123L13.3974 16.9123C13.7426 16.9123 14.0224 17.1921 14.0224 17.5373C14.0224 17.8825 13.7426 18.1623 13.3974 18.1623L4.63106 18.1623C4.28588 18.1623 4.00606 17.8825 4.00606 17.5373Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
};
