import { injectable } from 'inversify';
import { areIntervalsOverlapping } from 'date-fns';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { CalendarItemColor } from '@vk-hr-tek/ui/Calendar';
import { Range } from '@vk-hr-tek/ui/Calendar';

import {
  AbsenceEmployee,
  AbsenceListGroup,
  AbsenceItem,
  Unit,
} from '@app/gen/absences';

@injectable()
export class AbsencesMapper {
  constructor(private calendar: Calendar) {}

  processEmployee(employee: AbsenceEmployee, units?: Unit[]) {
    return {
      type: 'group',
      name: employee.unit?.name,
      personalNumber: employee.personnel_number,
      position: employee.position,
      structure: this.processGroupStructure(units, units?.[0]?.id),
      supervisor: this.processGroupSupervisor(employee.unit),
      employeeId: employee.id,
    };
  }

  processGroupStructure(units?: Unit[], rootId?: string): string[] {
    if (!units || !rootId) {
      return [];
    }

    const result: string[] = [];
    let nextId: string | undefined = rootId;

    const unitsMap = units?.reduce((acc: Record<string, Unit>, curr) => {
      acc[curr.id] = curr;

      return acc;
    }, {});

    while (nextId && unitsMap[nextId]) {
      result.push(unitsMap[nextId].name);
      nextId = unitsMap[nextId].parent_id;
    }

    return result.reverse();
  }

  processGroupSupervisor(unit?: Unit) {
    if (!unit || !unit.manager) {
      return;
    }

    const name = [
      unit.manager?.last_name || '',
      unit.manager?.first_name || '',
      unit.manager?.middle_name || '',
    ]
      .filter((el) => el)
      .join(' ');

    return {
      name,
      personalNumber: unit.manager?.personnel_number || '',
    };
  }

  private processRangeItem(absence: AbsenceItem) {
    let color: CalendarItemColor = 'green';

    if (absence.status === 'new') color = 'tertirary';
    else if (absence.status === 'event_created') color = 'purple';
    else if (absence.status === 'approved') color = 'green';
    else if (absence.status === 'in_progress') color = 'green';
    else if (absence.status === 'completed') color = 'gray';

    return {
      id: absence.type,
      eventId: absence.event_id || '',
      start: new Date(absence.from_date),
      end: new Date(absence.to_date),
      tooltip: absence.type,
      color,
      status: absence.status,
      count: absence.days_count,
    };
  }

  private processRanges(absences: AbsenceItem[]) {
    const ranges: Range[] = absences
      .map((range) => this.processRangeItem(range))
      .sort((range1, range2) => {
        if (this.calendar.isSameDay(range1.end, range2.end)) {
          return range1.start > range2.start ? 1 : -1;
        } else {
          return range1.end > range2.end ? 1 : -1;
        }
      });

    let i = 0;
    const result: {
      start: Date;
      end: Date;
      ranges: Range[];
    }[] = [];

    while (i < ranges.length) {
      const currentRange = ranges[i];
      const start = currentRange.start;
      let end = currentRange.end;
      const formattedRanges = [currentRange];

      let j = i + 1;

      while (
        ranges[j] &&
        (areIntervalsOverlapping(
          { start, end },
          { start: ranges[j].start, end: ranges[j].end },
        ) ||
          this.calendar.isSameDay(end, ranges[j].start))
      ) {
        end = ranges[j].end;
        formattedRanges.push(ranges[j]);
        j++;
        i = j - 1;
      }

      result.push({
        start,
        end,
        ranges: formattedRanges,
      });
      i++;
    }

    return result;
  }

  processEmployeeAbsences(absences: AbsenceItem[], employeeId: string) {
    const absenceTypes = absences.reduce(
      (acc: Record<string, AbsenceItem[]>, curr: AbsenceItem) => {
        if (acc[curr.type]) {
          acc[curr.type].push(curr);
        } else {
          acc[curr.type] = [curr];
        }
        return acc;
      },
      {},
    );

    return Object.entries(absenceTypes)
      .filter(([, ranges]) => ranges.length)
      .map(([absenceType, ranges]) => ({
        type: 'item',
        name: {
          fullName: absenceType,
        },
        employeeId,
        ranges: this.processRanges(ranges),
        calendarDiff: {},
      }));
  }

  processCompanyAbsences(
    absences: AbsenceItem[],
    employeeId: string,
    employeeName: string,
  ) {
    if (!absences.length) {
      return [
        {
          type: 'item',
          name: {
            fullName: employeeName,
          },
          eventId: '',
          tooltip: '',
          employeeId,
          ranges: [],
          calendarDiff: {},
        },
      ];
    }

    return [
      {
        type: 'item',
        name: {
          fullName: employeeName,
        },
        tooltip: '',
        employeeId,
        eventId: '',
        ranges: this.processRanges(absences),
        calendarDiff: {},
      },
    ];
  }

  processAbsenceClosestTask(groupes: AbsenceListGroup[]) {
    return groupes
      .reduce((acc: AbsenceItem[] | [], { absences }) => {
        return [...acc, ...absences];
      }, [])
      .sort((a, b) => {
        return a.from_date > b.from_date ? 1 : -1;
      })
      .filter(
        (item) =>
          !item.event_id &&
          item.is_scheduled_vacation &&
          item.status === 'new' &&
          new Date() < new Date(item.from_date),
      )
      .map((item) => {
        return { ...item, type: item.type };
      })[0];
  }

  processEmployeeGroups(groups: AbsenceListGroup[]) {
    const employeeGroups = groups.reduce(
      (acc: any, { employee, absences, units }) => {
        acc.push(this.processEmployee(employee, units));
        acc.push(...this.processEmployeeAbsences(absences, employee.id));

        return acc;
      },
      [],
    );

    return employeeGroups;
  }

  processCompanyGroups(groups: AbsenceListGroup[]) {
    const companyGroups = groups.reduce(
      (acc: any, { employee, absences, units }) => {
        const group = this.processEmployee(employee, units);

        const groupAlreadyInData = acc.find(
          ({ type, name }: { type: string; name: string }) =>
            type === group.type && name === group.name,
        );

        if (!groupAlreadyInData) {
          acc.push(group);
        }

        acc.push(
          ...this.processCompanyAbsences(absences, employee.id, employee.name),
        );

        return acc;
      },
      [],
    );

    return companyGroups;
  }
}
