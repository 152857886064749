import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import {
  UserLoginResponse as AuthResponse,
  UserRegisterPhoneVerifiedResponse,
} from '@app/gen/users';

import {
  RegisterInitDto,
  RegisterDto,
  RegisterWithVerifiedPhoneDto,
} from '../../dto';

@injectable()
export class AuthRegisterService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async registerInit(registerInitDto: RegisterInitDto) {
    await this.validator.validateOrReject(registerInitDto, RegisterInitDto);

    await this.http.post(
      '/user/register/init',
      {
        phone: registerInitDto.phone,
        invite_code: registerInitDto.inviteCode,
        snils: registerInitDto.snils
          ? registerInitDto.snils.replace(/(-| )/g, '')
          : /* istanbul ignore next */
            '',
      },
      { withAuth: false },
    );
  }

  async register(registerDto: RegisterDto) {
    await this.validator.validateOrReject(registerDto, RegisterDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/user/register',
        {
          invite_code: registerDto.inviteCode,
          credentials: {
            phone_verification: {
              phone: registerDto.phone,
              code: registerDto.code,
            },
            password: registerDto.password,
          },
        },
        { withAuth: false },
      ),
      AuthResponse,
    );

    return result;
  }

  async registerWithVerifiedPhone(
    registerWithVerifiedPhoneDto: RegisterWithVerifiedPhoneDto,
  ) {
    await this.validator.validateOrReject(
      registerWithVerifiedPhoneDto,
      RegisterWithVerifiedPhoneDto,
    );

    const { inviteCode, password, snils } = registerWithVerifiedPhoneDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/user/phone_verified/register',
        {
          invite_code: inviteCode,
          password,
          snils,
        },
        { withAuth: false },
      ),
      UserRegisterPhoneVerifiedResponse,
    );

    return result;
  }
}
