import React, { useEffect, useCallback, useLayoutEffect } from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';

import { Box } from '@vk-hr-tek/ui/Box';
import { LocalStorage } from '@vk-hr-tek/core/local-storage';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Idle } from '@vk-hr-tek/core/idle';
import { Logger } from '@vk-hr-tek/core/logger';
import { DownloadNotification } from '@vk-hr-tek/ui/DownloadNotification';
import { InfiniteScrollProvider } from '@vk-hr-tek/ui/InfiniteScroll';
import {
  selectDownloads,
  cancelDownloads,
} from '@vk-hr-tek/core/download-notification/slice';
import { VkIcon } from '@vk-hr-tek/ui/icons';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useMetaTitles } from '@vk-hr-tek/core/hooks';
import { useTheme } from '@vk-hr-tek/ui/Theme';

import { useSelector, useDispatch, useRole, useRedirects } from '@app/hooks';

import { Chat } from '../Chat';
import { useAuth } from '../../auth';
import { logout } from '../../auth/slice';
import { AuthRouterService } from '../../auth/services';
import {
  selectCertificateStatus,
  selectUserRepresentative,
  changeRole,
} from '../../user';
import { Notifications } from '../notifications';
import { ErrorBoundary } from '../error-boundary';
import { Logo } from '../ui/Logo';
import { DisabledLKLayout } from '../DisabledLKLayout';
import { Footer } from '../ui/Footer';
import { selectServiceUnavailable } from '../../layout/slice';
import { setSidebarState } from '../slice';
import { PageError } from '../page';
import { Header } from '../ui/Header';
import { Sidebar } from '../ui/Sidebar';

import { vkLinks } from './GeneralLayout.helpers';
import { useStyles } from './GeneralLayout.styles';

interface GeneralLayoutProps {
  children: JSX.Element;
  metaTitles: { title: { company: string; employee: string }; href: string }[];
}

window.webim = {
  accountName: window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME,
  domain: `${window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME}.webim.ru`,
  location: 'hr_box',
};

export const GeneralLayout = ({ children, metaTitles }: GeneralLayoutProps) => {
  useRedirects();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isAuthenticated = useAuth();
  const { pathname } = useLocation();
  const authRouter = useInject(AuthRouterService);
  const logger = useInject(Logger);
  const isDesktop = useIsDesktop();
  const localStorageService = useInject<LocalStorage>(LocalStorage);
  const sidebarState = localStorageService.getItem('sidebarState');

  const theme = useTheme();

  const [role] = useRole();

  const certificateStatus = useSelector(selectCertificateStatus);
  const isServiceUnavailable = useSelector(selectServiceUnavailable);
  const userRepresentative = useSelector(selectUserRepresentative);

  const notifications = useSelector(selectDownloads);

  const handleCloseDownloadNotification = useCallback(() => {
    dispatch(cancelDownloads());
  }, [dispatch]);

  const isLkDisabled =
    window.REACT_APP_VKHRTEK_LK_DISABLED && !userRepresentative;

  const isDashboard = pathname === '/';

  const layoutHeightGap = theme === 'vk' && isDesktop ? '37px' : '0px';

  const handleIdle = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useMetaTitles(metaTitles, role);

  const renderHeader = () => {
    if (isDashboard && !isLkDisabled) {
      return <Box id="dashboard-header" className={classes.dashboardHeader} />;
    } else {
      return <Header />;
    }
  };

  useEffect(() => {
    if (!isAuthenticated && !pathname.startsWith('/auth/')) {
      authRouter.redirectToLogin();
    }
  }, [isAuthenticated, pathname, authRouter]);

  useEffect(() => {
    dispatch(setSidebarState(sidebarState === 'true'));
  }, [dispatch, sidebarState, role]);

  useLayoutEffect(() => {
    dispatch(changeRole(role));
  }, [role, dispatch]);

  if (isServiceUnavailable) {
    return <PageError status={503} />;
  }

  return (
    <ErrorBoundary logger={logger}>
      <InfiniteScrollProvider target="scrollable-target">
        <div className={classes.root}>
          {isAuthenticated &&
            window.location.hostname ===
              window.REACT_APP_VKHRTEK_ALI_DOMAIN && (
              <Idle onIdle={handleIdle} />
            )}
          {isAuthenticated && certificateStatus === 'released' ? (
            <>
              <AppBar component="div" className={classes.myAppBar}>
                {theme === 'vk' && (
                  <div className={classes.vkHeader}>
                    <a
                      href="https://vk.company"
                      rel="noopener noreferrer"
                      target="_blank"
                      className={classes.vkHeaderLogo}
                    >
                      <Box fontSize={16}>
                        <VkIcon />
                      </Box>
                    </a>
                    {vkLinks.map(({ label, url }) => (
                      <a
                        href={url}
                        key={url}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.vkHeaderLink}
                      >
                        {label}
                      </a>
                    ))}
                  </div>
                )}
              </AppBar>
              <Box display="flex">
                {isDesktop && !isLkDisabled && (
                  <Sidebar logo={<Logo inSidebar />} vkTheme={theme === 'vk'} />
                )}

                <Box
                  id="scrollable-target"
                  display="flex"
                  flexDirection="column"
                  height={`calc(100vh - ${layoutHeightGap})`}
                  width="100%"
                  overflow="auto"
                >
                  {renderHeader()}

                  <div className={classNames(classes.main, classes.content)}>
                    <Container className={classes.container}>
                      {isLkDisabled ? (
                        <DisabledLKLayout />
                      ) : (
                        <>
                          <Notifications />
                          {children}
                          <DownloadNotification
                            notifications={notifications}
                            onClose={handleCloseDownloadNotification}
                          />
                        </>
                      )}
                    </Container>
                  </div>

                  {window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME && <Chat />}
                  <Footer />
                </Box>
              </Box>
            </>
          ) : (
            children
          )}
        </div>
      </InfiniteScrollProvider>
    </ErrorBoundary>
  );
};
