import React, { useCallback } from 'react';

import { useField } from 'react-final-form';

import { TreeViewAutocomplete as Input } from '../../input';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ValidateType = (value: any, allValues?: any) => string | undefined;

type TreeNode<T> = {
  children?: Option<T>[];
};

interface Filter {
  limit: number;
  offset: number;
  query: string;
}

type Option<T> = Omit<T, 'children'> & TreeNode<T>;

interface TreeViewAutocompleteProps<T> {
  disabled?: boolean;
  name: string;
  label: string;
  tooltip?: React.ReactNode;
  required?: boolean;
  options: Option<T>[];
  noOptionsText?: string;
  placeholder?: string;
  clearable?: boolean;
  getOptionLabel?: (option: T) => string;
  validate?: ValidateType[] | ValidateType;
  onChange?: (value: string | undefined) => void;
  onChildrenLoad?: (value: string) => Promise<T[]>;
  onSearch?: (filter: Filter) => Promise<T[]>;
  onLoadTree?: (unitId: string) => Promise<Option<T>[]>;
  testId?: string;
}

export const TreeViewAutocomplete = <T extends { id: string; label: string }>({
  disabled,
  name,
  label,
  tooltip,
  options,
  placeholder,
  clearable,
  required,
  getOptionLabel,
  noOptionsText,
  validate = [],
  onChange,
  onChildrenLoad,
  onSearch,
  onLoadTree,
  testId,
}: TreeViewAutocompleteProps<T>) => {
  const composeValidators =
    (validators: ValidateType[]) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: Record<string, any> | any, allValues: Record<string, any>) =>
      validators.reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any, validator) => error || validator(value, allValues),
        undefined,
      );

  const { input, meta } = useField(name, {
    validate: composeValidators(
      Array.isArray(validate) ? validate : [validate],
    ),
  });

  const handleChange = useCallback(
    (value) => {
      input.onChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [input, onChange],
  );

  return (
    <Input
      {...input}
      value={input.value}
      testId={testId}
      onChange={handleChange}
      label={label}
      options={options}
      disabled={disabled}
      tooltip={tooltip}
      placeholder={placeholder}
      clearable={clearable}
      required={required}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      error={meta.touched && !!meta.error}
      helperText={meta.error}
      onChildrenLoad={onChildrenLoad}
      onSearch={onSearch}
      onLoadTree={onLoadTree}
    />
  );
};
