import React, { ReactNode, KeyboardEvent, MouseEvent } from 'react';

import classNames from 'classnames';
import { Fade } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { Box } from '@vk-hr-tek/ui/Box/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Tooltip } from '@vk-hr-tek/ui/Tooltip';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { TIMEOUT_FOR_FADE } from '../Sidebar.constants';

import { useStyles } from './SidebarItem.styles';

interface SidebarItemProps {
  title: string;
  sidebarOpened: boolean;
  icon: ReactNode;
  href: string;
  isActive: boolean;
  aqaClass: string;
  skeletonLoading: boolean;
  closeDrawer?: (event: KeyboardEvent | MouseEvent) => void;
}

export const SidebarItem = ({
  title,
  sidebarOpened,
  skeletonLoading,
  href,
  aqaClass,
  icon,
  isActive,
  closeDrawer,
}: SidebarItemProps) => {
  const styles = useStyles();

  const renderAqaClass = (className: string): string =>
    `${aqaClass}_${className}`;

  return (
    <Box>
      <Link onClick={closeDrawer} to={href} variant="simple">
        <Box
          className={classNames(
            styles.menuItem,
            isActive && styles.active,
            aqaClass,
          )}
        >
          <Tooltip title={title} placement="right" disabled={sidebarOpened}>
            <Box
              p="8"
              display="flex"
              minWidth={40}
              minHeight={40}
              alignItems="center"
              justifyContent="center"
              className={renderAqaClass('menuItemWithoutSubmenuIcon')}
            >
              {skeletonLoading ? <Skeleton width="100%" /> : icon}
            </Box>
          </Tooltip>

          <Fade in={sidebarOpened} timeout={TIMEOUT_FOR_FADE}>
            <Typography
              variant="body3"
              noWrap
              className={renderAqaClass('menuItemWithoutSubmenuText')}
            >
              {skeletonLoading ? <Skeleton width={200} /> : title}
            </Typography>
          </Fade>
        </Box>
      </Link>
    </Box>
  );
};
