import React, { ReactNode } from 'react';

import { FormControlLabel, Switch } from '@material-ui/core';

import { Box } from '../../Box';
import { Label } from '../common';

import useStyles from './SwitchInput.styles';

interface SwitchInputProps {
  label: string;
  onChange: (check: boolean) => void;
  checked: boolean;
  disabled?: boolean;
  labelPlacement?: 'start' | 'end';
  tooltip?: ReactNode | null;
}

export const SwitchInput = ({
  label,
  onChange,
  disabled = false,
  labelPlacement = 'start',
  checked,
  tooltip,
}: SwitchInputProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ root: classes.root }}
      control={
        <Switch
          checked={checked}
          disableRipple
          classes={{
            root: classes.switchRoot,
            track: classes.track,
            switchBase: classes.switchBase,
            disabled: classes.disabled,
            thumb: classes.thumb,
          }}
          color="primary"
          disabled={disabled}
          onChange={(event, check) => {
            onChange(check);
          }}
        />
      }
      label={
        <Box
          mr={labelPlacement === 'start' ? '16' : '0'}
          ml={labelPlacement === 'end' ? '16' : '0'}
          mb="-4"
        >
          <Label label={label} tooltip={tooltip} />
        </Box>
      }
      labelPlacement={labelPlacement}
      className="aqa_switch_input"
    />
  );
};
