import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { WidgetPartitionsResponse } from '@app/gen/dashboard';

@injectable()
export class PartitionsService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getPartitions(role: 'employee' | 'company') {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/widget/partitions',
        {},
        { reloadIfOldVersion: true, withSide: true, role },
      ),
      WidgetPartitionsResponse,
    );

    return result.partitions;
  }
}
