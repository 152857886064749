import { Workbox } from 'workbox-window';

export const serviceWorkerRegister = () => {
  window.addEventListener('load', () => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js', {
        scope: `/`,
      });
      wb.register().then(() => {
        // do nothing
      });
    }
  });
};
