import React from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { useAuthType } from '@vk-hr-tek/app/auth/hooks';

import {
  GeneralAuth,
  VKAuth,
  AliAuth,
  CandidateAuth,
  OAuth2,
  OpenId,
} from './pages';

export const Auth = () => {
  const { status, authType, oauth2Source } = useAuthType();

  if (status === 'idle' || status === 'loading')
    return (
      <Box display="flex" justifyContent="center" p="32">
        <CircularProgress size={50} />
      </Box>
    );

  switch (authType) {
    case 'candidate':
      return <CandidateAuth />;

    case 'vk':
    case 'internalVpnOff':
      return <VKAuth />;

    case 'pass':
      return <AliAuth />;

    case 'openid':
      return <OpenId />;

    case 'oauth2': {
      if (oauth2Source === 'keycloak') {
        return <OAuth2 />;
      }

      return <GeneralAuth />;
    }

    default:
      return <GeneralAuth />;
  }
};
