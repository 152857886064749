import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { UserLoginResponse as AuthResponse } from '@app/gen/users';

import { LoginInitDto, LoginDto } from '../../dto';

@injectable()
export class AuthLoginService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async loginInit(loginInitDto: LoginInitDto) {
    await this.validator.validateOrReject(loginInitDto, LoginInitDto);

    await this.http.post('/user/login/init', loginInitDto, { withAuth: false });
  }

  async login(loginDto: LoginDto) {
    await this.validator.validateOrReject(loginDto, LoginDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/user/login',
        {
          credentials: {
            phone_verification: {
              phone: loginDto.phone,
              code: loginDto.code,
            },
            password: loginDto.password,
          },
          invite_code: loginDto.inviteCode,
        },
        { withAuth: false },
      ),
      AuthResponse,
    );

    return result;
  }

  async logout() {
    await this.http.post('/user/logout');
  }
}
