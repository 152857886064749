import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import { Box } from '@vk-hr-tek/ui/Box';
import { Link } from '@vk-hr-tek/ui/Link';
import { Typography } from '@vk-hr-tek/ui/Typography';

export const SubstitutesHeader = () => {
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.push('/profile#substitutes');
  }, [history]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb="8">
        <Link size="small" onClick={handleGoBack} stroke={false}>
          Назад
        </Link>
      </Box>
      <Box display="flex" alignItems="center">
        <Box whiteSpace="pre-wrap">
          <Typography variant="h4">Добавление заместителя</Typography>
        </Box>
      </Box>
    </Box>
  );
};
