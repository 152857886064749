import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { GetElectronicServiceLogoDto } from '@vk-hr-tek/app/dashboard/dto';
import { ValidationService } from '@vk-hr-tek/core/validation';

import { WidgetLinksResponse } from '@app/gen/dashboard';

@injectable()
export class ElectronicServicesService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
    private validator: ValidationService,
  ) {}

  async getElectronicServices() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get('/widget/links'),
      WidgetLinksResponse,
    );

    return result.links;
  }

  async getElectronicServiceLogo(
    getElectronicServiceLogoDto: GetElectronicServiceLogoDto,
  ) {
    await this.validator.validateOrReject(
      getElectronicServiceLogoDto,
      GetElectronicServiceLogoDto,
    );

    const result = await this.http.getFile(
      `/widget/link/${getElectronicServiceLogoDto.logo_id}/logo`,
    );

    return result;
  }
}
