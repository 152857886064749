import React, { useContext } from 'react';

import ReactInfiniteScroll, { Props } from 'react-infinite-scroll-component';

import { useStyles } from './InfiniteScroll.styles';
import { InfiniteScrollContext } from './InfiniteScrollProvider';

interface InfiniteScrollProps extends Props {
  withOverflow?: boolean;
}

export const InfiniteScroll = ({ children, ...props }: InfiniteScrollProps) => {
  const classes = useStyles();
  const { withOverflow } = props;
  const scrollTarget = useContext(InfiniteScrollContext);

  return (
    <ReactInfiniteScroll
      {...(scrollTarget
        ? {
            scrollableTarget: scrollTarget,
          }
        : {})}
      {...props}
      {...(withOverflow && {
        className: classes.infiniteScroll,
      })}
    >
      {children}
    </ReactInfiniteScroll>
  );
};
