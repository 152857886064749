import React, { useCallback } from 'react';

import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';
import { Typography } from '@vk-hr-tek/ui/Typography';

import { UserRouter } from '../../../types';

interface AttorneySettingsNotificationProps {
  companyId: string;
}

export const AttorneySettingsNotification = ({
  companyId,
}: AttorneySettingsNotificationProps) => {
  const router = useInject<UserRouter>(UserRouter);
  const isDesktop = useIsDesktop();

  const goToAttorneySettings = useCallback(() => {
    router.goToProfileAttorneySettings(companyId);
  }, [companyId, router]);

  const title = (
    <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>
      Управление машиночитаемыми доверенностями
    </Typography>
  );

  const content = (
    <Typography variant={isDesktop ? 'body2' : 'body3'} color="textTertiary">
      Вы можете сделать одну из доверенностей активной — она будет
      использоваться при подписании документов УКЭП. Если при подписании
      активная МЧД не будет найдена, система сделает активной любую из
      доверенностей, которые имеют юридическую силу при подписании. В нашей
      системе это МЧД в статусах Действующая и В процессе отзыва.
    </Typography>
  );

  const button = (
    <Button onClick={goToAttorneySettings} size="small">
      Выбрать
    </Button>
  );

  return isDesktop ? (
    <Box
      display="flex"
      mb="32"
      radius="l"
      border="1px solid"
      borderColor="stroke.primary"
      p="24"
      gap="24"
    >
      <Box flexGrow={1} flexShrink={1}>
        <Box mb="8">{title}</Box>
        {content}
      </Box>
      <Box flexGrow={0} flexShrink={0} alignSelf="center">
        {button}
      </Box>
    </Box>
  ) : (
    <Box
      mb="48"
      radius="l"
      border="1px solid"
      borderColor="stroke.primary"
      p="16"
      gap="16"
    >
      <Box display="flex" justifyContent="space-between" gap="12" mb="16">
        {title}
        {button}
      </Box>
      {content}
    </Box>
  );
};
