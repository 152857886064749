import {
  createAction,
  createAsyncThunk,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { TokenService } from '@vk-hr-tek/core/http';

import {
  UserLoginResponse as AuthResponse,
  UsersService,
  OpenIdUserRegisterRequest,
  OpenIdUserRegisterInitRequest,
  OpenIdTokenVerifyResponse,
} from '@app/gen/users';

import {
  AuthState,
  completeLoading,
  setError,
  startLoading,
  ThunkExtra,
} from '../../auth.state';
import { AuthRouterService, AuthOpenIdService } from '../../../services';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

export const setOpenIdToken = createAction<string | null>(
  'auth/setOpenIdToken',
);

export const verifyOpenIdToken = createAsyncThunk<
  OpenIdTokenVerifyResponse,
  void,
  ThunkExtra
>(
  'auth/verifyOpenIdToken',
  async (_, { rejectWithValue, dispatch, extra: { inject } }) => {
    const router = inject(AuthRouterService);
    const openidConfig = inject(AuthOpenIdService);

    try {
      const token = openidConfig.get();

      if (!token) {
        throw new AppError('server', {
          code: 500,
          message: 'Пустой OpenId токен',
          data: {
            error_code: 'front_empty_auth_code',
          },
        });
      }

      dispatch(setOpenIdToken(token));

      const result = await inject(UsersService).openidTokenVerify({
        body: {
          token,
        },
      });

      const tokenService = inject<TokenService>(TokenService);

      if (result.status === 'register') {
        router.redirectToRegister();
      } else {
        if (!result.access_token) {
          throw new AppError('server', {
            code: 500,
            message: 'Пустой токен',
            data: {
              error_code: 'front_empty_auth_code',
            },
          });
        }

        tokenService.save(result.access_token);

        await delay(1000);
        router.redirectToSaved();
      }

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const registerInitOpenId = createAsyncThunk<
  void,
  {
    values: Omit<OpenIdUserRegisterInitRequest, 'token'>;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerInitOpenId',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const { openIdToken } = state;

      await inject(UsersService).openidUserRegisterInit({
        body: {
          ...values,
          token: openIdToken || '',
        },
      });

      actions.resolve(null);
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const registerOpenId = createAsyncThunk<
  AuthResponse,
  {
    values: Omit<OpenIdUserRegisterRequest, 'phone' | 'token'>;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/registerOpenId',
  async (
    { values, actions },
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().auth;

      const {
        openIdToken,
        values: { phone },
      } = state;

      const result = await inject(UsersService).openidUserRegister({
        body: {
          phone_code: values.phone_code,
          token: openIdToken || '',
          phone: phone || '',
        },
      });

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(1000);

      actions.resolve(null);
      inject(AuthRouterService).redirectToSaved();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const openIdReducers = (builder: ActionReducerMapBuilder<AuthState>) => {
  builder.addCase(setOpenIdToken, (state, action) => {
    state.openIdToken = action.payload;
  });

  builder.addCase(verifyOpenIdToken.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(verifyOpenIdToken.fulfilled, (state) => {
    completeLoading(state);
    state.stage = 'init';
  });
  builder.addCase(verifyOpenIdToken.rejected, (state, { payload }) => {
    state.stage = 'error';

    if (payload && payload.errorCode === 'not_found_employee') {
      setError(state, {
        payload: {
          title: 'Вас пока не подключили к сервису',
          description:
            'Если вы считаете, что должны быть подключены, пожалуйста, <a href="mailto:hrbox-support@lists.vk.team">напишите нам</a>',
        },
      });
    } else {
      setError(state, { payload });
    }
  });

  builder.addCase(registerInitOpenId.pending, (state, action) => {
    startLoading(state);

    state.values.phone = action.meta.arg.values.phone;
  });
  builder.addCase(registerInitOpenId.fulfilled, (state) => {
    state.stage = 'finish';

    completeLoading(state);
  });
  builder.addCase(registerInitOpenId.rejected, (state, { payload }) => {
    setError(state, { payload });
  });

  builder.addCase(registerOpenId.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(registerOpenId.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
};
