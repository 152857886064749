import React from 'react';

import { useTheme } from '@material-ui/core';

export const SidebarHomePageIcon = () => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3 10.9991L2.95924 10.999L2.77789 10.988C2.26211 10.9317 1.78614 10.6811 1.44752 10.2861C0.712601 9.42867 0.811897 8.13783 1.66931 7.40291L8.21033 1.79632C9.24017 0.913596 10.7598 0.913596 11.7897 1.79632L18.3307 7.40291C18.7839 7.79137 19.0447 8.35848 19.0447 8.95539C19.0447 10.0847 18.1293 11.0001 17 11.0001V15.2501C17 16.4928 15.9926 17.5001 14.75 17.5001H11.95C11.4253 17.5001 11 17.0748 11 16.5501V13.5001C11 12.9478 10.5523 12.5001 10 12.5001C9.44772 12.5001 9 12.9478 9 13.5001V16.5501C9 17.0748 8.57467 17.5001 8.05 17.5001H5.25C4.00736 17.5001 3 16.4928 3 15.2501V10.9991ZM14.75 16.0001C15.1642 16.0001 15.5 15.6643 15.5 15.2501V10.3501C15.5 9.88068 15.8806 9.50012 16.35 9.50012H17C17.3008 9.50012 17.5447 9.25624 17.5447 8.95539C17.5447 8.79637 17.4753 8.64528 17.3545 8.54179L10.8135 2.93521C10.3454 2.53397 9.65462 2.53397 9.18651 2.93521L2.64549 8.54179C2.41707 8.73758 2.39062 9.08148 2.58641 9.3099C2.67661 9.41514 2.80344 9.4819 2.9001 9.49357L3 9.50012H3.65C4.11944 9.50012 4.5 9.88068 4.5 10.3501V15.2501C4.5 15.6643 4.83579 16.0001 5.25 16.0001H7.5V13.5001C7.5 12.1194 8.61929 11.0001 10 11.0001C11.3807 11.0001 12.5 12.1194 12.5 13.5001V16.0001H14.75Z"
        fill={theme.tokens.colors.text.light.primary}
      />
    </svg>
  );
};
