import { IsNotEmpty, IsUUID, IsOptional, IsInt } from 'class-validator';

export class GetCompanyUnitEmployeesDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsUUID()
  @IsNotEmpty()
  unitId: string;

  @IsInt()
  @IsOptional()
  limit?: number;

  @IsInt()
  @IsOptional()
  offset?: number;
}
