import { createSlice } from '@reduxjs/toolkit';

import { initialState, layoutAdapter } from './layout.state';
import { layoutReducers } from './actions';

export const layout = createSlice({
  name: 'layout',
  initialState: layoutAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    layoutReducers(builder);
  },
});

export const layoutReducer = layout.reducer;
