import React from 'react';

import { useTheme } from '@material-ui/core';

export const VacationScheduleWidgetIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill={theme.tokens.colors.accent.bg.warning}
      />
      <g clipPath="url(#clip0_2950_53302)">
        <rect
          width="24"
          height="24"
          transform="translate(8 8)"
          fill={theme.tokens.colors.accent.bg.warning}
        />
        <path
          d="M27.3111 10.0042C27.1641 9.70688 26.8578 9.50244 26.5038 9.50244C26.131 9.50244 25.8111 9.7291 25.6745 10.0521C25.6289 10.1598 25.6038 10.2782 25.6038 10.4024V12.2001H23.905C23.5322 12.2001 23.2124 12.4267 23.0757 12.7498C23.0302 12.8574 23.005 12.9758 23.005 13.1001C23.005 13.5971 23.4079 14.0001 23.905 14.0001H25.6038L25.6038 15.5978C25.6038 16.0948 26.0067 16.4978 26.5038 16.4978C26.7523 16.4978 26.9773 16.397 27.1402 16.2341C27.303 16.0713 27.4038 15.8463 27.4038 15.5978L27.4038 14.0001H29.1027C29.3512 14.0001 29.5762 13.8993 29.7391 13.7365C29.9019 13.5736 30.0027 13.3486 30.0027 13.1001C30.0027 12.9854 29.9812 12.8757 29.9421 12.7748C29.8117 12.4385 29.485 12.2001 29.1027 12.2001H27.4038V10.4024C27.4038 10.2594 27.3704 10.1242 27.3111 10.0042Z"
          fill={theme.tokens.colors.accent.text.warning}
        />
        <path
          d="M16.0007 9.50247C16.4978 9.50247 16.9007 9.90541 16.9007 10.4025V11.0064H20.5039C21.0009 11.0064 21.4039 11.4093 21.4039 11.9064C21.4039 12.4034 21.0009 12.8064 20.5039 12.8064H16.9007V13.595C16.9007 14.0921 16.4978 14.495 16.0007 14.495C15.5037 14.495 15.1007 14.0921 15.1007 13.595V12.8569C14.5786 12.9279 14.1309 13.0812 13.7803 13.3634C13.2924 13.756 12.8 14.5609 12.8 16.3715L12.8 17.005H23.7344C24.2314 17.005 24.6343 17.4079 24.6343 17.905C24.6343 18.402 24.2314 18.805 23.7344 18.805H12.8001L12.8003 24.1763C12.8003 25.0776 12.9924 25.8215 13.3992 26.3264C13.7766 26.7948 14.4537 27.1986 15.7605 27.1986H24.0913C25.1972 27.1986 25.9498 26.9059 26.4215 26.4613C26.8848 26.0247 27.1997 25.3257 27.1997 24.2499V18.8361C27.1997 18.339 27.6027 17.9361 28.0997 17.9361C28.5968 17.9361 28.9997 18.339 28.9997 18.8361V24.2499C28.9997 25.6861 28.5682 26.9115 27.6561 27.7712C26.7525 28.623 25.5009 28.9986 24.0913 28.9986H15.7605C14.0486 28.9986 12.7956 28.4461 11.9976 27.4558C11.2289 26.5019 11.0003 25.2847 11.0003 24.1764L11 16.3715C11 14.2442 11.5887 12.8165 12.6518 11.961C13.4036 11.3561 14.2904 11.1249 15.1007 11.0447V10.4025C15.1007 9.90541 15.5037 9.50247 16.0007 9.50247Z"
          fill={theme.tokens.colors.accent.text.warning}
        />
      </g>
      <defs>
        <clipPath id="clip0_2950_53302">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
