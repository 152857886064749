import React from 'react';

import MUIBadge, { BadgeProps as MUIBadgeProps } from '@material-ui/core/Badge';

import { useStyles } from './Badge.styles';

type BadgeProps = MUIBadgeProps & {
  visible?: boolean;
};

export const Badge = (props: BadgeProps) => {
  const { visible = false, ...rest } = props;

  const classes = useStyles();

  return (
    <MUIBadge
      invisible={!visible}
      variant="dot"
      className={`aqa_badge ${classes.badge}`}
      {...rest}
    />
  );
};
