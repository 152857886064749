import React from 'react';

import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ExpandIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9765 1.8C20.9765 0.805887 21.7824 0 22.7765 0H31.1766C32.1707 0 32.9766 0.805887 32.9766 1.8V10.2C32.9766 11.1941 32.1707 12 31.1766 12C30.1824 12 29.3766 11.1941 29.3766 10.2V6.14559L22.0494 13.4728C21.3464 14.1757 20.2067 14.1757 19.5038 13.4728C18.8008 12.7699 18.8008 11.6302 19.5038 10.9272L26.831 3.6H22.7765C21.7824 3.6 20.9765 2.79411 20.9765 1.8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4494 18.5272C15.1523 19.2302 15.1523 20.3698 14.4494 21.0728L7.12215 28.4H11.1729C12.167 28.4 12.9729 29.2059 12.9729 30.2C12.9729 31.1941 12.167 32 11.1729 32H2.77656C1.78245 32 0.976562 31.1941 0.976562 30.2V21.8C0.976562 20.8059 1.78245 20 2.77656 20C3.77067 20 4.57656 20.8059 4.57656 21.8V25.8544L11.9038 18.5272C12.6067 17.8243 13.7464 17.8243 14.4494 18.5272Z"
    />
  </SvgIcon>
);
