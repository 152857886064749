import React, { ReactNode } from 'react';

import useStyles from './EllipsisText.styles';

interface EllipsisTextProps {
  rows?: number;
  children: ReactNode;
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
  hyphens?: boolean;
}

export const EllipsisText = ({
  rows = 2,
  wordBreak = 'normal',
  children,
  hyphens = false,
}: EllipsisTextProps) => {
  const classes = useStyles({ rows, wordBreak, hyphens });

  return (
    <span className={`${classes.ellipsis} aqa_ellipsis_text`}>{children}</span>
  );
};
