import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  notifications: {
    position: 'fixed',
    bottom: '115px',
    right: '50px',
    zIndex: 10000,
  },
  stack: {
    position: 'absolute',
    bottom: -40,
    right: 0,
  },
  text: {
    fontSize: '14px',
    lineHeight: '20px',
    width: 360,
  },
  content: {
    background: theme.tokens.colors.bg.dark.primary,
    color: theme.tokens.colors.bg.light.primary,
    borderRadius: '16px',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  wrapper: {
    borderRadius: '16px',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    maxHeight: '310px',
    overflow: 'auto',
  },
  inner: {
    borderRadius: '16px',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));
