import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },

  switchRoot: {
    margin: 0,
  },

  track: {
    backgroundColor: theme.tokens.colors.stroke.primary,
  },

  switchBase: {
    '&:hover': {
      backgroundColor: theme.tokens.colors.stroke.secondary,

      '& + .MuiSwitch-track': {
        backgroundColor: theme.tokens.colors.stroke.secondary,
        transition: 'none',
      },

      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.tokens.colors.original.brand.tertirary,
      },
    },
  },

  disabled: {
    '&.Mui-disabled': {
      color: theme.tokens.colors.bg.light.primary,

      '& + .MuiSwitch-track': {
        backgroundColor: theme.tokens.colors.bg.greyscale.primary,
        color: theme.tokens.colors.bg.light.primary,
        opacity: 1,
      },
    },

    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.tokens.colors.original.brand.secondary,
    },
  },

  thumb: {
    boxShadow: 'none',
  },
}));

export default useStyles;
