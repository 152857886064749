import React, { ReactNode } from 'react';

import classNames from 'classnames';

import useStyles from './List.styles';

interface ListProps {
  listStyleType?: string;
  pl?: number;
  children: ReactNode;
}

export const List = ({
  listStyleType = 'none',
  pl = 0,
  children,
}: ListProps) => {
  const classes = useStyles({ listStyleType, pl });

  return <ul className={classNames(classes.list, 'aqa_list')}>{children}</ul>;
};
