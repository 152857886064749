import {
  SidebarCandidatesPageIcon,
  SidebarCompanyPageIcon,
  SidebarEmployeesPageIcon,
  SidebarEventsPageIcon,
  SidebarPayslipsPageIcon,
  SidebarPolicyPageIcon,
  SidebarSettingsPageIcon,
  SidebarVacationsPageIcon,
  SidebarMyCalendarPageIcon,
  SidebarCompetenciesPageIcon,
  SidebarPersonalPageIcon,
  IconProps,
} from '@vk-hr-tek/ui/icons';

import { Resources } from '../ability';

export interface Module {
  title: { company: string; employee: string };
  basename: string;
  icon?: React.ComponentType<IconProps>;
  resource: Resources;
  showInNavigation: boolean;
  withSide: boolean;
  isPrivate: boolean;
}

export type ModulesNames =
  | 'dashboard'
  | 'absences'
  | 'auth'
  | 'user'
  | 'create_certificate'
  | 'events'
  | 'settings'
  | 'candidates'
  | 'employees'
  | 'policy'
  | 'payslips'
  | 'organization'
  | 'competencies'
  | 'vacations'
  | 'personal';

export type ModulesConfig = Record<
  Exclude<ModulesNames, 'events' | 'settings'>,
  Module
> & {
  events: Module & {
    children: Record<'events' | 'paper', Module>;
  };
  settings: Module & {
    children: Record<
      | 'settings'
      | 'templates'
      | 'groups'
      | 'profiles'
      | 'substitutes'
      | 'vacations'
      | 'company'
      | 'attorneys'
      | 'attorney_users'
      | 'unep_types',
      Module
    >;
  };
};

export const modules: ModulesConfig = {
  dashboard: {
    title: { company: 'Главная', employee: 'Главная' },
    basename: '',
    icon: SidebarEventsPageIcon,
    resource: 'Dashboard',
    showInNavigation: false,
    withSide: false,
    isPrivate: true,
  },
  auth: {
    title: {
      company: 'Вход в личный кабинет',
      employee: 'Вход в личный кабинет',
    },
    basename: 'auth',
    resource: 'all',
    showInNavigation: false,
    withSide: false,
    isPrivate: false,
  },
  user: {
    title: {
      company: 'Профиль',
      employee: 'Профиль',
    },
    basename: 'profile',
    resource: 'User',
    showInNavigation: false,
    withSide: false,
    isPrivate: true,
  },
  create_certificate: {
    title: {
      company: 'Профиль',
      employee: 'Профиль',
    },
    basename: 'create-certificate',
    resource: 'User',
    showInNavigation: false,
    withSide: false,
    isPrivate: true,
  },
  personal: {
    title: { company: 'Персональные данные', employee: 'Персональные данные' },
    basename: 'personal',
    resource: 'Personal',
    icon: SidebarPersonalPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  events: {
    title: { company: 'Заявки', employee: 'Заявки' },
    basename: 'events',
    icon: SidebarEventsPageIcon,
    resource: 'Events',
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
    children: {
      events: {
        title: { company: 'Все заявки', employee: 'Все заявки' },
        basename: '',
        resource: 'Events',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      paper: {
        title: { company: 'В бумагу', employee: 'В бумагу' },
        basename: 'paper',
        resource: 'PaperEvents',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
    },
  },
  candidates: {
    title: { company: 'Кандидаты', employee: 'Кандидаты' },
    basename: 'candidates',
    resource: 'Candidates',
    icon: SidebarCandidatesPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  employees: {
    title: { company: 'Сотрудники', employee: 'Сотрудники' },
    basename: 'employees',
    resource: 'Employees',
    icon: SidebarEmployeesPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  policy: {
    title: { company: 'Корп. документы', employee: 'Корп. документы' },
    basename: 'policy',
    resource: 'Policy',
    icon: SidebarPolicyPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  absences: {
    title: { company: 'Рабочее время', employee: 'Мой календарь' },
    basename: 'absences',
    resource: 'Absences',
    icon: SidebarMyCalendarPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  payslips: {
    title: { company: 'Доходы', employee: 'Доходы' },
    basename: 'payslips',
    resource: 'Payslips',
    icon: SidebarPayslipsPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  organization: {
    title: { company: 'Компания', employee: 'Компания' },
    basename: 'organization',
    resource: 'Organization',
    icon: SidebarCompanyPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  settings: {
    title: { company: 'Настройки', employee: 'Настройки' },
    basename: 'settings',
    resource: 'Settings',
    icon: SidebarSettingsPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
    children: {
      settings: {
        title: { company: 'Типы заявок', employee: 'Типы заявок' },
        basename: '',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      templates: {
        title: {
          company: 'Шаблоны документов',
          employee: 'Шаблоны документов',
        },
        basename: 'templates',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      groups: {
        title: {
          company: 'Роли сотрудников',
          employee: 'Роли сотрудников',
        },
        basename: 'groups',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      profiles: {
        title: {
          company: 'Профили сотрудников',
          employee: 'Профили сотрудников',
        },
        basename: 'profiles',
        resource: 'CompanyProfiles',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      substitutes: {
        title: {
          company: 'Заместители сотрудников',
          employee: 'Заместители сотрудников',
        },
        basename: 'substitutes',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      vacations: {
        title: {
          company: 'Графики отпусков',
          employee: 'Графики отпусков',
        },
        basename: 'vacations',
        resource: 'VacationsSettings',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      company: {
        title: {
          company: 'Настройки компании',
          employee: 'Настройки компании',
        },
        basename: 'company',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      attorneys: {
        title: {
          company: 'Список МЧД',
          employee: 'Список МЧД',
        },
        basename: 'attorneys',
        resource: 'Attorneys',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      attorney_users: {
        title: {
          company: 'Пользователи МЧД',
          employee: 'Пользователи МЧД',
        },
        basename: 'attorneys-users',
        resource: 'Attorneys',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
      unep_types: {
        title: {
          company: 'Типы ЭЦП',
          employee: 'Типы ЭЦП',
        },
        basename: 'unep-types',
        resource: 'SettingsAdmin',
        showInNavigation: true,
        withSide: true,
        isPrivate: true,
      },
    },
  },
  vacations: {
    title: {
      company: 'Графики отпусков',
      employee: 'Графики отпусков',
    },
    basename: 'vacations',
    resource: 'Vacations',
    icon: SidebarVacationsPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
  competencies: {
    title: {
      company: 'Компетенции сотрудника',
      employee: 'Компетенции сотрудника',
    },
    basename: 'competencies',
    resource: 'Competencies',
    icon: SidebarCompetenciesPageIcon,
    showInNavigation: true,
    withSide: true,
    isPrivate: true,
  },
};
