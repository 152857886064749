import React from 'react';

import { useStyles } from './Container.styles';

export const ContainerBackground = () => {
  const classes = useStyles();

  return (
    <svg
      className={classes.background}
      viewBox="0 0 1362 968"
      preserveAspectRatio="none"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M673.778-.74v521.747M1-.74C1 164.022 148.981 968 673.778 968V521.007m0 0c469.882 0 573.002-332.575 619.382-521.747M673.778 521.007c413.432 0 634.032 323.727 686.512 446.993"
        stroke="#6AEAFC"
        strokeWidth="2"
      ></path>
    </svg>
  );
};
