import { Type, Transform } from 'class-transformer';
import {
  IsInt,
  IsNumber,
  IsIn,
  IsArray,
  IsString,
  IsDateString,
  IsUUID,
  IsNotEmpty,
  ValidateNested,
  IsOptional,
  IsBoolean,
} from 'class-validator';

export class WidgetPartition {
  @IsIn([
    'events',
    'absences',
    'candidates',
    'employees',
    'policies',
    'organization',
    'events_to_paper',
    'settings_event_types',
    'settings_templates',
    'settings_groups',
    'settings_substitutes',
    'settings_company',
    'settings_attorneys',
    'settings_attorneys_users',
    'settings_unep_types',
    'settings_vacation',
    'smev_errors',
    'vacation',
    'personal_profile',
    'payslips',
    'competencies',
  ])
  @IsString()
  @IsNotEmpty()
  permission:
    | 'events'
    | 'absences'
    | 'candidates'
    | 'employees'
    | 'policies'
    | 'organization'
    | 'events_to_paper'
    | 'settings_event_types'
    | 'settings_templates'
    | 'settings_groups'
    | 'settings_substitutes'
    | 'settings_company'
    | 'settings_attorneys'
    | 'settings_attorneys_users'
    | 'settings_unep_types'
    | 'settings_vacation'
    | 'smev_errors'
    | 'vacation'
    | 'personal_profile'
    | 'payslips'
    | 'competencies';

  @IsInt()
  @IsOptional()
  counter?: number;
}

export class WidgetPartitionsResponse {
  @ValidateNested({ each: true })
  @Type(() => WidgetPartition)
  @IsArray()
  @IsNotEmpty()
  partitions: WidgetPartition[];
}

export class BirthdayEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsNotEmpty()
  company_name: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsNotEmpty()
  position: string;

  @IsString()
  @IsOptional()
  unit_name?: string;
}

export class CalendarBirthdaysResponse {
  @ValidateNested({ each: true })
  @Type(() => BirthdayEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: BirthdayEmployee[];

  @IsInt()
  @IsNotEmpty()
  total: number;
}

export type AbsenceStatus = string;

export class AbsenceItem {
  @IsString()
  @IsNotEmpty()
  type: string;

  @IsIn([
    'new',
    'event_created',
    'approved',
    'in_progress',
    'completed',
    'canceled',
  ])
  @IsString()
  @IsNotEmpty()
  status:
    | 'new'
    | 'event_created'
    | 'approved'
    | 'in_progress'
    | 'completed'
    | 'canceled';

  @IsDateString()
  @IsNotEmpty()
  from_date: string;

  @IsDateString()
  @IsNotEmpty()
  to_date: string;

  @IsInt()
  @IsNotEmpty()
  days_count: number;
}

export type DateType = string;

export class WorkCalendarDate {
  @IsDateString()
  @IsNotEmpty()
  date: string;

  @IsIn(['workday', 'weekend', 'holiday'])
  @IsString()
  @IsNotEmpty()
  type: 'workday' | 'weekend' | 'holiday';
}

export class CalendarWidgetEmployee {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  personnel_number: string;

  @IsString()
  @IsNotEmpty()
  position: string;

  @IsString()
  @IsOptional()
  unit_name?: string;

  @IsInt()
  @IsNotEmpty()
  vacation_available_days: number;

  @ValidateNested({ each: true })
  @Type(() => WorkCalendarDate)
  @IsArray()
  @IsNotEmpty()
  calendar_diff: WorkCalendarDate[];

  @ValidateNested({ each: true })
  @Type(() => AbsenceItem)
  @IsArray()
  @IsNotEmpty()
  absences: AbsenceItem[];
}

export class CalendarWidgetCompany {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested({ each: true })
  @Type(() => CalendarWidgetEmployee)
  @IsArray()
  @IsNotEmpty()
  employees: CalendarWidgetEmployee[];

  @IsBoolean()
  @IsNotEmpty()
  birthdays_available: boolean;
}

export class CalendarWidgetResponse {
  @ValidateNested({ each: true })
  @Type(() => CalendarWidgetCompany)
  @IsArray()
  @IsNotEmpty()
  companies: CalendarWidgetCompany[];
}

export class Link {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @IsString()
  @IsNotEmpty()
  url: string;
}

export class WidgetLinksResponse {
  @ValidateNested({ each: true })
  @Type(() => Link)
  @IsArray()
  @IsNotEmpty()
  links: Link[];
}

export class WidgetTasksBadge {
  @IsString()
  @IsNotEmpty()
  title: string;

  @IsInt()
  @IsNotEmpty()
  value: number;

  @IsIn(['blue', 'red'])
  @IsString()
  @IsNotEmpty()
  color: 'blue' | 'red';
}

export class WidgetTasksPolicy {
  @IsIn(['policy'])
  @IsString()
  @IsNotEmpty()
  partition: 'policy';

  @IsString()
  @IsNotEmpty()
  partition_title: string;

  @IsString()
  @IsNotEmpty()
  title: string;

  @ValidateNested({ each: true })
  @Type(() => WidgetTasksBadge)
  @IsArray()
  @IsNotEmpty()
  badges: WidgetTasksBadge[];
}

export type Side = string;

export class WidgetTasksVacationSchedule {
  @IsIn(['vacation_schedule'])
  @IsString()
  @IsNotEmpty()
  partition: 'vacation_schedule';

  @IsString()
  @IsNotEmpty()
  partition_title: string;

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsIn(['employee', 'company'])
  @IsString()
  @IsNotEmpty()
  side: 'employee' | 'company';

  @ValidateNested({ each: true })
  @Type(() => WidgetTasksBadge)
  @IsArray()
  @IsNotEmpty()
  badges: WidgetTasksBadge[];
}

export class EventStatusFilter {}

export class WidgetTasksEventFilter {
  @IsString()
  @IsOptional()
  actor?: string;

  @ValidateNested()
  @Type(() => EventStatusFilter)
  @IsOptional()
  status?: EventStatusFilter;
}

export class WidgetTasksEvent {
  @IsIn(['event'])
  @IsString()
  @IsNotEmpty()
  partition: 'event';

  @IsString()
  @IsNotEmpty()
  partition_title: string;

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsIn(['employee', 'company'])
  @IsString()
  @IsNotEmpty()
  side: 'employee' | 'company';

  @ValidateNested({ each: true })
  @Type(() => WidgetTasksBadge)
  @IsArray()
  @IsNotEmpty()
  badges: WidgetTasksBadge[];

  @ValidateNested()
  @Type(() => WidgetTasksEventFilter)
  @IsOptional()
  filter?: WidgetTasksEventFilter;
}

export class WidgetTasksResponse {
  @IsArray()
  @IsNotEmpty()
  tasks: (WidgetTasksEvent | WidgetTasksVacationSchedule | WidgetTasksPolicy)[];
}

export class QuickActionCreateEvent {
  @IsIn(['employee', 'company'])
  @IsString()
  @IsNotEmpty()
  side: 'employee' | 'company';

  @IsString()
  @IsNotEmpty()
  title: string;

  @IsIn(['create_event'])
  @IsString()
  @IsNotEmpty()
  type: 'create_event';

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  company_ids?: string[];

  @IsUUID(undefined, { each: true })
  @IsArray()
  @IsOptional()
  event_type_ids?: string[];
}

export class WidgetQuickActionsResponse {
  @IsArray()
  @IsNotEmpty()
  actions: QuickActionCreateEvent[];
}

export class WidgetPosition {
  @IsInt()
  @IsNotEmpty()
  x: number;

  @IsInt()
  @IsNotEmpty()
  width: number;

  @IsInt()
  @IsOptional()
  height?: number;
}

export class Widget {
  @IsIn([
    'quick_actions',
    'tasks',
    'links',
    'calendar',
    'partitions_employee',
    'partitions_company',
  ])
  @IsString()
  @IsNotEmpty()
  type:
    | 'quick_actions'
    | 'tasks'
    | 'links'
    | 'calendar'
    | 'partitions_employee'
    | 'partitions_company';

  @ValidateNested()
  @Type(() => WidgetPosition)
  @IsNotEmpty()
  position: WidgetPosition;

  @IsBoolean()
  @IsNotEmpty()
  hidden: boolean;
}

export class MainLayoutResponse {
  @ValidateNested({ each: true })
  @Type(() => Widget)
  @IsArray()
  @IsNotEmpty()
  widgets: Widget[];
}
