import { IsArray, IsNotEmpty, IsOptional, IsUUID } from 'class-validator';

export class GetCompanyEventTypesDto {
  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsUUID()
  @IsOptional()
  companyId?: string;
}

export class CreateSubstituteDto {
  @IsUUID()
  @IsNotEmpty()
  substituteId: string;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;

  @IsArray()
  @IsNotEmpty()
  eventTypes: string[];
}

export class GetCreateParamsDto {
  @IsUUID()
  @IsNotEmpty()
  companyId: string;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;
}
