import {
  IsArray,
  IsNotEmpty,
  IsNumberString,
  IsString,
  IsUUID,
  Length,
} from 'class-validator';

const notEmptyOptions = {
  message: 'Обязательное поле',
};

export class AcceptConfirmPoliciesDto {
  @IsNotEmpty(notEmptyOptions)
  @IsUUID(undefined, { each: true })
  @IsArray()
  policyVersions: string[];

  @Length(6, 6, {
    message: 'Неверный СМС-код',
    groups: ['form'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный СМС-код',
      groups: ['form'],
    },
  )
  @IsNotEmpty({
    groups: ['form'],
    message: 'Вы забыли ввести СМС-код',
  })
  @IsString({
    groups: ['form'],
    message: 'Вы забыли ввести СМС-код',
  })
  code: string;
}
