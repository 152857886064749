import { createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { pollDownloadStatus } from '@vk-hr-tek/core/download-notification/slice';
import { setDownloadWaiting } from '@vk-hr-tek/core/download-notification';
import { FormatService } from '@vk-hr-tek/core/format';

import { CreateEventBatchDocumentZipResponse } from '@app/gen/events';
import { CompanyPolicyXlsxResponse } from '@app/gen/policy';

import { ThunkExtra } from '../../../app/store';
import { PolicyDownloadService, PolicyListService } from '../../services';
import {
  DownloadPolicyVersionDto,
  DownloadEmployeePolicyDto,
  GetPolicyDto,
} from '../../dto';
import { setFilters } from '../actions';

export const startDownloadAllAccepted = createAsyncThunk<
  CreateEventBatchDocumentZipResponse,
  DownloadPolicyVersionDto,
  ThunkExtra
>(
  'policy/startDownloadAllAccepted',
  async (
    downloadStartDto: DownloadPolicyVersionDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(PolicyDownloadService);

      const count = getState().policy.detail.employees.entities.length;

      const result = await service.startDownloadAllAcceptedPolicies(
        downloadStartDto,
      );

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: count,
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const startDownloadEmployeeAccepted = createAsyncThunk<
  CreateEventBatchDocumentZipResponse,
  DownloadEmployeePolicyDto,
  ThunkExtra
>(
  'policy/startDownloadEmployeeAccepted',
  async (
    downloadStartDto: DownloadEmployeePolicyDto,
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(PolicyDownloadService);

      const result = await service.startDownloadEmployeeAcceptedPolicies(
        downloadStartDto,
      );

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: 1,
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const startDownloadXlsx = createAsyncThunk<
  CompanyPolicyXlsxResponse,
  GetPolicyDto,
  ThunkExtra
>(
  'policy/startDownloadXlsx',
  async (
    startDownloadXlsxDto: GetPolicyDto,
    { rejectWithValue, getState, dispatch, extra: { inject } },
  ) => {
    try {
      const service = inject(PolicyDownloadService);
      const policiesService = inject(PolicyListService);
      const format = inject(FormatService);

      const state = getState().policy;

      const { totalCount, totalVersionCount } = state;

      let filters = state.filters;

      if (!filters) {
        filters = await policiesService.getFilters();
        dispatch(setFilters(filters));
      }

      const result = await service.startDownloadXlsx(
        startDownloadXlsxDto,
        filters,
      );

      const formattedTotalDocuments = totalCount
        ? format.pluralize(totalCount, ['документ', 'документа', 'документов'])
        : '';
      const formattedTotalVersion = totalVersionCount
        ? format.pluralize(totalVersionCount, ['версия', 'версии', 'версий'])
        : '';
      const message = `${formattedTotalDocuments}${
        formattedTotalDocuments && formattedTotalVersion && ', '
      }${formattedTotalVersion}`;

      await dispatch(
        setDownloadWaiting({
          id: result.download_id,
          count: totalCount || 0,
          text: {
            loading: `Создаем файл (${message})`,
            complete: `Файл создан  (${message})`,
            failed: 'Ошибка создания файла',
          },
        }),
      );

      dispatch(pollDownloadStatus(result));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);
