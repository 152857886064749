import React from 'react';

import { useTheme } from '@material-ui/core';

interface SidebarMenuIconProps {
  rotated: boolean;
}

export const SidebarMenuIcon = ({ rotated }: SidebarMenuIconProps) => {
  const theme = useTheme();

  if (rotated) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1585 3.9784C10.4651 4.28321 10.4655 4.77772 10.1593 5.08292L5.22553 10.0005L10.1592 14.917C10.4654 15.2222 10.4652 15.7167 10.1586 16.0215C9.85203 16.3264 9.35526 16.3261 9.04903 16.0209L3.56153 10.5526C3.25555 10.2476 3.25553 9.75363 3.56147 9.44868L9.04897 3.97911C9.35517 3.67391 9.85194 3.67359 10.1585 3.9784Z"
          fill={theme.tokens.colors.text.light.tertirary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4352 3.9784C16.7418 4.28321 16.7421 4.77772 16.4359 5.08292L11.5022 10.0005L16.4359 14.917C16.7421 15.2222 16.7418 15.7167 16.4353 16.0215C16.1287 16.3264 15.6319 16.3261 15.3257 16.0209L9.83821 10.5526C9.53223 10.2476 9.5322 9.75363 9.83815 9.44868L15.3256 3.97911C15.6318 3.67391 16.1286 3.67359 16.4352 3.9784Z"
          fill={theme.tokens.colors.text.light.tertirary}
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.83886 3.9784C9.53226 4.28321 9.53194 4.77772 9.83815 5.08292L14.7719 10.0005L9.8382 14.917C9.53197 15.2222 9.53224 15.7167 9.8388 16.0215C10.1454 16.3264 10.6421 16.3261 10.9484 16.021L16.4359 10.5526C16.7418 10.2476 16.7419 9.75363 16.4359 9.44869L10.9484 3.97911C10.6422 3.67391 10.1455 3.67359 9.83886 3.9784Z"
          fill={theme.tokens.colors.text.light.tertirary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.56219 3.9784C3.25559 4.28321 3.25527 4.77772 3.56147 5.08292L8.49519 10.0005L3.56153 14.917C3.2553 15.2222 3.25556 15.7167 3.56213 16.0215C3.86869 16.3264 4.36546 16.3261 4.6717 16.0209L10.1592 10.5526C10.4652 10.2476 10.4652 9.75363 10.1592 9.44868L4.67176 3.97911C4.36555 3.67391 3.86878 3.67359 3.56219 3.9784Z"
          fill={theme.tokens.colors.text.light.tertirary}
        />
      </svg>
    );
  }
};
