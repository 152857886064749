import React from 'react';

import { useTheme } from '@material-ui/core';

export const VacationDaysWidgetIcon = () => {
  const theme = useTheme();

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0H28C34.6274 0 40 5.37258 40 12V28C40 34.6274 34.6274 40 28 40H12C5.37258 40 0 34.6274 0 28V12Z"
        fill={theme.tokens.colors.accent.bg.review}
      />
      <g clipPath="url(#clip0_2950_53279)">
        <rect
          width="24"
          height="24"
          transform="translate(8 8)"
          fill={theme.tokens.colors.accent.bg.review}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0768 19.2562C20.478 19.3591 20.7198 19.7678 20.6169 20.169L19.7245 23.6482L18.463 28.8254C18.365 29.2279 17.9592 29.4746 17.5568 29.3766C17.1544 29.2785 16.9076 28.8728 17.0057 28.4703L18.2704 23.2799L19.164 19.7963C19.2669 19.3951 19.6755 19.1533 20.0768 19.2562Z"
          fill={theme.tokens.colors.accent.text.review}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9103 11.3385C23.0016 10.966 22.7736 10.5899 22.401 10.4986C22.0285 10.4073 21.6524 10.6353 21.5611 11.0079L21.5016 11.2507C21.5012 11.2526 21.5007 11.2546 21.5002 11.2565C16.957 10.2922 12.5217 12.1798 10.5661 16.1727C10.1162 17.0913 10.715 18.1231 11.7017 18.3926L27.6219 22.7408C28.6086 23.0103 29.6488 22.4261 29.7283 21.4064C30.0732 16.983 27.2259 13.1099 22.8389 11.6239C22.8433 11.6099 22.8472 11.5957 22.8508 11.5814L22.9103 11.3385ZM11.8136 16.7836C13.1996 13.9537 16.105 12.3165 19.3942 12.3984C17.7712 13.4377 16.0909 15.2293 15.6168 18.022L12.0677 17.0526C11.9368 17.0169 11.8546 16.9358 11.8208 16.8723C11.8053 16.8434 11.8034 16.8252 11.8031 16.8192C11.803 16.8161 11.8026 16.8061 11.8136 16.7836ZM16.9647 18.3901C17.4853 14.9689 20.1777 13.2947 21.7874 12.7747C22.9093 14.0407 24.3768 16.8512 23.0861 20.062L16.9647 18.3901ZM27.9879 21.4009L24.4339 20.4302C25.4456 17.7833 24.9086 15.3854 24.0386 13.6653C26.9164 15.2662 28.5886 18.1547 28.3435 21.2984C28.3415 21.3233 28.3362 21.3315 28.3345 21.3341C28.3313 21.3391 28.3202 21.354 28.2922 21.3711C28.2308 21.4085 28.1188 21.4366 27.9879 21.4009Z"
          fill={theme.tokens.colors.accent.text.review}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8026 29.0448C12.8026 28.6306 13.1384 28.2948 13.5526 28.2948L24.0722 28.2948C24.4864 28.2948 24.8222 28.6306 24.8222 29.0448C24.8222 29.459 24.4864 29.7948 24.0722 29.7948L13.5526 29.7948C13.1384 29.7948 12.8026 29.459 12.8026 29.0448Z"
          fill={theme.tokens.colors.accent.text.review}
        />
      </g>
      <defs>
        <clipPath id="clip0_2950_53279">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
