import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ tokens }) => ({
  formRoot: {
    width: '100%',
  },

  itemBox: {
    '&:last-child': {
      '& $itemRoot': {
        borderBottom: 0,
      },
    },
  },

  itemRoot: {
    margin: 0,
    width: '100%',
    border: `${tokens.border.s}px solid`,
    borderColor: tokens.colors.stroke.primary,
    borderLeft: 0,
    borderTop: 0,
    borderRight: 0,
  },

  inputRoot: {
    paddingTop: 0,
    paddingRight: tokens.spacing['2'],
    paddingBottom: 0,
    paddingLeft: tokens.spacing['2'],
    marginRight: tokens.spacing['12'],

    '&:hover, &:hover$checked': {
      backgroundColor: 'transparent',
    },

    '& .MuiFormControlLabel-root': {
      marginLeft: 0,
      marginRight: 0,
    },

    '& .MuiSvgIcon-root path': {
      fill: tokens.colors.stroke.primary,
    },
  },

  checked: {
    '& .MuiSvgIcon-root path': {
      fill: tokens.colors.original.brand.primary,
    },
  },
}));
