import React, { ReactNode } from 'react';

import { Fade } from '@material-ui/core';

import { AppError } from '@vk-hr-tek/core/error';
import { Box } from '@vk-hr-tek/ui/Box';

import { PageError } from './PageError';
import { PageHeader } from './PageHeader';
import { PageLoading } from './PageLoading';
import { PageLoadingText } from './PageLoadingText';

interface PageProps {
  actions?: React.ReactNode | React.ReactNode[];
  additionalActions?: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  error: AppError | null;
  link?: ReactNode;
  loadingText?: ReactNode;
  showTitleMobile?: boolean;
  status: 'idle' | 'loading' | 'loading-text' | 'complete' | 'failed';
  title: ReactNode;
  actionsPosition?: 'default' | 'absolute';
}

export const Page = ({
  actions = null,
  additionalActions = null,
  children,
  error,
  link,
  loadingText,
  showTitleMobile = false,
  status,
  title: titleNode,
  actionsPosition = 'default',
}: PageProps) => {
  const title = (
    <PageHeader
      actions={actions}
      additionalActions={additionalActions}
      link={link}
      showTitleMobile={showTitleMobile}
      title={titleNode}
      actionsPosition={actionsPosition}
    />
  );

  if (status === 'idle') {
    return <>{title}</>;
  }

  if (status === 'loading') {
    return (
      <>
        {title}
        <Fade timeout={1000} in>
          <div>
            <PageLoading />
          </div>
        </Fade>
      </>
    );
  }

  if (status === 'loading-text') {
    return (
      <>
        {title}
        <Fade timeout={1000} in>
          <PageLoadingText text={loadingText} />
        </Fade>
      </>
    );
  }

  if (status === 'failed') {
    return (
      <>
        {title}
        <Fade timeout={1000} in>
          <div>
            <PageError
              status={error ? error.status : 500}
              traceId={error?.traceId}
            />
          </div>
        </Fade>
      </>
    );
  }

  return (
    <>
      {title}
      <Fade timeout={1000} in>
        <Box pb={{ xs: '72', md: '0' }}>{children}</Box>
      </Fade>
    </>
  );
};
