import { Type } from 'class-transformer';
import {
  IsInt,
  IsOptional,
  IsIn,
  Min,
  IsString,
  IsNotEmpty,
  IsUUID,
} from 'class-validator';

import { FilterType } from '@vk-hr-tek/core/filter';

export class GetEventsDto {
  @IsUUID()
  @IsString()
  @IsNotEmpty()
  companyId: string;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Type(() => Number)
  offset = 0;

  @IsOptional()
  @IsInt()
  @Min(1)
  @Type(() => Number)
  limit = 50;

  @IsOptional()
  @IsString()
  @IsIn(['id', 'created_at'])
  sortBy?: string;

  @IsOptional()
  @IsString()
  @IsIn(['asc', 'desc'])
  sortOrder?: string;

  filters?: FilterType;
}
