import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export class GetFilterOptionsByUrlDto {
  @IsNotEmpty()
  @IsString()
  url: string;

  @IsNotEmpty()
  @IsNumber()
  limit: number;

  @IsNotEmpty()
  @IsNumber()
  offset: number;

  @IsNotEmpty()
  @IsString()
  query: string;

  @IsOptional()
  @IsString()
  employee_id?: string;
}
