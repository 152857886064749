import { injectable, inject } from 'inversify';

import {
  NotificationData,
  NotificationActionTypeEnum,
} from '@vk-hr-tek/core/notifications';
import {
  Notification,
  SignatureCompletedNotificationPayload,
} from '@vk-hr-tek/core/notifications/gen/notifications';
import { RoleService } from '@vk-hr-tek/core/http';

@injectable()
export class EventNotificationsMapper {
  constructor(@inject(RoleService) private readonly role: RoleService) {}

  private getActionText(
    actionType?:
      | 'upload'
      | 'accept'
      | 'sign'
      | 'generate_document_from_template',
  ): string {
    switch (actionType) {
      case 'generate_document_from_template':
        return 'Вам нужно создать документ в этой заявке';
      case 'upload':
        return 'Вам нужно загрузить документ в этой заявке';
      case 'sign':
        return 'Вам нужно подписать документ в этой заявке';
      case 'accept':
        return 'Вам нужно проверить эту заявку';
      default: {
        return '';
      }
    }
  }

  private getNotification(notification: Notification) {
    const objectType = notification.object_type;

    const payload = notification.payload
      ? (notification.payload as SignatureCompletedNotificationPayload)
      : null;

    const pathname = window.location.pathname;
    const currentEventId = pathname.split('/')[3];

    if (objectType !== 'event') {
      return;
    }

    if (payload?.required_action === 'decline') {
      return;
    }

    if (
      notification.notification_type === 'signature_completed' &&
      !payload?.required_action
    ) {
      return {
        title: 'Подписано!',
        subtitle: 'Заявка',
        message: payload?.event_type_name || '',
        type: 'info',
        group: 'eventSigning',
      };
    }

    if (
      notification.notification_type === 'signature_completed' &&
      payload?.required_action
    ) {
      return {
        title: 'Подписано!',
        subtitle: 'Заявка',
        message: payload.event_type_name,
        type: 'info',
        group: 'eventSigning',
        ...(currentEventId !== notification.object_id && {
          action: {
            text: this.getActionText(payload.required_action),
            actionType: NotificationActionTypeEnum.Redirect,
            link: `/${this.role.get()}/events/${notification.object_id}`,
          },
        }),
      };
    }

    if (notification.notification_type === 'signature_failed') {
      return {
        title: 'Не подписано!',
        subtitle: 'Заявка',
        message: payload?.event_type_name || 'Ошибка',
        type: 'error',
        group: 'eventSigning',
        ...(currentEventId !== notification.object_id && {
          action: {
            text: 'Попробовать еще раз',
            actionType: NotificationActionTypeEnum.Redirect,
            link: `/${this.role.get()}/events/${notification.object_id}`,
          },
        }),
      };
    }
  }

  private getNotifications(data: Notification[]) {
    const result = data
      .map((item) => this.getNotification(item))
      .filter((x) => x !== undefined);

    return result as NotificationData[];
  }

  public mapNotifications(data: Notification[]): NotificationData[] {
    return this.getNotifications(data);
  }
}
