/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Type } from 'class-transformer';
import {
  IsInt,
  IsUUID,
  IsNotEmpty,
  IsOptional,
  IsIn,
  ValidateNested,
  Min,
  IsString,
} from 'class-validator';

class Filters {
  @IsOptional()
  @IsIn(['false', 'true'])
  accepted?: 'false' | 'true' = 'false';

  @IsOptional()
  @IsString()
  employee_query?: string;
}

export class GetPolicyVersionEmployeesDto {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsUUID()
  @IsNotEmpty()
  versionId: string;

  @IsOptional()
  @IsInt()
  @Min(0)
  @Type(() => Number)
  offset: number = 0;

  @IsOptional()
  @IsInt()
  @Min(1)
  @Type(() => Number)
  limit: number = 20;

  @IsOptional()
  @ValidateNested()
  @Type(() => Filters)
  filters?: Filters;
}
