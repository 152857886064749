import { useCallback, useState } from 'react';

import { useBetween } from 'use-between';
import { useHistory } from 'react-router-dom';

import { useInject } from '@vk-hr-tek/core/ioc';
import { RoleService } from '@vk-hr-tek/core/http';

import { useSelector } from '../hooks';
import { UserRoleService } from '../../user/services';
import { selectHasCompanySide } from '../../user/slice';
import { UserRoleEnum } from '../types';

const getOppositeRole = (role: string): UserRoleEnum => {
  if (role === UserRoleEnum.Company) {
    return UserRoleEnum.Employee;
  }
  return UserRoleEnum.Company;
};

const useSharedState = () => {
  return useState<UserRoleEnum>();
};

export const useRole = () => {
  const userRoleService = useInject<UserRoleService>(RoleService);
  const history = useHistory();

  const hasCompanySide = useSelector(selectHasCompanySide);

  const [role, setRole] = useBetween(useSharedState);

  const defaultRole = userRoleService.get();

  const changeRole = useCallback(
    async (newRole: UserRoleEnum = getOppositeRole(role ?? defaultRole)) => {
      if (hasCompanySide || newRole === UserRoleEnum.Employee) {
        userRoleService.save(newRole);
        setRole(newRole);
        history.push('/');
      }
    },
    [role, defaultRole, hasCompanySide, userRoleService, setRole, history],
  );

  const targetRole = role ?? defaultRole;
  return [targetRole, changeRole] as [typeof targetRole, typeof changeRole];
};
