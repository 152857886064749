import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { UserLoginResponse as AuthResponse } from '@app/gen/users';

import {
  VerifyPassCodeDto,
  RegisterAliInitDto,
  RegisterAliDto,
} from '../../dto';

@injectable()
export class AuthAliRegisterService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async registerInit(registerInitDto: RegisterAliInitDto) {
    await this.validator.validateOrReject(registerInitDto, RegisterAliInitDto);

    await this.http.post(
      '/pass/register/init',
      {
        phone: registerInitDto.phone,
        pass_code: registerInitDto.passCode,
        snils: registerInitDto.snils
          ? registerInitDto.snils.replace(/(-| )/g, '')
          : /* istanbul ignore next */
            '',
      },
      { withAuth: false },
    );
  }

  async register(registerDto: RegisterAliDto) {
    await this.validator.validateOrReject(registerDto, RegisterAliDto);

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/pass/register',
        {
          phone: registerDto.phone,
          pass_code: registerDto.passCode,
          code: registerDto.code,
        },
        { withAuth: false },
      ),
      AuthResponse,
    );

    return result;
  }

  async changePhoneInit(changePhoneDto: VerifyPassCodeDto) {
    await this.validator.validateOrReject(changePhoneDto, VerifyPassCodeDto, [
      'load',
    ]);

    await this.http.post(
      '/pass/reset_phone',
      {
        pass_code: changePhoneDto.passCode,
      },
      { withAuth: false },
    );
  }
}
