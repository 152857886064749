import { makeStyles, Theme } from '@material-ui/core';

interface StylesProps {
  listStyleType: string;
  pl: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  list: {
    fontSize: 'inherit',
    color: 'inherit',
    listStyleType: ({ listStyleType }) => listStyleType,
    paddingLeft: ({ pl }) => theme.spacing(pl),
  },
}));

export default useStyles;
