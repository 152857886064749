/* eslint-disable @typescript-eslint/no-explicit-any */
import { useReducer } from 'react';

import { AppError } from '@vk-hr-tek/core/error';

import { Filter } from '../types';

type Status = 'idle' | 'loading' | 'complete' | 'failed';

interface State<T> {
  status: Status;
  items: T[];
  error: AppError | null;
  hasMore: boolean;
}

enum Actions {
  setStatus = 'setStatus',
  setItems = 'setItems',
  addItems = 'addItems',
  setError = 'setError',
  setHasMore = 'setHasMore',
  resetState = 'resetState',
}

interface Action<T = any> {
  type: Actions;
  payload?: T;
}

export const useAutocompleteReducer = <T>(
  callback?: (filter: Filter) => Promise<T[]>,
) => {
  const initialState: State<T> = {
    status: 'idle',
    items: [],
    error: null,
    hasMore: true,
  };

  const reducer = (state: State<T>, { type, payload }: Action): State<T> => {
    switch (type) {
      case Actions.setStatus:
        return {
          ...state,
          status: payload,
        };

      case Actions.setItems:
        return {
          ...state,
          items: payload,
        };

      case Actions.addItems:
        return {
          ...state,
          items: [...state.items, ...payload],
        };

      case Actions.setError:
        return {
          ...state,
          error:
            payload ||
            ({
              info: 'Что-то пошло не так',
              status: 500,
              source: 'client',
              title: 'Internal client error',
            } as AppError),
        };

      case Actions.setHasMore:
        return {
          ...state,
          hasMore: payload,
        };

      case Actions.resetState:
        return initialState;

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setStatus = (payload: Status): Action<Status> => ({
    type: Actions.setStatus,
    payload,
  });

  const setItems = (payload: T[]): Action<T[]> => ({
    type: Actions.setItems,
    payload,
  });

  const addItems = (payload: T[]): Action<T[]> => ({
    type: Actions.addItems,
    payload,
  });

  const setError = (payload: AppError | null): Action<AppError | null> => ({
    type: Actions.setError,
    payload,
  });

  const setHasMore = (payload: boolean): Action<boolean> => ({
    type: Actions.setHasMore,
    payload,
  });

  const resetState = (): Action => ({
    type: Actions.resetState,
  });

  const requestItems = async (filter: Filter, type: 'get' | 'search') => {
    try {
      dispatch(setStatus('loading'));

      const items = (await callback?.(filter)) || [];

      if (type === 'get') {
        dispatch(addItems(items));
      } else {
        dispatch(setItems(items));
      }

      dispatch(setHasMore(!!items.length));
      dispatch(setStatus('complete'));

      return items;
    } catch (err) {
      setStatus('failed');
      dispatch(setError(err as AppError));
    }
  };

  const resetItems = () => dispatch(resetState());

  return {
    ...state,
    requestItems: callback ? requestItems : undefined,
    resetItems,
  };
};
