import { Switch, useRouteMatch } from 'react-router-dom';

import { SentryRoute as Route } from '@vk-hr-tek/core/monitoring/SentryRoute';

import { Greeting } from './Greeting';
import { Login } from './Login';

export const CandidateAuth = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/login`}>
        <Greeting />
      </Route>
      <Route exact path={`${match.url}/candidate`}>
        <Login />
      </Route>
    </Switch>
  );
};
