import React from 'react';

import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

import { MultipleTextInput as Input } from './../../input';

export const MultipleTextInput = ({
  id,
  name,
  type,
  label,
  placeholder,
  max = 3,
  onChange,
  tooltip,
  showError = true,
  required = false,
  disabled = false,
  loading = false,
  testId,
  ...rest
}: FieldProps<string | undefined, FieldRenderProps<string | undefined>>) => {
  return (
    <Field name={name} {...rest}>
      {({ input, meta }) => {
        const values = Array.isArray(input.value) ? [...input.value] : [];

        const handleChange = (index: number, value: string) => {
          values[index] = value;
          input.onChange(values);

          if (onChange) {
            onChange(values);
          }
        };

        return (
          <Input
            {...input}
            id={id}
            testId={testId}
            label={label}
            required={required}
            disabled={disabled}
            values={values}
            onChange={handleChange}
            type={type}
            error={meta.touched && !!meta.error}
            helperText={showError && meta.touched && meta.error}
            placeholder={placeholder}
            tooltip={tooltip}
            max={max}
            loading={loading}
          />
        );
      }}
    </Field>
  );
};
