import { createSlice } from '@reduxjs/toolkit';

import { initialState } from './crypto.state';
import { cryptoReducers } from './crypto.actions';

export const crypto = createSlice({
  name: 'crypto',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    cryptoReducers(builder);
  },
});

export const cryptoReducer = crypto.reducer;
