import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  badge: {
    '& .MuiBadge-badge': {
      top: theme.tokens.spacing['8'],
      right: -theme.tokens.spacing['8'],
      minWidth: theme.px(6),
      height: theme.px(6),
      background: theme.tokens.colors.accent.text.error,
    },
  },
}));
