/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Slide, Portal } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Info as InfoIcon,
} from '@material-ui/icons';

import { useInject } from '@vk-hr-tek/core/ioc';
import { FormatService } from '@vk-hr-tek/core/format';
import { DownloadNotificationItem } from '@vk-hr-tek/core/download-notification';

import { Box } from '../Box';
import { Button } from '../Button';
import { CircularProgress } from '../CircularProgress';
import { Dialog } from '../Dialog';
import { Typography } from '../Typography';
import { useIsDesktop } from '../hooks';

import { useStyles } from './DownloadNotification.styles';

interface DownloadNotificationProps {
  show?: boolean;
  notifications: DownloadNotificationItem[];
  onClose: () => void;
}

export const DownloadNotification = ({
  show = true,
  notifications,
  onClose,
}: DownloadNotificationProps) => {
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const format = useInject(FormatService);

  const downloads = notifications || [];

  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const handleClose = () => {
    if (downloads.filter((item) => item.status === 'waiting').length) {
      setIsDialogOpened(true);
    } else {
      onClose();
    }
  };

  const handleCancelDownloads = () => {
    setIsDialogOpened(false);
    onClose();
  };

  useEffect(() => {
    const webimButtonCorner = Array.from(
      document.getElementsByClassName('webim-button-corner'),
    )[0];

    if (webimButtonCorner) {
      if (downloads && !!downloads.length) {
        webimButtonCorner.classList.add('webim-button-corner-right');
      } else {
        webimButtonCorner.classList.remove('webim-button-corner-right');
      }
    }

    const webimChat = document.getElementById('webim_chat');
    if (webimChat) {
      if (downloads && !!downloads.length) {
        webimChat.classList.add('webim-chat-right');
      } else {
        webimChat.classList.remove('webim-chat-right');
      }
    }

    return () => {
      if (webimButtonCorner) {
        webimButtonCorner.classList.remove('webim-button-corner-right');
      }

      if (webimChat) {
        webimChat.classList.remove('webim-chat-right');
      }
    };
  }, [downloads && !!downloads.length]);

  if (!show) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.notifications}>
        <Dialog onClose={() => setIsDialogOpened(false)} open={isDialogOpened}>
          <Box flexGrow={1}>
            <Box mb="48" pt="48" px="48">
              <Typography variant="h5">Отменить скачивание?</Typography>
            </Box>
            <Box mb={'48'} px="48">
              <Typography color="textTertiary">
                Загрузка документов не завершена. Все равно отменить?
              </Typography>
            </Box>
          </Box>
          <Box
            p="16"
            display="flex"
            justifyContent="flex-end"
            bgcolor="bg.greyscale.primary"
          >
            <Box mr="16">
              <Button
                variant="tertiaryLight"
                onClick={handleCancelDownloads}
                size="large"
              >
                Отменить
              </Button>
            </Box>
            <Button size="large" onClick={() => setIsDialogOpened(false)}>
              Продолжить скачивание
            </Button>
          </Box>
        </Dialog>
        {isDesktop && (
          <div className={classes.stack}>
            {downloads && !!downloads.length && (
              <Slide direction="left" in mountOnEnter unmountOnExit>
                <Box mb="8" className={classes.wrapper}>
                  <Box
                    p="16"
                    display="flex"
                    alignItems="center"
                    className={classes.content}
                  >
                    <Box ml="8" mr="20">
                      <Typography
                        className={classes.text}
                        color="contrast"
                        variant="body3"
                      >
                        Подготовка к скачиванию
                      </Typography>
                    </Box>
                    <Button
                      variant="tertiaryDark"
                      onClick={handleClose}
                      icon={<CloseIcon />}
                    />
                  </Box>
                  <Box
                    className={classes.inner}
                    bgcolor="bg.light.primary"
                    py="8"
                    display="flex"
                    flexDirection="column"
                  >
                    {downloads.map((item) => (
                      <Box px="24" py="8" key={item.id}>
                        {item.status === 'waiting' && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {item.text?.loading || (
                              <span>
                                Создаем архив (
                                {format.pluralize(item.counter, [
                                  'документ',
                                  'документа',
                                  'документов',
                                ])}
                                )
                              </span>
                            )}
                            <CircularProgress size={15} />
                          </Box>
                        )}
                        {item.status === 'complete' && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {item.text?.complete || (
                              <span>
                                Архив создан (
                                {format.pluralize(item.counter, [
                                  'документ',
                                  'документа',
                                  'документов',
                                ])}
                                )
                              </span>
                            )}
                            <CheckCircleIcon color="primary" />
                          </Box>
                        )}
                        {item.status === 'failed' && (
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            {item.text?.failed || (
                              <span>
                                Ошибка создания архива (
                                {format.pluralize(item.counter, [
                                  'документ',
                                  'документа',
                                  'документов',
                                ])}
                                )
                              </span>
                            )}
                            <InfoIcon color="error" />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Slide>
            )}
          </div>
        )}
      </div>
    </Portal>
  );
};
