import {
  ActionReducerMapBuilder,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { FORM_ERROR } from 'final-form';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';
import { TokenService } from '@vk-hr-tek/core/http';
import { AuthCandidateLoginService } from '@vk-hr-tek/app/auth/services';

import { CandidateLoginResponse } from '@app/gen/candidates';
import { ThunkExtra } from '@app/store';

import {
  CandidateLoginFinishDto,
  CandidateLoginInitDto,
} from '../../../dto/candidate';
import {
  AuthState,
  completeLoading,
  resetState,
  setError,
  startLoading,
} from '../../auth.state';
import { AuthRouterService } from '../../../services';

const delay = (time: number) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), time);
  });

const delayTime = 1000;

export const setCandidateInviteCode = createAction<string | null>(
  'auth/saveInviteCode',
);

export const candidateLoginInit = createAsyncThunk<
  void,
  {
    candidateLoginInitDto: CandidateLoginInitDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/candidateLoginInit',
  async (
    { candidateLoginInitDto, actions },
    { rejectWithValue, extra: { inject } },
  ) => {
    try {
      await inject(AuthCandidateLoginService).CandidateLoginInit(
        candidateLoginInitDto,
      );

      actions.resolve(null);
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const candidateLogin = createAsyncThunk<
  CandidateLoginResponse,
  {
    candidateLoginFinishDto: CandidateLoginFinishDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'auth/candidateLogin',
  async (
    { candidateLoginFinishDto, actions },
    { rejectWithValue, extra: { inject } },
  ) => {
    try {
      const result = await inject(AuthCandidateLoginService).CandidateLogin(
        candidateLoginFinishDto,
      );

      inject<TokenService>(TokenService).save(result.access_token);

      await delay(delayTime);

      actions.resolve(null);

      inject(AuthRouterService).redirectToHome();

      return result;
    } catch (err) {
      actions.resolve({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const loginCandidateReducers = (
  builder: ActionReducerMapBuilder<AuthState>,
) => {
  builder.addCase(setCandidateInviteCode, (state, action) => {
    state.inviteCode = action.payload;
  });
  builder.addCase(candidateLoginInit.pending, (state, action) => {
    startLoading(state);

    state.values.phone = action.meta.arg.candidateLoginInitDto.phone;
  });
  builder.addCase(candidateLoginInit.fulfilled, (state) => {
    completeLoading(state);
    state.stage = 'finish';
  });
  builder.addCase(candidateLoginInit.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
  builder.addCase(candidateLogin.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(candidateLogin.fulfilled, (state) => {
    resetState(state);
  });
  builder.addCase(candidateLogin.rejected, (state, { payload }) => {
    setError(state, { payload });
  });
};
