import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  content: {
    paddingLeft: theme.tokens.spacing['20'],
    paddingRight: theme.tokens.spacing['20'],
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}));
