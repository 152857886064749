import React from 'react';

import { useStyles } from './Content.styles';

interface ContentProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ children }, ref) => {
    const classes = useStyles();

    return (
      <div ref={ref} className={classes.content}>
        {children}
      </div>
    );
  },
);
