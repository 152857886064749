import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import CheckCircle from '@material-ui/icons/CheckCircle';

import { Box } from '../../Box';
import { Typography } from '../../Typography';

import useStyles from './FormKnownValue.styles';

interface FormKnownValueProps {
  label: string;
  value?: string | null;
  verified?: boolean;
  caption?: string | null;
}

export const FormKnownValue = ({
  label,
  value,
  verified = true,
  caption,
}: FormKnownValueProps) => {
  const classes = useStyles();

  return (
    <Box>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Box display="flex" justifyContent="start" alignItems="center">
        <Typography variant="body2">{value || ''}</Typography>

        {verified && (
          <Box ml="8" display="flex" alignItems="center">
            <CheckCircle fontSize="small" color="primary" />
          </Box>
        )}
      </Box>
      {caption && <span className={classes.caption}>{caption}</span>}
    </Box>
  );
};
