import { injectable } from 'inversify';

import { Calendar } from '@vk-hr-tek/core/calendar';
import { FormatService } from '@vk-hr-tek/core/format';

import {
  AbsenceItem,
  BirthdayEmployee,
  CalendarWidgetCompany,
} from '@app/gen/dashboard';

import { AbsencesMap } from '../types';

@injectable()
export class CalendarMapper {
  constructor(private calendar: Calendar, private format: FormatService) {}

  private processStatus(
    status:
      | 'new'
      | 'event_created'
      | 'approved'
      | 'in_progress'
      | 'completed'
      | 'canceled',
  ): { text: string; color: 'grey' | 'purple' | 'green' | 'lightGrey' | '' } {
    switch (status) {
      case 'new':
        return {
          text: 'Заявка не создана',
          color: 'grey',
        };
      case 'event_created':
        return {
          text: 'Заявка создана • Оформлено',
          color: 'purple',
        };
      case 'approved':
        return {
          text: 'Заявка создана • Согласовано',
          color: 'green',
        };
      case 'in_progress':
        return {
          text: 'В процессе',
          color: 'green',
        };
      case 'completed':
        return {
          text: 'Завершено',
          color: 'lightGrey',
        };
      case 'canceled':
        return {
          text: '',
          color: '',
        };
    }
  }

  private processAbsence(absence: AbsenceItem & { employee: string }) {
    const {
      from_date: fromDate,
      to_date: toDate,
      type,
      status,
      days_count: daysCount,
      employee,
    } = absence;

    const from = new Date(fromDate);
    const to = new Date(toDate);
    const statusData = this.processStatus(status);
    const dates = this.calendar.getAllDaysOfInterval(from, to);

    return dates.map((date) => ({
      date,
      tooltip: {
        type,
        status: statusData.text,
        from: this.format.toDate(fromDate),
        to: this.format.toDate(toDate),
        daysCount,
        employee,
      },
      isStart: this.calendar.isSameDay(date, from),
      isEnd: this.calendar.isSameDay(date, to),
      color: statusData.color,
    }));
  }

  private processAbsences(absences: (AbsenceItem & { employee: string })[]) {
    const absencesMap: AbsencesMap = {};

    absences.forEach((absence) => {
      this.processAbsence(absence).map(({ date, ...rest }) => {
        const index = date.toDateString();

        if (absencesMap[index])
          absencesMap[index] = [...absencesMap[index], rest];
        else absencesMap[index] = [rest];
      });
    });

    return absencesMap;
  }

  processCompany(
    company: CalendarWidgetCompany | undefined,
    includedEmployees: string[],
  ) {
    if (!company) return null;

    const { employees, birthdays_available: birthdaysAvailable } = company;

    return {
      employees: employees.map(
        ({ id: employeeId, personnel_number, position }) => ({
          value: employeeId,
          label: `${position}, ${personnel_number}`,
        }),
      ),
      birthdaysAvailable,
      absences: this.processAbsences(
        employees
          .filter(({ id }) => includedEmployees.includes(id))
          .map(({ absences, position, personnel_number }) =>
            absences.map((absence) => ({
              ...absence,
              employee: `${position}, ${personnel_number}`,
            })),
          )
          .flat()
          .sort(
            (
              { from_date: firstFromDate, to_date: firstToDate },
              { from_date: secondFromDate, to_date: secondToDate },
            ) => {
              if (
                this.calendar.isSameDay(
                  new Date(firstFromDate),
                  new Date(secondFromDate),
                )
              ) {
                return new Date(firstToDate) > new Date(secondToDate) ? 1 : -1;
              } else {
                return new Date(firstFromDate) > new Date(secondFromDate)
                  ? 1
                  : -1;
              }
            },
          ),
      ),
      holidays: employees
        .filter(({ id }) => includedEmployees.includes(id))
        .reduce(
          (acc: string[], curr) => [
            ...acc,
            ...curr.calendar_diff
              .map((item) => item.date)
              .filter((date) => !acc.includes(date)),
          ],
          [],
        )
        .map((date) => new Date(date)),
    };
  }

  processBirthdays(birthdays: BirthdayEmployee[]) {
    return birthdays.map(({ name, position }) => {
      const [lastName, firstName, middleName] = name.split(' ');

      return {
        name: `${lastName} ${firstName?.[0]}.${
          middleName ? `${middleName[0]}.` : ''
        }`,
        initials: `${lastName?.[0]}${firstName?.[0]}`,
        position,
      };
    });
  }

  processVacationDays(company: CalendarWidgetCompany | undefined) {
    if (!company) return null;

    const { employees } = company;

    return {
      total: employees.reduce(
        (acc, current) => acc + current.vacation_available_days,
        0,
      ),
      employees: employees.map(
        ({ position, personnel_number, vacation_available_days }) => ({
          position: `${position}, ${personnel_number}`,
          days: vacation_available_days,
        }),
      ),
    };
  }

  processCompanies(companies: CalendarWidgetCompany[]) {
    return companies.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }
}
