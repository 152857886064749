import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  autoFillBanner: {
    background: `linear-gradient(90deg, ${theme.tokens.colors.original.brand.primary} 0%, ${theme.palette.background.gradientPurple} 100%)`,
    borderRadius: theme.tokens.radius.m,
    padding: theme.tokens.spacing['12'],
    textAlign: 'center',
    color: theme.tokens.colors.text.dark.primary,
    fontSize: theme.tokens.typography.variant.caption.fontSize,

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.tokens.spacing['4'],
      paddingRight: theme.tokens.spacing['8'],
      paddingBottom: theme.tokens.spacing['4'],
      paddingLeft: theme.tokens.spacing['8'],
    },
  },
}));

export default useStyles;
