import React from 'react';

import ErrorIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';

import { Box } from '@vk-hr-tek/ui/Box';
import { Button } from '@vk-hr-tek/ui/Button';

interface NotificationProps {
  onClose?: () => void;
  children: React.ReactNode;
}

export const Notification = ({ onClose, children }: NotificationProps) => (
  <Box
    color="accent.text.error"
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    fontSize={14}
    height={32}
    py="8"
    px="16"
    bgcolor="accent.bg.error"
    radius="l"
  >
    <Box alignItems="center" display="flex">
      <ErrorIcon />
      <Box ml="8">{children}</Box>
    </Box>
    <Button
      variant="tertiaryLight"
      onClick={onClose}
      size="extraSmall"
      icon={<CloseIcon color="error" />}
    />
  </Box>
);
