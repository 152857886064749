import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain } from 'class-transformer';

import { AppError } from '@vk-hr-tek/core/error';

import { ThunkExtra } from '@app/store';
import { Link } from '@app/gen/dashboard';

import { ElectronicServicesService } from '../../services';
import { DashboardState } from '../dashboard.state';

export const getElectronicServices = createAsyncThunk<
  Link[],
  undefined,
  ThunkExtra
>(
  'dashboard/getElectronicServices',
  async (_, { rejectWithValue, extra: { inject } }) => {
    try {
      const service = inject(ElectronicServicesService);

      const result = await service.getElectronicServices();

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const electronicServicesReducers = (
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(getElectronicServices.pending, (state) => {
    state.electronicServices.status = 'loading';
  });
  builder.addCase(getElectronicServices.fulfilled, (state, { payload }) => {
    state.electronicServices.status = 'complete';
    state.electronicServices.items = payload;
  });
  builder.addCase(getElectronicServices.rejected, (state) => {
    state.electronicServices.status = 'failed';
  });
};
