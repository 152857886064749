import { IsString, IsNotEmpty, IsUUID } from 'class-validator';

import { FormAttributesRequest } from '../../types';

export class SignUkepDto {
  @IsNotEmpty()
  @IsString()
  signature: string;

  @IsNotEmpty()
  @IsUUID()
  eventId: string;

  @IsNotEmpty()
  @IsUUID()
  nodeId: string;

  attributes?: FormAttributesRequest;
}
