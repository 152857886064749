import { inject, injectable } from 'inversify';

import { History } from '@vk-hr-tek/core/history';
import { RoleService } from '@vk-hr-tek/core/http';

import { SettingsRouter } from '../../../settings/types';
import { modules } from '../../modules';

@injectable()
export class SettingsRouterService implements SettingsRouter {
  constructor(
    @inject(History) private history: History,
    @inject(RoleService) private readonly role: RoleService,
  ) {}

  goToMainSettings() {
    this.history.push(`/${this.role.get()}/${modules.settings.basename}`);
  }

  goToCompanyStructure(id: string) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.company.basename
      }/${id}/structure`,
    );
  }

  goToCompanyProfilesEdit(id: string) {
    this.history.push(
      `/${this.role.get()}/${modules.settings.basename}/${
        modules.settings.children.company.basename
      }/${id}/profiles/edit`,
    );
  }
}
