import React, { ReactNode } from 'react';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { Link } from '../Link';
import { Box } from '../Box';

import { useStyles } from './RedirectCard.styles';

interface RedirectCardProps {
  onClick: () => void;
  fullWidth?: boolean;
  children: ReactNode;
}

export const RedirectCard = ({
  onClick,
  children,
  fullWidth = false,
}: RedirectCardProps) => {
  const classes = useStyles();

  return (
    <Link onClick={onClick} stroke={false} variant="simple">
      <Box
        className={classes.card}
        display="flex"
        alignItems="center"
        gap="8"
        p="16"
        bgcolor="bg.greyscale.primary"
        radius="l"
        width={fullWidth ? '100%' : 'auto'}
      >
        {children}

        <Box display="flex" alignItems="center" ml="auto">
          <ArrowForwardIosIcon fontSize="small" color="disabled" />
        </Box>
      </Box>
    </Link>
  );
};
