import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';

import { useStyles } from './Button.styles';

interface ButtonProps {
  color: string | undefined;
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  className?: string;
  children: ReactNode;
}

export const Button = ({
  color,
  loading,
  disabled,
  fullWidth = true,
  onClick,
  className,
  children,
}: ButtonProps) => {
  const classes = useStyles({ color, fullWidth });

  return (
    <button
      className={classNames(classes.button, className)}
      type="submit"
      disabled={disabled}
      onClick={onClick}
    >
      <Box visibility={loading ? 'hidden' : 'visible'}>{children}</Box>
      {loading && (
        <CircularProgress
          size={18.8}
          className={classes.loading}
          color="inherit"
        />
      )}
    </button>
  );
};
