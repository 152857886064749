/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { useDispatch, useSelector } from '../../../../app/hooks';
import { PageLoading } from '../../../../layout';
import { useAuth } from '../../../hooks';
import {
  activateEmployee,
  verifyInviteCode,
  selectStage,
  selectStatus,
} from '../../../slice';
import { Page } from '../../../ui/page';

import { VerifyInviteInit } from './VerifyInviteInit';

const stepsWithCodeVerification = [
  {
    name: 'init',
    content: <VerifyInviteInit />,
    label: 'СНИЛС',
  },
  {
    name: 'phone',
    content: null,
    label: 'Телефон',
  },
  {
    name: 'verification',
    content: null,
    label: 'СМС-код',
  },
  {
    name: 'finish',
    content: null,
    label: 'Пароль',
  },
];

const stepsWithoutCodeVerification = [
  {
    name: 'init',
    content: <VerifyInviteInit />,
    label: 'СНИЛС',
  },
  {
    name: 'finish',
    content: null,
    label: 'Пароль',
  },
];

const registerWithoutPhoneCodeVerify =
  window.REACT_APP_VKHRTEK_AUTH_SKIP_PHONE_VERIFICATION.toString() === 'true';

const steps = registerWithoutPhoneCodeVerify
  ? stepsWithoutCodeVerification
  : stepsWithCodeVerification;

export const VerifyInvite = () => {
  const dispatch = useDispatch();
  const stage = useSelector(selectStage);
  const status = useSelector(selectStatus);

  const isDesktop = useIsDesktop();

  const isAuthenticated = useAuth();

  useEffect(() => {
    if (stage !== 'error') {
      dispatch(verifyInviteCode());
    }
  }, [stage]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(activateEmployee());
    }
  }, []);

  if ((isAuthenticated && stage !== 'error') || status === 'idle') {
    return <PageLoading />;
  }

  return (
    <Page title="Регистрация">
      <Stages
        steps={steps}
        active={stage}
        showHeader
        fullWidth={registerWithoutPhoneCodeVerify}
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
