import { injectable } from 'inversify';

import { ValidationService } from '@vk-hr-tek/core/validation';
import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { CandidateLoginResponse } from '@app/gen/candidates';

import {
  CandidateLoginFinishDto,
  CandidateLoginInitDto,
} from '../../dto/candidate';

@injectable()
export class AuthCandidateLoginService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async CandidateLoginInit(candidateLoginInitDto: CandidateLoginInitDto) {
    await this.validator.validateOrReject(
      candidateLoginInitDto,
      CandidateLoginInitDto,
      ['submit'],
    );

    await this.http.post(
      '/candidate/login/init',
      {
        event_id: candidateLoginInitDto.invite,
        phone: candidateLoginInitDto.phone,
      },
      { withAuth: false },
    );
  }

  async CandidateLogin(candidateLoginFinishDto: CandidateLoginFinishDto) {
    await this.validator.validateOrReject(
      candidateLoginFinishDto,
      CandidateLoginFinishDto,
      ['submit'],
    );

    const result = await this.unmarshaller.unmarshall(
      await this.http.post(
        '/candidate/login',
        {
          event_id: candidateLoginFinishDto.invite,
          phone: candidateLoginFinishDto.phone,
          code: candidateLoginFinishDto.code,
        },
        {
          withSide: false,
          withAuth: false,
        },
      ),
      CandidateLoginResponse,
    );

    return result;
  }
}
