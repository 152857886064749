import React, { forwardRef } from 'react';

import classNames from 'classnames';

import { InfiniteScroll } from '../../InfiniteScroll';
import { CircularProgress } from '../../CircularProgress';
import { Box } from '../../Box';

import useStyles from './ListBox.styles';

export const ListBox = forwardRef<
  HTMLDivElement,
  {
    hasMore: boolean;
    dataLength: number;
    next: () => void;
    loading: boolean;
    long?: boolean;
    children?: React.ReactNode | React.ReactNode[];
  }
>(
  (
    { hasMore, dataLength, next, loading, long = false, children, ...rest },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <div
        {...rest}
        id="scrollable"
        ref={ref}
        className={classNames(classes.scrollableWrapper, {
          [classes.longScrollableWrapper]: long,
        })}
      >
        <InfiniteScroll
          next={next}
          hasMore={hasMore}
          dataLength={dataLength}
          loader={
            <>
              {loading && (
                <Box display="flex" justifyContent="center" p="16">
                  <CircularProgress size={30} />
                </Box>
              )}
            </>
          }
          endMessage={<div />}
          scrollableTarget="scrollable"
        >
          <ul className={classes.listBox}>{children}</ul>
        </InfiniteScroll>
      </div>
    );
  },
);
