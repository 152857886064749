import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { AuthInstanceResponse } from '@vk-hr-tek/app/app/gen/users';

@injectable()
export class AuthService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getAuthInstance() {
    return {
      auth_instances: (
        await this.unmarshaller.unmarshall(
          await this.http.get('/auth_instance', {}, { withAuth: false }),
          AuthInstanceResponse,
        )
      ).auth_instances.filter((instance) => instance.auth_type !== 'openid'),
    };
  }
}
