import React from 'react';

import { useIsDesktop } from '../../hooks';
import { Button } from '../../Button';

interface FormButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

export const FormButton = ({
  children,
  disabled = false,
  loading = false,
}: FormButtonProps) => {
  const isDesktop = useIsDesktop();

  return (
    <Button
      className="qaSubmitButton"
      disabled={disabled}
      fullWidth
      loading={loading}
      size={isDesktop ? 'large' : 'medium'}
      type="submit"
    >
      {children}
    </Button>
  );
};
