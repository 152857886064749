export const NoPayslipsIcon = () => (
  <svg
    width="205"
    height="165"
    viewBox="0 0 205 165"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.1318 105.533C93.1318 115 99 125.643 110.896 125.643C127 125.643 136.033 115.341 136.033 100.17C136.033 84.9999 124.973 70.3406 105.868 70.3406C85.5 70.3406 67.3241 86.4262 67.3241 110.896C67.3241 138.5 92.0875 155.808 115.253 155.808C144 155.808 173.907 137.71 173.907 89.1098C173.907 57.5 142.5 22.412 99.5 22.412C50.5 22.412 8 49.2252 8 110.896C8 125.911 12.4414 135.595 13 138.5"
      stroke="#E2E6EB"
      strokeWidth="0.67033"
      strokeLinecap="round"
    />
    <circle cx="149.77" cy="40.5166" r="4.02198" fill="#E2E6EB" />
    <circle cx="12.3735" cy="77.7141" r="4.02198" fill="#E2E6EB" />
    <circle cx="103.186" cy="153.802" r="4.02198" fill="#E2E6EB" />
    <g filter="url(#filter0_d_39224_208179)">
      <mask id="path-5-inside-1_39224_208179" fill="white">
        <path d="M81 97.022C81 94.8007 82.8007 93 85.022 93H103.978C106.199 93 108 94.8007 108 97.022V109.978C108 112.199 106.199 114 103.978 114H85.022C82.8007 114 81 112.199 81 109.978V97.022Z" />
      </mask>
      <path
        d="M81 97.022C81 94.8007 82.8007 93 85.022 93H103.978C106.199 93 108 94.8007 108 97.022V109.978C108 112.199 106.199 114 103.978 114H85.022C82.8007 114 81 112.199 81 109.978V97.022Z"
        fill="white"
      />
      <path
        d="M81 93H108H81ZM108 109.978C108 112.57 105.899 114.67 103.308 114.67H85.6923C83.1008 114.67 81 112.57 81 109.978C81 111.829 82.8007 113.33 85.022 113.33H103.978C106.199 113.33 108 111.829 108 109.978ZM81 114V93V114ZM108 93V114V93Z"
        fill="#E2E6EB"
        mask="url(#path-5-inside-1_39224_208179)"
      />
      <mask id="path-7-inside-2_39224_208179" fill="white">
        <path d="M86 98.4947C86 97.7543 86.6002 97.1541 87.3407 97.1541H97.3956C98.136 97.1541 98.7363 97.7543 98.7363 98.4947C98.7363 99.2351 98.136 99.8354 97.3956 99.8354H87.3407C86.6002 99.8354 86 99.2351 86 98.4947Z" />
      </mask>
      <path
        d="M86 98.4947C86 97.7543 86.6002 97.1541 87.3407 97.1541H97.3956C98.136 97.1541 98.7363 97.7543 98.7363 98.4947C98.7363 99.2351 98.136 99.8354 97.3956 99.8354H87.3407C86.6002 99.8354 86 99.2351 86 98.4947Z"
        fill="#BAC6D1"
      />
      <path
        d="M86 97.1541H98.7363H86ZM98.7363 98.4947C98.7363 99.4202 97.986 100.171 97.0604 100.171H87.6758C86.7503 100.171 86 99.4202 86 98.4947C86 99.05 86.6002 99.5002 87.3407 99.5002H97.3956C98.136 99.5002 98.7363 99.05 98.7363 98.4947ZM86 99.8354V97.1541V99.8354ZM98.7363 97.1541V99.8354V97.1541Z"
        fill="#818C99"
        mask="url(#path-7-inside-2_39224_208179)"
      />
      <mask id="path-9-inside-3_39224_208179" fill="white">
        <path d="M86 107.857C86 107.117 86.6002 106.517 87.3407 106.517H97.3956C98.136 106.517 98.7363 107.117 98.7363 107.857C98.7363 108.598 98.136 109.198 97.3956 109.198H87.3407C86.6002 109.198 86 108.598 86 107.857Z" />
      </mask>
      <path
        d="M86 107.857C86 107.117 86.6002 106.517 87.3407 106.517H97.3956C98.136 106.517 98.7363 107.117 98.7363 107.857C98.7363 108.598 98.136 109.198 97.3956 109.198H87.3407C86.6002 109.198 86 108.598 86 107.857Z"
        fill="#BAC6D1"
      />
      <path
        d="M86 106.517H98.7363H86ZM98.7363 107.857C98.7363 108.783 97.986 109.533 97.0604 109.533H87.6758C86.7503 109.533 86 108.783 86 107.857C86 108.413 86.6002 108.863 87.3407 108.863H97.3956C98.136 108.863 98.7363 108.413 98.7363 107.857ZM86 109.198V106.517V109.198ZM98.7363 106.517V109.198V106.517Z"
        fill="#818C99"
        mask="url(#path-9-inside-3_39224_208179)"
      />
      <mask id="path-11-inside-4_39224_208179" fill="white">
        <path d="M86 103.176C86 102.435 86.6002 101.835 87.3407 101.835H101.418C102.158 101.835 102.758 102.435 102.758 103.176C102.758 103.916 102.158 104.517 101.418 104.517H87.3407C86.6002 104.517 86 103.916 86 103.176Z" />
      </mask>
      <path
        d="M86 103.176C86 102.435 86.6002 101.835 87.3407 101.835H101.418C102.158 101.835 102.758 102.435 102.758 103.176C102.758 103.916 102.158 104.517 101.418 104.517H87.3407C86.6002 104.517 86 103.916 86 103.176Z"
        fill="#BAC6D1"
      />
      <path
        d="M86 101.835H102.758H86ZM102.758 103.176C102.758 104.101 102.008 104.852 101.082 104.852H87.6758C86.7503 104.852 86 104.101 86 103.176C86 103.731 86.6002 104.181 87.3407 104.181H101.418C102.158 104.181 102.758 103.731 102.758 103.176ZM86 104.517V101.835V104.517ZM102.758 101.835V104.517V101.835Z"
        fill="#818C99"
        mask="url(#path-11-inside-4_39224_208179)"
      />
    </g>
    <g filter="url(#filter1_d_39224_208179)">
      <mask id="path-13-inside-5_39224_208179" fill="white">
        <path d="M40.8457 12.022C40.8457 9.8007 42.6464 8 44.8677 8H92.8237C95.045 8 96.8457 9.8007 96.8457 12.022V50.978C96.8457 53.1993 95.045 55 92.8237 55H44.8677C42.6464 55 40.8457 53.1993 40.8457 50.978V12.022Z" />
      </mask>
      <path
        d="M40.8457 12.022C40.8457 9.8007 42.6464 8 44.8677 8H92.8237C95.045 8 96.8457 9.8007 96.8457 12.022V50.978C96.8457 53.1993 95.045 55 92.8237 55H44.8677C42.6464 55 40.8457 53.1993 40.8457 50.978V12.022Z"
        fill="white"
      />
      <path
        d="M40.8457 8H96.8457H40.8457ZM96.8457 50.978C96.8457 53.5695 94.7449 55.6703 92.1534 55.6703H45.538C42.9465 55.6703 40.8457 53.5695 40.8457 50.978C40.8457 52.8291 42.6464 54.3297 44.8677 54.3297H92.8237C95.045 54.3297 96.8457 52.8291 96.8457 50.978ZM40.8457 55V8V55ZM96.8457 8V55V8Z"
        fill="#E2E6EB"
        mask="url(#path-13-inside-5_39224_208179)"
      />
      <mask id="path-15-inside-6_39224_208179" fill="white">
        <path d="M61.625 16.3846C61.625 15.6442 62.2252 15.0439 62.9657 15.0439H81.7349C82.4753 15.0439 83.0756 15.6442 83.0756 16.3846C83.0756 17.125 82.4753 17.7253 81.7349 17.7253H62.9657C62.2252 17.7253 61.625 17.125 61.625 16.3846Z" />
      </mask>
      <path
        d="M61.625 16.3846C61.625 15.6442 62.2252 15.0439 62.9657 15.0439H81.7349C82.4753 15.0439 83.0756 15.6442 83.0756 16.3846C83.0756 17.125 82.4753 17.7253 81.7349 17.7253H62.9657C62.2252 17.7253 61.625 17.125 61.625 16.3846Z"
        fill="#BAC6D1"
      />
      <path
        d="M61.625 15.0439H83.0756H61.625ZM83.0756 16.3846C83.0756 17.3101 82.3253 18.0604 81.3997 18.0604H63.3008C62.3753 18.0604 61.625 17.3101 61.625 16.3846C61.625 16.9399 62.2252 17.3901 62.9657 17.3901H81.7349C82.4753 17.3901 83.0756 16.9399 83.0756 16.3846ZM61.625 17.7253V15.0439V17.7253ZM83.0756 15.0439V17.7253V15.0439Z"
        fill="#818C99"
        mask="url(#path-15-inside-6_39224_208179)"
      />
      <mask id="path-17-inside-7_39224_208179" fill="white">
        <path d="M48 16.6813C48 15.2005 49.2005 14 50.6813 14H54.7033C56.1841 14 57.3846 15.2005 57.3846 16.6813V21.3736C57.3846 22.8545 56.1842 24.0549 54.7033 24.0549H50.6813C49.2005 24.0549 48 22.8545 48 21.3736V16.6813Z" />
      </mask>
      <path
        d="M48 16.6813C48 15.2005 49.2005 14 50.6813 14H54.7033C56.1841 14 57.3846 15.2005 57.3846 16.6813V21.3736C57.3846 22.8545 56.1842 24.0549 54.7033 24.0549H50.6813C49.2005 24.0549 48 22.8545 48 21.3736V16.6813Z"
        fill="#226DFE"
      />
      <path
        d="M48 14H57.3846H48ZM57.3846 21.3736C57.3846 23.0396 56.0341 24.3901 54.3681 24.3901H51.0165C49.3505 24.3901 48 23.0396 48 21.3736C48 22.6694 49.2005 23.7198 50.6813 23.7198H54.7033C56.1842 23.7198 57.3846 22.6694 57.3846 21.3736ZM48 24.0549V14V24.0549ZM57.3846 14V24.0549V14Z"
        fill="#1159E5"
        mask="url(#path-17-inside-7_39224_208179)"
      />
      <mask id="path-19-inside-8_39224_208179" fill="white">
        <path d="M61.625 21.077C61.625 20.3366 62.2252 19.7363 62.9657 19.7363H88.4382C89.1786 19.7363 89.7789 20.3366 89.7789 21.077C89.7789 21.8174 89.1786 22.4176 88.4382 22.4176H62.9657C62.2252 22.4176 61.625 21.8174 61.625 21.077Z" />
      </mask>
      <path
        d="M61.625 21.077C61.625 20.3366 62.2252 19.7363 62.9657 19.7363H88.4382C89.1786 19.7363 89.7789 20.3366 89.7789 21.077C89.7789 21.8174 89.1786 22.4176 88.4382 22.4176H62.9657C62.2252 22.4176 61.625 21.8174 61.625 21.077Z"
        fill="#BAC6D1"
      />
      <path
        d="M61.625 19.7363H89.7789H61.625ZM89.7789 21.077C89.7789 22.0025 89.0286 22.7528 88.103 22.7528H63.3008C62.3753 22.7528 61.625 22.0025 61.625 21.077C61.625 21.6323 62.2252 22.0825 62.9657 22.0825H88.4382C89.1786 22.0825 89.7789 21.6323 89.7789 21.077ZM61.625 22.4176V19.7363V22.4176ZM89.7789 19.7363V22.4176V19.7363Z"
        fill="#818C99"
        mask="url(#path-19-inside-8_39224_208179)"
      />
      <mask id="path-21-inside-9_39224_208179" fill="white">
        <path d="M47.8457 29.7912C47.8457 29.0507 48.4459 28.4505 49.1864 28.4505H88.505C89.2455 28.4505 89.8457 29.0507 89.8457 29.7912C89.8457 30.5316 89.2455 31.1318 88.505 31.1318H49.1864C48.4459 31.1318 47.8457 30.5316 47.8457 29.7912Z" />
      </mask>
      <path
        d="M47.8457 29.7912C47.8457 29.0507 48.4459 28.4505 49.1864 28.4505H88.505C89.2455 28.4505 89.8457 29.0507 89.8457 29.7912C89.8457 30.5316 89.2455 31.1318 88.505 31.1318H49.1864C48.4459 31.1318 47.8457 30.5316 47.8457 29.7912Z"
        fill="#E2E6EB"
      />
      <path
        d="M47.8457 28.4505H89.8457H47.8457ZM89.8457 29.7912C89.8457 30.7167 89.0954 31.467 88.1699 31.467H49.5215C48.596 31.467 47.8457 30.7167 47.8457 29.7912C47.8457 30.3465 48.4459 30.7967 49.1864 30.7967H88.505C89.2455 30.7967 89.8457 30.3465 89.8457 29.7912ZM47.8457 31.1318V28.4505V31.1318ZM89.8457 28.4505V31.1318V28.4505Z"
        fill="#BAC6D1"
        mask="url(#path-21-inside-9_39224_208179)"
      />
      <mask id="path-23-inside-10_39224_208179" fill="white">
        <path d="M47.8457 35.4725C47.8457 34.7321 48.4459 34.1318 49.1864 34.1318H88.505C89.2455 34.1318 89.8457 34.7321 89.8457 35.4725C89.8457 36.2129 89.2455 36.8132 88.505 36.8132H49.1864C48.4459 36.8132 47.8457 36.2129 47.8457 35.4725Z" />
      </mask>
      <path
        d="M47.8457 35.4725C47.8457 34.7321 48.4459 34.1318 49.1864 34.1318H88.505C89.2455 34.1318 89.8457 34.7321 89.8457 35.4725C89.8457 36.2129 89.2455 36.8132 88.505 36.8132H49.1864C48.4459 36.8132 47.8457 36.2129 47.8457 35.4725Z"
        fill="#E2E6EB"
      />
      <path
        d="M47.8457 34.1318H89.8457H47.8457ZM89.8457 35.4725C89.8457 36.398 89.0954 37.1483 88.1699 37.1483H49.5215C48.596 37.1483 47.8457 36.398 47.8457 35.4725C47.8457 36.0278 48.4459 36.478 49.1864 36.478H88.505C89.2455 36.478 89.8457 36.0278 89.8457 35.4725ZM47.8457 36.8132V34.1318V36.8132ZM89.8457 34.1318V36.8132V34.1318Z"
        fill="#BAC6D1"
        mask="url(#path-23-inside-10_39224_208179)"
      />
      <mask id="path-25-inside-11_39224_208179" fill="white">
        <path d="M47.8457 41.1538C47.8457 40.4133 48.4459 39.8131 49.1864 39.8131H88.505C89.2455 39.8131 89.8457 40.4133 89.8457 41.1538C89.8457 41.8942 89.2455 42.4944 88.505 42.4944H49.1864C48.4459 42.4944 47.8457 41.8942 47.8457 41.1538Z" />
      </mask>
      <path
        d="M47.8457 41.1538C47.8457 40.4133 48.4459 39.8131 49.1864 39.8131H88.505C89.2455 39.8131 89.8457 40.4133 89.8457 41.1538C89.8457 41.8942 89.2455 42.4944 88.505 42.4944H49.1864C48.4459 42.4944 47.8457 41.8942 47.8457 41.1538Z"
        fill="#E2E6EB"
      />
      <path
        d="M47.8457 39.8131H89.8457H47.8457ZM89.8457 41.1538C89.8457 42.0793 89.0954 42.8296 88.1699 42.8296H49.5215C48.596 42.8296 47.8457 42.0793 47.8457 41.1538C47.8457 41.7091 48.4459 42.1593 49.1864 42.1593H88.505C89.2455 42.1593 89.8457 41.7091 89.8457 41.1538ZM47.8457 42.4944V39.8131V42.4944ZM89.8457 39.8131V42.4944V39.8131Z"
        fill="#BAC6D1"
        mask="url(#path-25-inside-11_39224_208179)"
      />
      <mask id="path-27-inside-12_39224_208179" fill="white">
        <path d="M47.8457 46.835C47.8457 46.0946 48.4459 45.4944 49.1864 45.4944H88.505C89.2455 45.4944 89.8457 46.0946 89.8457 46.835C89.8457 47.5755 89.2455 48.1757 88.505 48.1757H49.1864C48.4459 48.1757 47.8457 47.5755 47.8457 46.835Z" />
      </mask>
      <path
        d="M47.8457 46.835C47.8457 46.0946 48.4459 45.4944 49.1864 45.4944H88.505C89.2455 45.4944 89.8457 46.0946 89.8457 46.835C89.8457 47.5755 89.2455 48.1757 88.505 48.1757H49.1864C48.4459 48.1757 47.8457 47.5755 47.8457 46.835Z"
        fill="#E2E6EB"
      />
      <path
        d="M47.8457 45.4944H89.8457H47.8457ZM89.8457 46.835C89.8457 47.7606 89.0954 48.5109 88.1699 48.5109H49.5215C48.596 48.5109 47.8457 47.7606 47.8457 46.835C47.8457 47.3904 48.4459 47.8405 49.1864 47.8405H88.505C89.2455 47.8405 89.8457 47.3904 89.8457 46.835ZM47.8457 48.1757V45.4944V48.1757ZM89.8457 45.4944V48.1757V45.4944Z"
        fill="#BAC6D1"
        mask="url(#path-27-inside-12_39224_208179)"
      />
    </g>
    <g filter="url(#filter2_d_39224_208179)">
      <mask id="path-29-inside-13_39224_208179" fill="white">
        <path d="M150.109 93.1318C150.109 90.9106 151.91 89.1099 154.131 89.1099H186.978C189.199 89.1099 190.999 90.9106 190.999 93.1318V119.945C190.999 122.166 189.199 123.967 186.978 123.967H154.131C151.91 123.967 150.109 122.166 150.109 119.945V93.1318Z" />
      </mask>
      <path
        d="M150.109 93.1318C150.109 90.9106 151.91 89.1099 154.131 89.1099H186.978C189.199 89.1099 190.999 90.9106 190.999 93.1318V119.945C190.999 122.166 189.199 123.967 186.978 123.967H154.131C151.91 123.967 150.109 122.166 150.109 119.945V93.1318Z"
        fill="white"
      />
      <path
        d="M150.109 89.1099H190.999H150.109ZM190.999 119.945C190.999 122.537 188.899 124.637 186.307 124.637H154.802C152.21 124.637 150.109 122.537 150.109 119.945C150.109 121.796 151.91 123.297 154.131 123.297H186.978C189.199 123.297 190.999 121.796 190.999 119.945ZM150.109 123.967V89.1099V123.967ZM190.999 89.1099V123.967V89.1099Z"
        fill="#E2E6EB"
        mask="url(#path-29-inside-13_39224_208179)"
      />
      <mask id="path-31-inside-14_39224_208179" fill="white">
        <path d="M166.197 95.7724C166.197 95.0544 166.779 94.4724 167.497 94.4724H178.974C179.692 94.4724 180.274 95.0544 180.274 95.7724C180.274 96.4904 179.692 97.0724 178.974 97.0724H167.497C166.779 97.0724 166.197 96.4904 166.197 95.7724Z" />
      </mask>
      <path
        d="M166.197 95.7724C166.197 95.0544 166.779 94.4724 167.497 94.4724H178.974C179.692 94.4724 180.274 95.0544 180.274 95.7724C180.274 96.4904 179.692 97.0724 178.974 97.0724H167.497C166.779 97.0724 166.197 96.4904 166.197 95.7724Z"
        fill="#BAC6D1"
      />
      <path
        d="M166.197 94.4724H180.274H166.197ZM180.274 95.7724C180.274 96.6755 179.542 97.4076 178.639 97.4076H167.832C166.929 97.4076 166.197 96.6755 166.197 95.7724C166.197 96.3053 166.779 96.7372 167.497 96.7372H178.974C179.692 96.7372 180.274 96.3053 180.274 95.7724ZM166.197 97.0724V94.4724V97.0724ZM180.274 94.4724V97.0724V94.4724Z"
        fill="#818C99"
        mask="url(#path-31-inside-14_39224_208179)"
      />
      <mask id="path-33-inside-15_39224_208179" fill="white">
        <path d="M166.197 100.3C166.197 99.582 166.779 99 167.497 99H183.667C184.384 99 184.967 99.582 184.967 100.3C184.967 101.018 184.384 101.6 183.667 101.6H167.497C166.779 101.6 166.197 101.018 166.197 100.3Z" />
      </mask>
      <path
        d="M166.197 100.3C166.197 99.582 166.779 99 167.497 99H183.667C184.384 99 184.967 99.582 184.967 100.3C184.967 101.018 184.384 101.6 183.667 101.6H167.497C166.779 101.6 166.197 101.018 166.197 100.3Z"
        fill="#BAC6D1"
      />
      <path
        d="M166.197 99H184.967H166.197ZM184.967 100.3C184.967 101.203 184.234 101.935 183.331 101.935H167.832C166.929 101.935 166.197 101.203 166.197 100.3C166.197 100.833 166.779 101.265 167.497 101.265H183.667C184.384 101.265 184.967 100.833 184.967 100.3ZM166.197 101.6V99V101.6ZM184.967 99V101.6V99Z"
        fill="#818C99"
        mask="url(#path-33-inside-15_39224_208179)"
      />
      <mask id="path-35-inside-16_39224_208179" fill="white">
        <path d="M155.5 96.6812C155.5 95.2003 156.7 93.9999 158.181 93.9999H159.819C161.3 93.9999 162.5 95.2003 162.5 96.6812V99.3186C162.5 100.799 161.3 102 159.819 102H158.181C156.7 102 155.5 100.799 155.5 99.3186V96.6812Z" />
      </mask>
      <path
        d="M155.5 96.6812C155.5 95.2003 156.7 93.9999 158.181 93.9999H159.819C161.3 93.9999 162.5 95.2003 162.5 96.6812V99.3186C162.5 100.799 161.3 102 159.819 102H158.181C156.7 102 155.5 100.799 155.5 99.3186V96.6812Z"
        fill="#226DFE"
      />
      <path
        d="M155.5 93.9999H162.5H155.5ZM162.5 99.3186C162.5 100.985 161.149 102.335 159.484 102.335H158.516C156.851 102.335 155.5 100.985 155.5 99.3186C155.5 100.614 156.7 101.665 158.181 101.665H159.819C161.3 101.665 162.5 100.614 162.5 99.3186ZM155.5 102V93.9999V102ZM162.5 93.9999V102V93.9999Z"
        fill="#1159E5"
        mask="url(#path-35-inside-16_39224_208179)"
      />
      <mask id="path-37-inside-17_39224_208179" fill="white">
        <path d="M155.473 106.838C155.473 106.12 156.055 105.538 156.773 105.538H183.667C184.385 105.538 184.967 106.12 184.967 106.838C184.967 107.556 184.385 108.138 183.667 108.138H156.773C156.055 108.138 155.473 107.556 155.473 106.838Z" />
      </mask>
      <path
        d="M155.473 106.838C155.473 106.12 156.055 105.538 156.773 105.538H183.667C184.385 105.538 184.967 106.12 184.967 106.838C184.967 107.556 184.385 108.138 183.667 108.138H156.773C156.055 108.138 155.473 107.556 155.473 106.838Z"
        fill="#E2E6EB"
      />
      <path
        d="M155.473 105.538H184.967H155.473ZM184.967 106.838C184.967 107.741 184.235 108.473 183.332 108.473H157.108C156.205 108.473 155.473 107.741 155.473 106.838C155.473 107.371 156.055 107.803 156.773 107.803H183.667C184.385 107.803 184.967 107.371 184.967 106.838ZM155.473 108.138V105.538V108.138ZM184.967 105.538V108.138V105.538Z"
        fill="#BAC6D1"
        mask="url(#path-37-inside-17_39224_208179)"
      />
      <mask id="path-39-inside-18_39224_208179" fill="white">
        <path d="M155.473 111.849C155.473 111.131 156.055 110.549 156.773 110.549H183.667C184.385 110.549 184.967 111.131 184.967 111.849C184.967 112.567 184.385 113.149 183.667 113.149H156.773C156.055 113.149 155.473 112.567 155.473 111.849Z" />
      </mask>
      <path
        d="M155.473 111.849C155.473 111.131 156.055 110.549 156.773 110.549H183.667C184.385 110.549 184.967 111.131 184.967 111.849C184.967 112.567 184.385 113.149 183.667 113.149H156.773C156.055 113.149 155.473 112.567 155.473 111.849Z"
        fill="#E2E6EB"
      />
      <path
        d="M155.473 110.549H184.967H155.473ZM184.967 111.849C184.967 112.752 184.235 113.484 183.332 113.484H157.108C156.205 113.484 155.473 112.752 155.473 111.849C155.473 112.382 156.055 112.814 156.773 112.814H183.667C184.385 112.814 184.967 112.382 184.967 111.849ZM155.473 113.149V110.549V113.149ZM184.967 110.549V113.149V110.549Z"
        fill="#BAC6D1"
        mask="url(#path-39-inside-18_39224_208179)"
      />
      <mask id="path-41-inside-19_39224_208179" fill="white">
        <path d="M155.473 116.86C155.473 116.142 156.055 115.56 156.773 115.56H183.667C184.385 115.56 184.967 116.142 184.967 116.86C184.967 117.578 184.385 118.16 183.667 118.16H156.773C156.055 118.16 155.473 117.578 155.473 116.86Z" />
      </mask>
      <path
        d="M155.473 116.86C155.473 116.142 156.055 115.56 156.773 115.56H183.667C184.385 115.56 184.967 116.142 184.967 116.86C184.967 117.578 184.385 118.16 183.667 118.16H156.773C156.055 118.16 155.473 117.578 155.473 116.86Z"
        fill="#E2E6EB"
      />
      <path
        d="M155.473 115.56H184.967H155.473ZM184.967 116.86C184.967 117.763 184.235 118.495 183.332 118.495H157.108C156.205 118.495 155.473 117.763 155.473 116.86C155.473 117.393 156.055 117.825 156.773 117.825H183.667C184.385 117.825 184.967 117.393 184.967 116.86ZM155.473 118.16V115.56V118.16ZM184.967 115.56V118.16V115.56Z"
        fill="#BAC6D1"
        mask="url(#path-41-inside-19_39224_208179)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_39224_208179"
        x="71"
        y="84.4617"
        width="51.2093"
        height="45.5383"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.819608 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_39224_208179"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_39224_208179"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_39224_208179"
        x="30.8457"
        y="0"
        width="80.3077"
        height="71"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.819608 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_39224_208179"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_39224_208179"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_39224_208179"
        x="140.109"
        y="81.1099"
        width="64.8901"
        height="58.8572"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.729412 0 0 0 0 0.776471 0 0 0 0 0.819608 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_39224_208179"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_39224_208179"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
