/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject, injectable } from 'inversify';
import * as Sentry from '@sentry/react';

import { User, LogOptions } from './logger.types';
import { Logger } from './logger';

@injectable()
export class SentryLogger extends Logger {
  private readonly user: User;

  constructor(@inject(User) user: User) {
    super();
    this.user = user;
  }

  /** Логирует сообщение об ошибке в Sentry
   * @param message Сообщение об ошибке
   * @param options Опции содержат данные, которые необходимо залогировать в Sentry
   * @returns {void}
   *  */
  log(message: any, { level, tags, context }: LogOptions) {
    const scopeContext = {
      user: {
        id: this.user.getId() || 'empty',
      },
      level,
      ...(tags ? { tags } : {}),
      ...(context ? { contexts: context } : {}),
    };

    if (typeof message === 'string') {
      Sentry.captureMessage(message, scopeContext);
      return;
    }

    Sentry.captureException(message, scopeContext);
  }
}
