import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  qrScanner: {
    display: 'flex',
    position: 'relative',
    width: 412,
    height: 412,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 6,
  },
  wrapper: {
    display: 'flex',
    width: 400,
    height: 400,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  border: {
    position: 'absolute',
  },
  border_position_top_left: {
    left: 0,
    top: 0,
  },
  border_position_top_right: {
    transform: 'scaleX(-1)',
    left: 'auto',
    right: 0,
    top: 0,
  },
  border_position_bottom_left: {
    transform: 'scaleY(-1)',
    left: 0,
    top: 'auto',
    bottom: 0,
  },
  border_position_bottom_right: {
    transform: 'scale(-1, -1)',
    left: 'auto',
    right: 0,
    top: 'auto',
    bottom: 0,
  },
  overlay: {
    position: 'absolute',
  },
  video: {
    height: 400,
  },
});
