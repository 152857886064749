import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints, tokens }) => ({
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1200,
    paddingLeft: tokens.spacing['16'],
    paddingRight: tokens.spacing['16'],
    background: tokens.colors.bg.light.primary,
    borderBottom: `1px solid ${tokens.colors.stroke.primary}`,

    [breakpoints.up('md')]: {
      paddingTop: tokens.spacing['24'],
      paddingLeft: tokens.spacing['32'],
      paddingBottom: tokens.spacing['24'],
      paddingRight: tokens.spacing['32'],
    },
  },
}));
