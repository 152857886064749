type DownloadNotificationStatus = 'waiting' | 'complete' | 'failed';

export interface NotificationText {
  loading: string;
  complete: string;
  failed: string;
}

export interface DownloadNotificationItem {
  id: string;
  status: DownloadNotificationStatus;
  counter: number;
  text?: NotificationText;
}

interface DownloadNotificationData {
  id: string;
  status: DownloadNotificationStatus;
  text?: string;
}

export interface DownloadNotificationEventEmitter {
  onShow(downloadNotification: DownloadNotificationData): void;
  onCancel(): void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DownloadNotificationEventEmitter = Symbol(
  'DownloadNotificationEventEmitter',
);
