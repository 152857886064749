import { IsNotEmpty, IsUUID } from 'class-validator';

export class DownloadEmployeePolicyDto {
  @IsUUID()
  @IsNotEmpty()
  id: string;

  @IsUUID()
  @IsNotEmpty()
  versionId: string;

  @IsUUID()
  @IsNotEmpty()
  employeeId: string;
}
