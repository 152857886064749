import { injectable } from 'inversify';

import { NotificationData } from '@vk-hr-tek/core/notifications';
import {
  Notification,
  PolicyStatusChangedNotificationPayload,
} from '@vk-hr-tek/core/notifications/gen/notifications';

@injectable()
export class PolicyNotificationsMapper {
  private getNotification(notification: Notification) {
    const objectType = notification.object_type;
    const payload =
      (notification.payload as PolicyStatusChangedNotificationPayload) || null;

    if (objectType !== 'policy_version') {
      return;
    }

    if (payload?.status === 'completed') {
      return {
        title: 'Ознакомление подтверждено!',
        message: payload.policy_name,
        type: 'info',
      };
    }

    if (payload?.status === 'error') {
      return {
        title: 'Ознакомление не подтверждено!',
        message: `Попробуйте подписать ${payload.policy_name} еще раз`,
        type: 'error',
      };
    }
  }

  private getNotifications(data: Notification[]) {
    const result = data
      .map((item) => this.getNotification(item))
      .filter((x) => x !== undefined);

    return result as NotificationData[];
  }

  public mapNotifications(data: Notification[]): NotificationData[] {
    return this.getNotifications(data);
  }
}
