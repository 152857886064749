import React, { RefObject } from 'react';

import {
  ThemeProvider as VKHRTekThemeProvider,
  DeepPartial,
  Tokens,
} from '@vk-hr-tek/ui/Theme';

import { useIsWidget } from '@app/hooks';
import { useTheme } from '@app/config';

interface ThemeProviderProps {
  children: React.ReactNode | React.ReactNode[];
  portalContainerRef?: RefObject<HTMLDivElement>;
  tokens?: DeepPartial<Tokens>;
}

export const ThemeProvider = ({
  children,
  portalContainerRef,
  tokens,
}: ThemeProviderProps) => {
  const theme = useTheme();
  const isWidget = useIsWidget();

  return (
    <VKHRTekThemeProvider
      portal={portalContainerRef}
      theme={theme}
      useContainerQuery={isWidget}
      tokens={tokens}
    >
      {children}
    </VKHRTekThemeProvider>
  );
};
