import React from 'react';

import { Form } from 'react-final-form';

import { useInject } from '@vk-hr-tek/core/ioc';
import { ValidationService } from '@vk-hr-tek/core/validation';
import { Box } from '@vk-hr-tek/ui/Box';
import { FormError, TextInput } from '@vk-hr-tek/ui/form';
import { Link } from '@vk-hr-tek/ui/Link';

import { useDispatch, useSelector } from '@app/hooks';

import { Button } from '../../../ui/Button';
import { LoginInitDto } from '../../../dto';
import {
  selectNotification,
  selectStatus,
  loginInit,
  redirectToSendInviteForPhone,
  redirectToPasswordChange,
  selectAuthInstance,
} from '../../../slice';

export const LoginInit = () => {
  const dispatch = useDispatch();
  const validator = useInject(ValidationService);

  const error = useSelector(selectNotification);
  const status = useSelector(selectStatus);
  const authInstance = useSelector(selectAuthInstance);

  const { button_color: buttonColor } = authInstance;

  const onSubmit = (values: LoginInitDto) =>
    new Promise((resolve) => {
      dispatch(
        loginInit({
          values,
          actions: { resolve },
        }),
      );
    });

  const checkWithoutPhoneCodeVerify =
    window.REACT_APP_VKHRTEK_AUTH_SKIP_PHONE_VERIFICATION.toString() === 'true';

  const handleRedirectToSendInviteForPhone = () =>
    dispatch(redirectToSendInviteForPhone());

  const handleRedirectToPasswordChange = () =>
    dispatch(redirectToPasswordChange());

  return (
    <Form
      onSubmit={onSubmit}
      validate={(values) => validator.validate(values, LoginInitDto)}
      render={({
        handleSubmit,
        submitting,
        pristine,
        hasValidationErrors,
        dirtySinceLastSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mt="8">
            <TextInput
              name="phone"
              label="Номер телефона"
              placeholder="+7"
              mask="+7 (999) 999-99-99"
              id="qaAuthLoginInitPhoneInput"
            />
          </Box>
          <Box mt="8">
            <TextInput
              name="password"
              label="Пароль"
              placeholder="Введите ваш пароль"
              type="password"
              id="qaAuthLoginInitPasswordInput"
            />
          </Box>
          <Box mt="32">
            <Button
              color={buttonColor && `#${buttonColor}`}
              disabled={pristine || hasValidationErrors}
              loading={submitting}
            >
              Далее
            </Button>
          </Box>
          {!dirtySinceLastSubmit && status && status === 'failed' && (
            <FormError error={error} />
          )}
          <Box
            mt="8"
            display="flex"
            justifyContent={
              checkWithoutPhoneCodeVerify ? 'flex-end' : 'space-between'
            }
            alignItems="center"
          >
            {!checkWithoutPhoneCodeVerify && (
              <Link
                onClick={handleRedirectToSendInviteForPhone}
                size="small"
                variant="tertiary"
              >
                Изменился телефон
              </Link>
            )}
            <Link
              onClick={handleRedirectToPasswordChange}
              size="small"
              variant="tertiary"
            >
              Не помню пароль
            </Link>
          </Box>
        </form>
      )}
    />
  );
};
