export const scaleBarInputConfigs = {
  low: {
    text: {
      label: 'Не соответствует',
      color: 'error' as const,
    },
    level: {
      color: 'accent.text.error' as const,
      strokeColor: 'accent.stroke.error' as const,
    },
  },
  normal: {
    text: {
      label: 'Соответствует',
      color: 'success' as const,
    },
    level: {
      color: 'accent.text.success' as const,
      strokeColor: 'accent.stroke.success' as const,
    },
  },
  high: {
    text: {
      label: 'Превышает',
      color: 'primary' as const,
    },
    level: {
      color: 'accent.text.blue' as const,
      strokeColor: 'accent.stroke.blue' as const,
    },
  },
};
