import React, { ReactNode } from 'react';

import { useIsDesktop } from '../hooks';
import { Box } from '../Box';
import { Dialog } from '../Dialog';
import { FilePreview } from '../FilePreview';
import { Snackbar } from '../Snackbar';

import { SpacingParams } from './FilePreviewWithSnackbar.types';
import useStyles from './FilePreviewWithSnackbar.styles';

interface FilePreviewWithSnackbarProps {
  open: boolean;
  onClose: () => void;
  fullScreen?: boolean;
  loadDocument: () => Promise<Blob>;
  snackbarMessage: ReactNode;
  action: ReactNode;
  closeIcon?: ReactNode;
  mt?: SpacingParams;
  mb?: SpacingParams;
}

export const FilePreviewWithSnackbar = ({
  open,
  onClose,
  fullScreen = false,
  loadDocument,
  snackbarMessage,
  action,
  closeIcon = null,
  mt = {} as SpacingParams,
  mb = {} as SpacingParams,
}: FilePreviewWithSnackbarProps) => {
  const isDesktop = useIsDesktop();

  const classes = useStyles({ mt, mb });

  return (
    <Box>
      <Dialog
        scroll="body"
        onClose={onClose}
        open={open}
        fullScreen={fullScreen}
        className="aqa_file_preview_dialog"
        classes={{
          root: classes.root,
          paper: classes.paper,
          paperFullScreen: classes.paperFullScreen,
          paperScrollBody: classes.paperScrollBody,
        }}
      >
        <FilePreview onLoad={loadDocument} />
        {closeIcon}
      </Dialog>
      <Snackbar
        open={open}
        message={snackbarMessage}
        action={action}
        className="aqa_file_preview_snackbar"
        variant={!isDesktop ? 'static' : 'sticky'}
      />
    </Box>
  );
};
