import React, {
  ChangeEvent,
  MouseEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

import { useDebounce } from '@vk-hr-tek/core/hooks';

import { Box } from '../../Box';
import { CancelIcon } from '../../icons';
import { CircularProgress } from '../../CircularProgress';
import { Tooltip } from '../../Tooltip';
import { Label, Preloader, Badge } from '../common';
import { useAutocompleteReducer } from '../hooks';
import { Filter } from '../types';

import { ListBox } from './ListBox';
import useStyles from './MultipleAutocompleteInput.styles';
import {
  MultipleAutocompleteInputOption,
  MultipleAutocompleteInputProps,
} from './MultipleAutocompleteInput.types';

const DEBOUNCE_TIMEOUT = 200;

const getSelectedValues = (
  items: MultipleAutocompleteInputOption[],
  value: string[] | undefined,
) =>
  items
    .filter((item) => value?.includes(item.value))
    .map((item) => `${item.prefix ? `${item.prefix}, ` : ''}${item.label}`)
    .join(', ') || '';

export const MultipleAutocompleteInput = (
  props: MultipleAutocompleteInputProps,
) => {
  const {
    value: initialValue,
    onChange,
    onBlur,
    onLogAction,
    label,
    tooltip,
    name,
    placeholder = '',
    required = false,
    limit = 50,
    disabledTooltip = 'Недоступен для выбранного типа заявки',
    disabled = false,
    clearable = true,
    loading = false,
    error = false,
    helperText = '',
    minInputValueLength = 1,
    renderTags,
    testId = 'test-id-multiple-autocomplete-input',
    additionalFilters,
  } = props;

  const stringifiedValue = JSON.stringify(
    initialValue ? initialValue.sort() : undefined,
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => initialValue, [stringifiedValue]);

  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState(
    props.type === 'local' ? getSelectedValues(props.externalItems, value) : '',
  );
  const [allSelectedItems, setAllSelectedItems] = useState<
    MultipleAutocompleteInputOption[]
  >([]);
  const [isFirstRenderDone, setIsFirstRenderDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<Filter>({
    limit,
    offset: 0,
    query: '',
  });
  const [open, setOpen] = useState(false);
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIMEOUT);

  const { items, status, hasMore, requestItems } =
    useAutocompleteReducer<MultipleAutocompleteInputOption>(
      props.type === 'load' ? props.loadItems : undefined,
    );

  const isItemsLoading = isLoading || status === 'loading';

  const autocompleteGetOptions = () => {
    if (props.type === 'local') return props.externalItems;

    if (!filter.offset && isItemsLoading) {
      return [];
    }

    return items;
  };

  const currentItems = autocompleteGetOptions();

  const autocompleteOptions = useMemo(() => currentItems, [currentItems]);

  const autocompleteGetInputValue = () => {
    if (loading || !isFirstRenderDone) {
      return 'Загрузка...';
    } else if (!open && !inputValue) {
      return selectedValues;
    }

    return inputValue;
  };

  const autocompleteLoadSelectedValuesHandler = () => {
    setSelectedValues(
      value
        ? allSelectedItems
            .filter((item) =>
              value.find(
                (selectedItemValue) => selectedItemValue === item.value,
              ),
            )
            .map(
              (item) => `${item.prefix ? `${item.prefix}, ` : ''}${item.label}`,
            )
            .join(', ')
        : '',
    );
  };

  const autocompleteOpenHandler = () => {
    onLogAction?.('Открыть выпадающий список');
    setOpen(true);
  };

  const autocompleteCloseHandler = () => {
    setOpen(false);
    if (props.type === 'local') {
      setSelectedValues(getSelectedValues(currentItems, value));
    }
  };

  const autocompleteFocusHandler = () => {
    setSelectedValues('');
  };

  const autocompleteBlurHandler = () => {
    if (props.type === 'load') {
      autocompleteLoadSelectedValuesHandler();
    }

    if (open) {
      autocompleteCloseHandler();
    }

    onBlur?.();
  };

  const autocompleteGetOptionLabel = (
    option: MultipleAutocompleteInputOption,
  ) => `${option.prefix ? `${option.prefix}, ` : ''}${option.label}`;

  const autocompleteGetOptionSelected = (
    option: MultipleAutocompleteInputOption,
    selected: MultipleAutocompleteInputOption,
  ) => option.value === selected.value;

  const autocompleteGetOptionDisabled = (
    option: MultipleAutocompleteInputOption,
  ) => option.disabled || false;

  const autocompleteChangeHandler = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: ChangeEvent<Record<string, any>>,
    nextValue: MultipleAutocompleteInputOption[],
    reason: string,
  ) => {
    event.stopPropagation();

    if (props.type === 'local') {
      onChange(nextValue.map((item) => item.value));
    } else {
      let valuesToSet: string[] = [];

      if (reason === 'select-option') {
        valuesToSet = [
          ...(value || []),
          ...nextValue
            .filter((item) => !value?.includes(item.value))
            .map((item) => item.value),
        ];
        setAllSelectedItems((prev) => [
          ...prev,
          ...nextValue.filter(
            (item) =>
              !prev.find(({ value: itemValue }) => itemValue === item.value),
          ),
        ]);
      } else if (reason === 'remove-option') {
        const valuesToRemove = items
          .filter(
            (item) =>
              value?.includes(item.value) &&
              !nextValue.find(
                ({ value: itemValue }) => itemValue === item.value,
              ),
          )
          .map((item) => item.value);
        valuesToSet = [
          ...(value || []).filter((item) => !valuesToRemove.includes(item)),
        ];
        setAllSelectedItems((prev) =>
          prev.filter((item) => valuesToSet.includes(item.value)),
        );
      }

      onChange(valuesToSet);
    }
  };

  const autocompleteClearHandler = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    onChange([]);
    setAllSelectedItems([]);
  };

  const autocompleteInputChangeHandler = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: ChangeEvent<Record<string, any>>,
    nextValue: string,
    reason: string,
  ) => {
    if (
      reason === 'reset' &&
      (!event ||
        (event as ChangeEvent<HTMLInputElement>)?.target.closest(
          'div#scrollable',
        ))
    )
      return;

    setInputValue(nextValue);

    if (reason === 'clear') {
      setSelectedValues('');
    }

    if (reason === 'input' && props.type === 'load') {
      setIsLoading(true);
      setFilter({
        ...filter,
        offset: 0,
        query: nextValue,
      });
    }
  };

  const autocompleteSelectAllChangeHandler = () => {
    if (props.type === 'load') return;

    if (
      value &&
      currentItems.filter((item) => !item.disabled).length === value.length
    ) {
      onChange([]);
      setSelectedValues('');
    } else {
      const itemsToSelect = currentItems
        .filter((item) => !item.disabled)
        .sort((a, b) => (a.label > b.label ? 1 : -1));

      onChange(itemsToSelect.map((item) => item.value));
      setSelectedValues(
        itemsToSelect
          .map(
            (item) => `${item.prefix ? `${item.prefix}, ` : ''}${item.label}`,
          )
          .join(', '),
      );
    }
  };

  useEffect(() => {
    if (props.type === 'local') return;

    if (
      !open ||
      (debouncedInputValue !== '' &&
        debouncedInputValue.length < minInputValueLength)
    ) {
      return;
    }

    requestItems?.(
      {
        ...filter,
        ...additionalFilters,
        offset: 0,
        query: debouncedInputValue,
      },
      'search',
    ).then(() => {
      setFilter({
        ...filter,
        offset: filter.limit,
      });
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, debouncedInputValue]);

  useEffect(() => {
    (async () => {
      if (value?.length && !open && props.type === 'load' && !selectedValues) {
        const itemsByIds = await requestItems?.(
          {
            limit: value.length,
            offset: 0,
            query: '',
            ids: value,
            ...additionalFilters,
          },
          'search',
        );

        const sortedItems = itemsByIds?.sort((a, b) =>
          a.label > b.label ? 1 : -1,
        );

        setSelectedValues(
          sortedItems
            ?.map(
              (item) => `${item.prefix ? `${item.prefix}, ` : ''}${item.label}`,
            )
            .join(', ') || '',
        );

        setAllSelectedItems(sortedItems || []);
      }

      setIsFirstRenderDone(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedValues]);

  useEffect(() => {
    if (props.type === 'local') {
      setSelectedValues(getSelectedValues(props.externalItems, value));
    } else if (isFirstRenderDone) {
      setAllSelectedItems((prev) =>
        prev.filter((item) => value?.includes(item.value)),
      );
      autocompleteLoadSelectedValuesHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Box className={classes.multipleAutocompleteInput}>
      <Label label={label} required={required} tooltip={tooltip} />
      <Autocomplete
        disableClearable
        data-testid={testId}
        open={open}
        onOpen={autocompleteOpenHandler}
        onClose={autocompleteCloseHandler}
        getOptionLabel={autocompleteGetOptionLabel}
        onChange={autocompleteChangeHandler}
        onFocus={autocompleteFocusHandler}
        onBlur={autocompleteBlurHandler}
        classes={{ paper: classes.paper, option: classes.option }}
        disabled={disabled || loading}
        multiple
        disableCloseOnSelect
        clearOnEscape
        options={autocompleteOptions}
        noOptionsText="Ничего не найдено..."
        value={currentItems.filter((item) => value?.includes(item.value))}
        defaultValue={[]}
        inputValue={autocompleteGetInputValue()}
        onInputChange={autocompleteInputChangeHandler}
        loading={isItemsLoading}
        loadingText={
          <Box display="flex" justifyContent="center" p="16">
            <CircularProgress size={30} />
          </Box>
        }
        renderOption={(option) => {
          const { badge } = option;
          const isBottom = badge?.position === 'bottom';
          return (
            <div
              className={classes.listItem}
              onClick={(event) => option.disabled && event.stopPropagation()}
            >
              <Checkbox
                checked={
                  !!value && value.some((item: string) => item === option.value)
                }
                color="primary"
              />
              {badge && (
                <Box mr="8" order={isBottom ? 1 : 0}>
                  <Badge
                    title={badge.title}
                    description={badge.description}
                    color={badge.color}
                  />
                </Box>
              )}
              {option.disabled ? (
                <div className={classes.listItemLabel}>
                  <Tooltip
                    title={`${disabledTooltip}${
                      option.tooltipText ? ` ${option.tooltipText}` : ''
                    }`}
                  >
                    <div>
                      {option.prefix ? `${option.prefix}, ` : ''}
                      {option.label}
                    </div>
                  </Tooltip>
                </div>
              ) : (
                <div>
                  {option.prefix ? `${option.prefix}, ` : ''}
                  {option.label}
                </div>
              )}
            </div>
          );
        }}
        getOptionSelected={autocompleteGetOptionSelected}
        getOptionDisabled={autocompleteGetOptionDisabled}
        {...(props.type === 'load'
          ? {
              ListboxComponent: ListBox as unknown as React.ComponentType<
                React.HTMLAttributes<HTMLElement>
              >,
              ListboxProps: {
                dataLength: currentItems.length,
                next: () => {
                  requestItems?.({ ...filter, ...additionalFilters }, 'get');
                  setFilter({
                    ...filter,
                    offset: filter.offset + filter.limit,
                  });
                },
                hasMore,
                loading: isItemsLoading,
              },
            }
          : {})}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => {
          return (
            <TextField
              {...params}
              name={name}
              classes={{ root: classes.input }}
              variant="outlined"
              autoComplete="off"
              fullWidth
              disabled={disabled || loading || !isFirstRenderDone}
              error={error}
              helperText={helperText}
              placeholder={placeholder || label}
              InputProps={{
                ref: params?.InputProps?.ref,
                endAdornment:
                  loading || !isFirstRenderDone ? (
                    <Preloader />
                  ) : (
                    <Box display="flex" alignItems="center" mx="16" gap="4">
                      {clearable && !disabled && !!value?.length && (
                        <Box width={24} height={24}>
                          <Box
                            className={`${classes.clearIcon} multipleAutocompleteClearIcon`}
                            onClick={autocompleteClearHandler}
                          >
                            <CancelIcon color="disabled" />
                          </Box>
                        </Box>
                      )}

                      <Box>{params?.InputProps?.endAdornment}</Box>
                    </Box>
                  ),
              }}
            />
          );
        }}
      />
      {props.type === 'local' && props.allowSelectAll && (
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              color="primary"
              checked={
                !!value?.length &&
                currentItems.filter((item) => !item.disabled).length ===
                  value.length
              }
              onChange={autocompleteSelectAllChangeHandler}
            />
          }
          label={
            <span className={classes.checkboxLabel}>
              {props.selectAllLabel || 'Выбрать всех'}
            </span>
          }
        />
      )}
      {renderTags && !disabled && !!value?.length && (
        <Box mt="16">
          {renderTags(
            value,
            props.type === 'load' ? allSelectedItems : props.externalItems,
          )}
        </Box>
      )}
    </Box>
  );
};
