import React, { ReactElement, useCallback } from 'react';

import { FieldProps, FieldRenderProps, useField } from 'react-final-form';

import { ScaleBarInput as Input } from '../../input';

interface ScaleBarInputProps {
  target: number;
  showLabel?: boolean;
  renderTooltip?: (index: number) => ReactElement | undefined;
  disabled?: boolean;
  showEmptyIfPristine?: boolean;
}

export const ScaleBarInput = ({
  renderTooltip,
  target,
  showLabel,
  disabled,
  showEmptyIfPristine,
  name,
  ...rest
}: ScaleBarInputProps &
  FieldProps<number | undefined, FieldRenderProps<number>>) => {
  const { input, meta } = useField(name, rest);
  const handleChange = useCallback(
    (onChange: (newValue: number) => void) => (newValue: number) => {
      onChange(newValue);
      input.onBlur();
    },
    [input],
  );
  return (
    <Input
      {...input}
      target={target}
      showLabel={showLabel}
      renderTooltip={renderTooltip}
      disabled={disabled}
      value={meta.touched || !showEmptyIfPristine ? input.value : 0}
      onChange={handleChange(input.onChange)}
    />
  );
};
