import React from 'react';

import { useLocation } from 'react-router-dom';

import { Link } from '@vk-hr-tek/ui/Link';
import { QueryService } from '@vk-hr-tek/core/query';
import { useInject } from '@vk-hr-tek/core/ioc';

interface BaseBackLinkProps {
  title: string;
  compareHistoryState?: (prevStateObj: {
    pathname: string;
    search: string;
  }) => boolean;
  withoutFilters?: boolean;
}

interface BackLinkOnClickProps extends BaseBackLinkProps {
  onClick: () => void;
}

interface BackLinkRouterProps extends BaseBackLinkProps {
  router: {
    goToList(query?: string): void;
  };
  queryParams?: Record<string, any>;
}

type BackLinkProps = BackLinkOnClickProps | BackLinkRouterProps;

const isOnClickProps = (props: BackLinkProps): props is BackLinkOnClickProps =>
  Object.prototype.hasOwnProperty.call(props, 'onClick');

const isRouterProps = (props: BackLinkProps): props is BackLinkRouterProps =>
  Object.prototype.hasOwnProperty.call(props, 'router');

export const BackLink = (props: BackLinkProps) => {
  const queryService = useInject<QueryService>(QueryService);
  const location =
    useLocation<{ prev: { pathname: string; search: string }[] }>();

  const { compareHistoryState } = props;

  const handleClick = () => {
    if (isOnClickProps(props)) {
      props.onClick();
      return;
    }

    if (isRouterProps(props)) {
      if (location.state?.prev) {
        if (compareHistoryState) {
          const idx = location.state.prev.findIndex(compareHistoryState);

          props.router.goToList(
            idx !== -1 ? location.state.prev?.[idx].search : undefined,
          );
        } else {
          if (props.withoutFilters && location.state.prev?.[0]?.search) {
            const queryObj = queryService.parse(
              location.state.prev?.[0]?.search,
            );

            const newQuery = queryService.stringify({
              ...queryObj,
              filters: {},
            });

            props.router.goToList(newQuery);

            return;
          }

          props.router.goToList(location.state.prev?.[0]?.search);
        }
      } else {
        const query =
          props.queryParams &&
          `?${new URLSearchParams(props.queryParams).toString()}`;

        if (props.withoutFilters && query) {
          const queryObj = queryService.parse(query);

          const newQuery = queryService.stringify({ ...queryObj, filters: {} });

          props.router.goToList(newQuery);
        }

        props.router.goToList(query);
      }
    }
  };

  return (
    <Link size="small" onClick={handleClick} stroke={false}>
      {props.title}
    </Link>
  );
};
