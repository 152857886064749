/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { useInject } from '@vk-hr-tek/core/ioc';

import { useSelector, useDispatch } from '@app/hooks';

import { PageLoading } from '../../../../layout';
import { PageError } from '../../../ui/page';
import { useAuth } from '../../../hooks';
import { AuthRouterService } from '../../../services';
import {
  verifyOpenIdToken,
  selectStage,
  selectNotification,
} from '../../../slice';

export const Login = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const step = useSelector(selectStage);
  const router = useInject(AuthRouterService);
  const error = useSelector(selectNotification);

  useEffect(() => {
    if (isAuthenticated) {
      router.redirectToSaved();
    }
  }, [isAuthenticated, router]);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(verifyOpenIdToken());
    }
  }, [isAuthenticated, dispatch]);

  if (step === 'error') {
    return <PageError theme="oauth2" error={error} />;
  }

  return <PageLoading />;
};
