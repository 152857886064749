import { interfaces } from 'inversify';

import { container as containerDefault } from '@vk-hr-tek/core/ioc';
import { RoleService, TokenService } from '@vk-hr-tek/core/http';
import { ListService } from '@vk-hr-tek/core/filter/types';
import {
  GroupingStrategy,
  NotificationsMapper,
  NotificationsEventEmitter,
} from '@vk-hr-tek/core/notifications/types';
import { Notification } from '@vk-hr-tek/core/notifications/gen/notifications';
import {
  Logger,
  ConsoleLogger,
  SentryLogger,
  User,
} from '@vk-hr-tek/core/logger';
import { History } from '@vk-hr-tek/core/history';
import {
  LocalStorage,
  LocalStorageService,
} from '@vk-hr-tek/core/local-storage';
import { RedirectPageService } from '@vk-hr-tek/app/auth/services/common';
import { UpdatorService } from '@vk-hr-tek/core/updator';
import { DownloadNotificationEventEmitter } from '@vk-hr-tek/core/download-notification/types';

import { DashboardRouter } from '../dashboard/types';
import { AbsencesRouter } from '../absences/types';
import { AuthTokenService, AuthOpenIdService } from '../auth/services';
import { CandidateRouter } from '../candidate/types';
import { CandidatesRouter } from '../candidates/types';
import { EmployeesRouter } from '../employees/types';
import { EventsRouter } from '../events/types';
import { EventNotificationsMapper } from '../events/mappers';
import { PolicyNotificationsMapper } from '../policy/mappers';
import {
  EventsListService,
  EventNotificationService,
} from '../events/services';
import { OrganizationRouter } from '../organization/types';
import { PolicyRouter } from '../policy/types';
import { RedirectPage } from '../auth/types';
import {
  SettingsRouter,
  SettingsAttorneysRouter,
  SettingsAttorneysUsersRouter,
  SettingsGroupsRouter,
  SettingsCompanyRouter,
  SettingsEventTypesRouter,
  SettingsSubstitutesRouter,
  SettingsTemplatesRouter,
  SettingsVacationsRouter,
  SettingsCompanyUnepTypesRouter,
} from '../settings/types';
import { UserRoleService, UserEntityService } from '../user/services';
import { UserRouter } from '../user/types';
import { VacationsRouter } from '../vacations/types';
import { CompetenciesRouter } from '../competencies/types';
import { PersonalRouter } from '../personal/types';

import {
  AbsencesRouterService,
  DashboardRouterService,
  CandidatesRouterService,
  CandidateRouterService,
  EmployeesRouterService,
  EventsRouterService,
  OrganizationRouterService,
  PolicyRouterService,
  RouterServices,
  SettingsAttorneysRouterService,
  SettingsAttorneysUsersRouterService,
  SettingsCompanyUnepTypesRouterService,
  SettingsCompanyRouterService,
  SettingsEventTypesRouterService,
  SettingsGroupsRouterService,
  SettingsRouterService,
  SettingsSubstitutesRouterService,
  SettingsTemplatesRouterService,
  SettingsVacationsRouterService,
  UserRouterService,
  VacationsRouterService,
  CompetenciesRouterService,
  PersonalRouterService,
} from './router';

const emptyNotificationsEmitter: NotificationsEventEmitter = {
  onShow: () => {},
};

const emptyDownloadNotificationsEmitter: DownloadNotificationEventEmitter = {
  onShow: () => {},
  onCancel: () => {},
};

interface InitOptions {
  history: History;
  router?: RouterServices;
  notifications?: NotificationsEventEmitter;
  downloadNotifications?: DownloadNotificationEventEmitter;
  localStorageNamespace?: string;
  role?: RoleService;
  redirectPageService?: RedirectPage;
  auth?: TokenService;
  updator?: UpdatorService;
  container?: typeof containerDefault;
}

export const init = ({
  container = containerDefault,
  router,
  notifications = emptyNotificationsEmitter,
  downloadNotifications = emptyDownloadNotificationsEmitter,
  history,
  role,
  redirectPageService,
  auth,
  localStorageNamespace = '',
  updator,
}: InitOptions) => {
  const createMapper = (mappers: NotificationsMapper[]) => {
    return {
      mapNotifications(data: Notification[]) {
        return mappers.flatMap((mapper: NotificationsMapper) =>
          mapper.mapNotifications(data),
        );
      },
    };
  };

  container.unbindAll();
  container.bind<History>(History).toConstantValue(history);
  container
    .bind<LocalStorage>(LocalStorage)
    .toConstantValue(new LocalStorageService(localStorageNamespace));

  container.bind<ListService>(ListService).to(EventsListService);

  if (role) {
    container.bind<RoleService>(RoleService).toConstantValue(role);
  } else {
    container.bind<RoleService>(RoleService).to(UserRoleService);
  }

  if (redirectPageService) {
    container
      .bind<RedirectPage>(RedirectPage)
      .toConstantValue(redirectPageService);
  } else {
    container.bind<RedirectPage>(RedirectPage).to(RedirectPageService);
  }

  container.bind<TokenService>(TokenService).to(AuthTokenService);

  if (auth && auth.get && auth.restore) {
    container.bind<AuthOpenIdService>(AuthOpenIdService).toConstantValue(auth);
  }

  container.bind<User>(User).to(UserEntityService);

  container
    .bind<Logger>(Logger)
    .to(window.REACT_APP_VKHRTEK_SENTRY_URL ? SentryLogger : ConsoleLogger);

  if (updator) {
    container.bind<UpdatorService>(UpdatorService).toConstantValue(updator);
  } else {
    container.bind<UpdatorService>(UpdatorService).to(UpdatorService);
  }

  container
    .bind<GroupingStrategy>(GroupingStrategy)
    .to(EventNotificationService);
  container
    .bind<NotificationsMapper>(NotificationsMapper)
    .toDynamicValue((context: interfaces.Context) => {
      const roleService = context.container.get<RoleService>(RoleService);
      return createMapper([
        new EventNotificationsMapper(roleService),
        new PolicyNotificationsMapper(),
      ]);
    });

  container
    .bind<NotificationsEventEmitter>(NotificationsEventEmitter)
    .toConstantValue({
      ...emptyNotificationsEmitter,
      ...('onShow' in notifications ? notifications : {}),
    });

  container
    .bind<DownloadNotificationEventEmitter>(DownloadNotificationEventEmitter)
    .toConstantValue({
      ...emptyDownloadNotificationsEmitter,
      ...('onShow' in downloadNotifications ? downloadNotifications : {}),
    });

  type Routers = Required<RouterServices>[keyof Required<RouterServices>];

  const createProxyRouter = <T extends Routers>(
    context: interfaces.Context,
    constructor: new (history: History, role: RoleService) => T,
    key: keyof RouterServices,
  ) => {
    const routerImplementation = new constructor(
      context.container.get<History>(History),
      context.container.get<RoleService>(RoleService),
    );

    return new Proxy(routerImplementation, {
      get(target, method: keyof Routers) {
        if (router && router[key] && method in (router as any)[key]) {
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          return function (...args: any[]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            router[key]?.[method](...args);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            target[method](...args);
          };
        } else {
          return target[method];
        }
      },
    });
  };

  container
    .bind<CandidatesRouter>(CandidatesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, CandidatesRouterService, 'candidates');
    });

  container.bind<CandidateRouter>(CandidateRouter).to(CandidateRouterService);

  container
    .bind<EmployeesRouter>(EmployeesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, EmployeesRouterService, 'employees');
    });

  container
    .bind<DashboardRouter>(DashboardRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, DashboardRouterService, 'dashboard');
    });

  container
    .bind<AbsencesRouter>(AbsencesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, AbsencesRouterService, 'absences');
    });

  container
    .bind<EventsRouter>(EventsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, EventsRouterService, 'events');
    });

  container
    .bind<CompetenciesRouter>(CompetenciesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, CompetenciesRouterService, 'events');
    });

  container
    .bind<OrganizationRouter>(OrganizationRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        OrganizationRouterService,
        'organization',
      );
    });

  container
    .bind<PolicyRouter>(PolicyRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, PolicyRouterService, 'policy');
    });

  container
    .bind<SettingsAttorneysRouter>(SettingsAttorneysRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsAttorneysRouterService,
        'attorneysSettings',
      );
    });

  container
    .bind<SettingsAttorneysUsersRouter>(SettingsAttorneysUsersRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsAttorneysUsersRouterService,
        'attorneysUsersSettings',
      );
    });

  container
    .bind<SettingsCompanyUnepTypesRouter>(SettingsCompanyUnepTypesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsCompanyUnepTypesRouterService,
        'companyUnepTypeSettings',
      );
    });

  container
    .bind<SettingsEventTypesRouter>(SettingsEventTypesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsEventTypesRouterService,
        'eventTypesSettings',
      );
    });

  container
    .bind<SettingsGroupsRouter>(SettingsGroupsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsGroupsRouterService,
        'groupsSettings',
      );
    });

  container
    .bind<SettingsRouter>(SettingsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, SettingsRouterService, 'settings');
    });

  container
    .bind<SettingsSubstitutesRouter>(SettingsSubstitutesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsSubstitutesRouterService,
        'substitutesSettings',
      );
    });

  container
    .bind<SettingsTemplatesRouter>(SettingsTemplatesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsTemplatesRouterService,
        'templatesSettings',
      );
    });

  container
    .bind<SettingsVacationsRouter>(SettingsVacationsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(
        context,
        SettingsVacationsRouterService,
        'vacationsSettings',
      );
    });

  container
    .bind<UserRouter>(UserRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, UserRouterService, 'user');
    });

  container
    .bind<VacationsRouter>(VacationsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, VacationsRouterService, 'vacations');
    });

  container
    .bind<PersonalRouter>(PersonalRouter)
    .toDynamicValue((context: interfaces.Context) => {
      return createProxyRouter(context, PersonalRouterService, 'personal');
    });

  container
    .bind<SettingsCompanyRouter>(SettingsCompanyRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsCompanyRouter = new SettingsCompanyRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsCompanyRouter, {
        get(target, method: keyof SettingsCompanyRouter) {
          if (router?.vacations && method in router.vacations) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.vacations?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });
};
