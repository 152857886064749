import {
  IsString,
  IsPhoneNumber,
  IsNotEmpty,
  IsUUID,
  Length,
  IsNumberString,
} from 'class-validator';

import { REQUIRED_FIELD_VALIDATION_MESSAGE } from '../../auth.constants';

export class RegisterAliDto {
  @Length(6, 6, {
    message: 'Неверный СМС-код',
    groups: ['code-verify'],
  })
  @IsNumberString(
    {
      no_symbols: true,
    },
    {
      message: 'Неверный СМС-код',
      groups: ['code-verify'],
    },
  )
  @IsString({
    message: 'Вы забыли ввести СМС-код',
    groups: ['code-verify'],
  })
  @IsNotEmpty({
    message: 'Вы забыли ввести СМС-код',
    groups: ['code-verify'],
  })
  code: string;

  @IsPhoneNumber('RU', {
    message: 'Неверный номер телефона',
  })
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  phone?: string;

  @IsUUID()
  @IsString()
  @IsNotEmpty(REQUIRED_FIELD_VALIDATION_MESSAGE)
  passCode?: string;
}
