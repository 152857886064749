import React, { ReactNode } from 'react';

import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { Variant } from '@material-ui/core/styles/createTypography';
import { useTheme } from '@material-ui/core';

import { Box, BoxColors, SpacingValues } from '../Box';
import { Typography } from '../Typography';

import { useStyles } from './Callout.styles';

type CalloutVariant = 'error' | 'warning' | 'primary' | 'paper';
type Size = 'small' | 'medium' | 'large';

export interface CalloutProps {
  bordered?: boolean;
  children: ReactNode | ReactNode[];
  showIcon?: boolean;
  variant?: CalloutVariant;
  withBackground?: boolean;
  expanded?: boolean;
  title?: string;
  size?: Size;
}

export const Callout = ({
  bordered = false,
  children,
  title,
  showIcon = true,
  withBackground = true,
  variant = 'primary',
  expanded = false,
  size = 'medium',
}: CalloutProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const borderColor: Record<string, BoxColors> = {
    error: 'accent.text.error',
    warning: 'accent.text.warning',
    primary: 'stroke.primary',
    paper: 'stroke.primary',
  };

  const bgColor: Record<string, BoxColors> = {
    error: 'accent.bg.error',
    warning: 'accent.bg.warning',
    primary: 'bg.greyscale.primary',
    paper: 'bg.light.primary',
  };

  const color: Record<string, BoxColors> = {
    error: 'accent.text.error',
    warning: 'accent.text.warning',
    primary: 'text.light.primary',
    paper: 'text.light.primary',
  };

  const expandedPaddingBySize: Record<string, SpacingValues> = {
    small: '16',
    medium: '24',
    large: '32',
  };

  const paddingXBySize: Record<string, SpacingValues> = {
    small: '12',
    medium: '16',
    large: '24',
  };
  const paddingYBySize: Record<string, SpacingValues> = {
    small: '4',
    medium: '8',
    large: '16',
  };

  const typographyTitleVariant = {
    small: 'subtitle2',
    medium: 'subtitle1',
    large: 'h6',
  };

  return (
    <Box
      bgcolor={withBackground ? bgColor[variant] : undefined}
      radius={size === 'large' ? 'l' : 'm'}
      border={bordered ? '1px solid' : 'none'}
      borderColor={withBackground ? borderColor[variant] : borderColor.primary}
      color={withBackground ? color[variant] : color.primary}
      display="flex"
      className="aqa_callout"
      alignItems="center"
      px={expanded ? expandedPaddingBySize[size] : paddingXBySize[size]}
      py={expanded ? expandedPaddingBySize[size] : paddingYBySize[size]}
    >
      {expanded ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <Box display="flex" alignItems="center" mb="8">
            {showIcon && (
              <Box
                mr={size === 'small' ? '8' : '12'}
                display="flex"
                alignItems="center"
                alignSelf="flex-start"
                color={color[variant]}
              >
                <ErrorIcon
                  color={variant === 'primary' ? 'primary' : 'inherit'}
                  className={classes.icon}
                />
              </Box>
            )}
            {title && (
              <Typography variant={typographyTitleVariant[size] as Variant}>
                {title}
              </Typography>
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            fontSize={
              size === 'large'
                ? theme.tokens.typography.variant.body2.fontSize
                : theme.tokens.typography.variant.body3.fontSize
            }
          >
            {children}
          </Box>
        </Box>
      ) : (
        <>
          {showIcon && (
            <Box
              mr={size === 'small' ? '8' : '12'}
              color={color[variant]}
              display="flex"
              alignItems="center"
            >
              <ErrorIcon
                color={variant === 'primary' ? 'primary' : 'inherit'}
                className={classes.icon}
              />
            </Box>
          )}
          {children}
        </>
      )}
    </Box>
  );
};
