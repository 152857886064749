import React from 'react';

import { AppError } from '@vk-hr-tek/core/error';
import { Stages } from '@vk-hr-tek/ui/Stages';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks/media';

import { Page, PageError } from '../../../ui/page';
import { selectInviteCode, selectStage } from '../../../slice';
import { useSelector } from '../../../../app/hooks';
import { useAuth } from '../../../hooks';

import { LoginInit } from './LoginInit';
import { LoginFinish } from './LoginFinish';

const steps = [
  {
    name: 'init',
    content: <LoginInit />,
    label: 'Номер телефона',
  },
  {
    name: 'finish',
    content: <LoginFinish />,
    label: 'СМС-код',
  },
];

export const Login = () => {
  const step = useSelector(selectStage);
  const invite = useSelector(selectInviteCode);

  const isAuthenticated = useAuth();

  const isDesktop = useIsDesktop();

  if (!isAuthenticated && !invite) {
    return (
      <PageError
        error={
          new AppError('client', {
            code: 400,
            message: 'Bad request',
            data: {
              error_code: 'invalid_candidate_invite_code',
            },
          })
        }
      />
    );
  }

  return (
    <Page title="Вход">
      <Stages
        active={step}
        steps={steps}
        size={isDesktop ? 'medium' : 'small'}
      />
    </Page>
  );
};
