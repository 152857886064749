import React from 'react';

import { useField, FieldProps, FieldRenderProps } from 'react-final-form';

import { Calendar } from '@vk-hr-tek/core/calendar';

import { DateInput as Input } from '../../input';

type ValidateType = (
  value: any,
  allValues?: any,
) => (string | undefined) | Promise<string | undefined>;

export const DateInput = ({
  label,
  name,
  tooltip,
  onChange,
  onClear,
  placeholder = 'ДД.ММ.ГГГГ',
  required = false,
  after,
  disablePast = false,
  minDate = Calendar.minDate,
  maxDate = Calendar.maxDate,
  recognizedValue,
  shouldDisableDate,
  externalError,
  otherSelectedDate,
  clearable = false,
  disabled = false,
  showRange = false,
  alwaysShowClear = false,
  loading = false,
  isRecognitionUsedBefore = false,
  testId,
  validateArray,
  ...rest
}: { after?: (value: string | undefined) => React.ReactNode } & FieldProps<
  string | undefined,
  FieldRenderProps<string | undefined> & {
    validateArray?: ValidateType[] | undefined;
  }
>) => {
  const composeValidators =
    (validators: ValidateType[]) =>
    (value: Record<string, any> | any, allValues: Record<string, any>) => {
      const result = validators.reduce(
        (error: any, validator?: ValidateType) =>
          error || validator?.(value, allValues),
        undefined,
      );
      return result;
    };

  const { input, meta } = useField(name, {
    validate: composeValidators(validateArray || []),
    ...rest,
  });

  return (
    <Input
      {...input}
      testId={testId}
      label={label}
      tooltip={tooltip}
      onClear={onClear}
      disabled={disabled}
      placeholder={placeholder}
      error={!disabled && meta.touched && meta.error}
      minDate={minDate}
      maxDate={maxDate}
      recognizedValue={recognizedValue}
      isRecognitionUsedBefore={isRecognitionUsedBefore}
      required={required}
      disablePast={disablePast}
      otherSelectedDate={otherSelectedDate}
      externalError={externalError}
      shouldDisableDate={shouldDisableDate}
      clearable={clearable}
      showRange={showRange}
      alwaysShowClear={alwaysShowClear}
      loading={loading}
    />
  );
};
