import {
  IsString,
  IsNotEmpty,
  IsUUID,
  IsOptional,
  IsIn,
  IsArray,
} from 'class-validator';

import { FormAttributesRequest } from '../../types';

export class UnepLocalBatchDto {
  @IsUUID()
  @IsNotEmpty()
  eventId: string;

  @IsUUID()
  @IsNotEmpty()
  nodeId: string;

  @IsString({ each: true })
  @IsArray()
  @IsNotEmpty()
  documentIds: string[];

  @IsString()
  @IsNotEmpty()
  certificateId: string;

  @IsString()
  @IsOptional()
  tsp?: string;

  @IsIn(['api', 'browser_plugin'])
  @IsString()
  @IsOptional()
  hashSource?: 'api' | 'browser_plugin';

  attributes?: FormAttributesRequest;
}
