import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';

import { WidgetQuickActionsResponse } from '@app/gen/dashboard';

@injectable()
export class FastActionsService {
  constructor(
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getFastActions() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        '/widget/quick_actions',
        {},
        { reloadIfOldVersion: true },
      ),
      WidgetQuickActionsResponse,
    );

    return result.actions;
  }
}
