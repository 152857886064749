import React, { useRef, useEffect } from 'react';

import classNames from 'classnames';

import Qr from './qr';
import border from './Rectangle.svg';
import { useStyles } from './QrScanner.styles';

interface QrScannerProps {
  onScan: (text: string) => Promise<void>;
}

type BorderPosition =
  | 'border_position_top_left'
  | 'border_position_top_right'
  | 'border_position_bottom_left'
  | 'border_position_bottom_right';

export const QrScanner = ({ onScan }: QrScannerProps) => {
  const videoEl = useRef<HTMLVideoElement | null>(null);
  const overlayEl = useRef<HTMLDivElement | null>(null);
  const qrScanner = useRef<Qr | null>(null);
  const classes = useStyles();

  const borders: BorderPosition[] = [
    'border_position_top_left',
    'border_position_top_right',
    'border_position_bottom_left',
    'border_position_bottom_right',
  ];

  useEffect(() => {
    if (videoEl.current && overlayEl.current) {
      qrScanner.current = new Qr(
        videoEl.current,
        (result) => onScan(result.data),
        {
          preferredCamera: 'user',
          highlightScanRegion: true,
          highlightCodeOutline: true,
          overlay: overlayEl.current,
          calculateScanRegion(video: HTMLVideoElement) {
            const scanRegionSize = video.videoHeight;

            return {
              x: Math.round((video.videoWidth - scanRegionSize) / 2),
              y: Math.round((video.videoHeight - scanRegionSize) / 2),
              width: scanRegionSize,
              height: scanRegionSize,
              downScaledWidth: video.videoHeight,
              downScaledHeight: video.videoHeight,
            };
          },
        },
      );

      qrScanner.current.start();
    }

    return () => {
      if (qrScanner.current) {
        qrScanner.current.destroy();
        qrScanner.current = null;
      }
    };
  }, [videoEl, onScan]);

  return (
    <div className={classes.qrScanner}>
      <div className={classes.wrapper}>
        <video ref={videoEl} className={classes.video}></video>
        <div
          ref={overlayEl}
          className={classNames('scan-region-highlight', classes.overlay)}
        />
      </div>
      {borders.map((position) => (
        <img
          src={border}
          key={position}
          className={classNames(classes.border, classes[position])}
        />
      ))}
    </div>
  );
};
