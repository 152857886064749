import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { classToPlain, plainToClass } from 'class-transformer';
import { FORM_ERROR } from 'final-form';

import { PrintService } from '@vk-hr-tek/core/print';
import { AppError } from '@vk-hr-tek/core/error';
import { QueryService } from '@vk-hr-tek/core/query';
import { showError, showNotification } from '@vk-hr-tek/core/notifications';
import { GetUnitTypesDto, UnitService } from '@vk-hr-tek/core/units';
import { History } from '@vk-hr-tek/core/history';

import {
  GetPolicyEmployeesResponse,
  GetPolicyWithVersionsResponse as Policy,
} from '@app/gen/policy';
import { GetUnitTypesResponse } from '@app/gen/settings';
import { ThunkExtra } from '@app/store';

import { PolicyDetailService } from '../../services';
import {
  CreatePolicyVersionDto,
  DeletePolicyVersionDto,
  GetPolicyDetailDto,
  GetPolicyVersionEmployeesDto,
  UpdatePolicyVersionDto,
  UpdatePolicyDto,
} from '../../dto';
import {
  completeLoading,
  PolicyState,
  setError,
  startLoading,
} from '../policy.state';
import { PolicyDetailMapper } from '../../mappers';
import { PolicyRouter } from '../../types';

export const getPolicyVersionEmployees = createAsyncThunk<
  GetPolicyEmployeesResponse,
  GetPolicyVersionEmployeesDto,
  ThunkExtra
>(
  'policy/getPolicyVersionEmployees',
  async (
    getPolicyVersionEmployeesDto,
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().policy.detail;

      if (
        state.entity &&
        !state.entity.versions.find(
          (version) =>
            version.policy_version_id ===
            getPolicyVersionEmployeesDto.versionId,
        )
      ) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const policyService = inject(PolicyDetailService);

      const result = await policyService.getPolicyVersionEmployees(
        getPolicyVersionEmployeesDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getAdditionalPolicyVersionEmployees = createAsyncThunk<
  GetPolicyEmployeesResponse,
  GetPolicyVersionEmployeesDto,
  ThunkExtra
>(
  'policy/getAdditionalPolicyVersionEmployees',
  async (
    getPolicyVersionEmployeesDto,
    { rejectWithValue, getState, extra: { inject } },
  ) => {
    try {
      const state = getState().policy.detail;

      if (
        state.entity &&
        !state.entity.versions.find(
          (version) =>
            version.policy_version_id ===
            getPolicyVersionEmployeesDto.versionId,
        )
      ) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const policyService = inject(PolicyDetailService);

      const result = await policyService.getPolicyVersionEmployees(
        getPolicyVersionEmployeesDto,
      );

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getPolicyUnitTypes = createAsyncThunk<
  GetUnitTypesResponse,
  GetUnitTypesDto,
  ThunkExtra
>(
  'policy/getPolicyUnitTypes',
  async (dto, { rejectWithValue, extra: { inject } }) => {
    try {
      const unitService = inject(UnitService);

      return await unitService.getUnitTypes(dto);
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const printPolicyVersionEmployeesFile = createAsyncThunk<
  void,
  GetPolicyVersionEmployeesDto,
  ThunkExtra
>(
  'policy/printPolicyVersionEmployeesFile',
  async (
    getPolicyVersionEmployeesDto,
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const policyDetailService = inject(PolicyDetailService);
      const file = await policyDetailService.getPolicyVersionEmployeesFile(
        getPolicyVersionEmployeesDto,
      );

      await inject(PrintService).print([file]);
    } catch (err) {
      dispatch(showError('При печати документов произошла ошибка'));
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const getPolicyDetail = createAsyncThunk<
  Policy,
  GetPolicyDetailDto,
  ThunkExtra
>(
  'policy/getPolicyDetail',
  async (
    getPolicyDetailDto,
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      const history = inject<History>(History);
      const policyService = inject(PolicyDetailService);
      const queryService = inject(QueryService);
      const mapper = inject(PolicyDetailMapper);

      const result = await policyService.get(getPolicyDetailDto);

      const filters = queryService.parse(history.location.search);

      if (!filters.versionId) {
        filters.versionId = mapper.findActiveVersion(result.versions);
      }

      if (
        !result.versions.find(
          (version) => version.policy_version_id === filters.versionId,
        )
      ) {
        throw new AppError('client', {
          code: 400,
          message: 'Bad Request',
          error: 'Bad Request',
        });
      }

      const getPolicyVersionEmployeesDto = plainToClass(
        GetPolicyVersionEmployeesDto,
        {
          ...filters,
          id: getPolicyDetailDto.id,
        },
      );

      dispatch(getPolicyVersionEmployees(getPolicyVersionEmployeesDto));

      return result;
    } catch (err) {
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const updatePolicyVersion = createAsyncThunk<
  void,
  {
    values: UpdatePolicyVersionDto;
    actions: {
      resolve: (value: unknown) => void;
      onError: () => void;
    };
  },
  ThunkExtra
>(
  'policy/updatePolicyVersion',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(PolicyDetailService).updatePolicyVersion(values);

      actions.resolve(null);

      dispatch(getPolicyDetail({ id: values.id }));
      dispatch(showNotification('Версия документа была изменена'));
    } catch (err) {
      actions.resolve({
        [FORM_ERROR]:
          (err instanceof AppError && err.description) ||
          'Упс! Что-то пошло не так',
      });
      actions.onError();
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const createPolicyVersion = createAsyncThunk<
  void,
  {
    values: CreatePolicyVersionDto;
    actions: {
      resolve: (value: unknown) => void;
      onError: () => void;
    };
  },
  ThunkExtra
>(
  'policy/createPolicyVersion',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(PolicyDetailService).createPolicyVersion(values);

      actions.resolve(null);

      dispatch(getPolicyDetail({ id: values.id }));
      dispatch(showNotification('Добавлена новая версия документа'));
    } catch (err) {
      actions.resolve({
        [FORM_ERROR]:
          (err instanceof AppError && err.description) ||
          'Упс! Что-то пошло не так',
      });
      actions.onError();
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const deletePolicyVersion = createAsyncThunk<
  void,
  {
    values: DeletePolicyVersionDto;
    actions: {
      resolve: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'policy/deletePolicyVersion',
  async (
    { values, actions },
    { rejectWithValue, dispatch, getState, extra: { inject } },
  ) => {
    try {
      const router = inject<PolicyRouter>(PolicyRouter);
      const state = getState().policy.detail;
      await inject(PolicyDetailService).deletePolicyVersion(values);

      actions.resolve(null);

      if (state.entity && state.entity.versions.length > 1) {
        dispatch(getPolicyDetail({ id: values.id }));
        dispatch(showNotification('Версия документа была удалена'));
        router.goToDetail(values.id);
      } else {
        dispatch(showNotification('Документ был удален'));
        router.goToList();
      }
    } catch (err) {
      actions.resolve({
        [FORM_ERROR]:
          (err instanceof AppError && err.description) ||
          'Упс! Что-то пошло не так',
      });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const updatePolicy = createAsyncThunk<
  void,
  {
    values: UpdatePolicyDto;
    actions: {
      resolve: (value: unknown) => void;
      reject: (value: unknown) => void;
    };
  },
  ThunkExtra
>(
  'policy/updatePolicy',
  async (
    { values, actions },
    { rejectWithValue, dispatch, extra: { inject } },
  ) => {
    try {
      await inject(PolicyDetailService).updatePolicy(values);

      actions.resolve(null);

      dispatch(getPolicyDetail({ id: values.id }));
      dispatch(showNotification('Изменения сохранены'));
    } catch (err) {
      actions.reject({ [FORM_ERROR]: err });
      return rejectWithValue(classToPlain(err) as AppError);
    }
  },
);

export const policyDetailReducers = (
  builder: ActionReducerMapBuilder<PolicyState>,
) => {
  builder.addCase(getPolicyDetail.pending, (state) => {
    startLoading(state);
  });
  builder.addCase(getPolicyDetail.fulfilled, (state, { payload }) => {
    completeLoading(state);

    state.detail.entity = payload;
  });
  builder.addCase(getPolicyDetail.rejected, (state, { payload, error }) => {
    setError(state, { payload, error });
  });

  builder.addCase(getPolicyVersionEmployees.pending, (state) => {
    state.detail.employees.entities = [];
    state.detail.employees.status = 'loading';
    state.detail.employees.error = null;
    state.detail.employees.hasMore = true;
  });
  builder.addCase(
    getPolicyVersionEmployees.fulfilled,
    (state, { payload, meta }) => {
      state.detail.employees.status = 'complete';
      state.detail.employees.error = null;
      state.detail.employees.entities = payload.employees;
      state.detail.employees.hasMore =
        payload.employees.length >= meta.arg.limit;
    },
  );
  builder.addCase(
    getPolicyVersionEmployees.rejected,
    (state, { payload, error, meta }) => {
      if (!meta.aborted) {
        state.detail.employees.status = 'failed';
        state.detail.employees.hasMore = true;
        state.detail.employees.error =
          payload ||
          ({
            info: (error && error.message) || 'Unknown error',
            status: 500,
            source: 'client',
            title: 'Internal client error',
          } as AppError);
      }
    },
  );
  builder.addCase(printPolicyVersionEmployeesFile.pending, (state) => {
    state.printPolicy.status = 'loading';
    state.printPolicy.error = null;
  });
  builder.addCase(printPolicyVersionEmployeesFile.fulfilled, (state) => {
    state.printPolicy.status = 'complete';
    state.printPolicy.error = null;
  });
  builder.addCase(
    printPolicyVersionEmployeesFile.rejected,
    (state, { payload, error }) => {
      state.printPolicy.status = 'failed';
      state.printPolicy.error =
        payload ||
        ({
          info: (error && error.message) || 'Что-то пошло не так',
          status: 500,
          source: 'client',
          title: 'Internal client error',
        } as AppError);
    },
  );
  builder.addCase(
    getAdditionalPolicyVersionEmployees.fulfilled,
    (state, { payload, meta }) => {
      state.detail.employees.status = 'complete';
      state.detail.employees.error = null;
      state.detail.employees.hasMore =
        payload.employees.length >= meta.arg.limit;
      state.detail.employees.entities = [
        ...state.detail.employees.entities,
        ...payload.employees,
      ];
    },
  );
  builder.addCase(getPolicyUnitTypes.fulfilled, (state, { payload }) => {
    state.detail.unitTypes = payload;
  });
};
